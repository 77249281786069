import { computed, onBeforeUnmount, onMounted } from 'vue';
import store from '@/store';
import {
  CREATE_DELIVERY_PROFILE,
  GET_DELIVERY_PROFILES,
  GET_TABLE_AMOUNT_OF_PAGES,
  GET_TABLE_CURRENT_PAGE,
  LOAD_DELIVERY_PROFILES,
  REMOVE_DELIVERY_PROFILE,
  SET_TABLE_CURRENT_PAGE,
} from '@/store/Shipping/constants';
import { scrollToTop } from '@/utils';

const useShipping = () => {
  const deliveryProfiles = computed(() => store.getters[GET_DELIVERY_PROFILES]);
  const currentPage = computed(() => store.getters[GET_TABLE_CURRENT_PAGE]);
  const amountOfPages = computed(() =>
    store.getters[GET_TABLE_AMOUNT_OF_PAGES]);
  const isShowPagination = computed(() => amountOfPages.value > 0);

  const handlePageChange = (page: number): void => {
    if (page === currentPage.value) return;
    store.commit(SET_TABLE_CURRENT_PAGE, page);
    loadDeliveryProfiles();
  };

  const removeDeliveryProfile = (id: number) =>
    store.dispatch(REMOVE_DELIVERY_PROFILE, id);
  
  const createDeliveryProfile = (name: string) =>
    store.dispatch(CREATE_DELIVERY_PROFILE, name);
  
  const loadDeliveryProfiles = async () => {
    await store.dispatch(LOAD_DELIVERY_PROFILES, {
      limit: 8,
      page: currentPage.value,
    });
    scrollToTop();
  };

  onMounted(loadDeliveryProfiles);
  onBeforeUnmount(() => store.commit(SET_TABLE_CURRENT_PAGE, 1));

  return {
    amountOfPages,
    createDeliveryProfile,
    currentPage,
    deliveryProfiles,
    handlePageChange,
    isShowPagination,
    removeDeliveryProfile,
  };
};

export default useShipping;
