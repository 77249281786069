import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRef,
  watchEffect,
} from 'vue';
import { white50 } from '@/constants/colors';
import router from '@/router/index';
import { GET_IS_MOBILE } from '@/store/Editor/constants';
import store from '@/store/index';
import {
  CLEAR_TEMPLATES,
  EDIT_TEMPLATE_NAME,
  GET_CURRENT_TEMPLATE,
  LOAD_CURRENT_TEMPLATE,
  SAVE_EDITED_TEMPLATE,
  SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
} from '@/store/Templates/constants';
import {
  maxLength,
  requiredField,
} from '@/utils/FieldValidationRules/index';

const useHeader = (props, { emit }) => {
  const isTemplateSaving = ref(false);
  const templateId = Number(router.currentRoute.params.templateId);
  const template = computed(() => store.getters[GET_CURRENT_TEMPLATE]);
  const templateName = ref(template.value?.name);
  const isNameEditing = ref(false);
  const templateNameRules = [ requiredField, maxLength(40) ];
  const isValidName = ref(false);
  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);

  const initTemplate = async (): Promise<void> =>
    await store.dispatch(LOAD_CURRENT_TEMPLATE, templateId);

  watchEffect((): boolean => templateName.value = template.value?.name);

  const toggleIsNameEditing = (): void => {
    templateName.value = template.value.name;
    isNameEditing.value = !isNameEditing.value;
  };

  const saveEditedName = async (): Promise<void | boolean> => {
    if (!isValidName.value) return;
    if (template.value.name === templateName.value.trim())
      return toggleIsNameEditing();
    
    await store.dispatch(
      EDIT_TEMPLATE_NAME,
      {
        id: template.value.id,
        name: templateName.value.trim(),
      },
    );
    toggleIsNameEditing();
  };

  const setIsValidName = (isValid: boolean) => {
    isValidName.value = isValid;
  };

  const setTemplateName = (name: string) => {
    templateName.value = name;
  };
  
  const clearTemplates = (): Promise<void> => store.dispatch(CLEAR_TEMPLATES);

  const saveTemplate = async (): Promise<void> => {
    isTemplateSaving.value = true;
    await store.dispatch(SAVE_EDITED_TEMPLATE);
    isTemplateSaving.value = false;
  };

  const openAddToMyCollectionModal = (): void => {
    store.commit(SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN, true);
  };

  onMounted(initTemplate);
  onBeforeUnmount(clearTemplates);

  const headerProps = reactive({
    canvas: toRef(props, 'canvas'),
    isNameEditing,
    isPublic: toRef(props, 'isPublic'),
    isLoading: toRef(props, 'isLoading'),
    isSaving: isTemplateSaving.value,
    isScanLoading: toRef(props, 'isScanLoading'),
    isShopifyLoading: toRef(props, 'isShopifyLoading'),
    isValidName,
    templateName,
    templateNameRules,
    white50,
  });

  const headerEmits = {
    getScan: () => emit('getScan', 'file'),
    openAddToMyCollectionModal,
    save: () => emit('save'),
    saveEditedName,
    saveTemplate,
    sendToShopifyApp: () => emit('sendToShopify'),
    setIsValidName,
    setTemplateName,
    toggleIsNameEditing,
  };
	
  return {
    headerEmits,
    headerProps,
    isMobile,
  };
};

export default useHeader;
