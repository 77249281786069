import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import { REMOVE_COLLECTION } from '@/store/Collections/constants';
import { Collection } from '@/store/Collections/types';

const useConfirmRemoveCollectionModal = (props: {
  collection: Collection
}, { emit }) => {
  const removeCollection = async (): Promise<void> => {
    await store.dispatch(REMOVE_COLLECTION, props.collection);
    emit('triggerInitCollections');
    emit('close');
  };
  
  return {
    primaryViolet,
    removeCollection,
  };
};

export default useConfirmRemoveCollectionModal;
