import { onMounted, onUnmounted, Ref } from 'vue';

export const useResizeListener = (
  editorContainer: Ref<HTMLElement | undefined>,
): void => {
  const resizeEditorContainer = () => {
    if (!editorContainer.value) return;
    editorContainer.value.style.height = `${document.documentElement.clientHeight}px`;
  };

  onMounted(() => {
    window.addEventListener('resize', resizeEditorContainer);
    if (!editorContainer.value) return;
    editorContainer.value.style.height = `${window.innerHeight}px`;
  });
  onUnmounted(() => {
    window.removeEventListener('resize', resizeEditorContainer);
  });
};
