<template>
  <MainLayout>
    <div
      v-if="order"
      class="order"
    >
      <div class="order__breadcrumbs">
        <router-link
          :to="{ name: 'orders' }"
          active-class="order__breadcrumbs-link"
        >
          {{ $t('orders.orders__title') }}
        </router-link>
        / #{{ order.id }}
      </div>
      <h1>#{{ order.id }}</h1>
      <div class="order__details">
        <BaseTable
          :headers="tableHeaders"
          :items="order.products"
          class="order__table"
        >
          <template #content="{ item: product }">
            <td>
              <div class="order__product">
                <img
                  class="order__product-image"
                  :src="product.template.imagePath"
                >
                <div>
                  {{ product.template.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="order__product">
                {{ $t('orders.quantity__text') }}: {{ product.quantity }}
              </div>
            </td>
            <td>
              <a
                :href="product.scanLink"
                class="order__product-pdf"
              >
                <FileIcon />
                <span class="order__product-pdf-link">PDF</span>
              </a>
            </td>
          </template>
        </BaseTable>
        <OrderInfoCard v-bind="orderDetails" />
      </div>
    </div>
  </MainLayout>
</template>
<script lang="ts">
import BaseTable from '@/components/Common/BaseTable/BaseTable.vue';
import FileIcon from '@/components/icons/FileIcon.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import OrderInfoCard from '../components/OrderInfoCard/OrderInfoCard.vue';
import { useSingleOrder } from './SingleOrder';
export default {
  components: {
    MainLayout,
    FileIcon,
    OrderInfoCard,
    BaseTable,
  },
  setup() {
    return useSingleOrder();
  },
};
</script>
<style lang="scss" scoped>
  @import './SingleOrder.scss';
</style>
