import { computed, DefineComponent, ref, watch } from 'vue';

import { TranslateResult } from 'vue-i18n';
import { useI18n } from 'vue-i18n-composable';
import InfoIcon from '@/assets/images/info-icon.png';
import { primaryViolet } from '@/constants/colors';
import { Tokens } from '@/constants/tokens';
import router from '@/router/index';
import store from '@/store/index';
import {
  isPasswordStrong,
  maxLength,
  maxLengthEmail,
  requiredField,
  validEmail,
} from '@/utils/FieldValidationRules/index';
import { isMobile } from '@/utils/Mobile';

const useSignUpForm = () => {
  const { t } = useI18n();
  const form = ref();
  const email = ref('');
  const lastName = ref('');
  const password = ref('');
  const firstName = ref('');
  const isValidForm = ref(true);
  const repeatPassword = ref('');
  const isShowPassword = ref(false);
  const agreeWithTerms = ref(false);
  const isShowRepeatPassword = ref(false);
  const isModalOpen = ref(false);

  const storeConnectionToken = computed(
    (): string =>
      router.currentRoute.query[Tokens.connectionToken] as string,
  );

  const passwordConfirmation = (v: string): boolean | TranslateResult => {
    return (!!v && v) === password.value
    || t('validationRules.passwordsDoNotMatch__text');
  };

  const textRules = [ requiredField, maxLength(40) ];
  const emailRules = [ requiredField, validEmail, maxLengthEmail ];
  const passwordRules = [
    requiredField,
    isPasswordStrong,
    passwordConfirmation,
    maxLength(127),
  ];

  const signUp = () => {
    store.dispatch('SIGN_UP', {
      email: email.value,
      forename: firstName.value,
      password: password.value,
      storeConnectionToken: storeConnectionToken.value,
      surname: lastName.value,
    });
  };

  watch([ password, repeatPassword ], () => {
    form.value.inputs.forEach((element: DefineComponent): void => {
      if (element.$options.propsData.rules.includes(passwordConfirmation)) {
        element.validate();
      }
    });
  });

  return {
    form,
    agreeWithTerms,
    email,
    emailRules,
    firstName,
    InfoIcon,
    isMobile,
    isModalOpen,
    isShowPassword,
    isShowRepeatPassword,
    isValidForm,
    lastName,
    password,
    passwordRules,
    primaryViolet,
    repeatPassword,
    signUp,
    textRules,
  };
};

export default useSignUpForm;
