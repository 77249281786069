<template>
  <div class="form__selector">
    <p 
      v-if="selectedCollection"
      class="title"
    >
      {{ $t('addToMyCollectionsModal.collectionName__placeholder') }}:
    </p>
    <p 
      v-else
      class="title_small"
    >
      {{ $t('addToMyCollectionsModal.collectionNotSelected__placeholder') }}
    </p>
    <div
      v-if="selectedCollection"
      class="collection"
    >
      <span class="collection__name">
        {{ selectedCollection.name }}
      </span>
      <div
        class="select-button"
        @click.stop.prevent="$emit('toggleCollectionsSelector')"
      >
        {{ $t('profile.change__label') }}
      </div>
    </div>
    <div
      v-else
      class="select-button"
      @click.stop.prevent="$emit('toggleCollectionsSelector')"
    >
      {{ $t('addToMyCollectionsModal.selectCollection__label') }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    selectedCollection: {
      required: false,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'FormSelectorMobile';
</style>
