<template>
  <div
    :class="{'is-shopify-page': isShopifyPage}"
  >
    <ChangeModeNoteMobile
      v-if="isMobile"
      v-bind="changeModeNoteProps"
      v-on="changeModeNoteEmits" 
    />
    <ChangeModeNoteDesktop
      v-else
      v-bind="changeModeNoteProps"
      v-on="changeModeNoteEmits" 
    />
  </div>
</template>

<script lang="ts">
import useChangeModeNote from '@/components/Editor/ChangeModeNote/ChangeModeNote';
import ChangeModeNoteDesktop from './ChangeModeNoteDesktop/ChangeModeNoteDesktop.vue';
import ChangeModeNoteMobile from './ChangeModeNoteMobile/ChangeModeNoteMobile.vue';

export default {
  components: {
    ChangeModeNoteDesktop,
    ChangeModeNoteMobile,
  },
  props: {
    isShopifyPage: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useChangeModeNote();
  },
};
</script>

<style lang="scss" scoped>
@import "ChangeModeNote";
</style>
