<template>
  <div class="user-guide-page">
    <h1 class="user-guide-page__title">
      {{ title }}
    </h1>
    <div class="user-guide-page__content">
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.shopifyIntegrationPage.onceYouInstall__Text') }}
      </p>
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.shopifyIntegrationPage.onceTheProductIsSaved__text') }}
      </p>
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.shopifyIntegrationPage.addBlankProducts__text') }}
      </p>
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.shopifyIntegrationPage.importantThing__text') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>
