<template>
  <TextSettingsMobile
    v-if="isMobile"
    v-bind="textSettingsProps"
    v-on="textSettingsEmits"
  />
  <TextSettingsDesktop
    v-else
    v-bind="textSettingsProps"
    v-on="textSettingsEmits"
  />
</template>

<script lang="ts">
import useTextSettings from './TextSettings';
import TextSettingsDesktop from './TextSettingsDesktop/TextSettingsDesktop.vue';
import TextSettingsMobile from './TextSettingsMobile/TextSettingsMobile.vue';

export default {
  components: {
    TextSettingsDesktop,
    TextSettingsMobile,
  },
  props:{
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return useTextSettings(props, context);
  },
};
</script>
