import { ref } from 'vue';
import { primaryViolet } from '@/constants/colors';
import { Collection } from '@/store/Collections/types';

const useCollectionsSelectorMobile = (props: {
  selectedCollection: Collection
}, { emit }) => {
  const currentCollection = ref(props.selectedCollection);

  const selectCollection = (collection: Collection): void => {
    currentCollection.value = collection;
  };

  const updateSelectedCollection = (): void => {
    emit('changeSelectedCollection', currentCollection.value);
    emit('close');
  };

  return {
    currentCollection,
    primaryViolet,
    selectCollection,
    updateSelectedCollection,
  };
};

export default useCollectionsSelectorMobile;
