import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { MeshData } from '@/components/Editor/types/editor';
import { FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_ACTIVE_MESH,
  GET_BACKGROUND_COLOR,
  GET_FABRIC_OBJECTS,
} from '@/store/Editor/constants';
import { CanvasDataByMesh } from '@/store/Editor/types';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';

interface CanvasDataToUpdate {
  activeMesh: MeshData,
  backgroundColor: string,
  canvasDataByMesh: CanvasDataByMesh[],
  fabricObjects: FabricObject[],
}

const getFabricObjectsWithoutBackground = (
  fabricObjects: FabricObject[],
): FabricObject[] => {
  return fabricObjects.filter((object: FabricObject): boolean => {
    return object.id !== BACKGROUND_OBJECT_ID;
  }).map((obj, index) => ({ ...obj, layerNumber: index })) as FabricObject[];
};

const createCanvasData = ({
  activeMesh,
  backgroundColor,
  fabricObjects,
}: CanvasDataToUpdate): CanvasDataByMesh[] => {
  return [{
    meshName: activeMesh.name,
    backgroundColor,
    canvasData: getFabricObjectsWithoutBackground(fabricObjects),
  }];
};

const updateCanvasData = ({
  activeMesh,
  backgroundColor,
  canvasDataByMesh,
  fabricObjects,
}: CanvasDataToUpdate): CanvasDataByMesh[] => {
  const canvasDataIndex = canvasDataByMesh.findIndex(
    (canvasData: CanvasDataByMesh): boolean => {
      return canvasData.meshName === activeMesh.name;
    },
  );

  if (canvasDataIndex !== -1) {
    canvasDataByMesh[canvasDataIndex] = {
      backgroundColor,
      meshName: activeMesh.name,
      canvasData: getFabricObjectsWithoutBackground(fabricObjects),
    };
  } else {
    canvasDataByMesh.push(...createCanvasData({
      backgroundColor,
      activeMesh,
      fabricObjects,
      canvasDataByMesh,
    }));
  }

  return canvasDataByMesh;
};

export const updateCanvasDataByMesh = (): CanvasDataByMesh[] => {
  const { canvasData: canvasDataByMesh } = store.getters[GET_CURRENT_TEMPLATE];
  const activeMesh = store.getters[GET_ACTIVE_MESH];
  const backgroundColor = store.getters[GET_BACKGROUND_COLOR];
  const fabricObjects = store.getters[GET_FABRIC_OBJECTS];

  const canvasDataToUpdate = {
    backgroundColor,
    activeMesh,
    fabricObjects,
    canvasDataByMesh,
  };

  return canvasDataByMesh?.length
    ? updateCanvasData(canvasDataToUpdate)
    : createCanvasData(canvasDataToUpdate);
};
