import { AxiosResponse } from 'axios';
import { FabricObject } from '@/components/Editor/types/fabric';
import { TemplateDesign } from '@/store/Editor/types';
import { instance } from '@/utils/axios/instance';

export const TemplateDesignAPI = { 

  getTemplateDesigns: async (
    { name }:{ name?: TemplateDesign['name'] },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get('/template-designs', {
        params: {
          name,
        },
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  createTemplateDesign: async (
    { background, img, name, objects }: {
      background: string,
      img: File,
      name: string,
      objects: FabricObject[],
    },
  ): Promise<AxiosResponse> => {
    try {
      const formData = new FormData();
      formData.append('img', img);
      formData.append('background', background);
      formData.append('name', name);
      formData.append('objects', JSON.stringify(objects));

      const response = await instance.post(
        '/template-designs',
        formData,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  editTemplateDesign: async (
    { name, templateDesignId }: {
      name: TemplateDesign['name'],
      templateDesignId: TemplateDesign['id'],
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.patch(
        `/template-designs/${templateDesignId}`,
        { name },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  deleteTemplateDesign: async (
    { templateDesignId }: {
      templateDesignId: TemplateDesign['id'],
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(
        `/template-designs/${templateDesignId}`,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
