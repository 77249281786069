import { SortItems } from '@/types';

export interface Collection {
    createdAt: string;
    id: number | string;
    imagePath: string;
    isPublic: boolean;
    name: string;
    updatedAt: string;
    userId: number;
}

export interface CollectionState {
    amountOfPages: number;
    isCollectionSaving: boolean;
    isCollectionsLoading: boolean;
    isCoverImageLoading: boolean;
    isEditingRights: boolean;
    localCollections: Collection[];
    selectedSort: SortItems;
}

type payloadType = number | string | object | boolean 
    | (number | string | object)[];

export interface Context {
    commit: (type: string, payload?: payloadType) => void;
    dispatch: (type: string, payload: payloadType) => void;
    state: CollectionState;
}

export enum CollectionFields {
    id = 'id',
    name = 'name',
    isPublic = 'isPublic',
    userId = 'userId',
}
