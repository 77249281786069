<template>
  <v-expansion-panels
    class="accordion"
    accordion
    flat
  >
    <slot />
  </v-expansion-panels>
</template>
<script>
export default {
  
};
</script>
<style lang="scss" scoped>
  @import 'BaseAccordion.scss';
</style>
