import { WeightUnits } from '@/constants/units';

export enum WeightOperators {
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}

export interface WeightCondition {
  createdAt: string;
  deliveryRateId: number;
  id: number;
  operator: WeightOperators,
  unit: WeightUnits;
  updatedAt: string;
  value: number;
}

export interface Rate {
  createdAt: string;
  currency: string;
  deliveryZoneId: number;
  id: number;
  name: string;
  price: string;
  updatedAt: string;
  weightConditions: WeightCondition[];
}

export type CreateRate = 
  | Pick<Rate, 'name' | 'price'> 
  & Partial<Pick<Rate, 'currency'>>
  & { weightConditions: Pick<WeightCondition, 'unit' | 'value' |'operator'>[] }

export interface Country {
  code: string;
  code3: string;
  createdAt: string;
  id: number;
  name: string;
  regionId: number;
  updatedAt: string;
}

export interface Region {
  countries: Country[];
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export interface ShippingZone {
  countries: Country[];
  createdAt: string;
  deliveryProfileId: number;
  id: number;
  name: string;
  rates: Rate[];
  updatedAt: string;
}

export interface DeliveryProfile {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
  zones: ShippingZone[];
}

export interface ShippingState {
  amountOfPages: number;
  currentDeliveryProfile: DeliveryProfile | null;
  currentPage: number;
  deliveryProfiles: DeliveryProfile[];
  isLoading: boolean;
  shippingRegions: Region[]
}

type payloadType = number
    | string 
    | object
    | boolean 
    | (number | string | object)[];

export interface Context {
  commit: (type: string, payload: payloadType) => void;
  dispatch: (type: string, payload: payloadType) => void;
  state: ShippingState;
}
