import { AxiosResponse } from 'axios';
import { Order } from '@/store/Orders/types';
import { instance } from '@/utils/axios/instance';

export const OrdersAPI = {
  getOrders: async ({ limit = 8, page = 1 }): Promise<AxiosResponse> => {
    try {
      const response = await instance.get('/orders', {
        params: { limit, page },
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getAllOrders: async ({ limit = 8, page = 1 }): Promise<AxiosResponse> => {
    try {
      const response = await instance.get('/orders/all', {
        params: { limit, page },
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getSingleOrder: async (orderId: Order['id']): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/orders/${orderId}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
