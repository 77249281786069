<template>
  <div class="authorization-background">
    <img
      class="authorization-background__background"
      :src="authBackgroundImage"
      :alt="$t('authBackground.backgroundImage__alt')"
    >
    <img
      class="authorization-background__image"
      :src="logoImg"
      :alt="$t('header.logoImage__alt')"
    >
    <p class="authorization-background__text">
      Your<br>
      limitation<br>
      it's only your<br>
      imagination
    </p>
  </div>
</template>

<script lang="ts">
import useAuthBackground from './AuthBackground';

export default {
  setup() {
    return useAuthBackground();
  },
};
</script>

<style lang="scss" scoped>
@import 'AuthBackground';
</style>
