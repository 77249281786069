import { computed, ref } from 'vue';
import { Tokens } from '@/constants/tokens';
import router from '@/router';
import { SIGN_IN } from '@/store/Authorization/constants';
import store from '@/store/index';
import {
  maxLength,
  maxLengthEmail,
  minLength,
  requiredField,
  validEmail,
} from '@/utils/FieldValidationRules/index';
import { isMobile } from '@/utils/Mobile';

const emailRules = [ requiredField, validEmail, maxLengthEmail ];
const passwordRules = [ requiredField, minLength(8), maxLength(127) ];

const useSignInForm = () => {
  const email = ref('');
  const password = ref('');
  const isValidForm = ref(false);

  const storeConnectionToken = computed(
    (): string =>
      router.currentRoute.query[Tokens.connectionToken] as string,
  );

  const logIn = () => {
    store.dispatch(SIGN_IN, {
      data: {
        email: email.value,
        password: password.value,
        storeConnectionToken: storeConnectionToken.value,
      },
      type: 'default',
    });
  };

  return {
    email,
    emailRules,
    isMobile,
    isValidForm,
    logIn,
    password,
    passwordRules,
    storeConnectionToken,
  };
};

export default useSignInForm;
