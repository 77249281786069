import { computed, reactive, ref, toRef } from 'vue';
import { Route } from 'vue-router';
import router from '@/router';
import { GET_USER } from '@/store/Authorization/constants';
import { User } from '@/store/Authorization/types';
import { SEARCH_COLLECTIONS } from '@/store/Collections/constants';
import { Collection } from '@/store/Collections/types';
import { SET_IS_SHOW_SEARCH_FIELD } from '@/store/constants';
import store from '@/store/index';
import { Template } from '@/store/Templates/types';
import type { SearchFoundProducts } from '@/types';
import { maxLength } from '@/utils/FieldValidationRules';
import { isTabletAndLower } from '@/utils/Mobile';
import { CollectionRouteTypes, CollectionTypes } from '@/views/Collections/types';

const useSearchField = (props: { isShowSearch: boolean }, { emit }) => {
  const isSearchOpen = toRef(props, 'isShowSearch');
  const search = ref('');
  const searchMaxLengthRule = maxLength(100);
  const searchRules = [searchMaxLengthRule];
  const isSearchValid = ref(true);
  const isLoading = ref(false);
  const foundProducts = reactive<SearchFoundProducts>({
    isEmpty: true,
    myCollections: [],
    myTemplates: [],
    notFound: false,
    publicCollections: [],
    publicTemplates: [],
  });
  const user = computed((): User => store.getters[GET_USER]);
  const getElementsToIgnoreClickOutside = (): (Element | null)[] => {
    const burgerMenuElementClass = document.querySelector('.burger-menu');
    const burgerMenuElementId = document.querySelector('#burger-menu');
    const ignorableElements: (Element | null)[] = [];
    if (burgerMenuElementClass) ignorableElements.push(burgerMenuElementClass);
    if (burgerMenuElementId) ignorableElements.push(burgerMenuElementId);
    return ignorableElements;
  };
    
  const closeSearch = (): void => {
    search.value = '';
    foundProducts.isEmpty = true;
    emit('closeSearch');
  };

  const onSearchInput = (newSearchValue: string): void => {
    if (typeof searchMaxLengthRule(newSearchValue) !== 'string')
      search.value = newSearchValue;
  };

  const findProduct = async (): Promise<void | boolean> => {
    if (!isSearchValid.value) return;
    clearFoundProducts();
    if (!search.value) return foundProducts.isEmpty = true;
    isLoading.value = true;
    const { collections, templates } =
      await store.dispatch(SEARCH_COLLECTIONS, search.value);
    collections.map((collection): void => {
      if (collection.isPublic)
        foundProducts.publicCollections.push(collection);
      if (collection.userId === user.value.id)
        foundProducts.myCollections.push(collection);
    });
    templates.map((template): void => {
      if (template.collection.isPublic)
        foundProducts.publicTemplates.push(template);
      if (template.collection.userId === user.value.id)
        foundProducts.myTemplates.push(template);
    });
    foundProducts.isEmpty = false;
    if (
      !foundProducts.publicCollections.length &&
      !foundProducts.myCollections.length &&
      !foundProducts.publicTemplates.length &&
      !foundProducts.myTemplates.length
    ) foundProducts.notFound = true;
    isLoading.value = false;
  };

  const isShowSearchList = computed((): boolean =>
    !!search.value && isSearchOpen.value);

  const clearFoundProducts = (): void => {
    foundProducts.isEmpty = true;
    foundProducts.myCollections = [];
    foundProducts.myTemplates = [];
    foundProducts.notFound = false;
    foundProducts.publicCollections = [];
    foundProducts.publicTemplates = [];
  };

  const redirectToCollection = (
    id: number,
    isPublic: boolean,
  ): Promise<Route> | void => {
    const fromQuery = isPublic
      ? CollectionTypes.publicCollections
      : CollectionTypes.privateCollections;
    const { name, query } = router.currentRoute;
    const newRouteName = isPublic ? 'public-collections/templates' : 'my-collections/templates';
    const isCurrentPage = query.from === fromQuery && name === newRouteName;
    if (isCurrentPage) return;

    return router.push({
      name: newRouteName,
      params: {
        collectionId: id.toString(),
      },
      query: {
        ...query,
        from: fromQuery,  
      },
    });
  };

  const redirectToTemplate = (
    collectionId: number, 
    templateId: number,
  ): Promise<Route> =>
    router.push(`/${CollectionRouteTypes.privateCollections}/${collectionId}/templates/${templateId}/editor`);
  
  const handleRedirect = (
    item: Collection & Template,
    isPublicCollections: boolean,
  ): void => {
    (document.activeElement as HTMLInputElement).blur();
    const isPublic = item.isPublic && isPublicCollections;
    setTimeout(() => {
      item.collectionId
        ? redirectToTemplate(item.collectionId, item.id)
        : redirectToCollection(item.id, isPublic);   
    }, 300);
    store.commit(SET_IS_SHOW_SEARCH_FIELD, false);
  };

  const searchListProps = reactive({
    foundProducts,
    isSearchOpen,
    search,
  });

  const searchListEmits = {
    handleRedirect,
    redirectToCollection,
    redirectToTemplate,
  };

  return {
    closeSearch,
    findProduct,
    foundProducts,
    getElementsToIgnoreClickOutside,
    handleRedirect,
    isLoading,
    isSearchOpen,
    isShowSearchList,
    isTabletAndLower,
    redirectToCollection,
    redirectToTemplate,
    search,
    searchListEmits,
    searchListProps,
    searchRules,
    isSearchValid,
    onSearchInput,
  };
};

export default useSearchField;
