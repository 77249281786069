import { computed, onMounted, ref, watch } from 'vue';
import { useForm } from '@/composables/useForm';
import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import { GET_DELIVERY_PROFILES, LOAD_ALL_DELIVERY_PROFILES } from '@/store/Shipping/constants';
import { DeliveryProfile } from '@/store/Shipping/types';
import {
  GET_CURRENT_TEMPLATE,
  LOAD_CURRENT_TEMPLATE,
  UPDATE_TEMPLATE_DETAILS,
} from '@/store/Templates/constants';
import { Template } from '@/store/Templates/types';
import { isGreater, isPriceField, isWeightField, maxLength, requiredField } from '@/utils/FieldValidationRules';
import { EditDetailsForm } from './types';

const useEditDetailsWindow = (
  props: {
    isOpen: boolean;
    templateId: number;
  },
  { emit },
) => {
  const initialForm: EditDetailsForm = {
    description: '',
    name: '',
    price: 0,
    shippingMethod: 0,
    sku: '',
    weight: 0,
  };

  const {
    form: editDetailsForm,
    hasChanged,
    update: updateForm,
  } = useForm<EditDetailsForm>(initialForm, { changeCheck: true });
  const template = computed(
    (): Template => store.getters[GET_CURRENT_TEMPLATE],
  );
  const deliveryProfiles = computed(
    (): DeliveryProfile[] => store.getters[GET_DELIVERY_PROFILES],
  );
  const isValidForm = ref(false);
  
  const rules = {
    name: [maxLength(40)],
    sku: [maxLength(40)],
    description: [maxLength(300)],
    price: [ isPriceField, isGreater(0) ],
    weight: [ requiredField, isWeightField ],
    requiredField: [requiredField],
  };

  const menuSettings = {
    bottom: true,
    color: primaryViolet,
    rounded: 'lg',
  };

  watch(template, () => {
    const { productDetails } = template.value;
    const {
      deliveryProfileId,
      description,
      price,
      sku,
      weight,
    } = productDetails;
    updateForm({
      description,
      name: template.value.name,
      price: +price,
      shippingMethod: deliveryProfileId,
      sku,
      weight: +weight,
    }, { overwriteInitial: true });
  });

  onMounted(async () => {
    await store.dispatch(LOAD_CURRENT_TEMPLATE, props.templateId);
    await store.dispatch(LOAD_ALL_DELIVERY_PROFILES);
  });

  const onSubmit = async (): Promise<void> => {
    await store.dispatch(UPDATE_TEMPLATE_DETAILS, {
      id: template.value.id,
      productDetails: {
        deliveryProfileId: editDetailsForm.shippingMethod,
        description: editDetailsForm.description,
        price: editDetailsForm.price,
        sku: editDetailsForm.sku,
        weight: editDetailsForm.weight,
      },
      template: {
        name: editDetailsForm.name,
      },
    });
    emit('close');
  };

  return {
    deliveryProfiles,
    editDetailsForm,
    hasChanged,
    isValidForm,
    menuSettings,
    onSubmit,
    rules,
    primaryViolet,
  };
};

export default useEditDetailsWindow;
