import { DisabledModelParts } from '@/components/Editor/types/editor';
import store from '@/store';
import {
  GET_MESHS,
  SET_SINGLE_EDITABLE_MESH,
} from '@/store/Editor/constants';

export const checkForSingleEditableMesh = (): void => {
  const meshList = store.getters[GET_MESHS];
  const editableMeshs = meshList.filter(
    child =>
      !child.name.includes(DisabledModelParts._disabled) &&
      !child.name.includes(DisabledModelParts._outline) &&
      !child.name.includes(DisabledModelParts.size),
  );
  if (editableMeshs.length > 1) store.commit(SET_SINGLE_EDITABLE_MESH, null);
  if (editableMeshs.length === 1) {
    store.commit(SET_SINGLE_EDITABLE_MESH, editableMeshs[0]);
  }
};
