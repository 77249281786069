import { computed, onMounted, ref, toRef } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { useI18n } from 'vue-i18n-composable';
import defaultBox from '@/assets/images/default-template-plug.png';
import { useStopScroll } from '@/composables/useStopScroll';
import { primaryViolet } from '@/constants/colors';
import router from '@/router/index';
import { CollectionsAPI } from '@/services/CollectionsAPI';
import { Collection } from '@/store/Collections/types';
import store from '@/store/index';
import {
  COPY_TEMPLATE,
  GET_TEMPLATES_SELECTED_SORT,
  INIT_TEMPLATES,
  SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
} from '@/store/Templates/constants';
import { AmountOfCards, SortItems } from '@/types';
import { maxLength, requiredField } from '@/utils/FieldValidationRules';
import { isTabletAndLower } from '@/utils/Mobile';
import { CollectionTypes } from '@/views/Collections/types';

const useAddToMyCollectionsModal = props => {
  const { t } = useI18n();
  const { disableScroll, enableScroll } = useStopScroll();
  const currentCollectionId = +router.currentRoute.params.collectionId;
  const selectedCollection = ref<Collection>();
  const collections = ref([] as Collection[]);
  const template = toRef(props, 'template');
  const newTemplateName = ref(template.value?.name);
  const isCollectionsSelectorOpen = ref(false);
  const templateNameRules = [ requiredField, maxLength(40) ];
  const isValidForm = ref(false);

  const title = computed((): TranslateResult => {
    const fromQuery = router.currentRoute.query.from;
    return fromQuery === CollectionTypes.privateCollections
      ? t('templates.copyToAnotherCollection__text')
      : t('templates.copyToMyCollection__text');
  });

  const toggleCollectionsSelector = (): boolean =>
    isCollectionsSelectorOpen.value = !isCollectionsSelectorOpen.value;

  const previewImage = computed((): string =>
    template.value.imagePath || defaultBox);
  
  const isPreviewImageDisplayed = computed((): boolean =>
    template.value.imagePath === previewImage.value);
  
  const changeSelectedCollection = (collection: Collection): Collection =>
    selectedCollection.value = collection;
  
  const initCollectionList = async (): Promise<void> => {
    disableScroll();
    const { collections: userCollections } =
      await CollectionsAPI.getAllUserCollections({
        selectedSort: SortItems.Alphabetically,
      });
    collections.value = userCollections;
  };

  const copySelectedTemplate = async (): Promise<void> => {
    await store.dispatch(COPY_TEMPLATE, {
      collection: selectedCollection.value,
      newTemplateName: newTemplateName.value,
      template: template.value,
    });
    if (currentCollectionId === selectedCollection.value?.id) {
      store.dispatch(INIT_TEMPLATES, {
        collectionId: currentCollectionId,
        limit: isTabletAndLower
          ? AmountOfCards.mobile
          : AmountOfCards.desktop,
        page: +router.currentRoute.query.page || 1,
        selectedSort: store.getters[GET_TEMPLATES_SELECTED_SORT],
      });
    }
    closeAddToMyCollectionsModal();
  };

  const closeAddToMyCollectionsModal = (): void => {
    enableScroll();
    store.commit(SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN, false);
  };

  const menuSettings = {
    bottom: true,
    color: primaryViolet,
    rounded: 'lg',
  };

  onMounted(initCollectionList);

  return {
    changeSelectedCollection,
    closeAddToMyCollectionsModal,
    collections,
    copySelectedTemplate,
    isCollectionsSelectorOpen,
    isPreviewImageDisplayed,
    isTabletAndLower,
    isValidForm,
    menuSettings,
    newTemplateName,
    previewImage,
    primaryViolet,
    selectedCollection,
    templateNameRules,
    toggleCollectionsSelector,
    title,
    template,
  };
};

export default useAddToMyCollectionsModal;
