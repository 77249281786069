
<template>
  <div
    class="resolution"
  >
    <div class="resolution__indicator">
      <BaseIndicator
        :color="dpiIndicatorColor"
      />
    </div>
    <span class="resolution__text">{{ dpi }}dpi</span>
  </div>
</template>
<script lang="ts">
import BaseIndicator from '@/components/Common/BaseIndicator/BaseIndicator.vue';

export default {
  components: { BaseIndicator },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    dpiIndicatorColor: {
      required: true,
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
  @import "./ResolutionMobile";
</style>
