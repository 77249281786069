<template>
  <div
    ref="scaleControls"
    :class="['editor-scale-controls', {
      'is-shopify-page': isShopifyPage,
      'disabled': isSettingsDisabled,
    }]"
    @click.stop
    @mouseenter="showZoomInOutControls"
    @mouseleave="hideZoomInOutControls"
  >
    <transition-group
      name="controls-transitions"
    >
      <div
        v-if="isZoomInOutVisible"
        key="zoomInControl"
        class="editor-scale-controls__zoom-controls"
        :class="{'editor-scale-controls__zoom-controls_active': zoomInActive}"
        @click.stop="zoomIn"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="ZoomInIconData1"
            :fill="zoomInColor"
          />
          <path
            :d="ZoomInIconData2"
            :fill="zoomInColor"
          />
        </svg>
      </div>
      <div
        v-if="isZoomInOutVisible"
        key="zoomOutControl"
        class="editor-scale-controls__zoom-controls"
        :class="{'editor-scale-controls__zoom-controls_active': zoomOutActive}"
        @click.stop="zoomOut"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="ZoomOutIconData"
            :fill="zoomOutColor"
          />
        </svg>
      </div>
    </transition-group>
    <div
      v-if="!isMobile || currentFabricZoom !== 1"
      class="editor-scale-controls__full-zoom"
      :class="{'editor-scale-controls__full-zoom_active': fullActive}"
      @click.stop="fullZoom"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :d="FullIconData"
          :fill="fullZoomColor"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import useEditorScaleControls from '@/components/Editor/EditorScaleControls/EditorScaleControls';

export default {
  props: {
    currentFabricZoom: {
      default: 1,
      type: Number,
    },
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useEditorScaleControls(props, context);
  },
};
</script>

<style lang="scss" scoped>
@import "EditorScaleControls";
</style>
