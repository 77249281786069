<template>
  <ModalWindow
    :is-open="isOpen"
    content-class="edit-details__modal"
    @close="$emit('close')"
  >
    <div class="edit-details__modal-card">
      <h1 class="card__heading">
        {{ $t('editDetailsWindow.editDetails__text') }}
      </h1>
      <v-form
        v-model="isValidForm"
        class="card__form"
      >
        <div class="card__form-row">
          <TextField
            v-model="editDetailsForm.name"
            :label="$t('editDetailsWindow.name__placeholder')"
            :rules="rules.name"
            input-type="text"
          />
          <TextField
            v-model="editDetailsForm.sku"
            :label="$t('editDetailsWindow.SKU__placeholder')"
            :rules="rules.sku"
            input-type="text"
          />
        </div>
        <div class="card__form-row">
          <BaseSelect
            v-model.number="editDetailsForm.shippingMethod"
            :items="deliveryProfiles"
            item-text="name"
            item-value="id"
            :placeholder="$t('editDetailsWindow.shippingMethod__placeholder')"
            :rules="rules.required"
            :dense="false"
            :menu-props="menuSettings"
            background-color="white"
          />
          <TextField
            v-model.number="editDetailsForm.price"
            :label="$t('editDetailsWindow.price__placeholder')"
            :rules="rules.price"
            input-type="price"
          />
          <TextField
            v-model.number="editDetailsForm.weight"
            :label="$t('editDetailsWindow.weight__placeholder')"
            :rules="rules.weight"
            input-type="number"
          >
            <template #append>
              <v-icon class="gramms-icon">
                g
              </v-icon>
            </template>
          </TextField>
        </div>
        <BaseTextarea
          v-model="editDetailsForm.description"
          :label="$t('editDetailsWindow.description__placeholder')"
          :rules="rules.description"
        />
      </v-form>
      <BaseButton
        large
        class="card__button"
        :button-text="$t('editDetailsWindow.save__text')"
        :color="primaryViolet"
        :disabled="!isValidForm || !hasChanged"
        @click="onSubmit"
      />
    </div>
  </ModalWindow>
</template>

<script>
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import BaseTextarea from '@/components/Common/BaseTextarea/BaseTextarea.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';  
import TextField from '@/components/Common/TextField/TextField.vue';
import useEditDetailsWindow from './EditDetailsWindow';

export default {
  components: {
    BaseButton,
    BaseSelect,
    BaseTextarea,
    ModalWindow,
    TextField,
  },
  props: {
    isOpen: Boolean,
    templateId: Number,
  },
  setup(props, context) {
    return useEditDetailsWindow(props, context);
  },
};
</script>

<style lang="scss">
@import 'EditDetailsWindow';
</style>
