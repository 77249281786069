import { DEFAULT_FONTS } from '@/components/Editor/constants/defaultConfigs';
import {
  DEFAULT_BACKGROUND_COLOR,
} from '@/components/Editor/constants/defaultSettings';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import {
  CanvasHistory,
  CanvasState,
  EditorState,
  HistoryActions,
} from '@/store/Editor/types';

export const getDefaultCanvasHistoryState = (): CanvasHistory => {
  return {
    isRedoDisabled: true,
    isUndoDisabled: true,
    redo: [],
    undo: [],
  };
};

export const getDefaultCanvasState = (): CanvasState => {
  return {
    activeObject: null,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    fabricObjects: [],
  };
};

export const getDefaultEditorState = (): EditorState => {
  return {
    modelData: {
      isModelReady: false,
      meshs: [],
      isSideSelectionEnabled: false,
      isMeshSelectionRemoved: true,
      singleEditableMesh: null,
      modelSizeInMM: null,
      texturesToUpdate: [],
      activeMesh: null,
      isModelReadyToLoad: false,
      textureCanvases: [],
      isTextureUpdated: true,
    },
    sceneScan: '',
    activeCanvas: {} as FabricCanvas,
    canvasConfigs: {
      canvasZoom: 1,
      isEditorActive: false,
      isUndoRedoHidden: true,
      zoomToDefault: 1,
      baseHeight: 0,
      baseWidth: 0,
      isObjectsRestored: true,
      canvasSizes: [],
      layerNumber: 0,
      layerName: 'background',
    },
    canvasHistory: Object.assign({}, getDefaultCanvasHistoryState()),
    canvasState: Object.assign({}, getDefaultCanvasState()),
    editorConfigs: {
      isMobile: false,
      isIframe: false,
      isImageSettingsActive: false,
      isLoadedFromHistory: false,
      isTextSettingsActive: false,
      isImageLoading: false,
      isSettingsDisabled: false,
      isDesignMode: false,
      isApplyingDesign: false,
      isEditorLoading: true,
      isSaveDesignDisabled: false,
    },
    templateDesigns: [],
    fontsConfigs: {
      googleFontsList: [],
      fontsToRender: [...DEFAULT_FONTS],
      isAddFontModalOpen: false,
      isFontSelectOpen: false,
      isRemoveFontModalOpen: false,
      fontToRemove: '',
    },
  };
};

export const IsMoreObjectsInNextState = (
  state: EditorState,
  action: HistoryActions,
): boolean => {
  const { canvasHistory, canvasState } = state;
  const currentObjects = canvasState.fabricObjects;
  const previousObjects = canvasHistory[action].at(-1)?.fabricObjects;

  return previousObjects
    ? currentObjects?.length < previousObjects?.length
    : false;
};
