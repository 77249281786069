<template>
  <span
    class="indicator"
    :style="{
      'backgroundColor': color,
      'width': `${size}px`,
      'height': `${size}px`,
    }"
  />
</template>
<script lang="ts">
export default {
  props: {
    color: {
      required: true,
      type: String,
    },
    size: {
      default: 12,
      type: Number,
    },
  },
};
</script>
<style scoped lang="scss">
@import 'BaseIndicator';
</style>
