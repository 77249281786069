<template>
  <div class="email-confirmed">
    <div class="email-confirmed__logo">
      <img
        src="@/assets/images/logo-default.svg"
        :alt="$t('header.logoImage__alt')"
      >
    </div>
    <div class="email-confirmed__body">
      <h1 class="email-confirmed__header">
        {{ $t("emailConfirmed.emailConfirmed__header") }}
      </h1>
      <div class="email-confirmed__text-wrapper">
        <div class="email-confirmed__text">
          {{ $t("emailConfirmed.confirmationLinkUsed__text") }}
        </div>
        <div class="email-confirmed__text">
          {{ $t("emailConfirmed.signInOrCreateAccount__text") }}
        </div>
      </div>
      <img
        class="email-confirmed__image"
        src="@/assets/images/email-confirmed.png"
        :alt="$t('emailConfirmed.boxImage__alt')"
      >
      <BaseButton
        class="email-confirmed__button"
        :button-text="$t('emailConfirmed.emailConfirmed__button')"
        :large="isMobile"
        @click="() => $router.push({ path: 'sign-in' })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { isMobile } from '@/utils/Mobile';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return {
      isMobile,
    };
  },
};
</script>

<style scoped lang="scss">
  @import 'EmailConfirmed';
</style>
