import {
  GET_IS_DEACTIVATING_LOADING,
  GET_IS_THEMES_LOADING,
  GET_STORE,
  GET_STORE_WEBSITE,
  GET_THEMES,
} from '@/store/Shopify/constants';
import { ShopifyState } from '@/store/Shopify/types';

export const getters = {
  [GET_IS_DEACTIVATING_LOADING]: (state: ShopifyState) =>
    state.isStoreResetting,
  [GET_IS_THEMES_LOADING]: (state: ShopifyState) => state.isThemesLoading,
  [GET_STORE_WEBSITE]: (state: ShopifyState) => state.website,
  [GET_THEMES]: (state: ShopifyState) => state.themes,
  [GET_STORE]: (state: ShopifyState) => state.shopifyStore,
};
