<template>
  <ModalWindow
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <div
      ref="pricingModal"
      class="pricing-modal"
    >
      <h1 class="pricing-modal__heading">
        {{ $t('pricingModal.title') }}
      </h1>
      <div class="pricing-modal__content pretty-scroll">
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.dearCustomer__text') }},
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.weCreatedAutomizedSolution__text') }}
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.yourTimeCreatingAndDesigningProducts__text') }}
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.ourManufacturingAndShippingCosts__text') }} 
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.shopifyWillAutomaticallyPayYou__text') }}
        </p>
        <p class="pricing-modal__content-subtitle">
          {{ $t('pricingModal.printingOfOnePhoneCases__text', {
            price: 9
          }) }}
        </p>
        <p class="pricing-modal__content-subtitle">
          {{ $t('pricingModal.weightOfOneCase__text', {
            grams: 49
          }) }}
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.shippedWithinAmountOfDays__text', {
            daysRange: "2-3"
          }) }}
        </p>
        <p class="pricing-modal__content-paragraph">
          {{ $t('pricingModal.deliveryCosts__text') }}:
        </p>
        <div class="pricing-modal__content-table">
          <v-data-table
            class="pricing-modal__content-table_data pretty-scroll"
            :items="deliveryProfiles"
            :items-per-page="-1"
            hide-default-header
            hide-default-footer
            disable-sort
            disable-filtering
            mobile-breakpoint="0"
          >
            <template #header>
              <thead class="pricing-modal__content-table_data-header">
                <th />
                <th
                  v-for="(unit, index) in amountOfUnits"
                  :key="index"
                  class="pricing-modal__content-table_data-header__cell"
                >
                  <span>
                    {{ unit }}, $<br>
                    {{ unitsGrams[unit] }}
                  </span>
                </th>
              </thead>
            </template>
            <template #item="{ item }">
              <tr class="pricing-modal__content-table_data-item">
                <td class="pricing-modal__content-table_data-item__cell">
                  {{ item.shippingZone }}
                </td>
                <td
                  v-for="cost in item.costs"
                  :key="cost.amount"
                  class="pricing-modal__content-table_data-item__cell"
                >
                  {{ cost.value.toFixed(2) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <p class="pricing-modal__content-paragraph no-margin">
          {{ $t('pricingModal.youWillBeChangedMonthly__text') }}
        </p>
      </div>
      <BackToTopButton
        :scrollable-element="pricingModal"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import usePricingModal from './PricingModal';

export default {
  components: {
    BackToTopButton,
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return usePricingModal();
  },
};
</script>

<style lang="scss" scoped>
@import 'PricingModal';
</style>
