import { useI18n } from 'vue-i18n-composable';
import { MemoryUnits } from '@/constants/units';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { NotificationType } from '@/types/notification';

export const checkMaxImageSize = (
  image: File,
  uploadSizeMB: number,
  callback: () => void,
): boolean => {
  const { t } = useI18n();
  const maxUploadSize = uploadSizeMB * MemoryUnits.BYTES_IN_MEGABYTE;
  if (image.size > maxUploadSize) {
    store.dispatch(SET_NOTIFICATION, {
      text: t('notifications.fileSizeExceeds', {
        maxUploadSize: uploadSizeMB,
      }),
      type: NotificationType.Error,
    });
    callback();
    return false;
  }
  return true;
};
