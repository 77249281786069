<template>
  <div class="templates-list__head">
    <div class="templates-list__breadcrumbs">
      <router-link :to="prevLink.to">
        {{ prevLink.name }}
      </router-link> / 
      <router-link
        class="breadcrumb_active"
        to=""
      >
        {{ collectionName }}
      </router-link>
    </div>
    <div class="templates-list__header">
      <h1 class="templates-list__header-title">
        {{ collectionName }}
      </h1>
      <div 
        v-if="templates.length"
        class="templates-list__header-sort"
      >
        <span class="sort__label">
          {{ $t('templates.sort__label') }}
        </span>
        <BaseSelect
          class="sort__select"
          :value="sort"
          :items="sortItems"
          :menu-props="menuSettings"
          :color="primaryViolet"
          background-color="transparent"
          attach
          @input="$emit('sortChange', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import useTemplatesHeader from './TemplatesHeader';

export default {
  components: {
    BaseSelect,
  },
  props: {
    collection: {
      required: false,
      type: Object,
    },
    sort: {
      required: true,
      type: String,
    },
    templates: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    return useTemplatesHeader(props);
  },
};
</script>

<style lang="scss" scoped>
@import 'TemplatesHeader';
</style>
