<template>
  <div class="not-exists_wrapper">
    <div class="not-exists_wrapper__title">
      <span class="not-exists_wrapper__title-text">
        {{ $t('templates.youHaventAddedAnyProduct__text') }}
      </span>
    </div>
    <div class="not-exists_wrapper__image">
      <img 
        src="@/assets/images/no-templates-plug.png" 
        :alt="$t('templates.noTemplates__alt')"
      >
    </div>
    <BaseButton
      class="not-exists_wrapper__button"
      :button-text="$t('templates.discoverPublicCollections__text')"
      no-margin-top
      large
      @click="redirectToPublicCollections"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useTemplatesNotExists from './TemplatesNotExists';
export default {
  components: {
    BaseButton,
  },
  setup() {
    return useTemplatesNotExists();
  },
};
</script>

<style lang="scss" scoped>
@import 'TemplatesNotExists';
</style>
