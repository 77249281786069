<template>
  <div
    class="side-controls pretty-scroll"
    :class="{
      'is-on-shopify': isCustomizationFrame,
      'disabled': isSideControlsDisabled,
    }"
    @click.stop
  >
    <div>
      <v-tabs
        v-model="currentTab"
        :slider-color="primaryViolet"
        :color="black87"
        grow
      >
        <v-tab>
          {{ $t('redactor.objectSettings__text') }}
        </v-tab>
        <v-tab>
          {{ $t('redactor.summary__text') }}
        </v-tab>
      </v-tabs>
    </div>
    <v-divider />
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <div class="side-controls__control">
          <span>
            {{ $t('redactor.objectColor__label') }}
          </span>
          <ColorPicker
            :color-picker-type="ColorPickerType.background"
            :is-mobile="isMobile"
            @input="$emit('changeObjectColor', $event)"
          />
        </div>
        <div
          v-if="activeMesh"
        >
          <ImageSettings
            v-if="isImageActive"
            v-bind="imageSettingsProps"
          />
          <FontSelect
            v-if="isFontSelectOpen"
          />
          <TextSettings
            v-if="isTextActive"
            v-bind="textControlsProps"
            v-on="textControlsEmits"
          />
        </div>
        <div
          v-if="activeObject"
        >
          <v-divider />
          <BaseButton
            no-margin-top
            :color="secondaryViolet"
            class="side-controls__delete-button"
            :class="{disabled: lockInfo.lockedDeletion}"
            :button-text="$t('redactor.delete__button')"
            @click="$emit('deleteActiveObject')"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <DropList
          :items="objects"
          mode="cut"
          @reorder="$event => reorder($event, objects)"
        >
          <template #item="{item: object}">
            <Drag
              :key="object.id"
              class="side-controls__summary-row"
              :data="object"
              handle=".side-controls__summary-row-drag-icon"
              @cut="() => {}"
            >
              <v-icon
                class="side-controls__summary-row-drag-icon"
                :color="
                  activeObjectId === object.id
                    ? primaryViolet
                    : 'inherit'
                "
              >
                mdi-drag
              </v-icon>
              <EditorSideSummary
                :is-editing="object.id === editLayerId"
                :object="object"
                :is-active="activeObjectId === object.id"
                @remove="deleteObjectById(object.id)"
                @openEditSummary="editLayerId = object.id"
                @saveSummary="(t) =>saveSummary(object.id, t)"
              />
              <template #drag-image />
            </Drag>
          </template>
        </DropList>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import { Drag, DropList } from 'vue-easy-dnd';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import useSideControlsDesktop from '@/components/Editor/EditorSideControls/EditorSideControlsDesktop/EditorSideControlsDesktop';
import FontSelect from '@/components/Editor/FontSelect/FontSelect.vue';
import ImageSettings from '@/components/Editor/ImageSettings/ImageSettings.vue';
import TextSettings from '@/components/Editor/TextSettings/TextSettings.vue';
import EditorSideSummary from './EditoSideSummary/EditorSideSummary.vue';
export default {
  components: {
    ColorPicker,
    FontSelect,
    ImageSettings,
    TextSettings,
    BaseButton,
    EditorSideSummary,
    Drag,
    DropList,
  },
  props:{
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isFontSelectOpen: {
      required: true,
      type: Boolean,
    },
    isImageActive: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    isTextActive: {
      required: true,
      type: Boolean,
    },
    secondaryViolet: {
      required: true,
      type: String,
    },
    activeMesh: {
      required: false,
      type: Object,
    },
    isImageLoading: {
      required: true,
      type: Boolean,
    },
    isSideControlsDisabled: {
      required: false,
      type: Boolean,
    },
    activeObject: {
      required: false,
      type: Object,
    },
    lockInfo: {
      required: false,
      type: Object,
      default: () => defaultLockObject,
    },
  },
  setup(props, context) {
    return useSideControlsDesktop(props, context);
  },
};
</script>

<style lang="scss" scoped>
@import "EditorSideControlsDesktop";
</style>
