<template>
  <div class="orders">
    <h1 class="orders__header">
      {{ $t('orders.orders__title') }}
    </h1>
    <BaseTable
      :headers="tableHeaders"
      :loading="isLoading"
      :items-per-page="limit"
      :items="orders"
      :page-count="amountOfPages"
      :page="currentPage"
      @update:page="changePage"
      @onRowClick="openOrder"
    >
      <template #content="{ item: order, headers }">
        {{ headers }}
        <td>
          <div class="orders__row">
            <span class="orders__text"> #{{ order.id }}</span>
          </div>
        </td>
        <td>
          <div class="orders__row">
            <OrderStatusChip :status="order.status">
              {{ order.status }}
            </OrderStatusChip>
            <BaseTooltip
              v-if="order.error"
              icon="mdi-help-circle-outline"
              mdi-icon
              top
            >
              {{ order.error }}
            </BaseTooltip>
          </div>
        </td>
        <td>
          <div class="orders__row orders__row_vertical">
            <div class="orders__text">
              {{ parseDate(order.createdAt).date }}
            </div>
            <span class="orders__time">
              {{ parseDate(order.createdAt).time }}
            </span>
          </div>
        </td>

        <td>
          <div class="orders__row">
            <ShopifyIcon
              :fill="black54"
            />
            {{ order.shopifyStore?.store }}
          </div>
        </td>

        <td>
          <span class="orders__row orders__products">
            {{ getProducts(order) }}
          </span>
        </td>
      </template>
    </BaseTable>
  </div>
</template>
<script lang="ts">
import BaseTable from '@/components/Common/BaseTable/BaseTable.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import ShopifyIcon from '@/components/icons/ShopifyIcon.vue';
import OrderStatusChip from '../components/OrderStatusChip/OrderStatusChip.vue';
import { useAdminOrders } from './AdminOrders';

export default {
  components: {
    ShopifyIcon,
    OrderStatusChip,
    BaseTooltip,
    BaseTable,
  },
  setup() {
    return useAdminOrders();
  },
};
</script>
<style lang="scss">
  @import './AdminOrders.scss'
</style>
