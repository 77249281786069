import { FabricCanvas } from '@/components/Editor/types/fabric';
import {
  GET_ACTIVE_CANVAS,
  GET_IS_APPLYING_DESIGN,
  GET_IS_DESIGN_MODE,
  GET_IS_EDITOR_LOADING,
  GET_IS_IFRAME,
  GET_IS_IMAGE_LOADING,
  GET_IS_IMAGE_SETTINGS_ACTIVE,
  GET_IS_LOADED_FROM_HISTORY,
  GET_IS_MOBILE,
  GET_IS_SAVE_DESIGN_DISABLED,
  GET_IS_SETTINS_DISABLED,
  GET_IS_TEXT_SETTINGS_ACTIVE,
} from '../constants';
import { EditorState } from '../types';

export const editorConfigGetters = {
  [GET_IS_DESIGN_MODE]: (state: EditorState): boolean => {
    return state.editorConfigs.isDesignMode;
  },
  [GET_ACTIVE_CANVAS]: (state: EditorState): FabricCanvas => {
    return state.activeCanvas;
  },
  [GET_IS_LOADED_FROM_HISTORY]: (state: EditorState): boolean => {
    return state.editorConfigs.isLoadedFromHistory;
  },
  [GET_IS_IFRAME]: (state: EditorState): boolean => {
    return state.editorConfigs.isIframe;
  },
  [GET_IS_MOBILE]: (state: EditorState): boolean => {
    return state.editorConfigs.isMobile;
  },
  [GET_IS_IMAGE_SETTINGS_ACTIVE]: (state: EditorState): boolean => {
    return state.editorConfigs.isImageSettingsActive;
  },
  [GET_IS_TEXT_SETTINGS_ACTIVE]: (state: EditorState): boolean => {
    return state.editorConfigs.isTextSettingsActive;
  },
  [GET_IS_IMAGE_LOADING]: (state: EditorState): boolean => {
    return state.editorConfigs.isImageLoading;
  },
  [GET_IS_SETTINS_DISABLED]: (state: EditorState): boolean => {
    return state.editorConfigs.isSettingsDisabled;
  },
  [GET_IS_APPLYING_DESIGN]: (state: EditorState): boolean => {
    return state.editorConfigs.isApplyingDesign;
  },
  [GET_IS_EDITOR_LOADING]: (state: EditorState): boolean => {
    return state.editorConfigs.isEditorLoading;
  },
  [GET_IS_SAVE_DESIGN_DISABLED]: (state: EditorState): boolean => {
    return state.editorConfigs.isSaveDesignDisabled;
  },
};
