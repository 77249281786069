import { fabric } from 'fabric';
import {
  DEFAULT_FRACTION_DIGITS,
} from '@/components/Editor/constants/defaultConfigs';

export const configureFabric = (): void => {
  fabric.Canvas.prototype.selection = false;
  fabric.Object.prototype.objectCaching = false;
  fabric.Object.NUM_FRACTION_DIGITS = DEFAULT_FRACTION_DIGITS;
};
