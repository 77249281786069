export const OBJECT_CUSTOM_ATTRIBUTES = [
  'id',
  'centeredScaling',
  'controls',
  'lockedInfo',
  'layerNumber',
  '_objects',
  'dpi',
  'objects',
  'deleted',
  'layerName',
];

export const OBJECT_ATTRIBUTES_TO_EXCLUDE = {
  controls: {},
};

export const ANGLE_TO_START_ROTATE = 15;
