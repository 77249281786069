import { useI18n } from 'vue-i18n-composable';
import { maxUploadCoverImageSizeMB } from '@/constants';
import { CollectionsAPI } from '@/services/CollectionsAPI';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import {
  ADD_COLLECTIONS,
  CHANGE_COLLECTION_IMAGE,
  CLEAR_COLLECTIONS,
  CREATE_LOCAL_COLLECTION,
  CREATE_NEW_COLLECTION,
  EDIT_COLLECTION_NAME,
  INIT_COLLECTIONS,
  LOAD_MORE_COLLECTIONS,
  PUBLISH_COLLECTION,
  REMOVE_COLLECTION,
  REMOVE_LOCAL_COLLECTION,
  REPLACE_LOCAL_COLLECTION,
  RESET_COLLECTIONS_SORTING,
  SET_COLLECTION_NAME,
  SET_COLLECTIONS,
  SET_COLLECTIONS_AMOUNT_OF_PAGES,
  SET_COLLECTIONS_LOADING,
  SET_COLLECTIONS_SELECTED_SORT,
  SET_IS_COLLECTION_IMAGE_LOADING,
  SET_IS_EDITING_RIGHTS_COLLECTIONS,
  SET_PREVIEW_IMAGE,
} from '@/store/Collections/constants';
import {
  Collection,
  Context,
} from '@/store/Collections/types';
import { SET_NOTIFICATION } from '@/store/constants';
import { SortItems } from '@/types';
import { NotificationType } from '@/types/notification';
import { handleResponse } from '@/utils/axios/handleResponse';
import { checkMaxImageSize } from '@/utils/checkMaxImageSize';

export const actions = {
  [CHANGE_COLLECTION_IMAGE]: async (
    { commit }: Context,
    { id, image }: {
      id: number,
      image: File
    },
  ) => {
    commit(SET_IS_COLLECTION_IMAGE_LOADING, true);

    const isImageSizeValid = checkMaxImageSize(
      image,
      maxUploadCoverImageSizeMB,
      () => commit(SET_IS_COLLECTION_IMAGE_LOADING, false),
    );
    
    if (!isImageSizeValid) return;

    const response = await CollectionsAPI.changeCollectionImage({ id, image });
        
    const handleSuccessStatus = ({
      imagePath,
    }: { imagePath: string }): void => {
      commit(SET_PREVIEW_IMAGE, { id, imagePath });
      commit(SET_IS_COLLECTION_IMAGE_LOADING, false);
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [CLEAR_COLLECTIONS]: (
    { commit }: Context,
  ) => {
    commit(CLEAR_COLLECTIONS);
  },

  [CREATE_LOCAL_COLLECTION]: (
    { commit }: Context,
  ) => {
    commit(CREATE_LOCAL_COLLECTION);
  },
  [CREATE_NEW_COLLECTION]: async (
    { commit }: Context,
    { id, name }: {
      id: number;
      name: string;
    },
  ) => {
    const response = await CollectionsAPI.createNewCollection(name);
        
    const handleSuccessStatus = ({
      collection,
    }: { collection: Collection }): void =>
      commit(REPLACE_LOCAL_COLLECTION, { collection, id });
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [EDIT_COLLECTION_NAME]: async (
    { commit }: Context,
    { id, name }: {
      id: number,
      name: string
    },
  ) => {
    const response = await CollectionsAPI.editCollectionName({ id, name });
        
    const handleSuccessStatus = (): void =>
      commit(SET_COLLECTION_NAME, { id, name });
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [INIT_COLLECTIONS]: async (
    { commit }: Context,
    data: {
      collectionType: string,
      limit: number,
      page: number,
      selectedSort: SortItems
    },
  ) => {
    commit(SET_COLLECTIONS_LOADING, true);

    const response = await CollectionsAPI.getCollections({
      ...data,
      userId: store.getters[GET_USER].id,
    });
        
    const handleSuccessStatus = ({
      amountOfPages,
      collections,
      isEditingRights,
    }: {
      amountOfPages: number,
      collections: Collection[],
      isEditingRights: boolean
    }): void => {
      commit(SET_COLLECTIONS_AMOUNT_OF_PAGES, amountOfPages);
      commit(SET_COLLECTIONS, collections);
      commit(SET_IS_EDITING_RIGHTS_COLLECTIONS, isEditingRights);
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });

    commit(SET_COLLECTIONS_LOADING, false);
  },
  [LOAD_MORE_COLLECTIONS]: async (
    { commit }: Context,
    data: {
      collectionType: string,
      limit: number,
      page: number,
      selectedSort: SortItems
    },
  ) => {
    commit(SET_COLLECTIONS_LOADING, true);

    const response = await CollectionsAPI.getCollections({
      ...data,
      userId: store.getters[GET_USER].id,
    });
        
    const handleSuccessStatus = ({
      amountOfPages,
      collections,
      isEditingRights,
    }: {
      amountOfPages: number,
      collections: Collection[],
      isEditingRights: boolean
    }): void => {
      commit(SET_COLLECTIONS_AMOUNT_OF_PAGES, amountOfPages);
      commit(ADD_COLLECTIONS, collections);
      commit(SET_IS_EDITING_RIGHTS_COLLECTIONS, isEditingRights);
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
    
    commit(SET_COLLECTIONS_LOADING, false);
  },
  [PUBLISH_COLLECTION]: async (
    { commit, dispatch }: Context,
    collection: Collection,
  ) => {
    const { t } = useI18n();
    const response = await CollectionsAPI.publishCollection(
      collection.id as number);
        
    const handleSuccessStatus = (): void => {
      commit(PUBLISH_COLLECTION, collection.id);
      collection.isPublic
        ? dispatch(SET_NOTIFICATION, {
          text: t('notifications.collectionIsPublished', {
            collectionName: collection.name,
          }),
          type: NotificationType.Success,
        })
        : dispatch(SET_NOTIFICATION, {
          text: t('notifications.collectionIsUnpublished', {
            collectionName: collection.name,
          }),
          type: NotificationType.Success,
        });
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [REMOVE_COLLECTION]: async (
    { commit, dispatch }: Context,
    collection: Collection,
  ) => {
    const { t } = useI18n();
    const response = await CollectionsAPI.removeCollection(
      collection.id as number);
        
    const handleSuccessStatus = (): void => {
      commit(REMOVE_COLLECTION, collection.id);
      dispatch(SET_NOTIFICATION, {
        text: t('notifications.collectionIsRemoved', {
          collectionName: collection.name,
        }),
        type: NotificationType.Success,
      });
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });
  },
  [REMOVE_LOCAL_COLLECTION]: (
    { commit }: Context,
    id: number,
  ) => {
    commit(REMOVE_COLLECTION, id);
  },
  [RESET_COLLECTIONS_SORTING]: ({ commit }: Context) => {
    commit(SET_COLLECTIONS_SELECTED_SORT, SortItems.New);
  },
  [SET_COLLECTIONS_SELECTED_SORT]: (
    { commit }: Context,
    selectedSort: SortItems,
  ) => {
    commit(SET_COLLECTIONS_SELECTED_SORT, selectedSort);
  },
};
