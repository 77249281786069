<template>
  <div class="auth-with-social">
    <div class="auth-with-social__text">
      {{ $t('authWithSocial.with__text') }}
    </div>
    <div class="auth-with-social__buttons">
      <img
        :src="googleImage"
        :alt="$t('authWithSocial.googleImage__alt')"
        @click="authWithGoogle"
      >
      <img
        :src="linkedinImage"
        :alt="$t('authWithSocial.linkedinImage__alt')"
        @click="authWithLinkedin"
      >
      <img
        :src="facebookImage"
        :alt="$t('authWithSocial.facebookImage__alt')"
        @click="authWithFacebook"
      >
    </div>
    <div class="auth-with-social__text">
      {{ $t(`authWithSocial.or${authWith}__text`) }}
    </div>
  </div>
</template>

<script lang="ts">
import useAuthWithSocial from './AuthWithSocial';

export default {
  props: {
    authWith: {
      required: true,
      type: String,
    },
    storeConnectionToken:{
      type: String,
    },
  },
  setup(props) {
    return useAuthWithSocial(props);
  },
};
</script>

<style lang="scss" scoped>
  @import 'AuthWithSocial';
</style>
