<template>
  <HeaderMobile
    v-if="isTabletAndLower"
    v-bind="headerProps"
    v-on="headerEmits"
  />
  <HeaderDesktop
    v-else
    v-bind="headerProps"
    v-on="headerEmits"
  />
</template>

<script lang="ts">
import useHeader from '@/components/Header/Header';
import HeaderDesktop from './HeaderDesktop/HeaderDesktop.vue';
import HeaderMobile from './HeaderMobile/HeaderMobile.vue';

export default {
  components: {
    HeaderDesktop,
    HeaderMobile,
  },
  setup() {
    return useHeader();
  },
};
</script>
