import authBackgroundImage from '@/assets/images/authorization-background.png';
import logoDefault from '@/assets/images/logo-default.svg';
import logoWhite from '@/assets/images/logo-white.svg';
import { isTabletAndLower } from '@/utils/Mobile';

const useAuthBackground = () => {
  const logoImg = isTabletAndLower ? logoDefault : logoWhite;

  return {
    authBackgroundImage,
    logoImg,
  };
};

export default useAuthBackground;
