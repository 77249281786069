import { Vector3 } from 'three';
import { MeshData, Size } from '@/components/Editor/types/editor';
import { screenCanvasWidthDelta } from '@/constants/editor';
import store from '@/store';
import {
  GET_BASE_CANVAS_HEIGHT,
  GET_MODEL_SIZE_IN_MM,
  SET_BASE_CANVAS_WIDTH,
} from '@/store/Editor/constants';

export const getSizeFromMeshName = (name: string): Size => {
  const sizeArray = name.split('_');
  let width = 10;
  let height = 10;
  sizeArray.forEach((element, index) => {
    const interger = Number(element);
    const isWidth = sizeArray[index-1] === 'width';
    const isHeight = sizeArray[index-1] === 'height';
    if (Number(element)) {
      if (isWidth) width = interger;
      if (isHeight) height = interger;
      const fractional = Number(sizeArray[index + 1]);
      if (fractional) {
        if (isWidth) width += Number(`0.${fractional}`);
        if (isHeight) height += Number(`0.${fractional}`);
      }
    }
  });
  return { height, width };
};

export const getFabricCanvasSize = (mesh: MeshData | null): Size => {
  const canvasHeight = store.getters[GET_BASE_CANVAS_HEIGHT];
  const modelSizeInMM = store.getters[GET_MODEL_SIZE_IN_MM];
  const size = mesh?.size || new Vector3();
  const availableWidth = 
    document.documentElement.clientWidth - screenCanvasWidthDelta;

  let planeWidth = size.z > size.x ? size.z : size.x;
  let planeHeight = size.y > size.x ? size.y : size.x;

  if (modelSizeInMM) {
    planeWidth = modelSizeInMM.width;
    planeHeight = modelSizeInMM.height;
  }

  let width = canvasHeight;
  let height = canvasHeight;

  if (planeWidth > planeHeight) {
    if (width > availableWidth) {
      width = availableWidth;
    }
    height = width / (planeWidth / planeHeight);
    store.commit(SET_BASE_CANVAS_WIDTH, width);
  } else {
    width = height / (planeHeight / planeWidth);
    if (width > availableWidth) {
      const aspectRatio = width / height;
      width = availableWidth;
      height = availableWidth / aspectRatio;
    }
  }

  return { height, width };
};
