import { computed, ref, SetupContext, toRef } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { primaryViolet } from '@/constants/colors';
import { TemplatesAPI } from '@/services/TemplatesAPI';
import store from '@/store';
import { REMOVE_COLLECTION } from '@/store/Collections/constants';
import { Collection } from '@/store/Collections/types';
import { Template } from '@/store/Templates/types';
import { SortItems } from '@/types';
import { isMobile } from '@/utils/Mobile';
import { CollectionTypes } from '@/views/Collections/types';

const useCollectionsList = (props: {
  collections: Collection[],
  collectionType: CollectionTypes,
  isEditingRights: boolean,
  sort: SortItems
}, { emit }: SetupContext) => {
  const { t } = useI18n();
  const sortItems: SortItems[] = [
    SortItems.New,
    SortItems.Old,
    SortItems.Alphabetically,
    SortItems.ReverseAlphabetically,
  ];

  const selectedCollection = ref();
  const templatesInCollection = ref([] as Template[]);
  const isConfirmRemoveModalOpen = ref(false);
  const collections = toRef(props, 'collections');
  const isEditingRights = toRef(props, 'isEditingRights');

  const isPublic = props.collectionType === CollectionTypes.publicCollections;
  const heading = isPublic
    ? t('collections.publicCollections__heading')
    : t('collections.myCollections__heading');
  
  const isLocalCollectionExists = computed((): boolean =>
    !!collections.value.find((collection: Collection) =>
      collection.userId === -1),
  );
  
  const isShowAddCollectionButton = computed((): boolean =>
    isEditingRights.value &&
    !isPublic &&
    !!collections.value.length &&
    !isLocalCollectionExists.value,
  );
  
  const menuSettings = {
    bottom: true,
    color: primaryViolet,
    rounded: 'lg',
  };

  const removeCollection = async (collection: Collection): Promise<void> => {
    await store.dispatch(REMOVE_COLLECTION, collection);
    emit('triggerInitCollections');
  };

  const openConfirmRemoveModal = async (
    collection: Collection,
  ): Promise<void> => {
    selectedCollection.value = collection;
    const { data: templates } = await TemplatesAPI.getAllTemplatesInCollection(
      collection.id as number,
    );
    if (!templates.length)
      return removeCollection(collection);
    templatesInCollection.value = templates;
    isConfirmRemoveModalOpen.value = true;
  };

  const closeConfirmRemoveModal = (): void => {
    selectedCollection.value = null;
    isConfirmRemoveModalOpen.value = false;
  };

  return {
    closeConfirmRemoveModal,
    heading,
    isConfirmRemoveModalOpen,
    isMobile,
    isPublic,
    isShowAddCollectionButton,
    menuSettings,
    openConfirmRemoveModal,
    primaryViolet,
    selectedCollection,
    sortItems,
    templatesInCollection,
  };
};

export default useCollectionsList;
