<template>
  <div>
    <ResolutionMobile
      v-if="isMobile"
      v-bind="resolutionProps"
    />
    <ResolutionDesktop
      v-else
      v-bind="resolutionProps"
    />
  </div>
</template>

<script lang="ts">

import { useResolution } from './Resolution';
import ResolutionDesktop from './ResolutionDesktop/ResolutionDesktop.vue';
import ResolutionMobile from './ResolutionMobile/ResolutionMobile.vue';
export default {
  components: {
    ResolutionDesktop,
    ResolutionMobile,
  },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    return useResolution(props);
  },
};
</script>
