import { fabric } from 'fabric';
import { ZoomVariants } from '@/components/Editor/types/editor';
import { MaxCanvasZoom, MinCanvasZoom } from '@/constants/editor';
import store from '@/store';
import {
  GET_ACTIVE_CANVAS,
  GET_CANVAS_ZOOM,
  GET_ZOOM_TO_DEFAULT,
  SET_CANVAS_ZOOM,
} from '@/store/Editor/constants';
import { calcZoom } from './calcZoom';

const zoomCanvasToCenter = async (canvasZoom: number): Promise<void> => {
  const canvas = store.getters[GET_ACTIVE_CANVAS];
  const zoomToDefault = store.getters[GET_ZOOM_TO_DEFAULT];
  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();
  if (canvas) {
    const pointToZoom = new fabric.Point(
      canvasWidth / 2,
      canvasHeight / 2,
    );
    canvas.zoomToPoint(
      pointToZoom,
      canvasZoom * zoomToDefault,
    );
  }
};

export const zoomCanvas = (zoom: ZoomVariants): void => {
  const canvasZoom = store.getters[GET_CANVAS_ZOOM];
  const currentZoom = canvasZoom || 1;
  const neededZoom = calcZoom(currentZoom, zoom);
  if (neededZoom > MaxCanvasZoom || neededZoom < MinCanvasZoom) return;
  zoomCanvasToCenter(neededZoom);
  store.commit(SET_CANVAS_ZOOM, neededZoom);
};
