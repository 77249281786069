import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import {
  zoomCanvas,
} from '@/components/Editor/helpers/fabric/canvasModifiers/zoomCanvas';
import { ZoomVariants } from '@/components/Editor/types/editor';
import { primaryViolet } from '@/constants/colors';
import {
  FullIconData,
  ZoomInIconData1,
  ZoomInIconData2,
  ZoomOutIconData,
} from '@/constants/editor';
import store from '@/store';
import {
  GET_BASE_CANVAS_HEIGHT,
  GET_IS_MOBILE,
  GET_IS_SETTINS_DISABLED,
} from '@/store/Editor/constants';

const EditorScaleControls = (props, { emit }) => {
  const isZoomInOutVisible = ref(false);
  const scaleControls = ref();
  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);
  const currentFabricZoom = computed((): number => props.currentFabricZoom);
  const isSettingsDisabled = computed((): boolean => {
    return store.getters[GET_IS_SETTINS_DISABLED];
  });

  const zoomIn = (): void => {
    zoomCanvas(ZoomVariants.zoomIn);
  };

  const zoomOut = (): void => {
    zoomCanvas(ZoomVariants.zoomOut);
  };

  const fullZoom = (): void => {
    zoomCanvas(ZoomVariants.zoomFull);
  };

  const zoomInActive = computed((): boolean => currentFabricZoom.value > 1);
  const zoomOutActive = computed((): boolean => currentFabricZoom.value < 1);
  const fullActive = computed((): boolean => currentFabricZoom.value === 1);

  const zoomInColor = computed((): string =>
    zoomInActive.value ? primaryViolet : 'black',
  );
  const zoomOutColor = computed((): string =>
    zoomOutActive.value ? primaryViolet : 'black',
  );
  const fullZoomColor = computed((): string =>
    fullActive.value ? primaryViolet : 'black',
  );

  const showZoomInOutControls = (): void => {
    if (!isMobile.value) {
      isZoomInOutVisible.value = true;
    }
  };

  const hideZoomInOutControls = (): boolean => isZoomInOutVisible.value = false;

  const scaleControlsStyleMoify = () => {
    const canvasHeight = store.getters[GET_BASE_CANVAS_HEIGHT];
    scaleControls.value.style.top = `calc(100% - (100% - ${canvasHeight}px) / 2)`;
  };

  onMounted(() => {
    scaleControlsStyleMoify();
    window.addEventListener('resize', scaleControlsStyleMoify);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', scaleControlsStyleMoify);
  });

  return {
    fullActive,
    FullIconData,
    fullZoom,
    fullZoomColor,
    hideZoomInOutControls,
    isSettingsDisabled,
    isZoomInOutVisible,
    showZoomInOutControls,
    zoomIn,
    zoomInActive,
    zoomInColor,
    ZoomInIconData1,
    ZoomInIconData2,
    zoomOut,
    zoomOutActive,
    zoomOutColor,
    ZoomOutIconData,
    isMobile,
    scaleControls,
  };
};

export default EditorScaleControls;
