<template>
  <ModalWindow
    :is-open="isOpen"
    override-with-class="confirm-remove__modal"
    content-class="confirm-remove__modal-card"
    @close="$emit('close')"
  >
    <div class="confirm-remove__modal-card__content">
      <h1 class="confirm-remove__modal-card__content-title">
        {{ $t('confirmRemoveTemplateModal.removeProduct__text') }}
      </h1>
      <p class="confirm-remove__modal-card__content-description">
        {{ $t('confirmRemoveTemplateModal.areYouSure__text', {
          templateName: template.name
        }) }}
      </p>
      <p class="confirm-remove__modal-card__content-text">
        {{ $t(`confirmRemoveTemplateModal.warningPleaseNote__text`) }}
      </p>
    </div>
    <div class="confirm-remove__modal-card__buttons">
      <BaseButton
        dark
        large
        no-margin-top
        apply-color-to-text
        class="confirm-remove__modal-card__buttons-cancel"
        :button-text="$t('confirmRemoveTemplateModal.cancel__text')"
        :color="primaryViolet"
        @click="$emit('close')"
      />
      <BaseButton
        large
        no-margin-top
        class="confirm-remove__modal-card__buttons-remove"
        :button-text="$t('templates.remove__text')"
        :color="primaryViolet"
        @click="removeTemplate"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';  
import useConfirmRemoveTemplateModal from './ConfirmRemoveTemplateModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    isOpen: Boolean,
    template: Object,
  },
  setup(props, context) {
    return useConfirmRemoveTemplateModal(props, context);
  },
};
</script>

<style lang="scss">
@import 'ConfirmRemoveTemplateModal';
</style>
