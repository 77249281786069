import './ColorPickerPipette.scss';
import { computed, onUnmounted, ref, watch } from 'vue';
import { FabricCanvas, FabricEvent, FabricObject } from '@/components/Editor/types/fabric';
import { BGCustomKey } from '@/constants/editor';
import store from '@/store';
import { GET_ACTIVE_CANVAS } from '@/store/Editor/constants';
import { ToggleTypes } from '@/types';
import { rgbToHex } from '@/utils/rgbToHex';

const useColorPickerPipette = (props, { emit }) => {
  const colorDemo = ref();
  const chosenColor = ref();
  const isPipetteActive = ref(false);
  const isOverlayActive = ref(false);

  const activeCanvas = computed(
    (): FabricCanvas => store.getters[GET_ACTIVE_CANVAS],
  );

  const setIsCanvasObjectsSelectable = (
    selectable: boolean,
  ): void => {
    (activeCanvas.value.getObjects() as FabricObject[])
      .forEach((object: FabricObject): void => {
        if (object.id !== BGCustomKey) object.selectable = selectable;
      });
  };

  const changeCanvasCursor = (cursor: string): void => {
    (activeCanvas.value.getObjects() as FabricObject[])
      .forEach((object: FabricObject): void => {
        object.hoverCursor = cursor;
      });
    activeCanvas.value.hoverCursor = cursor;
  };

  const renderColorDemo = (flag: boolean): void => {
    if (flag) {
      const colorDemoDiv = document.createElement('div');
      colorDemoDiv.classList.add('color-picker-pipette__color-demo');
      document.body.appendChild(colorDemoDiv);
      colorDemo.value = colorDemoDiv;
    } else {
      const colorDemoDiv = document.querySelector(
        '.color-picker-pipette__color-demo',
      );
      colorDemoDiv?.remove();
    }
  };

  const getColorFromCanvas = ({ e }: FabricEvent): void => {
    const { offsetX, offsetY, pageX, pageY } = e as MouseEvent;
    // @ts-ignore
    const context = activeCanvas.value.getContext('2d');
    const ratio = window.devicePixelRatio || 1;
    const pixel = context
      ?.getImageData(offsetX * ratio, offsetY * ratio, 1, 1)
      .data;
    if (pixel) {
      const [ red, green, blue ] = pixel;
      const hexColor = rgbToHex(red, green, blue);
      colorDemo.value.style.cssText = `
        display: block;
        left: ${pageX + 10}px;
        top: ${pageY + 10}px;
        background-color: ${hexColor};
      `;
      chosenColor.value = hexColor;
    }
  };

  const setActiveObjectIfSelectionCleared = (
    { deselected }: FabricEvent,
  ): void => {
    const deselectedObj = deselected as FabricObject[];
    (activeCanvas.value.getObjects() as FabricObject[])
      .forEach((object: FabricObject): void => {
        if (object.id === (deselected?.at(-1) as FabricObject)?.id) {
          activeCanvas.value.setActiveObject(object);
        }
      });
  };

  const applyPipette = (): void => {
    emit('applyColor', chosenColor.value);
    turnOffPipette();
  };

  const toggleCanvasListeners = (
    toggle: ToggleTypes,
  ): void => {
    activeCanvas.value[toggle]('mouse:move', getColorFromCanvas);
    activeCanvas.value[toggle]('selection:cleared', setActiveObjectIfSelectionCleared);
    activeCanvas.value[toggle]('mouse:down', applyPipette);
  };

  const turnOnPipette = (): void => {
    document.body.classList.add('overflow-hidden');
    emit('onActivate');
    setIsCanvasObjectsSelectable(false);
    changeCanvasCursor('crosshair');
    renderColorDemo(true);
    toggleCanvasListeners(ToggleTypes.on);
  };

  const turnOffPipette = (): void => {
    document.body.classList.remove('overflow-hidden');
    emit('onDeactivate');
    setIsCanvasObjectsSelectable(true);
    changeCanvasCursor('default');
    renderColorDemo(false);
    toggleCanvasListeners(ToggleTypes.off);
    isPipetteActive.value = false;
  };

  const togglePipette = (): void => {
    isPipetteActive.value = !isPipetteActive.value;
  };

  watch(isPipetteActive, (flag: boolean): void => {
    flag ? turnOnPipette() : turnOffPipette();
  });

  onUnmounted(turnOffPipette);

  return { isOverlayActive, togglePipette };
};

export default useColorPickerPipette;
