<template>
  <div class="not-found">
    <div class="not-found__heading">
      <img
        src="@/assets/images/logo-default.svg"
        :alt="$t('header.logoImage__alt')"
      >
    </div>
    <div class="not-found__content">
      <div class="not-found__content-text">
        <h1 class="not-found__content-text__title">
          {{ $t('notFoundPage.somethingWentWrong__text') }}
        </h1>
        <p class="not-found__content-text__description">
          {{ $t('notFoundPage.weCantFindPageYouLookingFor__text') }}
        </p>
        <img
          class="not-found__content-text__image"
          src="@/assets/images/not-found-plug.png"
          :alt="$t('notFoundPage.notFoundImage__alt')"
        >
      </div>
      <BaseButton
        large
        no-margin-top
        class="not-found__content-button"
        :button-text="$t('notFoundPage.backToHome__text')"
        @click="$router.push('/')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import 'NotFoundPage';
</style>
