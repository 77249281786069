import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { Route } from 'vue-router';
import logoDefault from '@/assets/images/logo-default.svg';
import { TabItem, Tabs } from '@/components/Header/types';
import { black87, secondaryMagenta } from '@/constants/colors';
import { UserRoles } from '@/constants/roles';
import router from '@/router/index';
import { GET_AUTHORIZED, GET_USER } from '@/store/Authorization/constants';
import store from '@/store/index';
import { isTabletAndLower } from '@/utils/Mobile';

const useHeader = () => {
  const { t } = useI18n();
  const isAuthorized = computed((): boolean => store.getters[GET_AUTHORIZED]);
  
  const isPricingModalOpen = ref(false);
  const isPrivacyPolicyModalOpen = ref(false);
  const isContactSupportModalOpen = ref(false);
  const isReturnsPolicyModalOpen = ref(false);

  const userHeaderNav = isTabletAndLower ?
    computed((): TabItem[] => ([
      {
        title: t('tabs.orders__text'),
        to: Tabs.Orders,
        isActive: currentTab.value === Tabs.Orders,
      },
      {
        title: t('tabs.myCollections__text'),
        to: Tabs.MyCollections,
        isActive: currentTab.value === Tabs.MyCollections,
      },
      {
        title: t('tabs.publicCollections__text'),
        to: Tabs.PublicCollections,
        isActive: currentTab.value === Tabs.PublicCollections,
      },
      {
        title: t('tabs.myProfile__text'),
        to: Tabs.MyProfile,
        isActive: currentTab.value === Tabs.MyProfile,
      },
      {
        action: () => openPricingModal(),
        title: t('menu.pricing__text'),
        to: '',
      },
      {
        action: () => router.push({ hash: '#welcome', name: 'user-guide' }),
        title: t('menu.userGuide__text'),
        to: '',
        isActive: currentTab.value === Tabs.UserGuide,
      },
      {
        action: () => openPrivacyPolicyModal(),
        title: t('privacyPolicy.title'),
        to: '',
      },
      {
        action: () => openReturnsPolicyModal(),
        title: t('returnsPolicy.title'),
        to: '',
      },
      {
        action: () => openContactSupportModal(),
        title: t('menu.support__text'),
        to: '',
      },
    ])) : computed((): TabItem[] => ([
      {
        title: t('tabs.orders__text'),
        to: Tabs.Orders,
        isActive: currentTab.value === Tabs.Orders,
      },
      {
        title: t('tabs.myCollections__text'),
        to: Tabs.MyCollections,
        isActive: currentTab.value === Tabs.MyCollections,
      },
      {
        title: t('tabs.publicCollections__text'),
        to: Tabs.PublicCollections,
        isActive: currentTab.value === Tabs.PublicCollections,
      },
    ]));

  const adminHeaderNav = computed((): TabItem[] => ([
    {
      title: t('tabs.shipping__text'),
      to: Tabs.Shipping,
      isActive: currentTab.value === Tabs.Shipping,
    },
    ...userHeaderNav.value,
    {
      title: t('tabs.upload__text'),
      to: Tabs.Upload,
      isActive: currentTab.value === Tabs.Upload,
    },
  ]));

  const navItems = computed(() => {
    const userRole = store.getters[GET_USER].role;

    return userRole === UserRoles.Admin ? adminHeaderNav : userHeaderNav;
  });

  const getTabValue = (path: string): string => {
    for (const tab of Object.values(Tabs)) {
      if (path.includes(tab)) return tab;
    }

    return '';
  };

  const currentTab = ref<string>(Tabs.MyCollections);
  const currentRoute = computed((): Route => router.currentRoute);
  watch(currentTab, (): void => {
    currentTab.value = getTabValue(currentRoute.value.fullPath);
  }, { immediate: true });

  watch(currentRoute, (route: Route): void => {
    currentTab.value = getTabValue(route.fullPath);
  });

  const changeTab = (tab: string): void => { currentTab.value = tab; };

  const openPricingModal = (): boolean => isPricingModalOpen.value = true;
  const closePricingModal = (): boolean => isPricingModalOpen.value = false;

  const openPrivacyPolicyModal = (): boolean =>
    isPrivacyPolicyModalOpen.value = true;
  const closePrivacyPolicyModal = (): boolean =>
    isPrivacyPolicyModalOpen.value = false;
  
  const openContactSupportModal = (): boolean =>
    isContactSupportModalOpen.value = true;
  const closeContactSupportModal = (): boolean =>
    isContactSupportModalOpen.value = false;
  
  const openReturnsPolicyModal = (): boolean =>
    isReturnsPolicyModalOpen.value = true;
  const closeReturnsPolicyModal = (): boolean =>
    isReturnsPolicyModalOpen.value = false;

  const headerProps = reactive({
    currentTab,
    isContactSupportModalOpen,
    isPricingModalOpen,
    isPrivacyPolicyModalOpen,
    isReturnsPolicyModalOpen,
    linkToMyCollections: Tabs.MyCollections,
    logoDefault,
    navItems: navItems.value,
  });

  const headerEmits = {
    changeTab,
    closeContactSupportModal,
    closePricingModal,
    closePrivacyPolicyModal,
    closeReturnsPolicyModal,
    openPrivacyPolicyModal,
    openPricingModal,
    openContactSupportModal,
    openReturnsPolicyModal,
  };

  return {
    black87,
    currentTab,
    headerEmits,
    headerProps,
    isAuthorized,
    isTabletAndLower,
    navItems,
    secondaryMagenta,
    Tabs,
  };
};

export default useHeader;
