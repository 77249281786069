import {
  isObjectLocked,
  setObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import { FabricObject } from '@/components/Editor/types/fabric';
import { hideExcessedControls } from './hideControls';
import { getModifiedFabricControls } from './modifiedControls';

const setControlsLock = (object: FabricObject): void => {
  if (!isObjectLocked(object)) return;

  setObjectLocked({
    isLocked: true,
    object,
    shouldSaveInStore: false,
  });
};

export const modifyDefaultFabricControls = (
  object: FabricObject,
): void => {
  const modifiedFabricControls = getModifiedFabricControls();

  for (const [ key, control ] of Object.entries(modifiedFabricControls)) {
    if (control) object.controls[key] = control;
    else if (object.controls[key])
      object.controls[key].visible = false;
  }
  hideExcessedControls(object);
  setControlsLock(object);
};
