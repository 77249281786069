import { ActionContext } from 'vuex';
import { DEFAULT_FONT } from '@/components/Editor/constants/defaultSettings';
import { firstLetterUppercase } from '@/components/Editor/helpers/customFonts/firstLetterUppercase';
import { getGoogleFont } from '@/components/Editor/helpers/customFonts/getGoogleFont';
import { loadCustomFonts } from '@/components/Editor/helpers/customFonts/loadCustomFonts';
import {
  loadGoogleFontsList,
} from '@/components/Editor/helpers/customFonts/loadGoogleFontsList';
import { FabricObjectAttributes } from '@/components/Editor/types/fabric';
import {
  ADD_CUSTOM_FONT,
  REMOVE_CUSTOM_FONT,
  UPDATE_CUSTOM_FONTS,
} from '@/store/Authorization/constants';
import {
  LOAD_GOOGLE_FONT,
  LOAD_GOOGLE_FONTS_LIST,
  REMOVE_FONT,
  REMOVE_FONT_FROM_RENDER,
  SET_FONT_TO_REMOVE,
  SET_GOOGLE_FONTS_LIST,
  SET_IS_REMOVE_FONT_MODAL_OPEN,
  TRIGGER_REMOVE_FONT,
  TRIGGER_UPDATE_OBJECT,
} from '../constants';
import { EditorState } from '../types';

export const fontsConfigsActions = {
  [LOAD_GOOGLE_FONTS_LIST]: async (
    { commit }: ActionContext<EditorState, any>,
  ): Promise<void> => {
    const fonts = await loadGoogleFontsList();
    commit(SET_GOOGLE_FONTS_LIST, fonts);
  },
  [LOAD_GOOGLE_FONT]: async (
    { commit, dispatch, rootState }: ActionContext<EditorState, any>,
    font: string,
  ): Promise<void> => {
    const user = rootState.authorization.user;
    const newFont = firstLetterUppercase(font);
    const fontIsValid = await getGoogleFont(newFont, true);
    if (fontIsValid) {
      commit(ADD_CUSTOM_FONT, font);
      await loadCustomFonts([newFont], true);
      
      if (user.id) {
        await dispatch(UPDATE_CUSTOM_FONTS, {
          id: user.id,
          customFontList: JSON.stringify(user.customFonts),
        });
      }
      dispatch(TRIGGER_UPDATE_OBJECT, {
        changes: { [FabricObjectAttributes.fontFamily]: font },
        isUpdateHistory: true,
      });
    }
  },
  [TRIGGER_REMOVE_FONT]: async (
    { commit }: ActionContext<EditorState, any>,
    font: string,
  ): Promise<void> => {
    commit(SET_IS_REMOVE_FONT_MODAL_OPEN, true);
    commit(SET_FONT_TO_REMOVE, font);
  },
  [REMOVE_FONT]: async (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
  ): Promise<void> => {
    const fontToRemove = state.fontsConfigs.fontToRemove;
    const activeObject = state.canvasState.activeObject;
    commit(REMOVE_CUSTOM_FONT, fontToRemove);
    commit(REMOVE_FONT_FROM_RENDER, fontToRemove);
    await dispatch(UPDATE_CUSTOM_FONTS, { fontToRemove });
    if (fontToRemove === activeObject?.fontFamily) {
      await dispatch(TRIGGER_UPDATE_OBJECT, {
        changes: { [FabricObjectAttributes.fontFamily]: DEFAULT_FONT },
        isUpdateHistory: true,
      });
    }
    commit(SET_IS_REMOVE_FONT_MODAL_OPEN, false);
  },
};
