<template>
  <ModalWindow
    :is-open="isRemoveFontModalOpen"
    class="remove-font__modal-window"
    @close="closeRemoveFontModal"
  >
    <div 
      class="remove-font"
      :class="{'remove-font_mobile': isMobile}"
    >
      <span class="remove-font__header">
        {{ $t("redactor.removeFont__label") }}
      </span>
      <span class="remove-font__description">
        {{ $t("redactor.areYouSureRemoveFont", {
          fontName: fontToRemove
        }) }}
      </span>
      <div
        class="remove-font__buttons"
      >
        <BaseButton 
          class="remove-font__cancel" 
          :button-text="$t('confirmRemoveTemplateModal.cancel__text')"
          @click="closeRemoveFontModal"
        />
        <BaseButton 
          class="remove-font__remove" 
          :button-text="$t('collections.remove__text')"
          :color="primaryViolet" 
          @click="removeFont"
        />
      </div>
    </div>
  </ModalWindow>
</template>
  
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useRemoveFontModal from '@/components/Editor/RemoveFontModal/RemoveFontModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  setup() {
    return useRemoveFontModal();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "RemoveFontModal";
</style>
