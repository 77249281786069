<template>
  <v-form
    ref="form"
    v-model="isValidForm"
    class="sign-up-form"
  >
    <BaseTitle>
      {{ $t('signUp.signUp__text') }}
    </BaseTitle>
    <AuthWithSocial auth-with="SignUpWithEmail" />
    <div class="sign-up-form__user-name">
      <TextField
        v-model="firstName"
        required
        input-type="text"
        :rules="textRules"
        :label="$t('textField.firstName__placeholder')"
      />
      <TextField
        v-model="lastName"
        required
        input-type="text"
        :rules="textRules"
        :label="$t('textField.lastName__placeholder')"
      />
    </div>
    <TextField
      v-model="email"
      required
      input-type="email"
      :rules="emailRules"
      :label="$t('textField.email__placeholder')"
      @keydown.space.prevent
    />
    <TextField
      v-model="password"
      required
      input-type="password"
      :rules="passwordRules"
      :label="$t('textField.password__placeholder')"
      @keydown.space.prevent
    />
    <TextField
      v-model="repeatPassword"
      required
      input-type="password"
      :rules="passwordRules"
      :label="$t('textField.repeatPassword__placeholder')"
      @keydown.space.prevent
    />
    <BaseCheckbox
      v-model="agreeWithTerms"
      required
    >
      <div>
        {{ $t('signUp.agreeTo__text') }}
        <span
          class="underline-animate"
          @click="isModalOpen = true" 
        >
          {{ $t('privacyPolicy.title') }}
        </span>
      </div>
    </BaseCheckbox>
    <BaseButton
      class="sign-up-form__submit"
      :class="{'disabled': !isValidForm}"
      has-background
      :button-text="$t('signUp.signUp__text')"
      :large="isMobile"
      @click="signUp"
    />
    <AlreadyHaveAccount :route-query="$router.currentRoute.query" />
    <PrivacyPolicyModal
      :is-open="isModalOpen"
      @close="isModalOpen = false"
    />
  </v-form>
</template>

<script lang="ts">
import AuthWithSocial from '@/components/AuthWithSocial/AuthWithSocial.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import AlreadyHaveAccount from '../AlreadyHaveAccount/AlreadyHaveAccount.vue';
import useSignUpForm from './SignUpForm';

export default {
  components: {
    AlreadyHaveAccount,
    AuthWithSocial,
    BaseButton,
    BaseCheckbox,
    BaseTitle,
    PrivacyPolicyModal,
    TextField,
  },
  setup() {
    return useSignUpForm();
  },
};
</script>

<style lang="scss" scoped>
  @import 'SignUpForm';
</style>
