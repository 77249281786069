import {
  GET_BASE_CANVAS_HEIGHT,
  GET_BASE_CANVAS_WIDTH,
  GET_CANVAS_SIZES,
  GET_CANVAS_ZOOM,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_OBJECTS_RESTORED,
  GET_IS_UNDO_REDO_HIDDEN,
  GET_ZOOM_TO_DEFAULT,
} from '../constants';
import { CanvasSize, EditorState } from '../types';

export const canvasConfigsGetters = {
  [GET_CANVAS_ZOOM]: (state: EditorState): number => {
    return state.canvasConfigs.canvasZoom;
  },
  [GET_IS_UNDO_REDO_HIDDEN]: (state: EditorState): boolean => {
    return state.canvasConfigs.isUndoRedoHidden;
  },
  [GET_IS_EDITOR_ACTIVE]: (state: EditorState): boolean => {
    return state.canvasConfigs.isEditorActive;
  },
  [GET_ZOOM_TO_DEFAULT]: (state: EditorState): number => {
    return state.canvasConfigs.zoomToDefault;
  },
  [GET_BASE_CANVAS_HEIGHT]: (state: EditorState): number => {
    return state.canvasConfigs.baseHeight;
  },
  [GET_BASE_CANVAS_WIDTH]: (state: EditorState): number => {
    return state.canvasConfigs.baseWidth;
  },
  [GET_IS_OBJECTS_RESTORED]: (state: EditorState): boolean => {
    return state.canvasConfigs.isObjectsRestored;
  },
  [GET_CANVAS_SIZES]: (state: EditorState): CanvasSize[] => {
    return state.canvasConfigs.canvasSizes;
  },
};
