<template>
  <v-dialog 
    :value="isOpen"
    :content-class="overrideWithClass"
    :attach="!isTabletAndLower"
    @click:outside="$emit('close')"
  >
    <v-card :class="['modal-content__wrapper', contentClass]">
      <div
        v-if="isTabletAndLower"
        class="modal-content__wrapper-heading"
      >
        <slot name="header" />
        <BaseButton
          class="modal-close"
          icon="mdi-close"
          :icon-size="26"
          dark
          no-margin-top
          @click="$emit('close')"
        />
      </div>
      <BaseButton
        v-else
        class="modal-close"
        icon="mdi-close"
        :icon-size="30"
        @click="$emit('close')"
      />
      <slot />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { onBeforeUnmount, watch } from 'vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { useStopScroll } from '@/composables/useStopScroll';
import { isTabletAndLower } from '@/utils/Mobile';

export default {
  components: {
    BaseButton,
  },
  props: {
    contentClass: {
      required: false,
      type: String,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    overrideWithClass: {
      default: 'modal-window',
      required: false,
      type: String,
    },
  },
  setup(props) {
    const { disableScroll, enableScroll } = useStopScroll();
    watch(() => props.isOpen, isOpen => 
      isOpen ? disableScroll() : enableScroll());

    onBeforeUnmount(enableScroll);
    return {
      isTabletAndLower,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/Common/ModalWindow/ModalWindow.scss';
</style>
