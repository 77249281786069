<template>
  <v-chip
    class="order-chip"
    v-bind="variation"
  >
    <span class="order-chip__text">
      <slot />
    </span>
  </v-chip>
</template>
<script lang="ts">
import { PropType } from 'vue';

import { OrderStatus } from '@/store/Orders/types';
import { useOrderStatusChip } from './OrderStatusChip';

export default {
  props: {
    status: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
  },
  setup(props) {
    return useOrderStatusChip(props);
  },
};
</script>
<style lang="scss" scoped>
  @import './OrderStatusChip.scss';
</style>
