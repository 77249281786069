import { computed, ref, SetupContext, watch } from 'vue';
import { black50, primaryViolet } from '@/constants/colors';
import store from '@/store';
import {
  GET_IS_APPLYING_DESIGN,
  SET_IS_UNDO_REDO_HIDDEN,
} from '@/store/Editor/constants';
import { TemplateDesign } from '@/store/Editor/types';
import { maxLength, requiredField } from '@/utils/FieldValidationRules';

export const useTemplateDesignCard = (props: {
  isActive: boolean;
  templateDesign: TemplateDesign;
}, { emit }: SetupContext) => {
  const name = ref(props.templateDesign.name);
  const isApplyingDesign = computed(
    (): boolean => store.getters[GET_IS_APPLYING_DESIGN],
  );
  const nameRules = [ maxLength(40), requiredField ];
  const isValidName = ref(true);

  const openEditMode = (): void => {
    emit('openEditMode', props.templateDesign.id);
    name.value = props.templateDesign.name;
  };

  watch(isApplyingDesign, (): void => {
    store.commit(SET_IS_UNDO_REDO_HIDDEN, isApplyingDesign.value);
  });
  
  return {
    openEditMode,
    black50,
    primaryViolet,
    name,
    isApplyingDesign,
    nameRules,
    isValidName,
  };
};
