import { fabric } from 'fabric';
import { DEFAULT_BACKGROUND_COLOR } from '@/components/Editor/constants/defaultSettings';
import { MeshData } from '@/components/Editor/types/editor';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  PUSH_CANVAS_SIZE,
  SET_ZOOM_TO_DEFAULT,
} from '@/store/Editor/constants';
import { addBackgroundElementToCanvas } from './addBackgroundElementToCanvas';
import { addCanvasBackgroundPattern } from './addCanvasBackgroundPattern';
import { addOutlineImage } from './addOutlineImage';
import { getCanvasDataByMeshName } from './getCanvasDataByMeshName';
import { getCanvasZoomToDefault } from './getCanvasZoomToDefault';
import {
  getFabricCanvasSize,
} from './getFabricCanvasSize';
import { restoreElementsOnCanvas } from './restoreElementsOnCanvas';

export enum CanvasType {
  render = 'render',
  texture = 'texture',
  scan = 'scan',
  colorTexture = 'colorTexture',
}

export const createCanvas = async (
  mesh: MeshData,
  canvasType: CanvasType,
): Promise<FabricCanvas> => {
  const canvasSize = getFabricCanvasSize(mesh);
  const canvasDataByMesh = getCanvasDataByMeshName(mesh.name);
  const { height, width } = canvasSize;
  const zoomToDefault = getCanvasZoomToDefault(height);
  
  store.commit(SET_ZOOM_TO_DEFAULT, zoomToDefault);

  const canvas = new fabric.Canvas(`${canvasType}-${mesh.name}`, {
    controlsAboveOverlay: true,
    height,
    preserveObjectStacking: true,
    width,
  }) as FabricCanvas;

  canvas.realSize = canvasSize;

  if (canvasType === CanvasType.render) {
    addCanvasBackgroundPattern(canvas);
    await addOutlineImage(canvas);
  }

  addBackgroundElementToCanvas({
    canvas,
    backgroundColor: canvasDataByMesh?.backgroundColor
      ? canvasDataByMesh.backgroundColor
      : DEFAULT_BACKGROUND_COLOR,
    canvasSize,
    canvasType,
  });

  if (canvasDataByMesh) {
    await restoreElementsOnCanvas(
      canvas,
      canvasDataByMesh.canvasData,
      canvasSize,
    );
  }

  canvas.setZoom(zoomToDefault);

  store.commit(PUSH_CANVAS_SIZE, {
    meshName: mesh.name,
    size: canvasSize,
  });

  return canvas;
};
