// eslint-disable-next-line
import './assets/scss/main.scss';
import './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faComment,
  faDownload,
  faEdit,
  faEye,
  faEyeSlash,
  faFolder,
  faPaperPlane,
  faPlus,
  faRedo,
  faSave,
  faSearch,
  faShapes,
  faTimes,
  faUndo,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import VueGtag from 'vue-gtag';
import ProgressBar from 'vuejs-progress-bar';
import { VuePlugin } from 'vuera';
import Vuex from 'vuex';
import App from './App/App.vue';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.use(VueGtag, {
  config: { id: 'UA-195034409-1' },
});

Vue.use(ProgressBar);
Vue.use(VuePlugin);
Vue.use(Vuex);
library.add(
  faEye,
  faEyeSlash,
  faTimes,
  faSearch,
  faPlus,
  faSave,
  faArrowRight,
  faDownload,
  faFolder,
  faUndo,
  faRedo,
  faShapes,
  faComment,
  faPaperPlane,
  faVideo,
  faEdit,
);
Vue.use(vueDebounce);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.prototype._i18n = i18n;

new Vue({
  i18n,
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount('#app');
