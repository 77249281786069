<template>
  <div class="template-design">
    <div class="template-design__header">
      <div class="template-design__header-title">
        {{ $t('templates.label') }} ({{ templateDesigns.length }})
      </div>
      <div
        class="template-design__close-btn"
        @click="close"
      >
        <v-icon color="white">
          mdi-close
        </v-icon>
      </div>
    </div>
    <div class="template-design__search">
      <TextField
        v-model="designSearch"
        :outlined="false"
        :label="$t('templates.searchTemplate__label')"
        required
        input-type="text"
        hide-details
        @keydown.enter="searchTemplateDesigns"
      >
        <template #prepend-inner>
          <v-icon @click="searchTemplateDesigns">
            mdi-magnify
          </v-icon>
        </template>
      </TextField>
    </div>
    <div class="template-design__content pretty-scroll">
      <TemplateDesignCard
        v-for="templateDesign in templateDesigns"
        :key="templateDesign.id"
        :is-active="editDesignId === templateDesign.id"
        :template-design="templateDesign"
        class="template-design__content-item"
        @openEditMode="editDesignId = templateDesign.id"
        @applyDesign="applyDesign"
        @removeDesign="removeDesign"
        @editDesign="editDesign"
      />
    </div>
  </div>
</template>
<script lang="ts">
import TextField from '@/components/Common/TextField/TextField.vue';
import TemplateDesignCard from './TemplateDesignCard/TemplateDesignCard.vue';
import { useTemplateDesignSidebar } from './TemplateDesignSidebar';

export default {
  components: {
    TextField,
    TemplateDesignCard,
  },
  setup() {
    return useTemplateDesignSidebar();
  },
};
</script>
<style lang="scss" scoped>
  @import './TemplateDesignSidebar';
</style>
