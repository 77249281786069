import { uuid } from 'vue-uuid';
import {
  ADD_COLLECTIONS,
  CLEAR_COLLECTIONS,
  CREATE_LOCAL_COLLECTION,
  PUBLISH_COLLECTION,
  REMOVE_COLLECTION,
  REPLACE_LOCAL_COLLECTION,
  SET_COLLECTION_NAME,
  SET_COLLECTIONS,
  SET_COLLECTIONS_AMOUNT_OF_PAGES,
  SET_COLLECTIONS_LOADING,
  SET_COLLECTIONS_SELECTED_SORT,
  SET_IS_COLLECTION_IMAGE_LOADING,
  SET_IS_EDITING_RIGHTS_COLLECTIONS,
  SET_PREVIEW_IMAGE,
} from '@/store/Collections/constants';
import {
  Collection, 
  CollectionFields,
  CollectionState,
} from '@/store/Collections/types';
import { SortItems } from '@/types';

const findCollection = (
  state: CollectionState,
  field: string,
  value: number | string | boolean,
) => state.localCollections.find(collection => collection[field] === value);

export const mutations = {
  [SET_IS_EDITING_RIGHTS_COLLECTIONS]: (
    state: CollectionState,
    isEditingRights: boolean,
  ) => {
    state.isEditingRights = isEditingRights;
  },
  [ADD_COLLECTIONS]: (
    state: CollectionState,
    collections: Collection[],
  ) => {
    state.localCollections = [ ...state.localCollections, ...collections ];
  },
  [CLEAR_COLLECTIONS]: (
    state: CollectionState,
  ) => {
    state.localCollections = [];
  },
  [CREATE_LOCAL_COLLECTION]: (state: CollectionState) => {
    state.localCollections.unshift({
      createdAt: '',
      id: uuid.v4(),
      imagePath: '',
      isPublic: false,
      name: '',
      updatedAt: '',
      userId: -1,
    });
  },
  [PUBLISH_COLLECTION]: (
    state: CollectionState,
    id: number,
  ) => {
    const foundCollection = findCollection(state, CollectionFields.id, id);
    if (foundCollection) foundCollection.isPublic = !foundCollection.isPublic;
  },
  [REMOVE_COLLECTION]: (
    state: CollectionState,
    id: number,
  ) => {
    state.localCollections =
            state.localCollections.filter(
              collection => collection.id !== id,
            );
  },
  [REPLACE_LOCAL_COLLECTION]: (
    state: CollectionState,
    { collection, id }: {
            collection: Collection,
            id: number
        },
  ) => {
    state.localCollections.forEach((localCollection, index) => {
      if (localCollection.id === id)
        state.localCollections.splice(index, 1, collection);
    });
  },
  [SET_COLLECTION_NAME]: (
    state: CollectionState,
    { id, name }: {
            id: number,
            name: string
        },
  ) => {
    const foundCollection = findCollection(state, CollectionFields.id, id);
    if (foundCollection) foundCollection.name = name;
  },
  [SET_COLLECTIONS]: (
    state: CollectionState,
    collections: Collection[],
  ) => {
    state.localCollections = collections;
  },
  [SET_COLLECTIONS_AMOUNT_OF_PAGES]: (
    state: CollectionState,
    amountOfPages: number,
  ) => {
    state.amountOfPages = amountOfPages;
  },
  [SET_COLLECTIONS_LOADING]: (
    state: CollectionState,
    isLoading: boolean,
  ) => {
    state.isCollectionsLoading = isLoading;
  },
  [SET_COLLECTIONS_SELECTED_SORT]: (
    state: CollectionState,
    sortName: SortItems,
  ) => {
    state.selectedSort = sortName;
  },
  [SET_IS_COLLECTION_IMAGE_LOADING]: (
    state: CollectionState,
    isLoading: boolean,
  ) => {
    state.isCoverImageLoading = isLoading;
  },
  [SET_PREVIEW_IMAGE]: (
    state: CollectionState,
    { id, imagePath }: {
            id: number,
            imagePath: string
        },
  ) => {
    const foundCollection = findCollection(state, CollectionFields.id, id);
    if (foundCollection) foundCollection.imagePath = imagePath;
  },
};
