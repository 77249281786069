<template>
  <v-menu
    :value="isMenuOpen"
    :nudge-width="nudgeWidth"
    :attach="attach"
    :min-width="minWidth"
    offset-y
    rounded="lg"
    left
    right
    bottom
    nudge-bottom="3"
  >
    <template #activator="{ on, attrs, value }">
      <BaseButton
        class="open-button"
        no-margin-top
        icon
        :v-activator-props="attrs"
        :v-activator-emits="on"
        @click="isMenuOpen = true"
      > 
        <template #prependSvg>
          <div class="icon-wrapper">
            <v-icon
              :class="{'rotate-animate': value}"
              :color="primaryBlack"
              class="icon-wrapper__menu"
            > 
              {{ icon }}
            </v-icon>
          </div>
        </template>
      </BaseButton>
    </template>
    <slot
      name="content"
      :close-menu="closeMenu"
    />
  </v-menu>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useBaseMenu from './BaseMenu';

export default {
  components: {
    BaseButton,
  },
  props: {
    attach: {
      default: true,
      required: false,
      type: [ String, Boolean ],
    },
    icon: {
      default: 'mdi-dots-horizontal',
      required: false,
      type: String,
    },
    nudgeWidth: {
      default: 170,
      required: false,
      type: Number,
    },
    minWidth: {
      required: false,
      type: [ Number, String ],
    },
  },
  setup() {
    return useBaseMenu();
  },
};
</script>

<style lang="scss" scoped>
@import 'BaseMenu';
</style>
