<template>
  <div class="spinner-loader">
    <img
      class="spinner-loader__image"
      src="@/assets/spinner-loader.gif"
      :alt="$t('collections.loading__text')"
    >
    <span class="spinner-loader__text">
      {{ $t('collections.loading__text') }}
    </span>
  </div>
</template>

<script lang="ts">
export default {};
</script>

<style lang="scss" scoped>
@import 'SpinnerLoader';
</style>
