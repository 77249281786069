import { useI18n } from 'vue-i18n-composable';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { NotificationType } from '@/types/notification';

export const warnImageDPI = (
  { dpi, minDpi }: { dpi: number, minDpi: number },
): void => {
  const { t } = useI18n();
  const dpiInteger = Math.floor(dpi);

  if (dpiInteger < minDpi) {
    store.dispatch(SET_NOTIFICATION, {
      text: t('redactor.DPIisLessThan__text', { minDpi, currentDpi: dpiInteger }),
      type: NotificationType.Warning,
    });
  }
};
