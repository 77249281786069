import { isEqual } from 'lodash';
import { reactive, ref, watch } from 'vue';

export const useForm = <T extends object>(
  data: T,
  { changeCheck }: { changeCheck?: boolean } = {},
) => {
  const form = reactive<T>(data);
  const formInitial = reactive<T>({ ...data });
  const hasChanged = ref(false);

  const overwriteObject = (obj: object, data: Partial<T>): void => {
    Object.entries(data).forEach(([ key, val ]) => {
      obj[key] = val;
    });
  };

  const update = (
    data: Partial<T>,
    { overwriteInitial }: { overwriteInitial?: boolean } = {},
  ): void => {
    overwriteObject(form, data);
    if (overwriteInitial) {
      overwriteInitialForm(data);
      hasChanged.value = false;
    }
  };

  const overwriteInitialForm = (data: Partial<T>): void => {
    overwriteObject(formInitial, data);
  };

  watch(form, () => {
    if (!changeCheck) return;
    hasChanged.value = !isEqual(form, formInitial);
  });

  return {
    form,
    hasChanged,
    overwriteInitialForm,
    update,
  };
};
