import * as PromiseBird from 'bluebird';
import store from '@/store';
import { GET_USER, LOAD_CUSTOM_FONTS } from '@/store/Authorization/constants';
import {
  ADD_FONT_TO_RENDER,
  GET_ACTIVE_CANVAS,
  GET_FONTS_TO_RENDER,
} from '@/store/Editor/constants';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';
import { getGoogleFont } from './getGoogleFont';
import { loadFonts } from './loadFonts';

export const loadCustomFonts = async (
  loadFontList: string[],
  flag?: boolean,
): Promise<void> => {
  const fontsToRender = store.getters[GET_FONTS_TO_RENDER];
  const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
  await PromiseBird.each(
    loadFontList,
    async (font: string): Promise<void> => {
      if (fontsToRender.includes(font)) return;
      if (!flag) {
        await getGoogleFont(font, false);
      }
      store.commit(ADD_FONT_TO_RENDER, font);
      await loadFonts(fontsToRender);
      if (activeCanvas.id)
        await activeCanvas.requestRenderAll();
    },
  );
};

export const initCustomFonts = async (): Promise<void> => {
  const template = store.getters[GET_CURRENT_TEMPLATE];
  const user = store.getters[GET_USER];
  await store.dispatch(LOAD_CUSTOM_FONTS, {
    id: user?.id || template?.userId,
  });
  if (user.customFonts.length) {
    await loadCustomFonts(JSON.parse(user.customFonts));
  }
};
