<template>
  <div>
    <TextFontSizeMobile
      v-if="isMobile"
      v-bind="TextFontSizeProps"
      v-on="TextFontSizeEmits" 
    />
    <TextFontSizeDesktop
      v-else
      v-bind="TextFontSizeProps"
      v-on="TextFontSizeEmits" 
    />
  </div>
</template>

<script lang="ts">
import useTextFontSize from './TextFontSize';
import TextFontSizeDesktop from './TextFontSizeDesktop/TextFontSizeDesktop.vue';
import TextFontSizeMobile from './TextFontSizeMobile/TextFontSizeMobile.vue';

export default {
  components: {
    TextFontSizeDesktop,
    TextFontSizeMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
    items: {
      required: false,
      type: Array,
    },
  },
  setup() {
    return useTextFontSize();
  },
};
</script>
