import {
  isObjectLocked,
  setObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import {
  FabricCanvas,
  FabricObject,
} from '@/components/Editor/types/fabric';
import {
  activeObjectCenterScaling,
} from '@/components/Editor/utils/activeObjectCenterScaling';

export const changeActiveObject = ({
  canvas,
  currentActiveObject,
  previousActiveObject,
}: {
  canvas: FabricCanvas,
  currentActiveObject: FabricObject
  previousActiveObject: FabricObject,
}): void => {
  const objectToSelect = (canvas.getObjects() as FabricObject[])
    .find((object: FabricObject): boolean => {
      return object?.id === currentActiveObject?.id;
    });

  if (objectToSelect) {
    objectToSelect.set(currentActiveObject);
    objectToSelect.setCoords();
    if (
      isObjectLocked(currentActiveObject)
      !== isObjectLocked(previousActiveObject)
    ) {
      setObjectLocked({
        isLocked: isObjectLocked(currentActiveObject),
        object: objectToSelect,
        shouldSaveInStore: false,
      });
    }
    activeObjectCenterScaling(objectToSelect);
    canvas._setActiveObject(objectToSelect);
    canvas.renderAll();
  }
};
