<template>
  <div
    v-click-outside="closeMenu" 
    class="select__wrapper"
  >
    <v-select
      ref="baseSelect"
      class="sort__select select"
      :value="value"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :height="height"
      :dense="dense"
      :background-color="backgroundColor"
      :menu-props="{
        ...menuProps,
        offsetY: true,
        openOnClick: false,
        value: isMenuOpened
      }"
      :color="color"
      :attach="attach"
      :placeholder="placeholder"
      :hide-details="hideDetails"
      :rules="rules"
      :label="label"
      outlined
      :disabled="disabled"
      :return-object="returnObject"
      @input="$emit('input', $event)"
      @click.native="toggleMenu"
    />
  </div>
</template>

<script lang="ts">
import { primaryViolet, whiteSmoke } from '@/constants/colors';
import { baseSelectHeight } from '@/constants/editor';
import useBaseSelect from './BaseSelect';

export default {
  props: {
    attach: {
      required: false,
      type: [ Boolean, String ],
    },
    backgroundColor: {
      default: whiteSmoke,
      required: false,
      type: String,
    },
    color: {
      default: primaryViolet,
      required: false,
      type: String,
    },
    dense: {
      default: true,
      required: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    height: {
      default: baseSelectHeight,
      required: false,
      type: Number,
    },
    hideDetails: {
      default: true,
      required: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
    },
    itemText: {
      required: false,
      type: String,
    },
    itemValue: {
      required: false,
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    menuProps: {
      required: false,
      type: Object,
    },
    placeholder: {
      required: false,
      type: String,
    },
    returnObject: {
      required: false,
      type: Boolean,
    },
    rules: {
      required: false,
      type: Array,
    },
    value: {
      default: '',
      required: true,
      type: [ String, Number, Object ],
    },
  },
  setup() {
    return useBaseSelect();
  },
};
</script>

<style lang="scss">
@import "BaseSelect";
</style>
