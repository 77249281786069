<template>
  <ModalWindow
    :is-open="isOpen"
    content-class="mobile-radio-selector"
    @close="$emit('close')"
  >
    <template #header>
      <div class="mobile-radio-selector__heading">
        {{ $t('addToMyCollectionsModal.selectCollection__label') }}
      </div>
    </template>
    <div class="mobile-radio-selector__list pretty-scroll__overlaying">
      <v-radio-group 
        :value="selectedCollection" 
        @change="selectCollection"
      >
        <v-radio
          v-for="collection in collections"
          :key="collection.id"
          v-ripple
          class="mobile-radio-selector__list-item"
          :label="collection.name"
          :value="collection"
          :color="primaryViolet"
        />
      </v-radio-group>
    </div>
    <div class="mobile-radio-selector__save">
      <BaseButton
        large
        no-margin-top
        class="mobile-radio-selector__save-btn"
        :color="primaryViolet"
        :button-text="$t('addToMyCollectionsModal.select__label')"
        @click="updateSelectedCollection"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useCollectionsSelectorMobile from './CollectionsSelectorMobile';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    collections: {
      required: false,
      type: Array,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    selectedCollection: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return useCollectionsSelectorMobile(props, context);
  },
};
</script>
