<template>
  <v-form
    v-model="isValidForm"
    class="password-form"
  >
    <BaseTitle>
      {{ $t('createNewPassword.createNewPassword') }}
    </BaseTitle>
    <TextField
      v-model="newPassword"
      required
      input-type="password"
      :rules="newPasswordRules"
      :label="$t('createNewPassword.newPasswordInput__placeholder')"
      @keydown.space.prevent
    />
    <TextField
      v-model="confirmNewPassword"
      required
      input-type="password"
      :rules="confirmNewPasswordRules"
      :label="$t('createNewPassword.confirmNewPasswordInput__placeholder')"
      @keydown.space.prevent
    />
    <BaseButton
      class="password-form__button"
      has-background
      :disabled="!isValidForm"
      :button-text="$t('createNewPassword.resetPassword__button')"
      :large="isMobile"
      @click="resetPassword"
    />
  </v-form>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useCreatePasswordForm from './CreatePasswordForm';

export default {
  components: {
    BaseButton,
    BaseTitle,
    TextField,
  },
  setup() {
    return useCreatePasswordForm();
  },
};
</script>

<style lang="scss" scoped>
@import 'CreatePasswordForm';
</style>
