import { ref } from 'vue';

const useImageSettingsMobile = () => {
  const isAlignmentsActive = ref(false);
  const isRotationActive = ref(false);

  const toggleAlignments = (): void => {
    isAlignmentsActive.value = !isAlignmentsActive.value;
  };

  const closeAlignments = (): void => {
    isAlignmentsActive.value = false;
  };

  const toggleRotation = (): void => {
    isRotationActive.value = !isRotationActive.value;
  };

  const closeRotation = (): void => {
    isRotationActive.value = false;
  };

  return {
    closeAlignments,
    closeRotation,
    isAlignmentsActive,
    isRotationActive,
    toggleAlignments,
    toggleRotation,
  };
};

export default useImageSettingsMobile;
