import { computed, toRef } from 'vue';
import { ObjectAlignments } from '@/components/Editor/types/editor';
import { primaryViolet } from '@/constants/colors';
import {
  alignTextCenterIconData,
  alignTextJustifyrIconData,
  alignTextLeftIconData,
  alignTextRightIconData,
} from '@/constants/editor';

const getAlignment = (value: string): string => {
  switch (value) {
  case ObjectAlignments.left:
    return alignTextLeftIconData;
  case ObjectAlignments.right:
    return alignTextRightIconData;
  case ObjectAlignments.center:
    return alignTextCenterIconData;
  case ObjectAlignments.justify:
    return alignTextJustifyrIconData;
  default:
    return '';
  }
}; 

const useAlignmentIcon = props => {
  const textAlignment = toRef(props, 'textAlignment');
  const alignment = toRef(props, 'alignmentType');

  const color = computed(() =>
    textAlignment.value === alignment.value ? primaryViolet : '#000000',
  );

  const svgData = getAlignment(alignment.value);

  return {
    color,
    svgData,
  };
};

export default useAlignmentIcon;
