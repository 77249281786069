import { priceRegex, weightRegex } from '@/constants/regex';

export const isPriceValid = (v: string | number): boolean => {
  return priceRegex.test(v.toString());
};

export const isWeightValid = (v: string | number): boolean => {
  return weightRegex.test(v.toString());
};

export const isRangeFieldsValid = (
  min = '',
  max = '',
  fieldValidation = (v: number) => true,
): {
  isMaxValid: boolean;
  isMinValid: boolean
} => {
  let isMinValid = true;
  let isMaxValid = true;

  if (min === '' && max === '') {
    isMaxValid = false;
    isMinValid = false;
  }
  if (min !== '' && max !== '') {
    const isValid = Number(max) > Number(min);
    isMaxValid = isValid;
    isMinValid = isValid;
  }
  if (min !== '') isMinValid = isMinValid && fieldValidation(Number(min));
  if (max !== '') isMaxValid = isMaxValid && fieldValidation(Number(max));

  return {
    isMaxValid,
    isMinValid,
  };
};
