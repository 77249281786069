<template>
  <div>
    <img
      class="color-picker-pipette"
      src="@/assets/icons/color-picker-pipette.svg"
      :alt="$t('redactor.pipette__button')"
      @click="togglePipette"
    >
  </div>
</template>

<script lang="ts">
import useColorPickerPipette from '@/components/Common/ColorPicker/ColorPickerPipette/ColorPickerPipette';

export default {
  setup(props, context) {
    return { ...useColorPickerPipette(props, context) };
  },
};
</script>

<style scoped lang="scss">
@import 'ColorPickerPipette';
</style>
