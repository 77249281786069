<template>
  <div class="alignments-desktop">
    <v-divider />
    <BaseAccordion>
      <BaseAccordionItem>
        <template #header>
          {{ $t('alignments.alignment__label') }}
        </template>
        <template #content>
          <div class="options__row">
            <BaseButton
              v-for="{ name, icon } in horizontalAlignments"
              :key="name"
              :apply-color-to-text="true"
              class="alignments__option"
              @click="$emit('triggerAlignment', name)"
            >
              <template #prependSvg>
                <BaseIcon>
                  <component
                    :is="icon"
                  />
                </BaseIcon>
              </template>
            </BaseButton>
          </div>
          <div class="alignments-desktop__divider" />
          <div class="options__row">
            <BaseButton
              v-for="{ name, icon } in verticalAlignments"
              :key="name"
              :apply-color-to-text="true"
              class="alignments__option"
              @click="$emit('triggerAlignment', name)"
            >
              <template #prependSvg>
                <BaseIcon>
                  <component
                    :is="icon"
                  />
                </BaseIcon>
              </template>
            </BaseButton>
          </div>
        </template>
      </BaseAccordionItem>
    </BaseAccordion>
  </div>
</template>

<script lang="ts">
import BaseAccordion from '@/components/Common/BaseAccordion/BaseAccordion.vue';
import BaseAccordionItem from '@/components/Common/BaseAccordion/BaseAccordionItem/BaseAccordionItem.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { black87 } from '@/constants/colors';

export default {
  components: { BaseButton, BaseIcon, BaseAccordion, BaseAccordionItem },
  props: {
    horizontalAlignments: {
      required: true,
      type: Array,
    },
    verticalAlignments: {
      required: true,
      type: Array,
    },
  },
  emits:['triggerAlignment'],
  setup() {
    return {
      black87,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "AlignmentsDesktop";
</style>
