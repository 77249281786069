import { Rate } from '@/store/Shipping/types';

export const localRate: Rate = {
  createdAt: '',
  currency: '',
  deliveryZoneId: -1,
  id: -1,
  name: '',
  price: '',
  updatedAt: '',
  weightConditions: [],
};
