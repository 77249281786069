import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { secondaryViolet } from '@/constants/colors';
import router from '@/router';
import { isTabletAndLower } from '@/utils/Mobile';
import { UserGuideTabs } from './types';
import UserGuideCollections from './UserGuideCollections/UserGuideCollections.vue';
import UserGuideEditor from './UserGuideEditor/UserGuideEditor.vue';
import UserGuideMyProfile from './UserGuideMyProfile/UserGuideMyProfile.vue';
import UserGuidePrintingAndShipping from './UserGuidePrintingAndShipping/UserGuidePrintingAndShipping.vue';
import UserGuideShopifyIntegration from './UserGuideShopifyIntegration/UserGuideShopifyIntegration.vue';
import UserGuideWelcome from './UserGuideWelcome/UserGuideWelcome.vue';

const useUserGuide = () => {
  const { t } = useI18n();
  
  const routeHash = computed(() => router.currentRoute.hash);
  const isPageOpen = ref(!!routeHash.value);
  const currentTab = ref(routeHash.value.slice(1));
  const userGuideTabs = [
    {
      component: UserGuideWelcome,
      hideOnWelcomePage: true,
      isHeadingTab: true,
      name: UserGuideTabs.Welcome,
      title: t('userGuide.userGuideTab__title'),
    },
    {
      component: UserGuideCollections,
      name: UserGuideTabs.Collections,
      title: t('userGuide.collectionsTab__title'),
    },
    {
      component: UserGuideEditor,
      name: UserGuideTabs.Editor,
      title: t('userGuide.editorTab__title'),
    },
    {
      component: UserGuideMyProfile,
      name: UserGuideTabs.MyProfile,
      title: t('userGuide.myProfileTab__title'),
    },
    {
      component: UserGuideShopifyIntegration,
      name: UserGuideTabs.ShopifyIntegration,
      title: t('userGuide.shopifyIntegrationTab__title'),
    },
    {
      component: UserGuidePrintingAndShipping,
      name: UserGuideTabs.PrintingAndShipping,
      title: t('userGuide.printingAndShippingTab__title'),
    },
  ];
  
  const navigateToTab = (name: string): void => {
    const foundTab = userGuideTabs.find(tab => tab.name === name);
    if (foundTab && !isTabOpened(foundTab.name)) {
      router.push({ hash: `#${foundTab.name}`, name: 'user-guide' });
      currentTab.value = foundTab.name;
      isPageOpen.value = true;
    }
  };

  const closePage = (): void => {
    isPageOpen.value = false;
    currentTab.value = '';
    const isUserGuidePage = !router.currentRoute.hash;
    if (!isUserGuidePage)
      router.push({ name: 'user-guide' });
  };

  const isTabOpened = (name: string): boolean => currentTab.value === name;

  watch(routeHash, hash => {
    if (!hash) {
      isTabletAndLower
        ? closePage()
        : navigateToTab(UserGuideTabs.Welcome);
    }
  });

  onMounted(() => {
    if (!isTabletAndLower) {
      const tabName = routeHash.value.slice(1);
      tabName
        ? isTabOpened(tabName) || navigateToTab(tabName)
        : navigateToTab(UserGuideTabs.Welcome);
    }
  });

  return {
    closePage,
    currentTab,
    isPageOpen,
    isTabletAndLower,
    navigateToTab,
    secondaryViolet,
    userGuideTabs,
  };
};

export default useUserGuide;
