import { UserRoles } from '@/constants/roles';
import { actions } from '@/store/Authorization/actions';
import { getters } from '@/store/Authorization/getters';
import { mutations } from '@/store/Authorization/mutations';
import {
  SignInType,
  UserState,
} from '@/store/Authorization/types';
import { lang } from '@/types/localization';

export const defaultUser = {
  customFonts: '',
  email: '',
  exp: null,
  forename: '',
  iat: null,
  id: null,
  isActivated: false,
  role: UserRoles.Visitor,
  surname: '',
};

const state: UserState = {
  isAuthorized: false,
  localization: lang.en,
  signInWithType: SignInType.default,
  user: defaultUser,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
