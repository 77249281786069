import { ZoomVariants } from '@/components/Editor/types/editor';
import { ZoomCoef } from '@/constants/editor';
import { roundToFixed } from '@/utils/math/roundToFixed';

export const calcZoom = (currentZoom: number, option: ZoomVariants): number => {
  switch (option) {
  case ZoomVariants.zoomIn:
    return roundToFixed(currentZoom * ZoomCoef, 2);
  case ZoomVariants.zoomOut:
    return roundToFixed(currentZoom / ZoomCoef, 2);
  case ZoomVariants.zoomFull:
    return 1;
  }
};
