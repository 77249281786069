export interface ShopifyStore {
  id: number;
  isDeactivated: boolean;
  store: string;
}

export const defaultShopifyStore: Partial<ShopifyStore> = {
  id: undefined,
  isDeactivated: false,
  store: undefined,
};

export interface ShopifyState {
  isStoreResetting: boolean,
  isThemesLoading: boolean;
  shopifyStore: ShopifyStore | typeof defaultShopifyStore;
  themes: []
  website: string
}

type payloadType = number | string | object | boolean 
    | (number | string | object)[];

export interface Context {
  commit: (type: string, payload?: payloadType) => void;
  dispatch: (type: string, payload: payloadType) => void;
  state: ShopifyState;
}
