import { computed, reactive, ref, watch } from 'vue';
import store from '@/store';
import {
  ACTIVATE_EDITOR,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_IFRAME,
  GET_IS_MOBILE,
} from '@/store/Editor/constants';

const useChangeModeNote = () => {
  const noteVisible = ref(true);

  const isIframe = computed((): boolean => store.getters[GET_IS_IFRAME]);
  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);

  const closeNote = (): void => {
    noteVisible.value = false;
  };

  const isEditorActive = computed(
    (): boolean => store.getters[GET_IS_EDITOR_ACTIVE],
  );

  watch(isEditorActive, (): void => {
    if (isEditorActive.value) closeNote();
  });

  const goToEditorView = async () => {
    closeNote();
    if (!isIframe.value) {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: document.body.scrollHeight,
      });
    }
    store.dispatch(ACTIVATE_EDITOR, true);
  };

  const changeModeNoteProps = reactive({
    isEditorActive,
    noteVisible,
  });

  const changeModeNoteEmits = {
    closeNote,
    goToEditorView,
  };

  return {
    changeModeNoteEmits,
    changeModeNoteProps,
    isMobile,
  };
};

export default useChangeModeNote;
