import { useI18n } from 'vue-i18n-composable';
import { OBJECT_CUSTOM_ATTRIBUTES } from '@/components/Editor/constants/customConfigs';
import {
  FabricObject,
  FabricObjectType,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { 
  CLEAR_LAST_UNDO,
  DELETE_OBJECT,
  DISABLE_SETTINGS_ON_IMAGE_LOADING, 
  GET_ACTIVE_CANVAS,
  GET_ACTIVE_MESH,
  GET_IS_LOADED_FROM_HISTORY,
  GET_MODEL_SIZE_IN_MM,
  GET_ZOOM_TO_DEFAULT,
  SET_IMAGE_OBJECT,
} from '@/store/Editor/constants';
import { UPLOAD_IMAGE } from '@/store/Templates/constants';
import { NotificationType } from '@/types/notification';
import { getCurrentCanvasSize } from '../canvasModifiers/getCurrentCanvasSize';
import { createFabricImage, prepareDataForFabricImage } from './newFabricImage';
import { setCustomPropsToImage } from './setCustomPropsToImage';
import {
  getFileType,
  validateImageFormat,
  validateImageSize,
} from './validateImageToUpload';

const loadImage = async (file: File, id: string): Promise<string | false> => {
  store.dispatch(DISABLE_SETTINGS_ON_IMAGE_LOADING, true);
  const isSizeValid = validateImageSize(file);
  const isFormatValid = validateImageFormat(file);
  const fileType = getFileType(file);

  if (!isSizeValid || !isFormatValid) {
    store.commit(DELETE_OBJECT, { id, type: FabricObjectType.image });
    store.commit(CLEAR_LAST_UNDO);
    return false;
  }

  const data = new FormData();
  data.append('file', file);
  data.append('typeFile', fileType);

  return await store.dispatch(UPLOAD_IMAGE, { data });
};

export const addNewImageOnCanvas = async (
  imageObject: FabricObject,
) => {
  const { t } = useI18n();
  try {
    const meshName = store.getters[GET_ACTIVE_MESH].name;
    const modelSizeInMM = store.getters[GET_MODEL_SIZE_IN_MM];
    const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
    const zoomToDefault = store.getters[GET_ZOOM_TO_DEFAULT];
    const isLoadedFromHistory = store.getters[GET_IS_LOADED_FROM_HISTORY];

    const canvasSize = getCurrentCanvasSize(meshName);

    const { file, id, isDefault, layerName, layerNumber, src } = imageObject;
    let imagePath: any = src;

    if (file) {
      imagePath = await loadImage(file, id || '');
      if (!imagePath) return;
    }

    let newImage: any = imageObject;

    if (isDefault) {
      newImage = prepareDataForFabricImage({
        canvasSize,
        src: imagePath,
        layerNumber: layerNumber || 1,
        layerName: layerName || '',  
        id: id || '',
        zoomToDefault,
      });
    }

    const newFabricImage = await createFabricImage({
      canvasSize,
      imageSettings: newImage,
      applyCanvasZoom: true,
      isCanvasRestored: false,
    }) as FabricObject;

    if (modelSizeInMM) {
      setCustomPropsToImage(activeCanvas, newFabricImage, true);
    }

    activeCanvas.add(newFabricImage);
    !isLoadedFromHistory.value && activeCanvas.setActiveObject(newFabricImage);

    store.commit(
      SET_IMAGE_OBJECT,
      activeCanvas.toObject(OBJECT_CUSTOM_ATTRIBUTES),
    );
  } catch (error) {
    store.dispatch(SET_NOTIFICATION, {
      text: t('notifications.somethingWentWrong'),
      type: NotificationType.Error,
    });
  } finally {
    store.dispatch(DISABLE_SETTINGS_ON_IMAGE_LOADING, false);
  }
};
