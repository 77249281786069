import { Color, Mesh, MeshPhysicalMaterial } from 'three';
import store from '@/store';
import { GET_ACTIVE_MESH, GET_SINGLE_EDITABLE_MESH } from '@/store/Editor/constants';
import { shadowAllMeshs } from './shadowAllMeshs';

export const highlightSelectedMesh = (meshList: Mesh[]): void => {
  const activeMesh = store.getters[GET_ACTIVE_MESH];
  const isSingleSide = store.getters[GET_SINGLE_EDITABLE_MESH];
  if (!activeMesh || isSingleSide) return;
  shadowAllMeshs(meshList);
  const { name: meshName } = activeMesh;
  const mesh = meshList.find(({ name }) => name === meshName);
  if (mesh) {
    const material = mesh.material as MeshPhysicalMaterial;
    material.color = new Color('#FFFFFF');
  }
};
