import { computed, onMounted } from 'vue';
import router from '@/router/index';
import store from '@/store';
import {
  GET_CURRENT_TEMPLATE,
  GET_IS_TEMPLATES_LOADING,
  GET_SHOPIFY_PRODUCT,
} from '@/store/Templates/constants';

export const useShopifyPreview = () => {
  const template = computed((): boolean =>
    store.getters[GET_CURRENT_TEMPLATE]);

  const isLoading = computed((): boolean =>
    store.getters[GET_IS_TEMPLATES_LOADING]);
  
  onMounted(async () => {  
    const { productId, storeId, variantId } = router.currentRoute.params;
    await store.dispatch(
      GET_SHOPIFY_PRODUCT,
      { productId, storeId, variantId },
    );
  });

  return {
    template,
    isLoading,
  };
};
