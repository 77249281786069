var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorContainer",staticClass:"object-wrapper",class:{
    'object-wrapper_shopify': _vm.isCustomizationFrame,
  }},[(!_vm.isIframe)?_c('EditorHeader',{attrs:{"is-public":_vm.isPublic,"is-loading":_vm.isEditorLoading,"is-scan-loading":_vm.isScanLoading,"is-shopify-loading":_vm.isShopifyLoading},on:{"getScan":_vm.requestScan,"sendToShopify":_vm.sendToShopify}}):_vm._e(),(_vm.isImageLoading)?_c('div',{staticClass:"image-loader",class:{
      'image-loader_shopify': _vm.isCustomizationFrame,
      'image-loader_mobile': _vm.isMobile,
      'image-loader_shopify-mobile': _vm.isMobile && _vm.isCustomizationFrame
    }}):_vm._e(),(_vm.isDesktopSidebarVisible && _vm.isDesignMode && !_vm.isIframe)?_c('TemplateDesignSidebar'):_vm._e(),(_vm.isDesktopSidebarVisible && !_vm.isDesignMode)?_c('EditorSideControls',{attrs:{"is-customization-frame":_vm.isCustomizationFrame}}):_vm._e(),(_vm.isCustomizationFrame)?_c('AddToOrderPanel',{attrs:{"template-name":_vm.template.name,"is-loading":_vm.isAddToOrderLoading,"is-mobile":_vm.isMobile,"is-object-selected":(_vm.isTextActive || _vm.isImageActive) && _vm.isEditorActive},on:{"addToShopifyOrder":_vm.addToShopifyOrder}}):_vm._e(),_vm._m(0),_c('div',{staticClass:"editor3d__overflow-controls"},[(_vm.isAddToMyCollectionsModalOpen)?_c('AddToMyCollectionsModal',{attrs:{"is-open":_vm.isAddToMyCollectionsModalOpen,"template":_vm.template}}):_vm._e(),(_vm.isAddFontModalOpen && !_vm.isIframe)?_c('AddFontModal',{attrs:{"is-open":_vm.isAddFontModalOpen}}):_vm._e(),(_vm.isRemoveFontModalOpen && !_vm.isIframe)?_c('RemoveFontModal'):_vm._e(),(_vm.isControlsVisible)?_c('ViewSwitcher',{attrs:{"is-shopify-page":_vm.isCustomizationFrame,"disabled":!_vm.activeMesh}}):_vm._e(),(!_vm.singleEditableMesh && _vm.isControlsVisible)?_c('SceneControls'):_vm._e(),(_vm.isMobile)?_c('UndoRedoIcons'):_vm._e(),(_vm.isControlsVisible && _vm.singleEditableMesh)?_c('ChangeModeNote',{attrs:{"is-shopify-page":_vm.isCustomizationFrame}}):_vm._e()],1),_c('div',{class:['editor3d_container', {
      'height-mobile': _vm.isMobile && _vm.isEditorActive,
      'height-mobile_shopify':
        _vm.isMobile && _vm.isCustomizationFrame,
      'height-mobile_shopify-editor':
        _vm.isMobile && _vm.isEditorActive && _vm.isCustomizationFrame,
      'full-width': !_vm.isEditorActive || _vm.isMobile,
      'selected-textbox': _vm.isMobile && _vm.isTextActive && _vm.isEditorActive,
      'selected-image': _vm.isMobile && _vm.isImageActive && _vm.isEditorActive,
      'selected-textbox_shopify':
        _vm.isMobile && _vm.isTextActive && _vm.isCustomizationFrame && _vm.isEditorActive,
      'selected-image_shopify':
        _vm.isMobile && _vm.isImageActive && _vm.isCustomizationFrame && _vm.isEditorActive,
    }]},[(_vm.isEditorLoading)?_c('div',{staticClass:"main-loader",class:{'main-loader_shopify': _vm.isCustomizationFrame}},[_c('img',{attrs:{"src":_vm.editorLoader,"alt":_vm.$t('redactor.loader_alt')}})]):_vm._e(),_c('div',{class:['editor2d', {
        'editor2d_width-mobile': _vm.isMobile
      }]},[_c('EditorCanvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditorActive),expression:"isEditorActive"}],attrs:{"is-shopify-page":_vm.isCustomizationFrame,"is-image-loading":_vm.isImageLoading,"is-mobile":_vm.isMobile,"is-object-selected":(_vm.isTextActive || _vm.isImageActive)},scopedSlots:_vm._u([(_vm.isDesignMode)?{key:"overlay",fn:function(){return [_c('div',{staticClass:"editor__save-template-btn"},[_c('BaseButton',{attrs:{"color":_vm.primaryViolet,"button-text":_vm.$t('templates.saveTemplate__text'),"disabled":_vm.isSaveDesignDisabled,"large":"","no-margin-top":""},on:{"click":_vm.copyDesign},scopedSlots:_vm._u([{key:"prependSvg",fn:function(){return [_c('BookMarkIcon',{staticClass:"editor__save-template-btn-icon"})]},proxy:true}],null,false,1682499277)})],1)]},proxy:true}:null],null,true)}),(_vm.isControlsVisible)?_c('div',[(_vm.isEditorActive && !_vm.isMobile)?_c('EditorControls',{style:(`transform: ${_vm.controlsStyleMods.controlsStyle}`),attrs:{"is-shopify-page":_vm.isCustomizationFrame}}):_vm._e(),(_vm.isEditorActive)?_c('EditorScaleControls',{style:(`transform: ${_vm.controlsStyleMods.scaleControlsStyle}`),attrs:{"is-shopify-page":_vm.isCustomizationFrame}}):_vm._e()],1):_vm._e()],1),(_vm.isModelReadyToLoad)?_c('Scene',{ref:"sceneRef",staticClass:"model-container",class:{'full-height': _vm.isIframe},attrs:{"call-scene-scan":_vm.callSceneScan,"scene-background":_vm.sceneBackground,"is-controls-visible":_vm.isControlsVisible}}):_vm._e()],1),(_vm.isMobile && _vm.isEditorActive)?_c('EditorSideControls',{attrs:{"is-customization-frame":_vm.isCustomizationFrame}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none !important"}},[_c('canvas',{attrs:{"id":"texture-canvas"}})])
}]

export { render, staticRenderFns }