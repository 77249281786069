<template>
  <header
    :class="[
      'editor-header',
      { 'editor-header_public': isPublic }
    ]"
  >
    <div class="left-menu">
      <div 
        class="left-menu__logo-button" 
        @click="() =>
          $router.push({ name: CollectionRouteTypes.privateCollections })"
      >
        <img src="@/assets/images/logo-box-white.svg">
      </div>
      <BaseButton
        class="left-menu__back-button"
        color="white"
        :button-text="$t('redactor.back__button')"
        tile
        plain
        dark
        no-margin-top
        @click="$router.go(-1)"
      >
        <template #prependSvg>
          <img src="@/assets/icons/back-icon.svg">
        </template>
      </BaseButton>
    </div>
    <div class="template-name">
      <div v-if="!isNameEditing">
        <div class="template-name_initial">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ templateName }}
              </span>
            </template>
            <span>{{ templateName }}</span>
          </v-tooltip>
        </div>
        <BaseButton
          v-if="!isPublic"
          light
          icon="mdi-pencil"
          no-margin-top
          :color="white50"
          @click="$emit('toggleIsNameEditing')"
        />
      </div>

      <v-form
        v-else
        :value="isValidName"
        @input="$emit('setIsValidName', $event)"
      >
        <div
          v-click-outside="{
            handler: () => $emit('saveEditedName'),
            include: () => ignorableClickOutsideElements
          }"
          class="template-name_edit"
        >
          <TextField
            dark
            autofocus
            color="white"
            input-type="text"
            :value="templateName"
            :outlined="false"
            :rules="templateNameRules"
            @input="$emit('setTemplateName', $event)"
            @keydown.enter.stop.prevent="$emit('saveEditedName')"
          >
            <template #append>
              <BaseButton
                icon="mdi-check"
                dark
                color="white"
                no-margin-top
                :disabled="!isValidName"
                @click="$emit('saveEditedName')"
              />
            </template>
          </TextField>
        </div>
      </v-form>
    </div>
    <BaseButton
      v-if="isPublic"
      v-show="!isLoading"
      class="add-to-my-collections-button"
      prepend-icon="mdi-plus"
      no-margin-top
      :button-text="$t('redactor.addToMyCollections__text')"
      @click="$emit('openAddToMyCollectionModal')"
    />
    <div 
      v-else
      v-show="!isLoading"
      class="right-menu"
    >
      <div class="right-menu__icons">
        <UndoRedoIcons />
        <BaseButton
          color="white"
          no-margin-top
          height="80"
          tile
          plain
          dark
          :is-loading="isShopifyLoading"
          @click="$emit('sendToShopifyApp')"
        >
          <template #prependSvg>
            <BaseTooltip 
              :icon="shopifyIcon"
              bottom
            >
              {{ $t("redactor.add_to_shopify__text") }}
            </BaseTooltip>
          </template>
        </BaseButton>
        <BaseButton
          color="white"
          tile
          plain
          dark
          no-margin-top
          height="80"
          :is-loading="isScanLoading"
          @click="$emit('getScan')"
        >
          <template #prependSvg>
            <BaseTooltip 
              :icon="downloadIcon"
              :bottom="true"
            >
              {{ $t("redactor.download__button") }}
            </BaseTooltip>
          </template>
        </BaseButton>
      </div>
      <BaseButton
        dark
        rounded
        large
        class="right-menu__save-button"
        no-margin-top
        :is-loading="isSaving"
        :button-text="$t('redactor.save__button')"
        @click="$emit('saveTemplate')"
      />
    </div>
  </header>
</template>

<script lang="ts">
import downloadIcon from '@/assets/icons/download-icon.svg';
import shopifyIcon from '@/assets/icons/shopify-icon.svg';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';
import { CollectionRouteTypes } from '@/views/Collections/types';

export default {
  components: {
    BaseButton,
    BaseTooltip,
    TextField,
    UndoRedoIcons,
  },
  props: {
    isNameEditing: {
      required: true,
      type: Boolean,
    },
    isPublic: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    isSaving: {
      required: true,
      type: Boolean,
    },
    isScanLoading: {
      required: true,
      type: Boolean,
    },
    isShopifyLoading: {
      required: true,
      type: Boolean,
    },
    isValidName: {
      required: true,
      type: Boolean,
    },
    templateName: {
      type: String,
    },
    templateNameRules: {
      required: true,
      type: Array,
    },
    white50: {
      required: true,
      type: String,
    },
  },
  setup() {
    const ignorableClickOutsideElements = [document.querySelector('.global-alert')];
    return {
      downloadIcon,
      ignorableClickOutsideElements,
      shopifyIcon,
      CollectionRouteTypes,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "EditorHeaderDesktop";
</style>
