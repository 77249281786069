import { onMounted, onUnmounted, ref, toRef, watch } from 'vue';
import { secondaryViolet } from '@/constants/colors';
import { smoothScrollToTop } from '@/utils';
import { isTabletAndLower } from '@/utils/Mobile';

const useBackToTopButton = (props: {
  scrollableElement?: HTMLElement
}) => {
  const showButton = ref(false);
  const scrollableElement = toRef(props, 'scrollableElement');

  const handleScroll = (e): void => {
    const { scrollHeight, scrollTop } = scrollableElement.value
      ? e.target
      : e.target.scrollingElement;
    const scrollDiff = scrollHeight - scrollTop;
    const scrollAccuracy = 40;

    showButton.value = (scrollDiff - scrollAccuracy) <= window.innerHeight;
  };

  onMounted(() => {
    if (!scrollableElement.value) window.addEventListener('scroll', handleScroll);
  });

  watch(scrollableElement, () =>
    scrollableElement.value?.addEventListener('scroll', handleScroll));
  
  onUnmounted(() => {
    if (scrollableElement.value) scrollableElement.value.removeEventListener('scroll', handleScroll);
    window.removeEventListener('scroll', handleScroll);
  });
  
  return {
    isTabletAndLower,
    secondaryViolet,
    showButton,
    smoothScrollToTop,
  };
};

export default useBackToTopButton;
