<template>
  <div class="text-field">
    <v-text-field
      :dark="dark"
      :label="label"
      :value="value"
      :rules="rules"
      :type="fieldType"
      :required="required"
      :color="color || controlColor"
      :append-icon="fieldIcon"
      :loading="loading"
      :hide-details="hideDetails"
      :dense="dense"
      :autofocus="autofocus"
      :background-color="backgroundColor"
      :outlined="outlined"
      :placeholder="placeholder"
      :persistent-placeholder="persistentPlaceholder"
      :suffix="suffix"
      :disabled="disabled"
      :clearable="clearable"
      :prepend-inner-icon="prependInnerIcon"
      :error="error"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
      @click:append="toggleShowPassword"
      @click.stop
      @keydown="$emit('keydown', $event)"
      @keyup="$emit('keyup', $event)"
      @focus="$emit('focus', $event)"
    >
      <template #append>
        <slot name="append" />
      </template>
      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import useTextField from '@/components/Common/TextField/TextField';

export default {
  props: {
    autofocus: {
      required: false,
      type: Boolean, 
    },
    backgroundColor: {
      required: false,
      type: String,
    },
    clearable: {
      required: false,
      type: Boolean,
    },
    color: {
      required: false,
      type: String,
    },
    dark: {
      required: false,
      type: Boolean,
    },
    dense: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    hideDetails: {
      required: false,
      type: Boolean,
    },
    inputType: {
      default: 'text',
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    loading: {
      required: false,
      type: Boolean,
    },
    error: {
      default: false,
      type: Boolean,
    },
    outlined: {
      default: true,
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      type: String,
    },
    prependInnerIcon: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    rules: {
      required: false,
      type: Array,
    },
    suffix: {
      required: false,
      type: String,
    },
    value: {
      default: '',
      required: false,
      type: [ String, Number ],
    },
  },
  setup(props) {
    return useTextField(props);
  },
  data() {
    return {
      persistentPlaceholder: false,
    };
  },
  mounted() {
    this.isAutoFilled(this.$el);
  },
  methods: {
    isAutoFilled(el) {
      const detectAutofill = setInterval(() => {
        if (el.querySelectorAll('input:-webkit-autofill').length) {
          this.persistentPlaceholder = true;
          clearInterval(detectAutofill);
        }
      }, 100);

      setTimeout(() => {
        if (detectAutofill) {
          clearInterval(detectAutofill);
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'TextField';
</style>
