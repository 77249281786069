import { useI18n } from 'vue-i18n-composable';
import { UPLOADED_IMAGE_MEGABYTE_LIMIT } from '@/components/Editor/constants/editorConstants';
import { MemoryUnits } from '@/constants/units';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { DISABLE_SETTINGS_ON_IMAGE_LOADING } from '@/store/Editor/constants';
import { UPLOAD_IMAGE_FORMATS } from '@/types/image';
import { NotificationType } from '@/types/notification';

export const getFileType = (file: File): string => {
  const splitedName = file.name.split('.');
  return splitedName[splitedName.length - 1];
};

export const validateImageFormat = (file: File): boolean => {
  const { t } = useI18n();
  store.dispatch(DISABLE_SETTINGS_ON_IMAGE_LOADING, true);
  const fileType = getFileType(file);
  if (!UPLOAD_IMAGE_FORMATS.includes(fileType.toLowerCase())) {
    store.dispatch(SET_NOTIFICATION, {
      text: t('upload.imageTypeNotSupported__text'),
      type: NotificationType.Error,
    });
    return false;
  }
  return true;
};

export const validateImageSize = (file: File): boolean => {
  const { t } = useI18n();
  const maxUploadSize = UPLOADED_IMAGE_MEGABYTE_LIMIT
    * MemoryUnits.BYTES_IN_MEGABYTE;

  if (file.size > maxUploadSize) {
    store.dispatch(SET_NOTIFICATION, {
      text: t(
        'upload.imageSizeExceeds__text',
        { size: UPLOADED_IMAGE_MEGABYTE_LIMIT },
      ),
      type: NotificationType.Error,
    });
    return false;
  }

  return true;
};
