import { computed, ref, Ref } from 'vue';
import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import {
  GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
  SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
} from '@/store/Templates/constants';
import { Template } from '@/store/Templates/types';

const useTemplatesList = () => {
  const isEditDetailsWindowOpen = ref(false);
  const isConfirmRemoveModalOpen = ref(false);
  const selectedTemplate = ref(null) as Ref<Template | null>;

  const isAddToMyCollectionModalOpen = computed((): boolean => {
    return store.getters[GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN];
  });

  const openAddToMyCollectionsModal = template => {
    selectedTemplate.value = template;
    store.commit(SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN, true);
  };

  const openEditDetailsWindow = (template: Template): void => {
    selectedTemplate.value = template;
    isEditDetailsWindowOpen.value = true;
  };

  const openConfirmRemoveModal = (template: Template): void => {
    selectedTemplate.value = template;
    isConfirmRemoveModalOpen.value = true;
  };

  const closeConfirmRemoveModal = (): void => {
    selectedTemplate.value = null;
    isConfirmRemoveModalOpen.value = false;
  };

  const generateTemplateKey = (template: Template): string => {
    return template.collectionId +
      template.imagePath +
      template.id + 
      template.name;
  };

  return {
    closeConfirmRemoveModal,
    generateTemplateKey,
    isConfirmRemoveModalOpen,
    isEditDetailsWindowOpen,
    isAddToMyCollectionModalOpen,
    openConfirmRemoveModal,
    openAddToMyCollectionsModal,
    openEditDetailsWindow,
    primaryViolet,
    selectedTemplate,
  };
};

export default useTemplatesList;
