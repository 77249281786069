<template>
  <v-form
    v-if="!messageWasSent"
    v-model="isValidForm"
    class="forgot-password"
    @submit.prevent="sendResetMail"
  >
    <BaseTitle>
      {{ $t('forgotPassword.forgotPassword__text') }}
    </BaseTitle>
    <p class="forgotPassword__description">
      {{ $t('forgotPassword.enterYourPassword__text') }}
    </p>
    <p class="forgotPassword__description">
      {{ $t('forgotPassword.weDoNotStoreYourPassword__text') }}
    </p>
    <TextField
      v-model="email"
      required
      input-type="email"
      :rules="emailRules"
      :label="$t('textField.email__placeholder')"
      @keydown.space.prevent
    />
    <BaseButton
      class="forgot-password__button"
      has-background
      :disabled="!isValidForm"
      :button-text="$t('forgotPassword.sendResetInstructions__button')"
      :large="isMobile"
      @click="sendResetMail"
    />
    <DontHaveAccount />
  </v-form>
  <div
    v-else
    class="verify-email"
  >
    <BaseTitle>
      {{ $t('forgotPassword.checkYourEmail__title') }}
    </BaseTitle>
    <div class="verify-email__description">
      {{ $t('forgotPassword.checkYourEmail__text') }}
    </div>
    <BaseButton
      class="verify-email__button"
      has-background
      :disabled="!isValidForm"
      :button-text="$t('forgotPassword.gotItThanks__button')"
      :large="isMobile"
      @click="redirectToHome"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import DontHaveAccount from '@/components/Common/DontHaveAccount/DontHaveAccount.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useForgotPasswordForm from './ForgotPasswordForm';

export default {
  components: {
    BaseButton,
    BaseTitle,
    DontHaveAccount,
    TextField,
  },
  setup() {
    return useForgotPasswordForm();
  },
};
</script>

<style lang='scss' scoped>
  @import 'ForgotPasswordForm';
</style>
