import { fabric } from 'fabric';
import {
  FabricCanvas,
  FabricImage,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_ZOOM_TO_DEFAULT } from '@/store/Editor/constants';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';

export const addOutlineImage = (canvas: FabricCanvas) => {
  const { outlineLink } = store.getters[GET_CURRENT_TEMPLATE];
  if (outlineLink) {
    const zoomToDefault = store.getters[GET_ZOOM_TO_DEFAULT];

    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();
    return new Promise(resolve => {
      fabric.Image.fromURL(
        outlineLink,
        (image: FabricImage): void => {
          const { height, width } = image;
  
          canvas.setOverlayImage(image, 
            // @ts-ignore
            resolve(() => {
              canvas.renderAll.bind(canvas);
            })
            , {
              opacity: 0.6,
              scaleX: canvasWidth / (width || 1) / zoomToDefault,
              scaleY: canvasHeight / (height || 1) / zoomToDefault,
              originX: 'left',
              originY: 'top',
            });
        },
        {
          crossOrigin: 'Anonymous',
        },
      );
    });
  }
};
