import { UserRoles } from '@/constants/roles';
import Collections from '@/views/Collections/Collections.vue';
import ConfirmEmail from '@/views/ConfirmEmail/ConfirmEmail.vue';
import CreatePassword from '@/views/CreatePassword/CreatePassword.vue';
import DeliveryProfile from '@/views/DeliveryProfile/DeliveryProfile.vue';
import Editor from '@/views/Editor/Editor.vue';
import EmailConfirmed from '@/views/EmailConfirmed/EmailConfirmed.vue';
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue';
import NotFoundPage from '@/views/NotFoundPage/NotFoundPage.vue';
import Orders from '@/views/Orders/Orders.vue';
import SingleOrder from '@/views/Orders/SingleOrder/SingleOrder.vue';
import Profile from '@/views/Profile/Profile.vue';
import Shipping from '@/views/Shipping/Shipping.vue';
import ShopifyPreview from '@/views/ShopifyPreview/ShopifyPreview.vue';
import SignIn from '@/views/SignIn/SignIn.vue';
import SignUp from '@/views/SignUp/SignUp.vue';
import Templates from '@/views/Templates/Templates.vue';
import uploadPage from '@/views/UploadPage/UploadPage.vue';
import UserGuide from '@/views/UserGuide/UserGuide.vue';

const routes = [
  {
    name: 'home',
    path: '/',
    redirect: '/my-collections',
  },
  {
    component: SignIn,
    name: 'sign-in',
    path: '/sign-in',
  },
  {
    component: SignUp,
    name: 'sign-up',
    path: '/sign-up',
  },
  {
    component: Profile,
    meta: {
      roles: [ UserRoles.Admin, UserRoles.User ],
    },
    name: 'profile',
    path: '/profile',
  },
  {
    component: ForgotPassword,
    name: 'forgot-password',
    path: '/forgot-password',
  },
  {
    component: ConfirmEmail,
    name: 'confirm-email',
    path: '/confirm-email/:email',
  },
  {
    component: CreatePassword,
    name: 'create-password',
    path: '/create-password/:email/:resetToken',
  },
  {
    component: EmailConfirmed,
    name: 'email-confirmed',
    path: '/email-confirmed',
  },
  {
    component: Collections,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'my-collections',
    path: '/my-collections',
  },
  {
    component: Collections,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'public-collections',
    path: '/public-collections',
  },
  {
    component: Templates,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'my-collections/templates',
    path: '/my-collections/:collectionId/templates',
  },
  {
    component: Templates,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'public-collections/templates',
    path: '/public-collections/:collectionId/templates',
  },
  {
    component: Editor,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'private-editor',
    path: '/my-collections/:collectionId/templates/:templateId/editor',
  },
  {
    component: Editor,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'public-editor',
    path: '/public-collections/:collectionId/templates/:templateId/3dview',
  },
  {
    component: uploadPage,
    meta: { roles: [UserRoles.Admin]},
    name: 'upload',
    path: '/upload',
  },
  {
    component: Shipping,
    meta: { roles: [UserRoles.Admin]},
    name: 'shipping',
    path: '/shipping',
  },
  {
    component: DeliveryProfile,
    meta: { roles: [UserRoles.Admin]},
    name: 'delivery-profile',
    path: '/delivery-profile/:id',
  },
  {
    component: ShopifyPreview,
    name: 'iframemodel/template',
    path: '/iframeModel/:storeId/:productId/:variantId/:customization',
  },
  {
    component: UserGuide,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'user-guide',
    path: '/user-guide',
  },
  {
    component: Orders,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'orders',
    path: '/orders',
  },
  {
    component: SingleOrder,
    meta: { roles: [ UserRoles.Admin, UserRoles.User ]},
    name: 'single-order',
    path: '/orders/:id',
  },
  {
    component: NotFoundPage,
    name: 'not-found',
    path: '/:catchAll(.*)',
  },
];

export default routes;
