<template>
  <div
    :class="[
      'editor-side-controls-mobile',
      { 'disabled': isSideControlsDisabled }
    ]"
  >
    <div
      v-if="isDefaultSettingsActive"
      class="editor-side-controls-mobile__content"
    >
      <div
        v-if="activeObject"
        @click="$emit('deleteActiveObject')"
      >
        <BaseIcon>
          <TrashcanIcon />
        </BaseIcon>
        <span>{{ $t('redactor.delete__button') }}</span>
      </div>
      <ColorPicker
        :color-picker-type="ColorPickerType.background"
        :is-mobile="isMobile"
      />
      <div 
        @click.stop="addText"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path :d="TextToolIconData1" />
          <path :d="TextToolIconData2" />
          <path :d="TextToolIconData3" />
          <path :d="TextToolIconData4" />
          <path :d="TextToolIconData5" />
        </svg>
        <span>
          {{ $t('redactor.textSettings__label') }}
        </span>
      </div>
      <div 
        @click.stop="selectFile"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path :d="ImageToolIconData1" />
          <path :d="ImageToolIconData2" />
          <path :d="ImageToolIconData3" />
          <path :d="ImageToolIconData4" />
          <path :d="ImageToolIconData5" />
        </svg>
        <span>
          {{ $t('redactor.imageSettings__label') }}
        </span>
      </div>
      <input
        ref="imageInput"
        class="image-input"
        type="file"
        accept="image/*"
        @change="uploadImage"
      >
    </div>
    <div v-if="isTextActive">
      <TextSettings
        v-bind="textControlsProps"
        v-on="textControlsEmits"
      />
    </div>
    <div v-if="isImageActive">
      <ImageSettings
        v-bind="imageSettingsProps"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import useSideControlsMobile from '@/components/Editor/EditorSideControls/EditorSideControlsMobile/EditorSideControlsMobile';
import ImageSettings from '@/components/Editor/ImageSettings/ImageSettings.vue';
import TextSettings from '@/components/Editor/TextSettings/TextSettings.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import {
  ImageToolIconData1,
  ImageToolIconData2,
  ImageToolIconData3,
  ImageToolIconData4,
  ImageToolIconData5,
  TextToolIconData1,
  TextToolIconData2,
  TextToolIconData3,
  TextToolIconData4,
  TextToolIconData5,
} from '@/constants/editor';

export default {
  components: {
    ColorPicker,
    ImageSettings,
    TextSettings,
    BaseIcon,
    TrashcanIcon,
  },
  props: {
    isSideControlsDisabled: {
      required: false,
      type: Boolean,
    },
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isImageActive: {
      required: true,
      type: Boolean,
    },
    isImageLoading: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    isTextActive: {
      required: true,
      type: Boolean,
    },
    secondaryViolet: {
      required: true,
      type: String,
    },
    activeObject: {
      required: false,
      type: Object,
    },
    lockInfo: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return {
      ImageToolIconData1,
      ImageToolIconData2,
      ImageToolIconData3,
      ImageToolIconData4,
      ImageToolIconData5,
      TextToolIconData1,
      TextToolIconData2,
      TextToolIconData3,
      TextToolIconData4,
      TextToolIconData5,
      ...useSideControlsMobile(props, context),
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'EditorSideControlsMobile';
</style>
