export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const LOG_OUT = 'LOG_OUT';
export const GET_USER = 'GET_USER';
export const GET_AUTHORIZED = 'GET_AUTHORIZED';
export const INIT_USER='INIT_USER';
export const SET_USER = 'SET_USER';
export const SET_CUSTOM_FONTS = 'SET_CUSTOM_FONTS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const REMOVE_CUSTOM_FONT = 'REMOVE_CUSTOM_FONT';
export const SEND_RESET_MAIL = 'SEND_RESET_MAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SET_LOCALIZATION = 'SET_LOCALIZATION';
export const GET_LOCALIZATION = 'GET_LOCALIZATION';
export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';
export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const SEND_ACTIVATION_MAIL = 'SEND_ACTIVATION_MAIL';
export const SET_SIGN_IN_WITH_TYPE = 'SET_SIGN_IN_WITH_TYPE';
export const LOAD_CUSTOM_FONTS = 'LOAD_CUSTOM_FONTS';
export const UPDATE_CUSTOM_FONTS = 'UPDATE_CUSTOM_FONTS';
export const ADD_CUSTOM_FONT = 'ADD_CUSTOM_FONT';
export const GET_USER_FONTS = 'GET_USER_FONTS';
