import { PerspectiveCamera, WebGLRenderer } from 'three';
import { Ref } from 'vue';
import {
  defaultCameraFOV,
  initialModelRotationYAxis,
  mobileScanCameraFOV,
} from '@/constants/editor';
import { OrbitControls } from '@/lib/OrbitControls';
import store from '@/store';
import { GET_IS_MOBILE } from '@/store/Editor/constants';
import { fitCameraToObject } from './fitCameraToObject';

export const prepareSceneForScan = async (
  camera: Ref<PerspectiveCamera>,
  renderer: Ref<WebGLRenderer>,
  controls: Ref<OrbitControls>,
  model: Ref,
): Promise<void> => {
  const isMobile = store.getters[GET_IS_MOBILE];
  const newScanSideLength = isMobile ? window.innerWidth : window.innerHeight;
  camera.value = new PerspectiveCamera(
    isMobile.value ? mobileScanCameraFOV : defaultCameraFOV,
    1,
    0.1,
    100,
  );

  renderer.value.setSize(newScanSideLength, newScanSideLength);
  model.value.rotation.set(0, initialModelRotationYAxis, 0);
  fitCameraToObject(camera.value, model.value, null, controls.value);
};
