import { fabric } from 'fabric';
import { Mesh } from 'three';
import { Ref } from 'vue';
import {
  DEFAULT_FONT_SIZES,
  DEFAULT_FONTS,
} from '@/components/Editor/constants/defaultConfigs';
import { LockedObjectInfo } from '../helpers/fabric/lockObjects/types';
import { EditorGuidelines } from '../helpers/Guidelines/types';
import { HEXColor } from './editor';

export enum ControlActionNames {
  scale = 'scale',
  width = 'width',
}

export interface DefaultImageObject {
  file: File,
  id: FabricObjectId,
  isDefault: boolean,
  layerName: string,
  layerNumber: number,
  type: FabricObjectType,
}

export interface DefaultTextObject {
  id: FabricObjectId,
  isDefault: boolean,
  layerName: string,
  layerNumber: number,
  type: FabricObjectType,
}

export interface CustomFabricObjectProps {
  deleted?: boolean,
  dpi?: number,
  file?: File,
  guidelines?: EditorGuidelines,
  id?: string,
  isDefault?: boolean,
  isScalingChanged?: boolean,
  layerName?: string,
  layerNumber?: number,
  lockedInfo?: LockedObjectInfo,
  src?: string,
}

export interface CustomFabricCanvasProps {
  guidelines?: EditorGuidelines
  lowerCanvasEl?: HTMLCanvasElement,
  realSize?: {
    height: number,
    width: number
  },
}

export type MeshRef = Ref<Mesh>;

export type FabricActiveSelection = fabric.ActiveSelection;

export interface FabricCanvas extends fabric.Canvas, CustomFabricCanvasProps {}

export type FabricControl = fabric.Control;

export type FabricEvent = fabric.IEvent;

export interface FabricImage extends fabric.Image, CustomFabricObjectProps {}

export interface FabricText extends
  fabric.IText,
  fabric.Text,
  CustomFabricObjectProps {}

export interface _Object extends fabric.Object, CustomFabricObjectProps {}

export interface FabricObject extends FabricImage, FabricText {
  _objects?: _Object[],
  objects: _Object[]
}

export type FabricObjectRef = Ref;

export type FabricObjectId = string;

export type LargeCanvasStorage = Map<string, FabricCanvas>;
export type LargeCanvasStorageRef = Map<string, FabricCanvas>;

export type FabricCanvasRef = Ref<FabricCanvas>;

export type FabricTextObjects = FabricObject[];
export type FabricTextObjectsRef = Ref<FabricObject[]>;

export enum FabricObjectPosition {
  left='left',
  top='top'
}

export enum FabricObjectType {
  activeSelection = 'activeSelection',
  image = 'image',
  text = 'textbox',
  rect = 'rect',
}
export enum FabricObjectAttributes {
  angle = 'angle',
  id = 'id',
  fill = 'fill',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  left = 'left',
  textAlign = 'textAlign',
  top = 'top',
  scaleX = 'scaleX',
  scaleY = 'scaleY',
  width = 'width',
}

export interface FabricObjectProps {
  [FabricObjectAttributes.angle]?: number,
  [FabricObjectAttributes.id]?: FabricObjectId,
  [FabricObjectAttributes.fill]?: HEXColor,
  [FabricObjectAttributes.fontFamily]?: typeof DEFAULT_FONTS,
  [FabricObjectAttributes.fontSize]?: typeof DEFAULT_FONT_SIZES,
  [FabricObjectAttributes.left]?: number,
  [FabricObjectAttributes.textAlign]?: TextAlignments,
  [FabricObjectAttributes.top]?: number,
  [FabricObjectAttributes.scaleX]?: number,
  [FabricObjectAttributes.scaleY]?: number,
  [FabricObjectAttributes.width]?: number,
}

export type FabricPoint = fabric.Point;

export type FabricTransform = fabric.Transform;

export interface FabricObjectScale {
  scaleX: number,
  scaleY: number,
}

export enum TextAlignments {
  center = 'center',
  justify = 'justify',
  left = 'left',
  right = 'right',
}
export interface GetTextObject {
  fabricTextObjects: FabricTextObjectsRef,
  id: string,
  name: string,
}
