import { GET_AUTHORIZED, GET_LOCALIZATION, GET_USER, GET_USER_FONTS } from '@/store/Authorization/constants';
import { UserState } from '@/store/Authorization/types';

export const getters = {
  [GET_AUTHORIZED]: (state: UserState) => state.isAuthorized,
  [GET_LOCALIZATION]: (state: UserState) => state.localization,
  [GET_USER]: (state: UserState) => state.user,
  [GET_USER_FONTS]: (state: UserState): string[] => {
    return state.user.customFonts.length
      ? JSON.parse(state.user.customFonts)
      : [] as string[];
  },
};
