import { render, staticRenderFns } from "./DeliveryProfileHeading.vue?vue&type=template&id=f7522cdc"
import script from "./DeliveryProfileHeading.vue?vue&type=script&lang=js"
export * from "./DeliveryProfileHeading.vue?vue&type=script&lang=js"
import style0 from "./DeliveryProfileHeading.vue?vue&type=style&index=0&id=f7522cdc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports