import { Template } from '@/store/Templates/types';

export interface LockedObjectInfo {
  lockedDeletion?: boolean;
  lockedMovement?: boolean;
  lockedRotation?: boolean;
  lockedScaling?: boolean;
  lockedSelection?: boolean;
  lockedTemplateId: Template['id'] | null;
  lockedWriting?: boolean
}

export const defaultLockObject: LockedObjectInfo = {
  lockedTemplateId: null,
  lockedMovement: false,
  lockedRotation: false,
  lockedScaling: false,
  lockedSelection: false,
  lockedWriting: false,
  lockedDeletion: false,
};
