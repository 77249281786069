<template>
  <div
    class="add-to-order-panel"
    :class="{
      'add-to-order-panel_mobile': isMobile,
      'add-to-order-panel_editor-active': isObjectSelected,
    }"
    @click.stop
  >
    <div
      class="add-to-order-panel__content"
    >
      <span class="content_title">
        {{ $t('redactor.customis3d__label') }}
      </span>
      <span class="content_name">{{ templateName }}</span>
    </div>
    <div class="add-to-order-panel__right-menu">
      <UndoRedoIcons v-if="!isMobile" />
      <BaseButton
        class="add-to-order-panel__button"
        :button-text="$t('redactor.addToOrder__button')"
        :is-loading="isLoading"
        no-margin-top
        @click="$emit('addToShopifyOrder')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';

export default {
  components: {
    BaseButton,
    UndoRedoIcons,
  },
  props: {
    from3D: {
      required: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    templateName: {
      required: true,
      type: String,
    },
    isObjectSelected: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "AddToOrderPanel";
</style>
