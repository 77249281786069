<template>
  <div
    :class="[
      'editor-canvas',
      {
        'editor-canvas_light': isShopifyPage,
        'editor-canvas_shopify-mobile': isShopifyPage && isMobile,
      }
    ]" 
    @click.stop="discardObjectSelection"
  >
    <div
      ref="canvasContainer"
      :class="[
        'fabric-canvas',
        {
          'fabric-canvas_object-selected': isObjectSelected,
          'disabled': isImageLoading
        }
      ]"
      @click.stop
    >
      <slot name="overlay" />
    </div>
  </div>
</template>

<script lang="ts">
import { useEditorCanvas } from './EditorCanvas';

export default {
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
    isImageLoading: {
      default: false,
      type: Boolean,
    },
    isObjectSelected: {
      default: false,
      type: Boolean,
    },
    isMobile: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useEditorCanvas();
  },
};
</script>

<style lang="scss" scoped>
@import "EditorCanvas";
</style>
