import { ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import router from '@/router/index';
import store from '@/store/index';
import {
  isPasswordStrong,
  requiredField,
} from '@/utils/FieldValidationRules';
import { isMobile } from '@/utils/Mobile';

const useCreatePasswordForm = () => {
  const { t } = useI18n();
  const { email, resetToken } = router.currentRoute.params;

  const newPassword = ref('');
  const isValidForm = ref(false);
  const confirmNewPassword = ref('');

  const passwordConfirmation = (v: string) => {
    return (!!v && v) === newPassword.value
    || t('validationRules.passwordsDoNotMatch__text');
  };

  const confirmNewPasswordRules = [
    requiredField,
    isPasswordStrong,
    passwordConfirmation,
  ];
  const newPasswordRules = [ requiredField, isPasswordStrong ];

  const resetPassword = async() => {
    await store.dispatch('RESET_PASSWORD', {
      email,
      password: newPassword.value,
      resetToken,
    });
  };

  return {
    confirmNewPassword,
    confirmNewPasswordRules,
    isMobile,
    isValidForm,
    newPassword,
    newPasswordRules,
    resetPassword,
  };
};

export default useCreatePasswordForm;
