import { computed, reactive } from 'vue';
import { FabricObject, FabricObjectAttributes } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_ACTIVE_OBJECT, TRIGGER_UPDATE_OBJECT } from '@/store/Editor/constants';

const useTextFontSize = () => {
  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );
  const fontSize = computed((): number => Number(activeObject.value?.fontSize));

  const changeTextFontSize = (size: string): void => {
    const { fontSize, scaleX, scaleY, width } = activeObject.value;

    if (fontSize && scaleX && scaleY && width) {
      let newFontSize = +size || 1;
      const fontSizeDelta = newFontSize / fontSize;
      let newScaleX = scaleX * fontSizeDelta;
      let newScaleY = scaleY * fontSizeDelta;

      if (fontSize * newScaleX < 1) {
        newScaleX = 1 / fontSize;
      }
      const newWidth = width * newScaleX;
    
      newFontSize = +(newFontSize * scaleX).toFixed(0);
      newScaleX = 1;
      newScaleY = 1;
      
      store.dispatch(TRIGGER_UPDATE_OBJECT, {
        changes: {
          [FabricObjectAttributes.fontSize]: newFontSize,
          [FabricObjectAttributes.scaleX]: newScaleX,
          [FabricObjectAttributes.scaleY]: newScaleY,
          [FabricObjectAttributes.width]: newWidth,
        },
        isUpdateHistory: true,
      });
    }
  };

  const TextFontSizeProps = reactive({ fontSize });

  const TextFontSizeEmits = {
    changeTextFontSize,
  };

  return {
    TextFontSizeEmits,
    TextFontSizeProps,
  };
};

export default useTextFontSize;
