import { ref } from 'vue';

import router from '@/router/index';
import store from '@/store/index';
import { validEmail } from '@/utils/FieldValidationRules/index';
import { isMobile } from '@/utils/Mobile';

const emailRules = [validEmail];

const useForgotPasswordForm = () => {
  const email = ref('');
  const isValidForm = ref(true);
  const messageWasSent = ref(false);

  const sendResetMail = async () => {
    const emailWasSend = await store.dispatch('SEND_RESET_MAIL', { email: email.value });
    if (emailWasSend) {
      messageWasSent.value = true;
      window.scrollTo(0, 0);
    }
  };

  const redirectToHome = () => router.push('/sign-in');

  return {
    email,
    emailRules,
    isMobile,
    isValidForm,
    messageWasSent,
    redirectToHome,
    sendResetMail,
  };
};

export default useForgotPasswordForm;
