/* eslint-disable */
import { Color } from 'three';
import 'vue-select/dist/vue-select.css';
import 'vue-swatches/dist/vue-swatches.css';
import store from '@/store/index';
import router from '@/router/index';
import { getScan } from '@/components/Editor/helpers/pdfScan/getScan';
import editorLoader from '@/components/Editor/assets/editorLoader.svg';
import { 
  MeshData,
  ScanTypes,
  controlsStyleMods,
} from '@/components/Editor/types/editor';
import { UserRoles } from '@/constants/roles';
import { ShopifyAPI } from "@/services/ShopifyAPI";
import { SEND_TO_SHOPIFY } from '@/store/Shopify/constants';
import { GET_USER } from '@/store/Authorization/constants';
import { Collection } from '@/store/Collections/types';
import { User } from '@/store/Authorization/types';
import {
  CLEAR_EDITOR_STATE,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_IFRAME,
  GET_IS_IMAGE_LOADING,
  GET_IS_MOBILE,
  GET_TEMPLATE_DESIGNS,
  SET_IS_IFRAME,
  SET_IS_MOBILE,
  GET_IS_SIDE_SELECTION_ENABLED,
  GET_IS_MESH_SELECTION_REMOVED,
  GET_SINGLE_EDITABLE_MESH,
  GET_IS_EDITOR_LOADING,
  GET_IS_MODEL_READY_TO_LOAD,
  GET_IS_DESIGN_MODE,
  GET_ACTIVE_MESH,
  GET_IS_IMAGE_SETTINGS_ACTIVE,
  GET_IS_TEXT_SETTINGS_ACTIVE,
  GET_IS_ADD_FONT_MODAL_OPEN,
  GET_IS_REMOVE_FONT_MODAL_OPEN,
  GET_IS_SAVE_DESIGN_DISABLED,
  SAVE_DESIGN,
  CLEAR_TEXTURE_CANVASES,
  SET_IS_DESIGN_MODE,
} from '@/store/Editor/constants';
import { CollectionsAPI } from '@/services/CollectionsAPI';
import { isTouchDevice } from '@/utils/isTouchDevice';
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount, watch} from 'vue';
import { Breakpoints } from '@/constants/breakpoints';
import { changeBaseCanvasHeight } from '@/components/Editor/helpers/fabric/canvasModifiers/changeBaseCanvasHeight';
import { getFabricCanvasSize } from '@/components/Editor/helpers/fabric/canvasModifiers/getFabricCanvasSize';
import { getcontrolsStyleMods } from '@/components/Editor/helpers/fabric/controls/getControlsStylesModifications';
import { configureFabric } from '@/components/Editor/helpers/fabric/configureFabric';
import { dimGrey, primaryViolet } from '@/constants/colors';
import { useResizeListener } from '@/components/Editor/helpers/changeEditorHeight';
import {
  GET_CURRENT_TEMPLATE,
  GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
} from '@/store/Templates/constants';
import { loadIcons } from '@/components/Editor/helpers/fabric/controls/renderIcons';
import { updateCanvasTexture } from '@/components/Editor/helpers/Three/updateCanvasTexture';

const useEditor = () => {
  const fileButton = ref<HTMLElement>();
  const editorContainer = ref<HTMLElement>();
  const colorChanged = ref(false);
  const isScanLoading = ref(false);
  const isShopifyLoading = ref(false);
  const isImageLoading = computed((): boolean => store.getters[GET_IS_IMAGE_LOADING]);
  const template = computed(() => store.getters[GET_CURRENT_TEMPLATE]);
  const isEditorActive = computed(() => store.getters[GET_IS_EDITOR_ACTIVE]);
  const isAddToOrderLoading = ref(false);
  const collections = ref([] as Collection[]);
  const sceneBackground = computed(() => isIframe.value
    ? new Color(0xffffff)
    : new Color(dimGrey)
  );
  const controlsStyleMods = ref({} as controlsStyleMods);
  
  const sceneRef = ref()
  const user = computed((): User => store.getters[GET_USER]);

  const singleEditableMesh = computed(
    (): MeshData | null => store.getters[GET_SINGLE_EDITABLE_MESH]);

  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);

  const isIframe = computed(():boolean => store.getters[GET_IS_IFRAME]);

  const isCustomizationFrame = computed((): boolean => {
    return Boolean(Number(router.currentRoute.params.customization));
  });

  const isPublicEditor = computed(
    (): boolean => router.currentRoute.name === 'public-editor'
  );


  const isPublicCollection = computed((): boolean => {
    if (template.value) {
      const collection = collections.value.filter(
        ({ id }): boolean => id === template.value.collectionId
      )[0];
      if (collection) return collection.isPublic;
    }
    return true;
  });

  const isAdmin = computed(
    (): boolean => store.getters[GET_USER].role === UserRoles.Admin
  );

  const isPublic = computed(
    (): boolean => 
      isPublicEditor.value ||
      (isPublicCollection.value && !isAdmin.value) 
  );

  const isControlsVisible = computed((): boolean => {
    return !isPublic.value && !isIframe.value || isCustomizationFrame.value;
  });

  const activeMesh = computed((): MeshData => store.getters[GET_ACTIVE_MESH]);

  const isSideSelectionEnabled = computed(
    (): boolean => store.getters[GET_IS_SIDE_SELECTION_ENABLED]);

  const isMeshSelectionRemoved = computed(
    (): boolean => store.getters[GET_IS_MESH_SELECTION_REMOVED]);

  const isDesktopSidebarVisible = computed((): boolean => isControlsVisible.value && isEditorActive.value && !isMobile.value);
  const isModelReadyToLoad = computed((): boolean => store.getters[GET_IS_MODEL_READY_TO_LOAD]);
  const isEditorLoading = computed((): boolean => store.getters[GET_IS_EDITOR_LOADING]);
  const isDesignMode = computed((): boolean => store.getters[GET_IS_DESIGN_MODE]);

  const isImageActive = computed(
    (): boolean => store.getters[GET_IS_IMAGE_SETTINGS_ACTIVE],
  );
  const isTextActive = computed(
    (): boolean => store.getters[GET_IS_TEXT_SETTINGS_ACTIVE],
  );
  const isAddFontModalOpen = computed((): boolean => store.getters[GET_IS_ADD_FONT_MODAL_OPEN]);
  const isRemoveFontModalOpen = computed((): boolean => store.getters[GET_IS_REMOVE_FONT_MODAL_OPEN]);
  const isSaveDesignDisabled = computed((): boolean => store.getters[GET_IS_SAVE_DESIGN_DISABLED]);
  const isAddToMyCollectionsModalOpen = computed((): boolean => {
    return store.getters[GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN];
  });

  useResizeListener(editorContainer);

  const requestScan = async (
    typeScan: ScanTypes
  ): Promise<string | void> => {
    if (typeScan === ScanTypes.file) isScanLoading.value = true;
    const scan = await getScan(typeScan);
    isScanLoading.value = false;
    return scan;
  };

  const sendToShopify = async (): Promise<void> => {
    isShopifyLoading.value = true;
    const scanLink = await requestScan(ScanTypes.link);
    await store.dispatch(SEND_TO_SHOPIFY, {
      templateName: template.value.name,
      templateId: template.value.id,
      scanLink
    })
    isShopifyLoading.value = false;
  };

  const callSceneScan = ref(false);

  const addToShopifyOrder = async (): Promise<void> => {
    isAddToOrderLoading.value = true;

    const { storeId, productId, variantId } = router.currentRoute.params;

    const textureCanvas = await updateCanvasTexture(activeMesh.value);
    await sceneRef.value.updateMeshMaterial(textureCanvas, activeMesh.value.name);

    const sceneImage = await sceneRef.value.generateSceneScan()
    const scanLink = (await requestScan(ScanTypes.link)) as string;

    const product = await ShopifyAPI.createOrder(
      storeId,
      productId,
      variantId,
      sceneImage,
      scanLink
    );

    const fabricData = {
      scanLink,
      preview: sceneImage,
      product,
    };
    window.parent.postMessage(JSON.stringify(fabricData), "*");
  };

  const init = async (): Promise<void> => {
    loadIcons();
    configureFabric();
    changeBaseCanvasHeight();
  };

  const handleShopifyFrameClick = (event: MessageEvent): void => {
    const { loading } = event.data;
    isAddToOrderLoading.value = loading;
  }

  const initCollections = async () => {
    if (user.value?.id) {
      const { collections: userCollections } = 
        await CollectionsAPI.getAllUserCollections();
        
      collections.value = userCollections;
    }
  }

  const initTemplateDesigns = () => {
    if (user.value?.id && !isIframe.value) {
      store.dispatch(GET_TEMPLATE_DESIGNS, { userId: user.value.id })
    }
  }

  const mountedCB = (): void => {
    store.commit(
      SET_IS_MOBILE,
      window.innerWidth < Breakpoints.maxMobileWidth || isTouchDevice()
    );
    initCollections();
    init();
    initTemplateDesigns();
    window.addEventListener("message", handleShopifyFrameClick);
  };

  const onBeforeMountCB = () => {
    store.commit(
      SET_IS_IFRAME,
      router.currentRoute.name?.includes('iframemodel/template')
    );
  }

  const beforeDestroyCB = (): void => {
    store.commit(CLEAR_TEXTURE_CANVASES);
    store.commit(CLEAR_EDITOR_STATE);
    window.removeEventListener("message", handleShopifyFrameClick);
  };

  const changeControlsStyle = (): void => {
    if (activeMesh.value) {
      const { width, height } = getFabricCanvasSize(activeMesh.value);
      controlsStyleMods.value = getcontrolsStyleMods(width, height);
    }
    store.dispatch(SET_IS_DESIGN_MODE, { isActive: false });
  }

  const copyDesign = (): void => {
    store.dispatch(
      SAVE_DESIGN,
      { name: template.value.name },
    );
  };

  watch(isEditorActive, changeControlsStyle);

  onBeforeMount(() => onBeforeMountCB())
  onMounted(() => mountedCB());
  onBeforeUnmount(() => beforeDestroyCB());

  return {
    isCustomizationFrame,
    isIframe,
    isPublic,
    isEditorLoading,
    isScanLoading,
    isShopifyLoading,
    isImageLoading,
    isTextActive,
    isImageActive,
    isMobile,
    sceneRef,
    fileButton,
    requestScan,
    sendToShopify,
    isDesktopSidebarVisible,
    isAddFontModalOpen,
    isRemoveFontModalOpen,
    isControlsVisible,
    singleEditableMesh,
    isEditorActive,
    activeMesh,
    isSideSelectionEnabled,
    isMeshSelectionRemoved,
    editorLoader,
    isModelReadyToLoad,
    callSceneScan,
    sceneBackground,
    isDesignMode,
    controlsStyleMods,
    primaryViolet,
    isSaveDesignDisabled,
    copyDesign,
    addToShopifyOrder,
    editorContainer,
    isAddToMyCollectionsModalOpen,
    template,
    isAddToOrderLoading
  };
};

export default useEditor;
