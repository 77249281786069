import { Collection } from '@/store/Collections/types';
import {
  GET_CURRENT_TEMPLATE,
  GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
  GET_IS_EDITING_RIGHTS_TEMPLATES,
  GET_IS_TEMPLATE_IMAGE_LOADING,
  GET_IS_TEMPLATES_LOADING,
  GET_SELECTED_COLLECTION,
  GET_TEMPLATES,
  GET_TEMPLATES_AMOUNT_OF_PAGES,
  GET_TEMPLATES_SELECTED_SORT,
} from '@/store/Templates/constants';
import { Template, TemplatesState } from '@/store/Templates/types';
import { SortItems } from '@/types';

export const getters = {
  [GET_IS_TEMPLATE_IMAGE_LOADING]: (state: TemplatesState): boolean =>
    state.isCoverImageLoading,
  [GET_IS_TEMPLATES_LOADING]: (state: TemplatesState): boolean =>
    state.isTemplatesLoading,
  [GET_SELECTED_COLLECTION]: (state: TemplatesState): Collection | null =>
    state.selectedCollection,
  [GET_TEMPLATES]: (state: TemplatesState): Template[] =>
    state.localTemplates,
  [GET_TEMPLATES_AMOUNT_OF_PAGES]: (state: TemplatesState): number =>
    state.amountOfPages,
  [GET_TEMPLATES_SELECTED_SORT]: (state: TemplatesState): SortItems =>
    state.selectedSort,
  [GET_IS_EDITING_RIGHTS_TEMPLATES]: (state: TemplatesState): boolean =>
    state.isEditingRights,
  [GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN]: (state: TemplatesState): boolean =>
    state.isAddToMyCollectionsModalOpen,
  [GET_CURRENT_TEMPLATE]: (state: TemplatesState): Template | null => {
    return state.currentTemplate;
  },
};
