import lodash from 'lodash';
import {
  BACKGROUND_OBJECT_ID,
} from '@/components/Editor/constants/defaultConfigs';
import {
  FabricObject, FabricObjectAttributes,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_ACTIVE_CANVAS,
  GET_FABRIC_OBJECTS,
  GET_IS_LOADED_FROM_HISTORY,
  GET_IS_OBJECTS_RESTORED,
  SET_IS_OBJECTS_RESTORED,
} from '@/store/Editor/constants';

export const checkIsObjectsRestored = (): void => {
  const isLoadedFromHistory = store.getters[GET_IS_LOADED_FROM_HISTORY];
  const isObjectsRestored = store.getters[GET_IS_OBJECTS_RESTORED];
  const objectsFromCanvas = store.getters[GET_ACTIVE_CANVAS]
    .getObjects()as FabricObject[];
  const objectsFromStore = store.getters[GET_FABRIC_OBJECTS] as FabricObject[];
  const objectsAmountToRestore = lodash.differenceBy(
    objectsFromStore,
    objectsFromCanvas,
    FabricObjectAttributes.id,
  ).filter(({ id }) => id !== BACKGROUND_OBJECT_ID).length;

  if (!objectsAmountToRestore) {
    if (!isObjectsRestored && isLoadedFromHistory) {
      store.commit(SET_IS_OBJECTS_RESTORED, true);
    }
  }
};
