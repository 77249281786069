import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { OrderState } from './types';

export const getDefaultOrderState = (): OrderState => ({
  amountOfPages: 0,
  orders: [],
  currentOrder: null,
  isLoading: false,
});

export default {
  actions,
  getters,
  mutations,
  state: getDefaultOrderState(),
};
