import {
  PUSH_CANVAS_SIZE,
  SET_BASE_CANVAS_HEIGHT,
  SET_BASE_CANVAS_WIDTH,
  SET_CANVAS_ZOOM,
  SET_IS_EDITOR_ACTIVE,
  SET_IS_OBJECTS_RESTORED,
  SET_IS_UNDO_REDO_HIDDEN,
  SET_LAYER_NUMBER,
  SET_ZOOM_TO_DEFAULT,
} from '../constants';
import { CanvasSize, EditorState } from '../types';

export const canvasConfigsMutations = {
  [SET_IS_UNDO_REDO_HIDDEN]: (state: EditorState, flag: boolean): void => {
    state.canvasConfigs.isUndoRedoHidden = flag;
  },
  [SET_ZOOM_TO_DEFAULT]: (state: EditorState, zoomToDefault: number): void => {
    state.canvasConfigs.zoomToDefault = zoomToDefault;
  },
  [SET_BASE_CANVAS_HEIGHT]: (state: EditorState, height: number): void => {
    state.canvasConfigs.baseHeight = height;
  },
  [SET_BASE_CANVAS_WIDTH]: (state: EditorState, width: number): void => {
    state.canvasConfigs.baseWidth = width;
  },
  [SET_IS_EDITOR_ACTIVE]: (state: EditorState, flag: boolean): void => {
    state.canvasConfigs.isEditorActive = flag;
  },
  [SET_CANVAS_ZOOM]: (state: EditorState, zoom: number): void => {
    state.canvasConfigs.canvasZoom = zoom;
  },
  [SET_IS_OBJECTS_RESTORED]: (state: EditorState, flag: boolean): void => {
    state.canvasConfigs.isObjectsRestored = flag;
  },
  [PUSH_CANVAS_SIZE]: (state: EditorState, canvasSize: CanvasSize): void => {
    state.canvasConfigs.canvasSizes.push(canvasSize);
  },
  [SET_LAYER_NUMBER]: (state: EditorState, layerNumber: number): void => {
    state.canvasConfigs.layerNumber = layerNumber;
  },
};
