<template>
  <div class="profile-info">
    <div class="profile-info__heading">
      <h2>{{ $t('profile.heading') }}</h2>
      <BaseButton
        icon="mdi-pencil"
        icon-size="20"
        :color="black54"
        no-margin-top
        dark
        @click="toggleMenu"
      />
    </div>
    <v-card
      class="profile-info__card"
      :flat="isTabletAndLower"
    >
      <div class="profile-info__card-description">
        <v-avatar
          class="profile-info__card-description__avatar"
          size="95"
        >
          <img
            :src="profileImg"
            :alt="$t('profile.avatarImg__alt')"
          >
        </v-avatar>
        <div class="card-info">
          <v-card-title class="card-info__title username">
            <span>{{ firstName }}</span>
            <span>{{ lastName }}</span>
          </v-card-title>
          <v-card-subtitle class="card-info__subtitle email">
            {{ email }}
          </v-card-subtitle>
        </div>
      </div>
    </v-card>
    <ProfileCardModal 
      v-if="isModalOpen" 
      :is-open="isModalOpen" 
      @close="toggleMenu" 
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useProfileCard from './ProfileCard';
import ProfileCardModal from './ProfileCardModal/ProfileCardModal.vue';

export default {
  components: {
    BaseButton,
    ProfileCardModal,
  },
  setup() {
    return useProfileCard();
  },
};
</script>
