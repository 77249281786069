<template>
  <div class="order-info">
    <div class="order-info__row">
      <div>
        {{ $t('orders.orderId__text') }}
      </div>
      <div class="order-info__text">
        #{{ id }}
      </div>
    </div>

    <div class="order-info__row">
      <div>
        {{ $t('orders.status__text') }}
      </div>
      <OrderStatusChip :status="status">
        {{ status }}
      </OrderStatusChip>
    </div>

    <div class="order-info__row">
      <div>
        {{ $t('orders.createdAt__text') }}
      </div>
      <div class="order-info__text order-info__date">
        <div>
          {{ date }}
        </div>
        <span class="order-info__time">
          {{ time }}
        </span>
      </div>
    </div>

    <div
      v-if="store"
      class="order-info__row"
    >
      <div>
        {{ $t('orders.store__text') }}
      </div>
      <div class="order-info__text">
        <div class="order-info__shop">
          <ShopifyIcon
            :fill="black54"
            class="order-info__shop-icon"
          />
          {{ store }}
        </div>
      </div>
    </div>

    <div
      v-if="shopifyOrderId"
      class="order-info__row"
    >
      <div>
        {{ $t('orders.shopifyOrderId__text') }}
      </div>
      <div class="order-info__text">
        <CopyToClipboard :value="shopifyOrderId">
          {{ shopifyOrderId }}
        </CopyToClipboard>
      </div>
    </div>

    <div
      v-if="country"
      class="order-info__row"
    >
      <div>
        {{ $t('orders.deliveryTo__text') }}
      </div>
      <div class="order-info__text">
        {{ country }}
      </div>
    </div>

    <div
      v-if="customerName"
      class="order-info__row"
    >
      <div>
        {{ $t('orders.customerName__text') }}
      </div>
      <div class="order-info__text">
        {{ customerName }}
      </div>
    </div>

    <div
      v-if="!isNil(price)"
      class="order-info__row order-info__price"
    >
      <div class="order-info__price-header">
        {{ $t('orders.totalPrice__text') }}
      </div>
      <div>
        ${{ price }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { isNil } from 'lodash';
import { PropType } from 'vue';
import CopyToClipboard from '@/components/CopyToClipboard/CopyToClipboard.vue';
import ShopifyIcon from '@/components/icons/ShopifyIcon.vue';
import { black54, black60 } from '@/constants/colors';
import { OrderStatus } from '@/store/Orders/types';
import { parseDate } from '@/utils/date';
import OrderStatusChip from '../OrderStatusChip/OrderStatusChip.vue';
export default {
  components: { OrderStatusChip, ShopifyIcon, CopyToClipboard },
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
    date: {
      type: String,
      require: true,
    },
    time: {
      type: String,
      required: true,
    },
    store: {
      type: String,
    },
    price: {
      type: Number,
    },
    shopifyOrderId: {
      type: Number,
    },
    country: {
      type: String,
    },
    customerName: {
      type: String,
    },
  },
  setup() {
    return {
      parseDate,
      black54,
      black60,
      isNil,
    };
  },
};
</script>
<style lang="scss" scoped>
  @import './OrderInfoCard.scss';
</style>
