<template>
  <div
    ref="sceneContainer" 
    :class="[
      'scene',
    ]"
  />
</template>
  
<script lang="ts">
import useScene from './Scene';

export default {
  props: {
    lockScene: {
      default: false,
      type: Boolean,
    },
    callSceneScan: {
      default: false,
      type: Boolean,
    },
    sceneBackground: {
      required: true,
      type: Object,
    },
    isControlsVisible: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useScene(props, context);
  },
};
</script>
  
