import { MeshData, Size } from '@/components/Editor/types/editor';
import {
  GET_ACTIVE_MESH,
  GET_IS_MESH_SELECTION_REMOVED,
  GET_IS_MODEL_READY,
  GET_IS_MODEL_READY_TO_LOAD,
  GET_IS_SIDE_SELECTION_ENABLED,
  GET_IS_TEXTURE_UPDATED,
  GET_LAYER_NUMBER,
  GET_MESHS,
  GET_MODEL_SIZE_IN_MM,
  GET_SINGLE_EDITABLE_MESH,
  GET_TEXTURES_TO_UPDATE,
} from '../constants';
import { EditorState, TextureToUpdate } from '../types';

export const sceneGetters = {
  [GET_IS_MODEL_READY]: (state: EditorState): boolean => {
    return state.modelData.isModelReady;
  },
  [GET_MESHS]: (state: EditorState): MeshData[] => {
    return state.modelData.meshs;
  },
  [GET_IS_SIDE_SELECTION_ENABLED]: (state: EditorState): boolean => {
    return state.modelData.isSideSelectionEnabled;
  },
  [GET_IS_MESH_SELECTION_REMOVED]: (state: EditorState): boolean => {
    return state.modelData.isMeshSelectionRemoved;
  },
  [GET_SINGLE_EDITABLE_MESH]: (state: EditorState): MeshData | null => {
    return state.modelData.singleEditableMesh;
  },
  [GET_MODEL_SIZE_IN_MM]: (state: EditorState): Size | null => {
    return state.modelData.modelSizeInMM;
  },
  [GET_TEXTURES_TO_UPDATE]: (state: EditorState): TextureToUpdate[] => {
    return state.modelData.texturesToUpdate;
  },
  [GET_ACTIVE_MESH]: (state: EditorState): MeshData | null => {
    return state.modelData.activeMesh;
  },
  [GET_LAYER_NUMBER]: (state: EditorState): number => {
    return state.canvasConfigs.layerNumber;
  },
  [GET_IS_MODEL_READY_TO_LOAD]: (state: EditorState): boolean => {
    return state.modelData.isModelReadyToLoad;
  },
  [GET_IS_TEXTURE_UPDATED]: (state: EditorState): boolean => {
    return state.modelData.isTextureUpdated;
  },
};
