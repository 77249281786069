<template>
  <tr
    v-if="!isEditingMode"
    class="rates-table__item"
  >
    <td class="rates-table__item-cell">
      {{ item.name }}
    </td>
    <td class="rates-table__item-cell">
      {{ getWeightConditionsLabel(item.weightConditions) }}
    </td>
    <td class="rates-table__item-cell">
      {{ parseFloat(item.price) }}
      <v-menu
        :nudge-width="174"
        rounded="lg"
        offset-y
        left
        right
        bottom
        attach
        close-on-content-click
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list dark>
          <v-list-item
            v-for="(listItem, index) in menuItemsList"
            :key="index"
            @click="listItem.action"
          >
            <v-list-item-title>
              {{ listItem.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
  </tr>
  <tr
    v-else
    class="rates-table__item"
  >
    <td class="rates-table__item-cell">
      <TextField
        v-model="rateForm.itemName"
        class="rates-table__item-name-textfield"
        dense
        input-type="text"
        hide-details
        value="1"
        :rules="rules.required"
      />
    </td>
    <td class="rates-table__item-cell">
      <div class="rates-table__item-weight">
        <div class="rates-table__item-weight-content">
          <span class="rates-table__item-weight-label">Min (g)</span>
          <TextField
            v-model="rateForm.itemLowerWeightCondition"
            :error="!weightValidations.isMinValid"
            class="rates-table__item-weight-textfield"
            dense
            input-type="number"
            hide-details
          />
        </div>
        <div class="rates-table__item-weight-content">
          <span class="rates-table__item-weight-label">Max (g)</span>
          <TextField
            v-model="rateForm.itemHigherWeightCondition"
            :error="!weightValidations.isMaxValid"
            class="rates-table__item-weight-textfield"
            dense
            input-type="number"
            hide-details
          />
        </div>
      </div>
    </td>
    <td class="rates-table__item-cell">
      <TextField
        v-model.number="rateForm.itemPrice"
        class="rates-table__item-price-textfield"
        dense
        input-type="number"
        hide-details
        :rules="rules.price"
      />
      <BaseButton
        :disabled="isFormDisabled"
        :class="{
          'rates-table__item-save-btn_disabled': isFormDisabled
        }"
        class="rates-table__item-save-btn"
        dense
        icon="mdi-check"
        color="white"
        text
        no-margin-top
        @click="addRate"
      />
      <BaseButton
        icon="mdi-close"
        no-margin-top
        dark
        @click="$emit('closeEditingMode')"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useRateTableItem from './RateTableItem';

export default {
  components: {
    BaseButton,
    TextField,
  },
  props: {
    isEditingItem: {
      default: false,
      type: Boolean,
    },
    item: {
      required: true,
      type: Object,
    },
    zone: {
      required: true,
      type: Object,
    },
    isValidForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    return useRateTableItem(props, context);
  },
};
</script>

<style lang="scss">
@import 'RateTableItem';
</style>
