import { RESET_ORDERS, SET_CURRENT_ORDER, SET_ORDER_LOADING, SET_ORDERS, SET_ORDERS_AMOUNT_OF_PAGES } from './constants';
import { getDefaultOrderState } from './module';
import { Order, OrderState } from './types';

export const mutations = {
  [SET_ORDERS]: (
    state: OrderState,
    orders: Order[],
  ) => {
    state.orders = orders;
  },
  [SET_ORDERS_AMOUNT_OF_PAGES]: (
    state: OrderState,
    pages: number,
  ) => {
    state.amountOfPages = pages;
  },
  [SET_CURRENT_ORDER]: (
    state: OrderState,
    order: Order,
  ) => {
    state.currentOrder = order;
  },
  [SET_ORDER_LOADING]: (
    state: OrderState,
    isLoading: boolean,
  ) => {
    state.isLoading = isLoading;
  },
  [RESET_ORDERS]: (
    state: OrderState,
  ) => {
    state = Object.assign(state, getDefaultOrderState());
  },
};
