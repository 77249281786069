import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';
import { checkIsObjectsRestored } from '../objectModifiers/checkIsObjectsRestored';

export const objectAdded = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('object:added', checkIsObjectsRestored);
};
