import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { useForm } from '@/composables/useForm';
import { localRate } from '@/constants/shipping';
import { WeightUnits } from '@/constants/units';
import store from '@/store';
import {
  ADD_DELIVERY_ZONE_RATE,
  REMOVE_DELIVERY_ZONE_RATE,
  UPDATE_DELIVERY_ZONE_RATE,
} from '@/store/Shipping/constants';
import {
  CreateRate,
  Rate,
  ShippingZone,
  WeightCondition,
  WeightOperators,
} from '@/store/Shipping/types';
import {
  isPriceField,
  requiredField,
} from '@/utils/FieldValidationRules';
import { isRangeFieldsValid, isWeightValid } from '@/utils/FieldValidationRules/validations';
import { RateForm } from './types';

const useRateTableItem = (
  props: {
    isEditingItem: boolean;
    isValidForm: boolean;
    item: Rate;
    zone: ShippingZone;
  },
  { emit },
) => {
  const { t } = useI18n();
  const menuItemsList = [
    {
      action: () => emit('setEditingItemId', props.item.id),
      title: t('shipping.editRate__text'),
    },
    {
      action: () =>
        store.dispatch(REMOVE_DELIVERY_ZONE_RATE, {
          id: props.zone.deliveryProfileId,
          rateId: props.item.id,
          zoneId: props.zone.id,
        }),
      title: t('shipping.remove__text'),
    },
  ];
  const isEditingMode = computed(
    (): boolean => props.item.id === localRate.id || props.isEditingItem,
  );

  const { form: rateForm, hasChanged, update: updateForm } = useForm<RateForm>(
    {
      itemName: props.item.name,
      itemPrice: +props.item.price,
      itemLowerWeightCondition: undefined,
      itemHigherWeightCondition: undefined,
    },
    { changeCheck: true },
  );

  const rules = {
    price: [isPriceField],
    required: [requiredField],
  };

  const weightValidations = computed((): {
    isMaxValid: boolean;
    isMinValid: boolean;
  } => isRangeFieldsValid(
    rateForm.itemLowerWeightCondition?.toString(),
    rateForm.itemHigherWeightCondition?.toString(),
    isWeightValid,
  ));

  const getWeightConditionsLabel = (conditions: WeightCondition[]): string => {
    const { maxWeight, minWeight } = parseWeightConditions(conditions);

    const lowestWeight = minWeight ? parseFloat(`${minWeight}`) : 0;
    const highestWeight = maxWeight ? parseFloat(`${maxWeight}`) : undefined;
    if (!highestWeight) return `${lowestWeight}+`;
    return `${lowestWeight} - ${highestWeight}g`;
  };

  const getRateWeightConditions = (): CreateRate['weightConditions'] => {
    const weightConditions = [] as CreateRate['weightConditions'];
    if (rateForm.itemLowerWeightCondition) {
      weightConditions.push({
        operator: WeightOperators.GREATER_THAN_OR_EQUAL,
        unit: WeightUnits.GRAMS,
        value: +rateForm.itemLowerWeightCondition,
      });
    }
    if (rateForm.itemHigherWeightCondition) {
      weightConditions.push({
        operator: WeightOperators.LESS_THAN_OR_EQUAL,
        unit: WeightUnits.GRAMS,
        value: +rateForm.itemHigherWeightCondition,
      });
    }
    return weightConditions;
  };

  const addRate = async (): Promise<void> => {
    const rate = {
      name: rateForm.itemName,
      price: rateForm.itemPrice,
      weightConditions: getRateWeightConditions(),
    };
    const isRateExist = props.item.id !== localRate.id;
    if (isRateExist) {
      const rateToUpdate = { ...props.item, ...rate };
      await store.dispatch(UPDATE_DELIVERY_ZONE_RATE,{
        id: props.zone.deliveryProfileId,
        rate: rateToUpdate,
        zoneId: props.zone.id,
      });
    } else {
      await store.dispatch(ADD_DELIVERY_ZONE_RATE,{
        id: props.zone.deliveryProfileId,
        rate,
        zoneId: props.zone.id,
      });
    }
    updateForm(rateForm, { overwriteInitial: true });
    emit('closeEditingMode');
  };

  const isFormDisabled = computed((): boolean => {
    return !props.isValidForm || !hasChanged.value;
  });

  const parseWeightConditions = (weightConditions: Rate['weightConditions']): {
    maxWeight: number | undefined,
    minWeight: number | undefined,
  } => {
    const minWeight = weightConditions.find(
      (condition: WeightCondition) =>
        condition.operator === WeightOperators.GREATER_THAN_OR_EQUAL,
    )?.value;

    const maxWeight = weightConditions.find(
      (condition: WeightCondition) =>
        condition.operator === WeightOperators.LESS_THAN_OR_EQUAL,
    )?.value;
    
    return {
      minWeight,
      maxWeight,
    };
  };

  watch(props.item, (item: Rate): void => {
    const { name, price, weightConditions } = item;
    const { maxWeight, minWeight } = parseWeightConditions(weightConditions);
    updateForm({
      itemName: name,
      itemPrice: +price,
      itemLowerWeightCondition: minWeight ? parseFloat(`${minWeight}`) : undefined,
      itemHigherWeightCondition: maxWeight ? parseFloat(`${maxWeight}`) : undefined,
    }, { overwriteInitial: true });
  }, { immediate: true });

  onMounted((): void => props.item.id === localRate.id && emit('setEditingItemId', localRate.id));

  return {
    weightValidations,
    addRate,
    getWeightConditionsLabel,
    isEditingMode,
    rateForm,
    menuItemsList,
    rules,
    isFormDisabled,
  };
};

export default useRateTableItem;
