<template>
  <div
    class="alignments"
    :class="{disabled: isDisabled}"
  >
    <AlignmentsMobile
      v-if="isMobile"
      v-bind="alignmentsProps"
      v-on="alignmentsEmits" 
    />
    <AlignmentsDesktop
      v-else
      v-bind="alignmentsProps"
      v-on="alignmentsEmits" 
    />
  </div>
</template>

<script lang="ts">
import useAlignments from '@/components/Editor/Alignments/Alignments';
import AlignmentsDesktop from './AlignmentsDesktop/AlignmentsDesktop.vue';
import AlignmentsMobile from './AlignmentsMobile/AlignmentsMobile.vue';

export default {
  components: {
    AlignmentsDesktop,
    AlignmentsMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return useAlignments();
  },
};
</script>

<style lang="scss" scoped>
@import "Alignments";
</style>
