import facebookButtonImageMobile from '@/assets/images/sign-in-facebook-mobile.svg';
import facebookButtonImage from '@/assets/images/sign-in-facebook.svg';
import googleButtonImageMobile from '@/assets/images/sign-in-google-mobile.svg';
import googleButtonImage from '@/assets/images/sign-in-google.svg';
import linkedinButtonImageMobile from '@/assets/images/sign-in-linkedin-mobile.svg';
import linkedinButtonImage from '@/assets/images/sign-in-linkedin.svg';
import { signInWithSocial } from '@/utils/Auth0/index';
import { connectionTypes } from '@/utils/Auth0/types';
import { isMobile } from '@/utils/Mobile';

const useAuthWithSocial = props => {
  const authWithGoogle = async () => {
    signInWithSocial(connectionTypes.google, props.storeConnectionToken);
  };

  const authWithLinkedin = async () => {
    signInWithSocial(connectionTypes.linkedin, props.storeConnectionToken);
  };

  const authWithFacebook = () => {
    signInWithSocial(connectionTypes.facebook, props.storeConnectionToken);
  };

  const linkedinImage = isMobile
    ? linkedinButtonImageMobile
    : linkedinButtonImage;
  
  const googleImage = isMobile
    ? googleButtonImageMobile
    : googleButtonImage;
  
  const facebookImage = isMobile
    ? facebookButtonImageMobile
    : facebookButtonImage;

  return {
    authWithFacebook,
    authWithGoogle,
    authWithLinkedin,
    facebookImage,
    googleImage,
    isMobile,
    linkedinImage,
  };
};

export default useAuthWithSocial;
