import { ActionContext } from 'vuex';
import { HEXColor } from '@/components/Editor/types/editor';
import { UPDATE_CANVAS_DATA } from '@/store/Templates/constants';
import {
  CHANGE_BACKGROUND_COLOR,
  SET_BACKGROUND_COLOR,
  UPDATE_CANVAS_HISTORY,
} from '../constants';
import { EditorState } from '../types';

export const canvasConfigsActions = {
  [CHANGE_BACKGROUND_COLOR]: (
    { commit, dispatch }: ActionContext<EditorState, any>,
    color: HEXColor,
  ): void => {
    dispatch(UPDATE_CANVAS_HISTORY);
    commit(SET_BACKGROUND_COLOR, color);
    commit(UPDATE_CANVAS_DATA);
  },
};
