<template>
  <div
    v-if="noteVisible"
    class="change-mode-note"
    @click.stop
  >
    <img
      class="change-mode-note__info-icon"
      :src="info"
      :alt="$t('redactor.noteInfo__text')"
    >

    <div class="change-mode-note__text">
      <span>{{ $t('redactor.note__text') }}</span>
      <div @click.stop="$emit('goToEditorView')">
        <span class="change-mode-note__link">
          {{ $t('redactor.note__button') }}
        </span>
      </div>
    </div>

    <img
      class="change-mode-note__close-icon"
      :src="closeIcon"
      :alt="$t('redactor.noteClose__text')"
      @click.stop="$emit('closeNote')"
    >
  </div>
</template>

<script lang="ts">
import closeIcon from '@/components/Editor/assets/icons/closeIcon.svg';
import info from '@/components/Editor/assets/icons/info.svg';

export default {
  props: {
    isEditorActive: {
      required: true,
      type: Boolean,
    },
    noteVisible: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      closeIcon,
      info,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "ChangeModeNoteDesktop";
</style>
