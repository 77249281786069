<template>
  <div class="alignments">
    <div class="alignments-options__row">
      <BaseButton
        v-for="{ name } in textAlignments"
        :key="name"
        class="alignments__option"
        @click="$emit('triggerTextAlignment', name)"
      >
        <template #prependSvg>
          <Icon
            :alignment-type="name"
            :text-alignment="textAlignment"
          />
        </template>
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import Icon from '@/components/Editor/TextAlignIcons/TextAlignIcons.vue';

export default {
  components: {
    BaseButton,
    Icon,
  },
  props: {
    textAlignment: {
      required: true,
      type: String,
    },
    textAlignments: {
      required: true,
      type: Array,
    },
  },
  emits: ['triggerTextAlignment'],
};
</script>

<style lang="scss" scoped>
@import "TextAlignmentsDesktop";
</style>
