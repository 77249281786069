<template>
  <AuthLayout>
    <ForgotPasswordForm />
  </AuthLayout>
</template>

<script lang="ts">
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm.vue';

export default {
  components: {
    AuthLayout,
    ForgotPasswordForm,
  },
};
</script>
