import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import defaultBox from '@/assets/images/default-collection.png';
import {
  black50,
  black54,
  primaryBlack,
  primaryViolet,
} from '@/constants/colors';
import { UserRoles } from '@/constants/roles';
import router from '@/router';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import { User } from '@/store/Authorization/types';
import {
  CHANGE_COLLECTION_IMAGE,
  CREATE_NEW_COLLECTION,
  EDIT_COLLECTION_NAME,
  GET_IS_COLLECTION_IMAGE_LOADING,
  PUBLISH_COLLECTION,
  REMOVE_LOCAL_COLLECTION,
} from '@/store/Collections/constants';
import { Collection } from '@/store/Collections/types';
import { MenuItem } from '@/types';
import { maxLength, requiredField } from '@/utils/FieldValidationRules';
import {
  CollectionRouteTypes,
  CollectionTypes,
} from '@/views/Collections/types';
import { InputFileEvent } from '@/views/UploadPage/types';
import { EditTypes } from './types';

const useCollectionCard = (
  props: { collection: Collection },
  { emit },
) => {
  const { t } = useI18n();
  const collection = toRef(props, 'collection');
  const isCoverImageLoading = computed((): boolean => 
    store.getters[GET_IS_COLLECTION_IMAGE_LOADING]);
  const menuItems = computed((): MenuItem[] => [
    {
      action: () => removeCollection(),
      roles: [ UserRoles.User, UserRoles.Admin ],
      title: t('collections.remove__text'),
    },
    {
      action: () => chooseCollectionImage(),
      disabled: isCoverImageLoading.value,
      roles: [ UserRoles.User, UserRoles.Admin ],
      title: t('collections.changeCollectionCover__text'),
    },
    {
      action: () => publishCollection(),
      roles: [UserRoles.Admin],
      title: collection.value.isPublic
        ? t('collections.unpublish__text')
        : t('collections.publish__text'),
    },
  ]);

  const user = computed((): User => store.getters[GET_USER]);
  const menuItemsList = computed((): MenuItem[] =>
    menuItems.value.filter(({ roles }): boolean =>
      roles.includes(user.value.role)));
  const editNameRules = [ requiredField, maxLength(40) ];
  const isEditCollectionName = ref(false);
  const fieldEditName = ref(collection.value.name);
  const isValidName = ref(false);
  const imageInput = ref<HTMLInputElement>();
  const isLocalCollection = computed((): boolean => !collection.value.name);
  const ignorableClickOutsideElements = [document.querySelector('.global-alert')];

  const previewImage = computed((): string =>
    collection.value.imagePath || defaultBox);
  
  const isPreviewImageDisplayed = computed((): boolean =>
    collection.value.imagePath === previewImage.value);
  
  const chooseCollectionImage = (): void => {
    imageInput.value?.click();
  };
    
  const changeCollectionImage = (event: InputFileEvent): void => {
    const image = event.target.files?.[0];
    store.dispatch(CHANGE_COLLECTION_IMAGE, {
      id: collection.value.id,
      image,
    });
  };

  const toggleEditField = (): void => {
    isEditCollectionName.value = !isEditCollectionName.value;
  };

  const editCollection = (editType: EditTypes): void | boolean => {
    if (!isValidName.value) return;
    if (collection.value.name === fieldEditName.value) return toggleEditField();
    if (editType === EditTypes.collectionName)
      store.dispatch(EDIT_COLLECTION_NAME, {
        id: collection.value.id,
        name: fieldEditName.value,
      });
    
    if (editType === EditTypes.collectionCreate)
      store.dispatch(CREATE_NEW_COLLECTION, {
        id: collection.value.id,
        name: fieldEditName.value,
      });
    
    toggleEditField();
  };

  const openCollection = (): void => {
    if (isLocalCollection.value) return;
    const isOnPublicCollectionsPage =
      router.currentRoute.name === CollectionRouteTypes.publicCollections;
    const collectionsRoute =
      collection.value.isPublic && isOnPublicCollectionsPage
        ? CollectionRouteTypes.publicCollections
        : CollectionRouteTypes.privateCollections;
    router.push({
      path: `/${collectionsRoute}/${collection.value.id}/templates`,
      query: {
        from:
          router.currentRoute.name === CollectionRouteTypes.publicCollections
            ? CollectionTypes.publicCollections
            : CollectionTypes.privateCollections,
        page: '1',
      },
    });
  };

  const publishCollection = (): Promise<void> =>
    store.dispatch(PUBLISH_COLLECTION, collection.value);
  
  const removeCollection = (): Promise<void> =>
    emit('openConfirmRemoveModal', collection.value);
  
  const removeLocalCollection = (): void => {
    fieldEditName.value = '';
    store.dispatch(REMOVE_LOCAL_COLLECTION, collection.value.id);
  };

  return {
    black50,
    black54,
    changeCollectionImage,
    editCollection,
    editNameRules,
    EditTypes,
    fieldEditName,
    ignorableClickOutsideElements,
    imageInput,
    isEditCollectionName,
    isLocalCollection,
    isPreviewImageDisplayed,
    isValidName,
    menuItems,
    menuItemsList,
    openCollection,
    previewImage,
    primaryBlack,
    primaryViolet,
    publishCollection,
    removeLocalCollection,
    toggleEditField,
  };
};

export default useCollectionCard;
