import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';
import { beforeSelectionCleared } from './beforeSelectionCleared';
import { keyDownDelete } from './keyDownDelete';
import { mouseDown } from './mouseDown';
import { mouseUp } from './mouseUp';
import { mouseWheel } from './mouseWheel';
import { objectAdded } from './objectAdded';
import { objectModified } from './objectModified';
import { objectMoving } from './objectMoving';
import { objectScaled } from './objectScaled';
import { objectScaling } from './objectScaling';
import { selectionCleared } from './selectionCleared';
import { selectionCreated } from './selectionCreated';
import { selectionUpdated } from './selectionUpdated';
import { textChanged } from './textChanged';
import { touchEvents } from './touchEvents';
import { zoomToTextOnInputMobile } from './zoomToTextOnInputMobile';

export const toggleCanvasListeners = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  beforeSelectionCleared(canvas, toggle);
  keyDownDelete(toggle);
  mouseDown(canvas, toggle);
  mouseUp(canvas, toggle);
  mouseWheel(canvas, toggle);
  objectAdded(canvas, toggle);
  objectModified(canvas, toggle);
  objectMoving(canvas, toggle);
  objectScaled(canvas, toggle);
  objectScaling(canvas, toggle);
  selectionCreated(canvas, toggle);
  selectionUpdated(canvas, toggle);
  textChanged(canvas, toggle);
  touchEvents(canvas, toggle);
  zoomToTextOnInputMobile(canvas, toggle);
  selectionCleared(canvas);
};
