import axios, { AxiosResponse } from 'axios';

export const fetchFoundProduct = async (
  searchQuery: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/api/v1/collections/search`,
      {
        params: {
          searchQuery,
        },
        withCredentials: true,
      },
    );

    return response;
  } catch (e) {
    return e.response;
  }
};
