import jwt_decode from 'jwt-decode';
import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';
import { useStopScroll } from '@/composables/useStopScroll';
import { fetchFoundProduct } from '@/services/Collections/GetFoundProduct';
import { INIT_USER, SET_IS_AUTHORIZED, SET_USER } from '@/store/Authorization/constants';
import authorization, { defaultUser } from '@/store/Authorization/module';
import { SEARCH_COLLECTIONS } from '@/store/Collections/constants';
import collections from '@/store/Collections/module';
import {
  GET_IS_SHOW_SEARCH_FIELD,
  REMOVE_NOTIFICATION,
  SET_IS_SHOW_SEARCH_FIELD,
  SET_NOTIFICATION,
  SET_NOTIFICATION_TEXT,
} from '@/store/constants';
import editor from '@/store/Editor/module';
import orders from '@/store/Orders/module';
import shipping from '@/store/Shipping/module';
import shopifyApp from '@/store/Shopify/module';
import templates from '@/store/Templates/module';
import { initialNotificationLocalization, Notification, NotificationType } from '@/types/notification';
import { isTabletAndLower } from '@/utils/Mobile';

Vue.use(Vuex);

export default new Vuex.Store<any>({
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger({
      collapsed: true,
      logActions: true,
      logMutations: true,
      logger: console,
    })]
    : [],
  actions: {
    changeLanguage(state: any) {
      state.language = state.language === 'en' ? 'de': 'en';
    },
    [INIT_USER]: ({ commit }) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;
        commit(SET_USER, jwt_decode(token));
        commit(SET_IS_AUTHORIZED, true);
      } catch (error) {
        commit(SET_USER, defaultUser);
        commit(SET_IS_AUTHORIZED, false);
      }
    },
    [SEARCH_COLLECTIONS]: async ({ commit }, query) => {
      const response = await fetchFoundProduct(query);

      if (response.status !== 200) {
        commit(SET_NOTIFICATION, { notificationText: response.statusText, type: 'error' });
        return {
          collections: [],
          templates: [],
        };
      }

      return response.data;
    },
    [SET_NOTIFICATION]: (
      { commit, state },
      data: Notification,
    ) => {
      commit(REMOVE_NOTIFICATION);
      commit(SET_NOTIFICATION_TEXT, data);
      state.notificationData.timeoutId = setTimeout(() => {
        commit(REMOVE_NOTIFICATION);
      }, 5000);
    },
    setUser(state: any, user) {
      state.user = user;
    },
  },
  modules: {
    authorization,
    collections,
    editor,
    shipping,
    shopifyApp,
    templates,
    orders,
  },
  getters: {
    [GET_IS_SHOW_SEARCH_FIELD]: state => state.isShowSearchField,
  },
  mutations: {
    [REMOVE_NOTIFICATION]: state => {
      state.notificationData.isVisible = false;
      clearTimeout(state.notificationData.timeoutId);
      state.notificationData.timeoutId = null;
      state.notificationData.localizationData = initialNotificationLocalization;
    },
    [SET_NOTIFICATION_TEXT]: (state,
      {
        localizationData = initialNotificationLocalization,
        text,
        type,
      }: Notification) => {
      state.notificationData.notificationText = text;
      state.notificationData.type = type;
      state.notificationData.localizationData = localizationData;
      state.notificationData.isVisible = true;
    },
    [SET_IS_SHOW_SEARCH_FIELD]: (state, isShowSearchField: boolean) => {
      const { disableScroll, enableScroll } = useStopScroll();
      state.isShowSearchField = isShowSearchField;
      isTabletAndLower && isShowSearchField
        ? disableScroll()
        : enableScroll();
    },
  },
  state: {
    isAuthorized: false,
    language: 'en',
    nameSelectedTemplate: null,
    notificationData: {
      isVisible: false,
      localizationData: initialNotificationLocalization,
      notificationText: '',
      timeoutId: null,
      type: NotificationType.Error,
    },
    user: {},
    isShowSearchField: false,
  },
});
