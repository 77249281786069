import axios from 'axios';
import { useI18n } from 'vue-i18n-composable';
import { CSS_BODY_TAG } from '@/components/Editor/constants/fonts';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { GET_FONTS_TO_RENDER } from '@/store/Editor/constants';
import { NotificationType } from '@/types/notification';
import { registerFontFace } from './registerFontFace';
const uninterceptedAxiosInstance = axios.create();

export const getGoogleFont = async (
  font: string,
  widthNotification?:boolean,
): Promise<boolean> => {
  const { t } = useI18n();
  const fontsToRender = store.getters[GET_FONTS_TO_RENDER];
  if (fontsToRender.some((existingFont: string) => existingFont === font)) {
    store.dispatch(SET_NOTIFICATION, {
      text: t('notifications.fontAlreadyExists', {
        fontName: font,
      }),
      type: NotificationType.Error,
    });
    return false;
  }

  try {
    const res = await uninterceptedAxiosInstance.get(
      'https://fonts.googleapis.com/css', { 
        params: {
          family: font,
        },
      },
    );
    if (res.data.includes(CSS_BODY_TAG)) {
      store.dispatch(SET_NOTIFICATION, {
        text: t('notifications.fontNotSupported'),
        type: NotificationType.Error,
      });
      return false;
    }
    registerFontFace(res.data);
    if (widthNotification) {
      store.dispatch(SET_NOTIFICATION, {
        text: t('notifications.fontAdded', {
          fontName: font,
        }),
        type: NotificationType.Success,
      });
    }
    return true;
  } catch {
    store.dispatch(SET_NOTIFICATION, {
      text: t('notifications.fontAddError', {
        fontName: font,
      }),
      type: NotificationType.Error,
    });
    return false;
  }
};
