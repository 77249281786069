import CryptoJS from 'crypto-js';

export const MODEL_BASE64_IDENTIFICATOR = 'data:application/octet-stream;base64,';

export const is3DModelEncoded = (data: string): boolean => {
  return !data.startsWith('glTF');
};

export const decrypt3DModel = (data: string): string => {
  const key = CryptoJS.enc.Utf8.parse(
    process.env.VUE_APP_ECRYPTION_3DMODEL_KEY,
  );
  const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ECRYPTION_3DMODEL_IV);
  const decrypted = CryptoJS.AES.decrypt(data, key, {
    iv,
    keySize: 16,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
