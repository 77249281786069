<template>
  <div class="already-have-account">
    {{ $t('signIn.alreadyHaveAccount') }}
    <router-link :to="{ path: '/sign-in', query: routeQuery}">
      <span class="underline-animate">
        {{ $t('signIn.signIn__text') }}
      </span>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    routeQuery: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'AlreadyHaveAccount';
</style>
