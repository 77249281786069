import Vue, { computed } from 'vue';
import VueRouter from 'vue-router';
import { UserRoles } from '@/constants/roles';
import { Tokens } from '@/constants/tokens';
import routes from '@/router/routes';
import store from '@/store';
import { GET_USER, INIT_USER, LOG_OUT } from '@/store/Authorization/constants';
import { User } from '@/store/Authorization/types';
import { REMOVE_NOTIFICATION } from '@/store/constants';
import { CollectionRouteTypes } from '@/views/Collections/types';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition;
    return null;
  },
});

const authRoutes = [ 'sign-in', 'sign-up' ];

router.beforeEach(async (to, _, next) => {
  store.commit(REMOVE_NOTIFICATION);
  
  const roles = to.meta?.roles || [];
  const requiresAuth = !!roles?.length;

  const user = computed((): User => store.getters[GET_USER]);
  const isAuthRoute = authRoutes.includes(to.name!);
  const isLoggedIn = computed(
    (): boolean => user.value?.role !== UserRoles.Visitor,
  );

  if (isAuthRoute && to.query[Tokens.connectionToken]) {
    if (isLoggedIn.value) {
      await store.dispatch(LOG_OUT);
      next({ name: to.name || 'sign-in', query: to.query });
      return;
    }
    next();
    return;
  }
  if (!user.value.id) {
    await store.dispatch(INIT_USER);
  }
  
  if (isAuthRoute && isLoggedIn.value) {
    next({ name: CollectionRouteTypes.privateCollections });
    return;
  }

  if (requiresAuth && !isLoggedIn.value) {
    next({ name: 'sign-in' });
    return;
  }

  if (roles.includes(user.value.role)) {
    next();
    return;
  }

  if (!requiresAuth) {
    next();
    return;
  }
});

export default router;
