import { EditorGuidelinesVisibility } from './types';

export const initialVisibleGuidelines: EditorGuidelinesVisibility = {
  horizontalBottom: true,
  horizontalCenter: true,
  horizontalTop: true,
  verticalCenter: true,
  verticalLeft: true,
  verticalRight: true,
};

export const GUIDELINE_OFFSET = 5;
