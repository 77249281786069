import { computed, toRef } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { useI18n } from 'vue-i18n-composable';
import { primaryViolet } from '@/constants/colors';
import router from '@/router';
import { SortItems } from '@/types';

interface LinkObject {
  name: TranslateResult,
  to: { name: string }
}

const useTemplatesHeader = props => {
  const collection = toRef(props, 'collection');
  const collectionName = computed((): string => collection.value?.name);
  const { t } = useI18n();
  const menuSettings = {
    bottom: true,
    color: primaryViolet,
    rounded: 'lg',
  };

  const sortItems: SortItems[] = [
    SortItems.New,
    SortItems.Old,
    SortItems.Alphabetically,
    SortItems.ReverseAlphabetically,
  ];

  const prevLink = computed((): LinkObject => {
    const fromPublic = router.currentRoute.query.from === 'public';
    const isNavigationFromPublic = fromPublic && collection.value?.isPublic;

    return {
      name: isNavigationFromPublic ? t('tabs.publicCollections__text') : t('tabs.myCollections__text'),
      to: isNavigationFromPublic ? { name: 'public-collections' } : { name: 'my-collections' },
    };
  });

  return {
    collectionName,
    menuSettings,
    prevLink,
    primaryViolet,
    sortItems,
  };
};

export default useTemplatesHeader;
