import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import { REMOVE_TEMPLATE } from '@/store/Templates/constants';
import { Template } from '@/store/Templates/types';

const useConfirmRemoveTemplateModal = (props: {
  template: Template
}, { emit }) => {
  const removeTemplate = async (): Promise<void> => {
    await store.dispatch(REMOVE_TEMPLATE, props.template.id);
    emit('triggerInitTemplates');
    emit('close');
  };
  
  return {
    primaryViolet,
    removeTemplate,
  };
};

export default useConfirmRemoveTemplateModal;
