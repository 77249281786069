import {
  GET_CURRENT_DELIVERY_PROFILE,
  GET_DELIVERY_LOADING,
  GET_DELIVERY_PROFILES,
  GET_SHIPPING_REGIONS,
  GET_TABLE_AMOUNT_OF_PAGES,
  GET_TABLE_CURRENT_PAGE,
} from '@/store/Shipping/constants';
import { ShippingState } from '@/store/Shipping/types';

export const getters = {
  [GET_CURRENT_DELIVERY_PROFILE]: (state: ShippingState) =>
    state.currentDeliveryProfile,
  [GET_DELIVERY_LOADING]: (state: ShippingState) => state.isLoading, 
  [GET_DELIVERY_PROFILES]: (state: ShippingState) => state.deliveryProfiles,
  [GET_SHIPPING_REGIONS]: (state: ShippingState) => state.shippingRegions,
  [GET_TABLE_AMOUNT_OF_PAGES]: (state: ShippingState) => state.amountOfPages,
  [GET_TABLE_CURRENT_PAGE]: (state: ShippingState) => state.currentPage,
};
