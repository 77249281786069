import { useI18n } from 'vue-i18n-composable';
import { CollectionTypes } from '@/views/Collections/types';

const useCollectionsNotExists = ({ collectionType }: {
  collectionType: CollectionTypes
}) => {
  const { t } = useI18n();
  const isPublic: boolean =
    collectionType === CollectionTypes.publicCollections;
  const message = isPublic
    ? t('collections.noPublicCollections__text')
    : t('collections.noMyCollections__text');
  return {
    isPublic,
    message,
  };
};

export default useCollectionsNotExists;
