import store from '@/store';
import { CanvasDataByMesh } from '@/store/Editor/types';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';

export const getCanvasDataByMeshName = (
  meshName: string,
): CanvasDataByMesh | null => {
  const { canvasData } = store.getters[GET_CURRENT_TEMPLATE];
  if (!canvasData) return null;
  return canvasData.find(
    (data: CanvasDataByMesh): boolean => data.meshName === meshName,
  );
};
