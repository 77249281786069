import { computed, ref } from 'vue';
import { black54 } from '@/constants/colors';
import store from '@/store';
import {
  DELIVERY_PROFILE_SHOPIFY_UPDATE,
  EDIT_DELIVERY_PROFILE_NAME,
  GET_CURRENT_DELIVERY_PROFILE,
  GET_DELIVERY_LOADING,
} from '@/store/Shipping/constants';
import { DeliveryProfile } from '@/store/Shipping/types';
import { maxLength, requiredField } from '@/utils/FieldValidationRules';

const useDeliveryProfileHeading = (props: {deliveryProfileId: DeliveryProfile['id']}) => {
  const isEditingName = ref(false);
  const currentDeliveryProfile = computed(() =>
    store.getters[GET_CURRENT_DELIVERY_PROFILE]);
  const name = ref('');
  const nameFieldRules = [ requiredField, maxLength(30) ];
  const isValidName = ref(false);
  const isLoading = computed(() => store.getters[GET_DELIVERY_LOADING]);
  const ignorableClickOutsideElements = [document.querySelector('.global-alert')];

  const saveEditedName = async () => {
    if (!isValidName.value) return;
    if (name.value !== currentDeliveryProfile.value.name) {
      await store.dispatch(EDIT_DELIVERY_PROFILE_NAME, {
        id: currentDeliveryProfile.value.id,
        name: name.value,
      });
    }
    isEditingName.value = false;
  };

  const openEditNameForm = (): void => {
    name.value = currentDeliveryProfile.value.name;
    isEditingName.value = true;
  };

  const saveShopify = () => 
    store.dispatch(
      DELIVERY_PROFILE_SHOPIFY_UPDATE,
      props.deliveryProfileId,
    );

  return {
    black54,
    currentDeliveryProfile,
    ignorableClickOutsideElements,
    isEditingName,
    isLoading,
    isValidName,
    name,
    nameFieldRules,
    openEditNameForm,
    saveEditedName,
    saveShopify,
  };
};

export default useDeliveryProfileHeading;
