import { useI18n } from 'vue-i18n-composable';
import { Route } from 'vue-router';
import { primaryBlack, secondaryViolet } from '@/constants/colors';
import router from '@/router';
import { ShippingZone } from '@/store/Shipping/types';

const useShippingTable = () => {
  const { t } = useI18n();
  const tableHeaders = [
    { title: t('shipping.groupOfProducts__text') },
    { title: t('shipping.shippingZones__text') },
  ];

  const openDeliveryProfile = (id: number): Promise<Route> =>
    router.push(`/delivery-profile/${id}`);

  const getZones = (zones: ShippingZone[] | null, quantity: number): string => {
    if (!zones) return '';
    const isMoreThanQuantity = zones?.length > quantity;
    const zoneNames = zones.slice(0, quantity).map(
      (zone: ShippingZone): string => zone.name);
    if (!zoneNames) return '';
    return isMoreThanQuantity
      ? (zoneNames.join(', ') + ' ...')
      : zoneNames.join(', ');
  };

  return {
    getZones,
    openDeliveryProfile,
    primaryBlack,
    secondaryViolet,
    tableHeaders,
  };
};

export default useShippingTable;
