export const loadImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = err => reject(err);
    image.src = src;
    image.crossOrigin = 'anonymous';
  });
