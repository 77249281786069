<template>
  <div class="cards-loader">
    <v-skeleton-loader
      class="cards-loader__title"
      type="heading"
    />
    <div class="cards-loader__cards">
      <div
        v-for="item in cardsCount"
        :key="item"
        class="cards-loader__cards-card"
      >
        <v-skeleton-loader
          class="cards-loader__cards-card-image"
          type="image"
        />
        <v-skeleton-loader
          class="cards-loader__cards-card-title"
          type="heading"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardsCount: {
      default: 8,
      required: false,
      type: Number,
    },
  },
};
</script>

<style lang="scss">
@import
'@/components/SkeletonLoader/Skeletons/CardsSkeleton/CardsSkeleton.scss';
</style>
