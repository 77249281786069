import { actions } from '@/store/Editor/actions';
import { getters } from '@/store/Editor/getters';
import { getDefaultEditorState } from '@/store/Editor/helpers';
import { mutations } from '@/store/Editor/mutations';
import { EditorState } from '@/store/Editor/types';

const state: EditorState = Object.assign({}, getDefaultEditorState());

export default {
  actions,
  getters,
  mutations,
  state,
};
