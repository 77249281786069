import {
  ADD_FONT_TO_RENDER,
  REMOVE_FONT_FROM_RENDER,
  SET_FONT_TO_REMOVE,
  SET_GOOGLE_FONTS_LIST,
  SET_IS_ADD_FONT_MODAL_OPEN,
  SET_IS_FONT_SELECT_OPEN,
  SET_IS_REMOVE_FONT_MODAL_OPEN,
} from '../constants';
import { EditorState, GoogleFontsList } from '../types';

export const fontsConfigsMutations = {
  [SET_GOOGLE_FONTS_LIST]: (
    state: EditorState,
    fonts: GoogleFontsList[],
  ): void => {
    state.fontsConfigs.googleFontsList = fonts;
  },
  [SET_IS_ADD_FONT_MODAL_OPEN]: (state: EditorState, flag: boolean): void => {
    state.fontsConfigs.isAddFontModalOpen = flag;
  },
  [ADD_FONT_TO_RENDER]: (state: EditorState, font: string): void => {
    state.fontsConfigs.fontsToRender.unshift(font);
  },
  [SET_IS_FONT_SELECT_OPEN]: (state: EditorState, flag: boolean): void => {
    state.fontsConfigs.isFontSelectOpen = flag;
  },
  [SET_IS_REMOVE_FONT_MODAL_OPEN]: (
    state: EditorState,
    flag: boolean,
  ): void => {
    state.fontsConfigs.isRemoveFontModalOpen = flag;
  },
  [SET_FONT_TO_REMOVE]: (state: EditorState, font: string): void => {
    state.fontsConfigs.fontToRemove = font;
  },
  [REMOVE_FONT_FROM_RENDER]: (state: EditorState, font: string): void => {
    state.fontsConfigs.fontsToRender = state
      .fontsConfigs
      .fontsToRender
      .filter(fontToRender => fontToRender !== font);
  },
};
