import { Size } from '@/components/Editor/types/editor';
import store from '@/store';
import { GET_CANVAS_SIZES } from '@/store/Editor/constants';
import { CanvasSize } from '@/store/Editor/types';

export const getCurrentCanvasSize = (meshName: string): Size => {
  const canvasSizes = store.getters[GET_CANVAS_SIZES];
  return canvasSizes.find((canvasSize: CanvasSize): boolean => {
    return canvasSize.meshName === meshName;
  }).size;
};
