import { OBJECT_CUSTOM_ATTRIBUTES } from '@/components/Editor/constants/customConfigs';
import {
  FabricObject,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_ACTIVE_CANVAS,
  GET_ACTIVE_MESH,
  GET_IS_LOADED_FROM_HISTORY,
  GET_ZOOM_TO_DEFAULT,
  SET_TEXT_OBJECT,
} from '@/store/Editor/constants';
import { getCurrentCanvasSize } from '../canvasModifiers/getCurrentCanvasSize';
import { createFabricText, prepareDataForFabricText } from './newFabricText';
import { setCustomPropsToText } from './setCustomPropsToText';

export const addNewTextOnCanvas = (
  textData: FabricObject,
): void => {
  const zoomToDefault = store.getters[GET_ZOOM_TO_DEFAULT];
  const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
  const isLoadedFromHistory = store.getters[GET_IS_LOADED_FROM_HISTORY];
  const meshName = store.getters[GET_ACTIVE_MESH].name;

  const { id, isDefault, layerName, layerNumber } = textData;

  const canvasSize = getCurrentCanvasSize(meshName);

  let newFabricText = textData;

  if (isDefault) {
    newFabricText = prepareDataForFabricText({
      layerName: layerName || '',
      canvasSize,
      zoomToDefault,
      id: id || '',
      layerNumber: layerNumber || 1,
    });
  }

  newFabricText = createFabricText(newFabricText) as FabricObject;
  setCustomPropsToText(newFabricText);

  activeCanvas.add(newFabricText);

  if (!isLoadedFromHistory) {
    activeCanvas.setActiveObject(newFabricText);
    store.commit(
      SET_TEXT_OBJECT,
      newFabricText.toObject(OBJECT_CUSTOM_ATTRIBUTES),
    );
  }
};
