import { computed } from 'vue';
import store from '@/store';
import { GET_IS_DEACTIVATING_LOADING } from '@/store/Shopify/constants';

export const useShopifyDeactivatedStore = () => {
  const isDeactivatingLoading = computed(
    (): boolean => store.getters[GET_IS_DEACTIVATING_LOADING],
  );

  return {
    isDeactivatingLoading,
  };
};
