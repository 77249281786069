import { useI18n } from 'vue-i18n-composable';
import profileImg from '@/assets/images/test-profile-img.png';
import { primaryBlack } from '@/constants/colors';
import router from '@/router/index';
import { LOG_OUT } from '@/store/Authorization/constants';
import store from '@/store/index';

const useProfileMenu = ({ emit }) => {
  const { t } = useI18n();

  const menuItems = [
    {
      action: () => router.push('/profile'),
      disabled: router.currentRoute.name === 'profile',
      title: t('menu.myProfile__text'),
    },
    {
      action: () => emit('openPricingModal'),
      title: t('menu.pricing__text'),
    },
    {
      action: () => router.push('/user-guide'),
      title: t('menu.userGuide__text'),
    },
    {
      action: () => emit('openPrivacyPolicyModal'),
      title: t('privacyPolicy.title'),
    },
    {
      action: () => emit('openReturnsPolicyModal'),
      title: t('returnsPolicy.title'),
    },
    {
      action: () => emit('openContactSupportModal'),
      title: t('menu.support__text'),
    },
    {
      action: () => store.dispatch(LOG_OUT),
      title: t('menu.logOut__text'),
    },
  ];

  return {
    menuItems,
    primaryBlack,
    profileImg,
  };
};

export default useProfileMenu;
