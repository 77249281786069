<template>
  <div class="shipping-heading">
    <h2 class="shipping-heading__title">
      {{ $t('shipping.title__text') }}
    </h2>
    <BaseButton
      class="shipping-heading__button"
      :button-text="$t('shipping.addGroup__text')"
      prepend-icon="mdi-plus"
      no-margin-top
      outlined
      dark
      large
      @click="$emit('createDeliveryProfile', (new Date()).toLocaleString())"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { primaryViolet } from '@/constants/colors';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return {
      primaryViolet,
    };
  },
};
</script>

<style lang="scss">
@import 'ShippingHeading';
</style>
