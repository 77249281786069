import auth0, { Auth0Error, Auth0Result } from 'auth0-js';

import store from '@/store';
import { connectionTypes } from './types';

const webAuth = new auth0.WebAuth({
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  redirectUri: process.env.VUE_APP_PUBLIC_URL,
});

const authCallback = async (
  err: Auth0Error | null,
  authResult: Auth0Result,
  storeConnectionToken?: string,
) => {
  if (err) return;
  const { idToken } = authResult;
  store.dispatch('SIGN_IN', {
    data: { idToken, storeConnectionToken },
    type: 'socials',
  });
};

export const signInWithSocial = (
  connection: string,
  storeConnectionToken?: string,
) => {
  webAuth.popup.authorize({
    connection,
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    owp: true,
    // @ts-ignore
    prompt: (connection === connectionTypes.google) && 'login',
    redirectUri: process.env.VUE_APP_PUBLIC_URL,
    responseType: 'token id_token',
  }, (err, authResult) => authCallback(err, authResult, storeConnectionToken));
};

export const signOutWithSocial = () => {
  webAuth.logout({
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    returnTo: `${process.env.VUE_APP_PUBLIC_URL}/sign-in`,
  });
};
