import { ShopifyStore } from '@/store/Shopify/types';
import { Template } from '@/store/Templates/types';
import { User } from '../Authorization/types';

export enum OrderStatus {
  Processing = 'processing',
  Paid = 'paid',
  Error = 'error',
  SubError = 'suberror',
  Cancelled = 'cancelled',
  PrintReady = 'printready',
  Shipped = 'shipped',
  Completed = 'completed',
}

interface OrderProduct {
  id: number
  quantity: number;
  scanLink: string;
  template: Pick<Template, 'id' | 'name' | 'imagePath'>;
}

export interface Order {
  country: string;
  createdAt: Date;
  error?: string;
  firstName: string;
  id: number;
  lastName: string;
  products: OrderProduct[];
  shopifyOrderId: number;
  shopifyStore: Pick<ShopifyStore, 'id' | 'store'>;
  status: OrderStatus;
  totalPrice: number;
  userId?: User['id']
}
export interface OrderState {
  amountOfPages: number;
  currentOrder: Order | null;
  isLoading: boolean;
  orders: Order[]
}

type payloadType =
  | number
  | string
  | object
  | boolean
  | null
  | (number | string | object)[];

export interface Context {
  commit: (type: string, payload?: payloadType) => void;
  dispatch: (type: string, payload: payloadType) => void;
  state: OrderState;
}
