import jwt_decode from 'jwt-decode';

import { useI18n } from 'vue-i18n-composable';
import router from '@/router/index';
import { UserAPI } from '@/services/UserAPI';
import {
  LOAD_CUSTOM_FONTS,
  LOG_OUT,
  RESET_PASSWORD,
  SEND_ACTIVATION_MAIL,
  SEND_RESET_MAIL,
  SET_CUSTOM_FONTS,
  SET_USER,
  SIGN_IN,
  SIGN_UP,
  UPDATE_CREDENTIALS,
  UPDATE_CUSTOM_FONTS,
  UPDATE_PASSWORD,
} from '@/store/Authorization/constants';
import {
  Context,
  NewCredentials,
  NewPassword,
  ResetPasswordData,
  SignUpData,
  UserData,
  UserState,
} from '@/store/Authorization/types';
import { SignInType } from '@/store/Authorization/types';
import { SET_NOTIFICATION } from '@/store/constants';
import { NotificationType } from '@/types/notification';
import { smoothScrollToTop } from '@/utils';
import { signOutWithSocial } from '@/utils/Auth0';
import { handleResponse } from '@/utils/axios/handleResponse';
import { isiOS } from '@/utils/isiOS';
import { CollectionRouteTypes } from '@/views/Collections/types';
import { removeFontFromUserFonts } from './helpers';
import { defaultUser } from './module';

export const actions = {
  [LOAD_CUSTOM_FONTS]: async (
    { commit }: Context,
    data: { id: number },
  ) => {
    const response = await UserAPI.getCustomFonts(data);

    const handleSuccessStatus = (): void => {
      commit(SET_CUSTOM_FONTS, response.data.customFonts);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [LOG_OUT]: async ({ commit, state }: Context) => {
    (state.signInWithType === SignInType.socials)
      && signOutWithSocial();
    
    localStorage.removeItem('token');
    commit('SET_IS_AUTHORIZED', false);
    commit(SET_USER, defaultUser);

    (state.signInWithType !== SignInType.socials)
      && router.push('/sign-in');
  },

  [RESET_PASSWORD]: async(
    { dispatch }: Context,
    data: ResetPasswordData,
  ) => {
    const response = await UserAPI.resetPassword(data);

    const handleSuccessStatus = () => {
      dispatch(SET_NOTIFICATION, {
        text: response.data,
        type: NotificationType.Success,
      });
      setTimeout(() => router.push('/sign-in'), 2000);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [SEND_ACTIVATION_MAIL]: async (
    { dispatch }: Context,
    data: { email: string },
  ) => {
    const response = await UserAPI.sendActivationEmail(data);

    const handleSuccessStatus = () => {
      dispatch(SET_NOTIFICATION, {
        text: response.data,
        type: NotificationType.Success,
      });
    };
  
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [SEND_RESET_MAIL]: async (
    { dispatch }: Context,
    data: { email: string },
  ) => {
    const response = await UserAPI.sendResetEmail(data);

    const handleSuccessStatus = () => {
      dispatch(SET_NOTIFICATION, {
        text: response.data,
        type: NotificationType.Success,
      });
      return true;
    };

    return handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [SIGN_IN]: async (
    { commit }: Context,
    { data, type }: UserData,
  ) => {
    const response = type === SignInType.socials
      ? await UserAPI.signInWithSocials(data)
      : await UserAPI.signIn(data);
  
    const handleSuccessStatus = ({ token }: { token: string }) => {
      const jwtDecodeToken: UserState = jwt_decode(token);
      localStorage.setItem('token', token);
      commit('SET_IS_AUTHORIZED', true);
      commit('SET_USER', jwtDecodeToken);
      commit('SET_SIGN_IN_WITH_TYPE', type);
      setTimeout(() => {
        if (isiOS()) smoothScrollToTop();
        router.push({ name: CollectionRouteTypes.privateCollections });
      }, 500);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200, 
    });
  },

  [SIGN_UP]: async (
    context: Context,
    data: SignUpData,
  ) => {
    const response = await UserAPI.signUp(data);
  
    const handleSuccessStatus = () => {
      router.push(`/confirm-email/${data.email}`);
    };
  
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },

  [UPDATE_CREDENTIALS]: async (
    { commit, dispatch }: Context,
    data: NewCredentials,
  ) => {
    const { t } = useI18n();
    const response = await UserAPI.updateCredentials(data);

    const handleSuccessStatus = ({ token }) => {
      dispatch(SET_NOTIFICATION, {
        text: t('notifications.credentialsChangedSuccesfully'),
        type: NotificationType.Success,
      });
      const jwtDecodeToken: UserState = jwt_decode(token);
      commit('SET_USER', jwtDecodeToken);
      localStorage.setItem('token', token);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },

  [UPDATE_CUSTOM_FONTS]: async (
    { commit, state }: Context,
    fontToRemove: string,
  ) => {
    const { customFonts, id } = state.user;
    const updatedCustomFonts = removeFontFromUserFonts(
      customFonts,
      fontToRemove,
    );
    if (id) {
      const response = await UserAPI.patchUser({
        id,
        customFontList: updatedCustomFonts,
      });

      const handleSuccessStatus = (): void => {
        commit(SET_CUSTOM_FONTS, updatedCustomFonts);
      };

      handleResponse({
        handleSuccessStatus,
        response,
        successStatus: 200,
      });
    }
  },

  [UPDATE_PASSWORD]: async (
    { dispatch }: Context,
    data: NewPassword,
  ) => {
    const { t } = useI18n();
    const response = await UserAPI.updatePassword(data);

    const handleSuccessStatus = () => {
      dispatch(SET_NOTIFICATION, {
        text: t('notifications.passwordChangedSuccessfully'),
        type: NotificationType.Success,
      });
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
};
