<template>
  <ModalWindow
    :is-open="isOpen"
    override-with-class="confirm-remove__modal"
    content-class="confirm-remove__modal-card"
    @close="$emit('close')"
  >
    <div class="confirm-remove__modal-card__content">
      <h1 class="confirm-remove__modal-card__content-title">
        {{ $t('confirmRemoveCollectionModal.removeCollection__text') }}
      </h1>
      <p class="confirm-remove__modal-card__content-description">
        {{ $t('confirmRemoveCollectionModal.areYouSure__text') }}
      </p>
      <div class="confirm-remove__modal-card__content-templates__list-wrapper">
        <div
          class="
          confirm-remove__modal-card__content-templates__list 
          pretty-scroll
        "
        >
          <p class="confirm-remove__modal-card__content-text">
            {{ $t('confirmRemoveCollectionModal.collectionContain__text', {
              collectionName: collection.name
            }) }}
          </p>
          <ul class="confirm-remove__modal-card__content-list">
            <li
              v-for="{ name, id } in templatesInCollection"
              :key="id"
              class="confirm-remove__modal-card__content-list__item"
            >
              {{ name }}
            </li>
          </ul>
        </div>
      </div>
      <p class="confirm-remove__modal-card__content-text">
        {{ $t('confirmRemoveCollectionModal.warningPleaseNote__text') }}
      </p>
    </div>
    <div class="confirm-remove__modal-card__buttons">
      <BaseButton
        dark
        large
        no-margin-top
        apply-color-to-text
        class="confirm-remove__modal-card__buttons-cancel"
        :button-text="$t('confirmRemoveTemplateModal.cancel__text')"
        :color="primaryViolet"
        @click="$emit('close')"
      />
      <BaseButton
        large
        no-margin-top
        class="confirm-remove__modal-card__buttons-remove"
        :button-text="$t('collections.remove__text')"
        :color="primaryViolet"
        @click="removeCollection"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';  
import useConfirmRemoveCollectionModal from './ConfirmRemoveCollectionModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    collection: Object,
    isOpen: Boolean,
    templatesInCollection: Array,
  },
  setup(props, context) {
    return useConfirmRemoveCollectionModal(props, context);
  },
};
</script>

<style lang="scss" scoped>
@import 'ConfirmRemoveCollectionModal';
</style>
