import { fabric } from 'fabric';
import { OBJECT_ATTRIBUTES_TO_EXCLUDE } from '@/components/Editor/constants/customConfigs';
import { DEFAULT_TEXT_SETTINGS } from '@/components/Editor/constants/defaultConfigs';
import { Size } from '@/components/Editor/types/editor';
import {
  CustomFabricObjectProps,
  FabricObject,
} from '@/components/Editor/types/fabric';
import { centerObjectPosition } from '../objectModifiers/centerObjectPosition';

export const createFabricText = (
  textSettings: fabric.ITextOptions & CustomFabricObjectProps,
): fabric.Text => {
  return new fabric.Textbox(textSettings.text || 'New Text', {
    ...textSettings,
    ...OBJECT_ATTRIBUTES_TO_EXCLUDE,
  });
};

export const prepareDataForFabricText = ({
  canvasSize,
  id,
  layerName,
  layerNumber,
  zoomToDefault,
}:{
  canvasSize: Size,
  id: string,
  layerName: string,
  layerNumber: number,
  zoomToDefault: number,
}): FabricObject => {
  return {
    id,
    layerNumber,
    layerName,
    ...DEFAULT_TEXT_SETTINGS,
    ...centerObjectPosition(canvasSize, zoomToDefault),
  } as FabricObject;
};
