<template>
  <div
    v-click-outside="() => toggleFontSelect(false)"
    class="font-select"
  >
    <div class="font-select__selected-font">
      <span 
        :style="`font-family: ${font}`"
      >
        {{ font }}
      </span>
    </div>
    <div class="font-select__list">
      <div
        v-for="fontName in fontsToRender"
        :key="fontName"
        class="font-select__font"
        :class="{ 'font-select__font_selected': fontName === font }"
        :style="`font-family: ${fontName}`"
        @click="selectFont(fontName)"
      >
        <span>{{ fontName }}</span>
        <BaseButton 
          v-if="userFonts.includes(fontName) && !isIframe"
          class="font-select__delete-button"
          no-margin-top
          @click.stop="triggerRemoveFont(fontName)"
        >
          <template #prependSvg>
            <BaseIcon
              :color="black54"
              :size="16"
            >
              <TrashcanIcon />
            </BaseIcon>
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import useFontSelect from './FontSelect';

export default {
  components: {
    BaseButton,
    BaseIcon,
    TrashcanIcon,
  },
  setup() {
    return useFontSelect();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "FontSelect";
</style>
