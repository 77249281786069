import { actions } from '@/store/Collections/actions';
import { getters } from '@/store/Collections/getters';
import { mutations } from '@/store/Collections/mutations';
import { CollectionState } from '@/store/Collections/types';
import { SortItems } from '@/types';

const state: CollectionState = {
  amountOfPages: 0,
  isCollectionSaving: false,
  isCollectionsLoading: true,
  isCoverImageLoading: false,
  localCollections: [],
  selectedSort: SortItems.New,
  isEditingRights: false,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
