import { ref } from 'vue';
import { primaryBlack } from '@/constants/colors';

const useBaseMenu = () => {
  const isMenuOpen = ref(false);
  
  const closeMenu = (): void => {
    isMenuOpen.value = false;
  };

  return {
    closeMenu,
    isMenuOpen,
    primaryBlack,
  };
};

export default useBaseMenu;
