import { computed, reactive, toRef } from 'vue';
import { getFabricObjectColorByDPI } from '@/utils/dpi';

export const useResolution = props => {
  const dpiIndicatorColor = computed((): string =>
    getFabricObjectColorByDPI(props.dpi),
  );

  const dpiInteger = computed((): number => Math.floor(props.dpi));

  const resolutionProps = reactive({
    dpi: dpiInteger,
    dpiIndicatorColor,
    isMobile: toRef(props, 'isMobile'),
  });

  return {
    resolutionProps,
  };
};
