export const GET_ORDERS = 'GET_ORDERS';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const RESET_ORDERS = 'RESET_ORDERS';
export const SET_ORDERS_AMOUNT_OF_PAGES = 'SET_ORDERS_AMOUNT_OF_PAGES';
export const GET_ORDERS_AMOUNT_OF_PAGES = 'GET_ORDERS_AMOUNT_OF_PAGES';
export const GET_CURRENT_ORDER = 'GET_CURRENT_ORDER';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const SET_ORDER_LOADING = 'SET_ORDER_LOADING';
