<template>
  <div class="rates-table__wrapper">
    <v-form v-model="isValidForm">
      <v-data-table
        class="rates-table"
        :headers="tableHeaders"
        hide-default-header
        hide-default-footer
        disable-sort
        disable-filtering
        :items-per-page="-1"
        :items="zone.rates"
      >
        <template #header="{ props: { headers } }">
          <thead class="rates-table__header">
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="rates-table__header-cell"
            >
              <span>
                {{ header.title }}
                {{ header.icon ? `(${header.icon})` : '' }}
              </span>
            </th>
          </thead>
        </template>
        <template #item="{ item }">
          <RateTableItem
            :is-editing-item="item.id === editingItemId"
            :item="item"
            :zone="zone"
            :is-valid-form="isValidForm"
            @setEditingItemId="setEditingItemId"
            @closeEditingMode="closeEditingMode"
          />
        </template>
      </v-data-table>
    </v-form>
  </div>
</template>

<script lang="ts">
import useRateTable from './RateTable';
import RateTableItem from './RateTableItem/RateTableItem.vue';

export default {
  components: {
    RateTableItem,
  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    return useRateTable(props);
  },
};
</script>

<style lang="scss">
@import 'RateTable';
</style>
