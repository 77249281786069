<template>
  <div
    v-if="!isLoading"
    class="frame-wrapper"
  >
    <Editor
      v-if="template"
    />
    <ProductUnavailable v-else />
  </div>
</template>

<script lang="ts">
import ProductUnavailable from '@/components/ProductUnavailable/ProductUnavailable.vue';
import Editor from '@/views/Editor/Editor.vue';

import { useShopifyPreview } from './ShopifyPreview';
export default {
  components: {
    Editor,
    ProductUnavailable,
  },
  setup() {
    return useShopifyPreview();
  },
};
</script>

<style scoped lang="scss">
@import 'ShopifyPreview';
</style>
