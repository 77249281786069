import { computed, reactive, ref, watchEffect } from 'vue';
import { FabricObject, FabricObjectAttributes } from '@/components/Editor/types/fabric';
import {
  MAX_ROTATION_ANGLE,
  MIN_ROTATION_ANGLE,
  UNPOSSIBLE_ROTATION_ANGLE,
} from '@/constants/rotationAngle';
import store from '@/store';
import {
  GET_ACTIVE_OBJECT,
  TRIGGER_UPDATE_OBJECT,
} from '@/store/Editor/constants';
import { forceUpdateRef } from '@/utils/forceUpdateRef';
import { roundToFixed } from '@/utils/math/roundToFixed';

const useRotation = () => {
  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );

  //@ts-ignore
  const rotation = ref<number>(activeObject.value?.angle);

  watchEffect((): void => {
    forceUpdateRef<number>(
      rotation,
      //@ts-ignore
      activeObject.value.angle,
      UNPOSSIBLE_ROTATION_ANGLE,
    );
  });

  const changeRotation = (e: HTMLElementEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    const currentSymbol = e.data;
    const rotationMax = inputValue > 0
      ? roundToFixed(Math.min(inputValue, MAX_ROTATION_ANGLE), 2)
      : MIN_ROTATION_ANGLE;
    const isNumberPointSymbol = currentSymbol === '.';
    if (!isNumberPointSymbol) {
      store.dispatch(TRIGGER_UPDATE_OBJECT, {
        changes: { [FabricObjectAttributes.angle]: rotationMax },
        isUpdateHistory: false,
      });
    }
  };

  const saveInitialRotationInHistory = (): void => {
    store.dispatch(TRIGGER_UPDATE_OBJECT, {
      changes: { [FabricObjectAttributes.angle]: rotation.value },
      isUpdateHistory: true,
    });
  };

  const rotationProps = reactive({ rotation });

  const rotationEmits = {
    changeRotation,
    saveInitialRotationInHistory,
  };

  return {
    rotationEmits,
    rotationProps,
  };
};

export default useRotation;
