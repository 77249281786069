import { WeightUnits } from '@/constants/units';
import { Collection } from '@/store/Collections/types';
import { DeliveryProfile } from '@/store/Shipping/types';
import { SortItems } from '@/types';
import { CanvasDataByMesh } from '../Editor/types';

export interface ProductDetails {
  createdAt: Date
  deliveryProfile: DeliveryProfile
  deliveryProfileId: DeliveryProfile['id']
  description: string
  id: number
  price: number
  sku: string
  updatedAt: Date
  weight: number
  weightUnit: WeightUnits
}

export interface Template {
  canvasData: CanvasDataByMesh[];
  collection: Collection;
  collectionId: number;
  coloringData: any;
  createdAt: string;
  customFonts: string[];
  id: number;
  imagePath: string;
  name: string;
  nodes: string;
  outlineLink: string;
  productDetailId: number;
  productDetails: ProductDetails;
  textData: any;
  updatedAt: string;
  userId: number;
}

export interface TemplatesState {
  amountOfPages: number;
  currentTemplate: Template | null;
  isAddToMyCollectionsModalOpen: boolean;
  isCoverImageLoading: boolean;
  isEditingRights: boolean;
  isTemplatesLoading: boolean;
  localTemplates: Template[];
  selectedCollection: Collection | null;
  selectedSort: SortItems,
}

type payloadType = number | string | object | boolean
  | (number | string | object)[];

export interface Context {
  commit: (type: string, payload?: payloadType) => void;
  dispatch: (type: string, payload: payloadType) => void;
  state: TemplatesState;
}

export enum TemplateFields {
  id = 'id',
  name = 'name',
  collectionId = 'collectionId',
  isPublic = 'isPublic',
}

export interface UpdateTemplateDetails {
  id: Template['id'],
  productDetails: Pick<
    ProductDetails,
    'price' | 'description' | 'weight' | 'sku' | 'deliveryProfileId'
  >,
  template: Pick<Template, 'name'>
}
