import {
  ADD_CUSTOM_FONT,
  REMOVE_CUSTOM_FONT,
  SET_CUSTOM_FONTS,
  SET_IS_AUTHORIZED,
  SET_LOCALIZATION,
  SET_SIGN_IN_WITH_TYPE,
  SET_USER,
} from '@/store/Authorization/constants';
import { SignInType, User, UserState } from '@/store/Authorization/types';

export const mutations = {
  [SET_CUSTOM_FONTS]: (state: UserState, customFonts: string) => {
    state.user.customFonts = customFonts;
  },
  [SET_IS_AUTHORIZED]: (state: UserState, isAuthorized: boolean) => {
    state.isAuthorized = isAuthorized;
  },
  [SET_LOCALIZATION]: (state: UserState, localization: string) => {
    state.localization = localization;
  },
  [SET_SIGN_IN_WITH_TYPE]: (state: UserState, type: SignInType) => {
    state.signInWithType = type;
  },
  [SET_USER]: (state: UserState, user: User) => {
    state.user = user;
  },
  [ADD_CUSTOM_FONT]: (state: UserState, font: string): void => {
    state.user.customFonts = 
      state.user.customFonts.length
        ? JSON.stringify(
          [ ...JSON.parse(state.user.customFonts), font ],
        )
        : JSON.stringify([font]);
  },
  [REMOVE_CUSTOM_FONT]: (state: UserState, font: string): void => {
    state.user.customFonts = JSON.stringify(
      [...JSON.parse(state.user.customFonts)]
        .filter(userFont => userFont !== font),
    );
  },
};
