import { Ref } from 'vue';

export const updateCameraAspectRatio = (
  renderer: Ref<THREE.Renderer>,
  camera: Ref<THREE.PerspectiveCamera>,
): void => {
  const canvas = renderer.value.domElement;
  camera.value.aspect = canvas.clientWidth / canvas.clientHeight;
  camera.value.updateProjectionMatrix();
};
