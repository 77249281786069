import {
  FabricEvent,
  FabricObject,
  FabricObjectType,
} from '@/components/Editor/types/fabric';

export const getFontSizeOnResize = ({ target }: FabricEvent): void => {
  const fabricObject = target as FabricObject;
  if (fabricObject.type === FabricObjectType.text) {
    if (
      fabricObject.fontSize
      && fabricObject.scaleX
      && fabricObject.fontSize
      && fabricObject.width
    ) {
      if (fabricObject.fontSize * fabricObject.scaleX < 1) {
        fabricObject.scaleX = 1 / fabricObject.fontSize;
      }
      const cachedScaleX = fabricObject.scaleX;
      fabricObject.fontSize *= fabricObject.scaleX;
      const newFontSizeRounded = fabricObject.fontSize.toFixed(0);
      fabricObject.fontSize = +newFontSizeRounded;
      fabricObject.scaleX = 1;
      fabricObject.scaleY = 1;
      fabricObject.width *= cachedScaleX;
      fabricObject._clearCache();
    }
  }
};
