var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUndoRedoHidden),expression:"!isUndoRedoHidden"}],ref:"undoRedoIcons",class:[
    'undo-redo-icons',
    {
      'undo-redo-icons_mobile': _vm.isMobile,
      'undo-redo-icons_iframe': _vm.isIframe
    }
  ]},[_c('BaseButton',{attrs:{"no-margin-top":"","apply-color-to-text":"","disabled":_vm.isUndoDisabled},on:{"click":_vm.undo},scopedSlots:_vm._u([{key:"prependSvg",fn:function(){return [_c('BaseIcon',{attrs:{"color":_vm.isIframe ? 'black' : 'white',"disabled":_vm.isUndoDisabled}},[_c('UndoIcon')],1)]},proxy:true}])}),_c('BaseButton',{attrs:{"no-margin-top":"","apply-color-to-text":"","disabled":_vm.isRedoDisabled},on:{"click":_vm.redo},scopedSlots:_vm._u([{key:"prependSvg",fn:function(){return [_c('BaseIcon',{attrs:{"color":_vm.isIframe ? 'black' : 'white',"disabled":_vm.isRedoDisabled}},[_c('RedoIcon')],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }