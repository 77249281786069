export const GET_COLLECTIONS_SELECTED_SORT = 'GET_COLLECTIONS_SELECTED_SORT';
export const SET_COLLECTIONS_SELECTED_SORT = 'SET_COLLECTIONS_SELECTED_SORT';
export const SET_COLLECTIONS_AMOUNT_OF_PAGES = 'SET_COLLECTIONS_AMOUNT_OF_PAGES';
export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const SET_PREVIEW_IMAGE = 'SET_PREVIEW_IMAGE';
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
export const SET_COLLECTION_NAME = 'SET_COLLECTION_NAME';
export const CREATE_LOCAL_COLLECTION = 'CREATE_LOCAL_COLLECTION';
export const REPLACE_LOCAL_COLLECTION = 'REPLACE_LOCAL_COLLECTION';
export const PUBLISH_COLLECTION = 'PUBLISH_COLLECTION';
export const INIT_COLLECTIONS = 'INIT_COLLECTIONS';
export const CHANGE_COLLECTION_IMAGE = 'CHANGE_COLLECTION_IMAGE';
export const EDIT_COLLECTION_NAME = 'EDIT_COLLECTION_NAME';
export const GET_PUBLIC_COLLECTIONS = 'GET_PUBLIC_COLLECTIONS';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const GET_COLLECTIONS_AMOUNT_OF_PAGES = 'GET_COLLECTIONS_AMOUNT_OF_PAGES';
export const CREATE_NEW_COLLECTION = 'CREATE_NEW_COLLECTION';
export const GET_IS_COLLECTIONS_LOADING = 'GET_IS_COLLECTIONS_LOADING';
export const SET_COLLECTIONS_LOADING = 'SET_COLLECTIONS_LOADING';
export const SEARCH_COLLECTIONS = 'SEARCH_COLLECTIONS';
export const RESET_COLLECTIONS_SORTING = 'RESET_COLLECTIONS_SORTING';
export const ADD_COLLECTIONS = 'ADD_COLLECTIONS';
export const CLEAR_COLLECTIONS = 'CLEAR_COLLECTIONS';
export const REMOVE_LOCAL_COLLECTION = 'REMOVE_LOCAL_COLLECTION';
export const LOAD_MORE_COLLECTIONS = 'LOAD_MORE_COLLECTIONS';
export const GET_IS_COLLECTION_IMAGE_LOADING = 'GET_IS_COLLECTION_IMAGE_LOADING';
export const SET_IS_COLLECTION_IMAGE_LOADING = 'SET_IS_COLLECTION_IMAGE_LOADING';
export const GET_IS_EDITING_RIGHTS_COLLECTIONS = 'GET_IS_EDITING_RIGHTS_COLLECTIONS';
export const SET_IS_EDITING_RIGHTS_COLLECTIONS = 'SET_IS_EDITING_RIGHTS_COLLECTIONS';
