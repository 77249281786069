<template>
  <v-textarea
    no-resize
    outlined
    :value="value"
    :label="label"
    :color="color"
    :rules="rules"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { primaryViolet } from '@/constants/colors';

export default {
  props: {
    color: {
      default: primaryViolet,
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    rules: {
      required: false,
      type: Array,
    },
    value: {
      required: true,
      type: String,
    },
  },
};
</script>
