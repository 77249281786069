<template>
  <div class="profile-info">
    <div class="profile-info__heading">
      <h2>{{ item.name }}</h2>
      <BaseMenu
        v-if="!item.isDeactivated"
      >
        <template #content="{ closeMenu }">
          <v-list
            class="menu-list-width"
            dark
          >
            <v-list-item @click="toggleMenu(); closeMenu();">
              <v-list-item-title class="menu-item__title">
                {{ $t('profile.resetChanges__text') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </BaseMenu>
    </div>
    <ShopifyDeactivatedStore
      v-if="item.isDeactivated"
      :website="`https://${item.website}`"
      @activateStore="activateStore"
    />
    <v-card
      v-else
      class="profile-info__card column"
      :flat="isTabletAndLower"
    >
      <div class="store-wrapper">
        <h3 class="store-wrapper_heading">
          {{ $t('profile.myStore__text') }}
        </h3>
        <div class="store-wrapper_link">
          <v-icon :color="primaryViolet">
            mdi-store
          </v-icon>
          <a
            :href="`https://${item.website}`"
            target="_blank"
          >
            {{ item.website }}
          </a>
        </div>
      </div>
      <div class="current-theme">
        <div class="current-theme__title">
          <h3 class="current-theme__title-text">
            {{ $t('profile.currentTheme__text') }}
          </h3>
          <BaseTooltip
            icon="mdi-information"
            :top="true"
            mdi-icon
            content-class="current-theme__title-tooltip"
          >
            {{ $t('profile.tooltipInfo__text') }}
          </BaseTooltip>
        </div>
        <div
          v-if="isTabletAndLower"
          class="current-theme__mobile"
        >
          <div class="current-theme__mobile-title">
            {{ currentThemeName }}
          </div>
          <div 
            class="current-theme__mobile-change__btn"
            @click.stop.prevent="toggleThemeSelector"
          >
            {{ $t('profile.change__label') }}
          </div>
        </div>
        <BaseSelect
          v-else
          :value="currentThemeId"
          :items="themes"
          :dense="false"
          :placeholder="$t('profile.selectTheme__placeholder')"
          :menu-props="menuSettings"
          item-text="name"
          item-value="id"
          background-color="white"
          attach
          @input="$emit('setCurrentThemeId', $event)"
        />
        <div class="button-wrapper">
          <BaseButton
            has-background
            class="button-wrapper__button"
            :is-loading="isLoading"
            :color="secondaryViolet"
            :button-text="$t('profile.update-button__text')"
            large
            light
            @click="$emit('updateTheme')"
          />
          <div class="button-wrapper__helper-text">
            {{ $t('profile.updateHelperText__text') }}
          </div>
        </div>
      </div>
    </v-card>
    <ShopifyThemeSettingsModal 
      :is-open="isModalOpen"
      :is-resetting-themes="isDeactivatingLoading"
      :is-reseted-themes="isStoreDeactivated"
      @resetChanges="resetChanges"
      @close="toggleMenu"
    />
    <ThemeSelectorMobile
      v-if="isTabletAndLower"
      :is-open="isThemeSelectorOpen"
      :themes="themes"
      :current-theme-id="currentThemeId"
      @setCurrentThemeId="$emit('setCurrentThemeId', $event)"
      @close="toggleThemeSelector"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import ShopifyDeactivatedStore from './ShopifyDeactivatedStore/ShopifyDeactivatedStore.vue';
import useShopifyThemeSettings from './ShopifyThemeSettings';
import ShopifyThemeSettingsModal from './ShopifyThemeSettingsModal/ShopifyThemeSettingsModal.vue';
import ThemeSelectorMobile from './ThemeSelectorMobile/ThemeSelectorMobile.vue';

export default {
  components: {
    BaseButton,
    BaseMenu,
    BaseSelect,
    BaseTooltip,
    ShopifyDeactivatedStore,
    ShopifyThemeSettingsModal,
    ThemeSelectorMobile,
  },
  props: {
    currentThemeId: [ String, Number ],
    currentThemeName: String,
    item: Object,
    themes: Array,
    userStore: Object,
  },
  emits: [ 'updateTheme', 'setCurrentThemeId', 'resetChanges' ],
  setup(props) {
    return useShopifyThemeSettings(props);
  },
};
</script>

<style lang="scss">
@import 'ShopifyThemeSettings';
</style>
