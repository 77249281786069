export const ACTIVATE_EDITOR = 'ACTIVATE_EDITOR';
export const ADD_TEMPLATE_DESIGN = 'ADD_TEMPLATE_DESIGN';
export const APPLY_DESIGN = 'APPLY_DESIGN';
export const CHANGE_BACKGROUND_COLOR = 'CHANGE_BACKGROUND_COLOR';
export const CLEAR_CANVAS_HISTORY = 'CLEAR_CANVAS_HISTORY';
export const CLEAR_EDITOR_STATE = 'CLEAR_EDITOR_STATE';
export const CLEAR_REDO = 'CLEAR_REDO';
export const CLEAR_UNDO = 'CLEAR_UNDO';
export const DELETE_OBJECT = 'DELETE_OBJECT';
export const DISABLE_UNDO_REDO = 'DISABLE_UNDO_REDO';
export const DISABLE_SETTINGS_ON_IMAGE_LOADING = 'DISABLE_SETTINGS_ON_IMAGE_LOADING';
export const HIDE_OBJECT_SETTINGS = 'HIDE_OBJECT_SETTINGS';
export const EDIT_DESIGN = 'EDIT_DESIGN';
export const GET_ACTIVE_CANVAS = 'GET_ACTIVE_CANVAS';
export const GET_ACTIVE_OBJECT = 'GET_ACTIVE_OBJECT';
export const GET_BACKGROUND_COLOR = 'GET_BACKGROUND_COLOR';
export const GET_IS_DESIGN_MODE = 'SET_IS_DESIGN_MODE';
export const GET_CANVAS_ZOOM = 'GET_CANVAS_ZOOM';
export const GET_FABRIC_OBJECTS = 'GET_FABRIC_OBJECTS';
export const GET_IS_APPLYING_DESIGN = 'GET_IS_APPLYING_DESIGN';
export const GET_IS_EDITOR_ACTIVE = 'GET_IS_EDITOR_ACTIVE';
export const GET_IS_IMAGE_SETTINGS_ACTIVE = ' GET_IS_IMAGE_SETTINGS_ACTIVE';
export const GET_IS_IFRAME = 'GET_IS_IFRAME';
export const GET_IS_IMAGE_LOADING = 'GET_IS_IMAGE_LOADING';
export const GET_IS_MOBILE = 'GET_IS_MOBILE';
export const GET_IS_LOADED_FROM_HISTORY = 'GET_IS_LOADED_FROM_HISTORY';
export const GET_IS_OBJECTS_RESTORED = 'GET_IS_OBJECTS_RESTORED';
export const GET_IS_REDO_DISABLED = 'GET_IS_REDO_DISABLED';
export const GET_IS_SETTINS_DISABLED = 'GET_IS_SETTINS_DISABLED';
export const GET_IS_TEXT_SETTINGS_ACTIVE = 'GET_IS_TEXT_SETTINGS_ACTIVE';
export const GET_IS_UNDO_DISABLED = 'GET_IS_UNDO_DISABLED';
export const GET_IS_UNDO_REDO_HIDDEN = 'GET_IS_UNDO_REDO_HIDDEN';
export const GET_REDO = 'GET_REDO';
export const GET_TEMPLATE_DESIGNS = 'GET_TEMPLATE_DESIGNS';
export const GET_TEXT_TO_ADD = 'GET_TEXT_TO_ADD';
export const GET_UNDO = 'GET_UNDO';
export const GET_ZOOM_TO_DEFAULT = 'GET_ZOOM_TO_DEFAULT';
export const GET_BASE_CANVAS_HEIGHT = 'GET_BASE_CANVAS_HEIGHT';
export const GET_BASE_CANVAS_WIDTH = 'GET_BASE_CANVAS_WIDTH';
export const LOAD_REDO_CANVAS_STATE = 'LOAD_REDO_CANVAS_STATE';
export const LOAD_UNDO_CANVAS_STATE = 'LOAD_UNDO_CANVAS_STATE';
export const REDO_CANVAS_STATE = 'REDO_CANVAS_STATE';
export const REMOVE_TEMPLATE_DESIGN = 'REMOVE_TEMPLATE_DESIGN';
export const SET_ACTIVE_CANVAS = 'SET_ACTIVE_CANVAS';
export const SET_ACTIVE_OBJECT = 'SET_ACTIVE_OBJECT';
export const SET_ACTIVE_SETTINGS = 'SET_ACTIVE_SETTINGS';
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';
export const SET_CANVAS_ZOOM = 'SET_CANVAS_ZOOM';
export const SET_FABRIC_OBJECTS = 'SET_FABRIC_OBJECTS';
export const SAVE_DESIGN = 'SAVE_DESIGN';
export const SET_IS_REDO_DISABLED = 'SET_IS_REDO_DISABLED';
export const SET_IS_SETTINS_DISABLED = 'SET_IS_SETTINS_DISABLED';
export const SET_IS_UNDO_DISABLED = 'SET_IS_UNDO_DISABLED';
export const SET_IS_UNDO_REDO_HIDDEN = 'SET_IS_UNDO_REDO_HIDDEN';
export const SET_REDO = 'SET_REDO';
export const SET_TEXT_TO_ADD = 'SET_TEXT_TO_ADD';
export const SET_DEFAULT_IMAGE_OBJECT = 'SET_DEFAULT_IMAGE_OBJECT';
export const SET_DEFAULT_TEXT_OBJECT = 'SET_DEFAULT_TEXT_OBJECT';
export const SET_IMAGE_OBJECT = 'SET_IMAGE_OBJECT';
export const SET_INITIAL_EDITOR_DATA = 'SET_INITIAL_EDITOR_DATA';
export const SET_IS_APPLYING_DESIGN = 'SET_IS_APPLYING_DESIGN';
export const SET_IS_DESIGN_MODE = 'SET_IS_DESIGN_MODE';
export const SET_IS_EDITOR_ACTIVE = 'SET_IS_EDITOR_ACTIVE';
export const SET_IS_IFRAME = 'SET_IS_IFRAME';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_IMAGE_LOADING = 'SET_IS_IMAGE_LOADING';
export const SET_IS_IMAGE_SETTINGS_ACTIVE = ' SET_IS_IMAGE_SETTINGS_ACTIVE';
export const SET_IS_LOADED_FROM_HISTORY = 'SET_IS_LOADED_FROM_HISTORY';
export const SET_IS_OBJECTS_RESTORED = 'SET_IS_OBJECTS_RESTORED';
export const SET_IS_TEXT_SETTINGS_ACTIVE = 'SET_IS_TEXT_SETTINGS_ACTIVE';
export const SET_TEMPLATE_DESIGNS = 'SET_TEMPLATE_DESIGNS';
export const SET_TEXT_OBJECT = 'SET_TEXT_OBJECT';
export const SET_UNDO = 'SET_UNDO';
export const SET_ZOOM_TO_DEFAULT = 'SET_ZOOM_TO_DEFAULT';
export const SET_BASE_CANVAS_HEIGHT = 'SET_BASE_CANVAS_HEIGHT';
export const SET_BASE_CANVAS_WIDTH = 'SET_BASE_CANVAS_WIDTH';
export const TRIGGER_ADD_IMAGE = 'TRIGGER_ADD_IMAGE';
export const TRIGGER_ADD_TEXT = 'TRIGGER_ADD_TEXT';
export const TRIGGER_DELETE_OBJECT = 'TRIGGER_DELETE_OBJECT';
export const TRIGGER_UPDATE_OBJECT = 'TRIGGER_UPDATE_OBJECT';
export const TRIGGER_SET_ACTIVE_OBJECT = 'TRIGGER_SET_ACTIVE_OBJECT';
export const UNDO_CANVAS_STATE = 'UNDO_CANVAS_STATE';
export const UPDATE_ACTIVE_OBJECT = 'UPDATE_ACTIVE_OBJECT';
export const UPDATE_CANVAS_HISTORY = 'UPDATE_CANVAS_HISTORY';
export const UPDATE_FABRIC_OBJECTS = 'UPDATE_FABRIC_OBJECTS';
export const UPDATE_TEMPLATE_DESIGN = 'UPDATE_TEMPLATE_DESIGN';
export const GET_IS_MODEL_READY = 'GET_IS_MODEL_READY';
export const SET_IS_MODEL_READY = 'SET_IS_MODEL_READY';
export const GET_MESHS = 'GET_MESHS';
export const PUSH_MESH = 'PUSH_MESH';
export const GET_IS_SIDE_SELECTION_ENABLED = 'GET_IS_SIDE_SELECTION_ENABLED';
export const SET_IS_SIDE_SELECTION_ENABLED = 'SET_IS_SIDE_SELECTION_ENABLED';
export const GET_IS_MESH_SELECTION_REMOVED = 'GET_IS_MESH_SELECTION_REMOVED';
export const SET_IS_MESH_SELECTION_REMOVED = 'SET_IS_MESH_SELECTION_REMOVED';
export const GET_SINGLE_EDITABLE_MESH = 'GET_SINGLE_EDITABLE_MESH';
export const SET_SINGLE_EDITABLE_MESH = 'SET_SINGLE_EDITABLE_MESH';
export const SET_MODEL_SIZE_IN_MM = 'SET_MODEL_SIZE_IN_MM';
export const GET_MODEL_SIZE_IN_MM = 'GET_MODEL_SIZE_IN_MM';
export const GET_TEXTURES_TO_UPDATE = 'GET_TEXTURES_TO_UPDATE';
export const SET_TEXTURES_TO_UPDATE = 'SET_TEXTURES_TO_UPDATE';
export const SET_IS_EDITOR_LOADING = 'SET_IS_EDITOR_LOADING';
export const GET_IS_EDITOR_LOADING = 'GET_IS_EDITOR_LOADING';
export const SET_ACTIVE_MESH = 'SET_ACTIVE_MESH';
export const GET_ACTIVE_MESH = 'GET_ACTIVE_MESH';
export const GET_CANVAS_SIZES = 'GET_CANVAS_SIZES';
export const PUSH_CANVAS_SIZE = 'PUSH_CANVAS_SIZE';
export const GET_LAYER_NUMBER = 'GET_LAYER_NUMBER';
export const SET_LAYER_NUMBER = 'SET_LAYER_NUMBER';
export const GET_IS_MODEL_READY_TO_LOAD = 'GET_IS_MODEL_READY_TO_LOAD';
export const LOAD_GOOGLE_FONTS_LIST = 'LOAD_GOOGLE_FONTS_LIST';
export const GET_GOOGLE_FONTS_LIST = 'GET_GOOGLE_FONTS_LIST';
export const SET_GOOGLE_FONTS_LIST = 'SET_GOOGLE_FONTS_LIST';
export const GET_FONTS_TO_RENDER = 'GET_FONTS_TO_RENDER';
export const SET_IS_ADD_FONT_MODAL_OPEN = 'SET_IS_ADD_FONT_MODAL_OPEN';
export const GET_IS_ADD_FONT_MODAL_OPEN = 'GET_IS_ADD_FONT_MODAL_OPEN';
export const LOAD_GOOGLE_FONT = 'LOAD_GOOGLE_FONT';
export const ADD_FONT_TO_RENDER = 'ADD_FONT_TO_RENDER';
export const REMOVE_FONT_FROM_RENDER = 'REMOVE_FONT_FROM_RENDER';
export const GET_IS_FONT_SELECT_OPEN = 'GET_IS_FONT_SELECT_OPEN';
export const SET_IS_FONT_SELECT_OPEN = 'SET_IS_FONT_SELECT_OPEN';
export const GET_IS_REMOVE_FONT_MODAL_OPEN = 'GET_IS_REMOVE_FONT_MODAL_OPEN';
export const SET_IS_REMOVE_FONT_MODAL_OPEN = 'SET_IS_REMOVE_FONT_MODAL_OPEN';
export const TRIGGER_REMOVE_FONT = 'TRIGGER_REMOVE_FONT';
export const GET_FONT_TO_REMOVE = 'GET_FONT_TO_REMOVE';
export const SET_FONT_TO_REMOVE = 'SET_FONT_TO_REMOVE';
export const REMOVE_FONT = 'REMOVE_FONT';
export const GET_IS_SAVE_DESIGN_DISABLED = 'GET_IS_SAVE_DESIGN_DISABLED';
export const SET_IS_SAVE_DESIGN_DISABLED = 'SET_IS_SAVE_DESIGN_DISABLED';
export const SET_IS_MODEL_READY_TO_LOAD = 'SET_IS_MODEL_READY_TO_LOAD';
export const REORDER_FABRIC_OBJECTS = 'REORDER_FABRIC_OBJECTS';
export const CLEAR_CANVAS_STATE = 'CLEAR_CANVAS_STATE';
export const UPDATE_TEXTURE_CANVASES = 'UPDATE_TEXTURE_CANVASES';
export const CLEAR_TEXTURE_CANVASES = 'CLEAR_TEXTURE_CANVASES';
export const CLEAR_LAST_UNDO = 'CLEAR_LAST_UNDO';
export const SET_IS_TEXTURE_UPDATED = 'SET_IS_TEXTURE_UPDATED';
export const GET_IS_TEXTURE_UPDATED = 'GET_IS_TEXTURE_UPDATED';
