import { AxiosResponse } from 'axios';
import { CreateRate, DeliveryProfile, Rate, ShippingZone } from '@/store/Shipping/types';
import { instance } from '@/utils/axios/instance';

export const ShippingAPI = {
  createDeliveryProfile: async (name: string): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('/delivery-profiles', { name });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  createDeliveryZone: async (
    id: DeliveryProfile['id'],
    zone: ShippingZone,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(`/delivery-profiles/${id}/zones`, zone);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  createDeliveryZoneRate: async (
    id: DeliveryProfile['id'],
    zoneId: ShippingZone['id'],
    rate: CreateRate,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(`/delivery-profiles/${id}/zones/${zoneId}/rates`, rate);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  editDeliveryProfileName: async (
    { id, name }: { id: number, name: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.patch(`/delivery-profiles/${id}`, { name });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getDeliveryProfile: async (
    id: number,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/delivery-profiles/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getShippingRegions: async (): Promise<AxiosResponse> => {
    try {
      const response = await instance.get('/regions');
      return response;
    } catch (e) {
      return e.response;
    }
  },
  loadDeliveryProfiles: async (
    { limit, page }: {
      limit: number,
      page: number,
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get('/delivery-profiles', {
        params: {
          limit,
          page,
        },
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  removeDeliveryProfile: async (
    id: number,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(`/delivery-profiles/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  removeDeliveryZone: async (
    id: DeliveryProfile['id'],
    zoneId: ShippingZone['id'],
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(`/delivery-profiles/${id}/zones/${zoneId}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  removeDeliveryZoneRate: async (
    id: DeliveryProfile['id'],
    zoneId: ShippingZone['id'],
    rateId: Rate['id'],
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(`/delivery-profiles/${id}/zones/${zoneId}/rates/${rateId}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  updateDeliveryZone: async (
    id: DeliveryProfile['id'],
    zone: ShippingZone,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/delivery-profiles/${id}/zones/${zone.id}`, zone);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  updateDeliveryZoneRate: async (
    id: DeliveryProfile['id'],
    zoneId: ShippingZone['id'],
    rate: Rate,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/delivery-profiles/${id}/zones/${zoneId}/rates/${rate.id}`, rate);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  updateShopify: async (
    id: number,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(`/delivery-profiles/${id}/update/shopify`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
