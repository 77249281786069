
import { FabricObject, FabricObjectType } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_IS_IFRAME, GET_IS_MOBILE } from '@/store/Editor/constants';

export const hideMobileControls = (object: FabricObject) => {
  object.setControlsVisibility({
    mtr: false,
    //@ts-ignore
    scaleControl: false,
    closeControl: false,
  });
};

export const hideShopifyControls = (object: FabricObject) => {
  object.setControlsVisibility({
    //@ts-ignore
    lockControl: false,
  });
};

export const hideActiveSelectionControls = (object: FabricObject) => {
  object.setControlsVisibility({
    mr: false,
    ml: false,
  });
};

export const hideExcessedControls = (object: FabricObject) => {
  const isMobile = store.getters[GET_IS_MOBILE];
  const isIframe = store.getters[GET_IS_IFRAME];
  const isActiveSelection = object.type === FabricObjectType.activeSelection;
  if (isMobile) hideMobileControls(object);
  if (isIframe) hideShopifyControls(object);
  if (isActiveSelection) hideActiveSelectionControls(object);
};
