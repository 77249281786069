import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { primaryViolet } from '@/constants/colors';
import {
  ImageToolIconData1,
  ImageToolIconData2,
  ImageToolIconData3,
  ImageToolIconData4,
  ImageToolIconData5,
  TextToolIconData1,
  TextToolIconData2,
  TextToolIconData3,
  TextToolIconData4,
  TextToolIconData5,
} from '@/constants/editor';
import store from '@/store';
import {
  GET_BASE_CANVAS_HEIGHT,
  GET_IS_DESIGN_MODE,
  GET_IS_IFRAME,
  GET_IS_IMAGE_SETTINGS_ACTIVE,
  GET_IS_SETTINS_DISABLED,
  GET_IS_TEXT_SETTINGS_ACTIVE,
  SET_IS_DESIGN_MODE,
  TRIGGER_ADD_IMAGE,
  TRIGGER_ADD_TEXT,
} from '@/store/Editor/constants';

const useControls = () => {
  const imageInput = ref<HTMLInputElement>();
  const editorControls = ref();
  const isSettingsDisabled = computed((): boolean => {
    return store.getters[GET_IS_SETTINS_DISABLED];
  });

  const isImageActive = computed(
    (): boolean => store.getters[GET_IS_IMAGE_SETTINGS_ACTIVE],
  );
  const isTextActive = computed(
    (): boolean => store.getters[GET_IS_TEXT_SETTINGS_ACTIVE],
  );

  const designModeActive = computed(
    (): boolean => store.getters[GET_IS_DESIGN_MODE],
  );

  const getIconColor = (isActive: boolean): string =>
    isActive ? primaryViolet : 'black';

  const textToolColor = computed(
    (): string => getIconColor(isTextActive.value),
  );
  const imgToolColor = computed(
    (): string => getIconColor(isImageActive.value),
  );
  const designModeToolColor = computed((): string =>
    getIconColor(designModeActive.value),
  );

  const isIframe = computed((): boolean => store.getters[GET_IS_IFRAME]);

  const addText = (): void => {
    store.dispatch(TRIGGER_ADD_TEXT);
  };

  const selectFile = (): void => {
    if (imageInput.value) {
      const button = imageInput.value;
      imageInput.value.value = '';
      button.click();
    }
  };

  const uploadImage = (event: HTMLElementEvent<HTMLInputElement>): void => {
    const fileInput = event.target;
    const file = fileInput.files && fileInput.files[0];
    store.dispatch(TRIGGER_ADD_IMAGE, file);
  };

  const saveDesign = (): Promise<void> =>
    store.dispatch(SET_IS_DESIGN_MODE, { isActive: true });

  const editorControlsStyleModify = () => {
    const canvasHeight = store.getters[GET_BASE_CANVAS_HEIGHT];
    editorControls.value.style.top = `calc((100% - ${canvasHeight}px) / 2)`;
  };

  onMounted(() => {
    editorControlsStyleModify();
    window.addEventListener('resize', editorControlsStyleModify);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', editorControlsStyleModify);
  });
  
  return {
    isIframe,
    getIconColor,
    addText,
    imageInput,
    ImageToolIconData1,
    ImageToolIconData2,
    ImageToolIconData3,
    ImageToolIconData4,
    ImageToolIconData5,
    imgToolColor,
    selectFile,
    textToolColor,
    TextToolIconData1,
    TextToolIconData2,
    TextToolIconData3,
    TextToolIconData4,
    TextToolIconData5,
    uploadImage,
    isSettingsDisabled,
    saveDesign,
    designModeToolColor,
    editorControls,
  };
};

export default useControls;
