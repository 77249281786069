import { computed, ref, watch } from 'vue';
import router from '@/router';
import { REMOVE_NOTIFICATION } from '@/store/constants';
import store from '@/store/index';
import { NotificationType } from '@/types/notification';

const useApp = () => {

  const notificationText = computed(
    () => store.state.notificationData.notificationText,
  );

  const notificationLocalization = computed(
    () => store.state.notificationData.localizationData,
  );

  const notificationType = computed(() => store.state.notificationData.type);
  const isNotificationVisible = computed(() =>
    store.state.notificationData.isVisible);
  
  const closeNotification = (close: () => void) => {
    store.commit(REMOVE_NOTIFICATION);
    close();
  };

  const getLinkValue = ({ link }): string => {
    return router.currentRoute.path !== link
      ? link
      : '';
  };

  // Without this watcher another watcher in Header.ts won't work.
  // just don't get rid of it, pls.
  const rou = ref(router);
  watch(rou, () => {});

  return {
    closeNotification,
    isNotificationVisible,
    notificationLocalization,
    notificationText,
    notificationType,
    NotificationType,
    getLinkValue,
  };
};

export default useApp;
