import { computed, reactive, ref, toRef } from 'vue';
import { ColorPickerType } from '@/components/Editor/types/editor';
import store from '@/store';
import {
  TRIGGER_ADD_IMAGE,
  TRIGGER_ADD_TEXT,
} from '@/store/Editor/constants';

const useSideControlsMobile = (props, { emit }) => {
  const imageInput = ref<HTMLElement>();
  const isDefaultSettingsActive = computed(
    (): boolean => !props.isImageActive && !props.isTextActive,
  );

  const selectFile = (): void => {
    if (imageInput.value) {
      const button = imageInput.value as HTMLElement;
      button.click();
    }
  };

  const addText = (): void => {
    store.dispatch(TRIGGER_ADD_TEXT);
  };

  const uploadImage = (event: Event): void => {
    const fileInput = (event.target as HTMLInputElement);
    const file = fileInput.files && fileInput.files[0];
    store.dispatch(TRIGGER_ADD_IMAGE, file);
  };

  const textControlsProps = reactive({
    isCustomizationFrame: toRef(props, 'isCustomizationFrame'),
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const imageSettingsProps = reactive({
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const textControlsEmits = {
    addToShopifyOrder: () => emit('addToShopifyOrder'),
    selectFile: () => emit('selectFile'),
  };

  return {
    addText,
    ColorPickerType,
    imageInput,
    imageSettingsProps,
    isDefaultSettingsActive,
    selectFile,
    textControlsEmits,
    textControlsProps,
    uploadImage,
  };
};

export default useSideControlsMobile;
