import { isFabricImage, isFabricText } from '@/components/Editor/helpers/fabric/objectModifiers/checkObjectType';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import {
  CLEAR_EDITOR_STATE,
  HIDE_OBJECT_SETTINGS,
  SET_ACTIVE_CANVAS,
  SET_ACTIVE_SETTINGS,
  SET_IS_DESIGN_MODE,
  SET_IS_EDITOR_LOADING,
  SET_IS_IFRAME,
  SET_IS_IMAGE_LOADING,
  SET_IS_IMAGE_SETTINGS_ACTIVE,
  SET_IS_LOADED_FROM_HISTORY,
  SET_IS_MOBILE,
  SET_IS_SAVE_DESIGN_DISABLED,
  SET_IS_SETTINS_DISABLED,
  SET_IS_TEXT_SETTINGS_ACTIVE,
} from '../constants';
import { getDefaultEditorState } from '../helpers';
import { EditorState } from '../types';

export const editorConfigMutations = {
  [SET_IS_DESIGN_MODE]: (state: EditorState, isActive: boolean): void => {
    state.editorConfigs.isDesignMode = isActive;
  },
  [HIDE_OBJECT_SETTINGS]: (state: EditorState): void => {
    state.editorConfigs.isTextSettingsActive = false;
    state.editorConfigs.isImageSettingsActive = false;
  },
  [SET_ACTIVE_CANVAS]: (state: EditorState, canvas: FabricCanvas): void => {
    state.activeCanvas = canvas;
  },
  [SET_IS_IMAGE_LOADING]: (
    state: EditorState,
    isImageLoading: boolean,
  ): void => {
    state.editorConfigs.isImageLoading = isImageLoading;
  },
  [SET_ACTIVE_SETTINGS]: (state: EditorState): void => {
    const activeObject = state.canvasState.activeObject;
    if (activeObject) {
      switch (true) {
      case isFabricImage(activeObject):
        state.editorConfigs.isTextSettingsActive = false;
        state.editorConfigs.isImageSettingsActive = true;
        break;
      case isFabricText(activeObject):
        state.editorConfigs.isImageSettingsActive = false;
        state.editorConfigs.isTextSettingsActive = true;
        break;
      default:
        state.editorConfigs.isTextSettingsActive = false;
        state.editorConfigs.isImageSettingsActive = false;
      }
    }
  },
  [SET_IS_IFRAME]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isIframe = flag;
  },
  [SET_IS_MOBILE]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isMobile = flag;
  },
  [SET_IS_IMAGE_SETTINGS_ACTIVE]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isImageSettingsActive = flag;
  },
  [SET_IS_TEXT_SETTINGS_ACTIVE]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isTextSettingsActive = flag;
  },
  [SET_IS_SETTINS_DISABLED]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isSettingsDisabled = flag;
  },
  [SET_IS_LOADED_FROM_HISTORY]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isLoadedFromHistory = flag;
  },
  [CLEAR_EDITOR_STATE]: (state: EditorState): void => {
    state = Object.assign(state, getDefaultEditorState());
  },
  [SET_IS_EDITOR_LOADING]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isEditorLoading = flag;
  },
  [SET_IS_SAVE_DESIGN_DISABLED]: (state: EditorState, flag: boolean): void => {
    state.editorConfigs.isSaveDesignDisabled = flag;
  },
};
