import { ref } from 'vue';
import { PricingTableData } from './types';

const usePricingModal = () => {
  const pricingModal = ref(false);

  const amountOfUnits = [ '1-2 units', '3-5 units', '6+ units' ];
  const unitsGrams = {
    [amountOfUnits[0]]: '>100g',
    [amountOfUnits[1]]: '100g-250g',
    [amountOfUnits[2]]: '250g-500g',
  };

  const deliveryProfiles: PricingTableData[] = [
    {
      costs: [
        {
          amount: amountOfUnits[0],
          value: 1.50,
        },
        {
          amount: amountOfUnits[1],
          value: 2.00,
        },
        {
          amount: amountOfUnits[2],
          value: 2.25,
        },
      ],
      shippingZone: 'UK (Royal Mail 24)',
    },
    {
      costs: [
        {
          amount: amountOfUnits[0],
          value: 5.05,
        },
        {
          amount: amountOfUnits[1],
          value: 7.25,
        },
        {
          amount: amountOfUnits[2],
          value: 8.35,
        },
      ],
      shippingZone: 'Europe (International Economy)',
    },
    {
      costs: [
        {
          amount: amountOfUnits[0],
          value: 5.05,
        },
        {
          amount: amountOfUnits[1],
          value: 7.25,
        },
        {
          amount: amountOfUnits[2],
          value: 8.35,
        },
      ],
      shippingZone: 'Rest of World (International Economy)',
    },
  ];

  return {
    amountOfUnits,
    deliveryProfiles,
    pricingModal,
    unitsGrams,
  };
};

export default usePricingModal;
