import { CanvasTexture, Vector3 } from 'three';
import {
  FabricCanvas,
  FabricObjectAttributes,
  FabricObjectProps,
} from '@/components/Editor/types/fabric';

export type AlignCoordinates = Pick<
  FabricObjectProps,
  FabricObjectAttributes.left | FabricObjectAttributes.top
>;

export enum AlignDirections {
  bottom = 'bottom',
  horizontalCenter = 'horizontalCenter',
  left = 'left',
  right = 'right',
  top = 'top',
  verticalCenter = 'verticalCenter',
}

export enum ColorPickerType {
  background = 'background',
  text = 'text',
}

export type HEXColor = `#${string}`;

export enum ObjectAlignments {
  center = 'center',
  justify = 'justify',
  left = 'left',
  right = 'right',
}

export interface TouchData {
  canvas: FabricCanvas,
  initialPinchScaleX: number,
  initialPinchScaleY: number,
  initialRotateAngle: number,
  initialRotateTheta: number,
  previousObjectScale: number,
}

export interface Size {
  height: number,
  width: number
}

export interface controlsStyleMods {
  controlsStyle: string,
  scaleControlsStyle: string,
}

export enum TextParams {
  color = 'color',
  font = 'font',
  alignment = 'alignment',
  angle = 'angle',
  fontSize='fontSize',
}

export enum ZoomVariants {
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
  zoomFull = 'zoomFull',
}

export enum ScanTypes {
  file = 'file',
  link = 'link',
}

export enum ScreenHeights {
  large = 1080,
  medium = 720,
  small = 480
}

export enum CanvasHeights {
  large = 720,
  default = 520,
  medium = 360,
  small = 300,
}

export enum CanvasHeightsMobile {
  large = 520,
  default = 480,
  medium = 320,
  small = 280,
}

export enum FontTypes {
  regular = 'regular',
  italic = 'italic',
  fontWeight100 = '100',
  fontWeight200 = '200',
  fontWeight300 = '300',
  fontWeight400 = '400',
  fontWeight500 = '500',
  fontWeight600 = '600',
  fontWeight700 = '700',
  fontWeight800 = '800',
}

export enum ActiveModelParts {
  toe = 'toe',
  front = 'front',
  main = 'main',
}

export enum DisabledModelParts {
  _disabled = '_disabled',
  _outline = '_outline',
  size = 'size',
}

export interface MeshData {
  disabled: boolean,
  name: string,
  size: Vector3,
}

export interface MeshTexture {
  name: string,
  texture: CanvasTexture,
}
