
<template>
  <div class="resolution">
    <div class="resolution__title">
      <span>
        {{ $t('redactor.resolution__label') }}
      </span>
      <BaseTooltip
        icon="mdi-information"
        :top="true"
        mdi-icon
        class="resolution__tooltip"
      >
        {{ $t('redactor.tooltipDpi__text') }}
      </BaseTooltip>
    </div>
    <div>
      <BaseIndicator 
        :color="dpiIndicatorColor"
      />
      <span class="resolution__text">{{ dpi }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import BaseIndicator from '@/components/Common/BaseIndicator/BaseIndicator.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
export default {
  components: { BaseIndicator, BaseTooltip },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    dpiIndicatorColor: {
      required: true,
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
  @import './ResolutionDesktop';
</style>
