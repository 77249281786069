
import { canvasConfigsGetters } from './canvasConfigsGetters';
import { canvasHistoryGetters } from './canvasHistoryGetters';
import { editorConfigGetters } from './editorConfigGetters';
import { fontsConfigsGetters } from './fontsConfigsGetters';
import { sceneGetters } from './modelDataGetters';
import { templateDesignGetters } from './templateDesignGetters';

export const getters = {
  ...editorConfigGetters,
  ...canvasHistoryGetters,
  ...canvasConfigsGetters,
  ...templateDesignGetters,
  ...sceneGetters,
  ...fontsConfigsGetters,
};
