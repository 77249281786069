export const removeFontFromUserFonts = (
  userFonts: string,
  fontToRemove: string,
): string => {
  return JSON.stringify(
    JSON.parse(userFonts).filter(
      (font: string): boolean => font !== fontToRemove,
    ),
  );
};
