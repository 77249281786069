import {
  renderScaleFromCenterIcon,
  renderScaleFromEdgeIcon,
} from '@/components/Editor/helpers/fabric/controls/renderIcons';
import { FabricObjectType } from '@/components/Editor/types/fabric';

export const activeObjectCenterScaling = activeObject => {
  if (activeObject.type === FabricObjectType.activeSelection) {
    activeObject.centeredScaling = true;
  }
  if (activeObject.controls.scaleControl) {
    activeObject.controls.scaleControl.render = activeObject.centeredScaling
      ? renderScaleFromCenterIcon
      : renderScaleFromEdgeIcon;
  }
};
