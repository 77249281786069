var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'view-switcher',
    {
      'is-shopify-page': _vm.isShopifyPage,
      'disabled': _vm.isSettingsDisabled,
      'view-switcher_editor-active-iframe': _vm.editorActive && _vm.isIframe,
    }],on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{class:{
            'active': _vm.editorActive,
            'disabled': _vm.disabled,
          },on:{"click":function($event){$event.stopPropagation();return _vm.goToEditorView.apply(null, arguments)}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":_vm.EditorViewIconData,"fill":_vm.editorViewColor}})])])])]}}])},[(_vm.isMobile)?_c('p',{staticClass:"view-switcher__tooltip"},[_vm._v(" "+_vm._s(_vm.$t("redactor.disabledSwitcherTooltipMobile__text"))+" ")]):_c('p',{staticClass:"view-switcher__tooltip"},[_vm._v(" "+_vm._s(_vm.$t("redactor.disabledSwitcherTooltipFirst__text"))+" "),_c('img',{staticClass:"view-switcher__cursor-icon",attrs:{"src":_vm.cursorLogo,"alt":_vm.$t('redactor.cusrsorIcon__alt')}}),_vm._v(" "+_vm._s(_vm.$t("redactor.disabledSwitcherTooltipSecond__text"))+" ")])]),_c('div',{class:{'active': _vm.editor3DViewActive},on:{"click":function($event){$event.stopPropagation();return _vm.goTo3DView.apply(null, arguments)}}},[(_vm.isTextureUpdated)?_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":_vm.Editor3DViewIconData,"fill":_vm.Editor3DViewColor}})]):_c('v-progress-circular',{attrs:{"value":50,"color":_vm.primaryViolet,"indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }