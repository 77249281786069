<template>
  <div class="text-align-icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :d="svgData"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import useAlignmentIcon from './TextAlignIcons';

export default {
  props: {
    alignmentType: {
      required: true,
      type: String,
    },
    textAlignment: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return useAlignmentIcon(props);
  },
};
</script>
<style lang="scss" scoped>
  @import './TextAlignIcons.scss'
</style>
