import { Representation } from 'packaging3d-engine/lib/core/base/representation';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import fileIcon from '@/assets/icons/file.svg';
import uploadedGLBIcon from '@/assets/icons/uploadedGLB.svg';
import uploadFileIcon from '@/assets/icons/uploadFile.svg';
import uploadOutlineIcon from '@/assets/icons/uploadOutlineIcon.svg';
import { primaryViolet, white87 } from '@/constants/colors';
import { WeightUnits } from '@/constants/units';
import { CollectionsAPI } from '@/services/CollectionsAPI';
import { TemplatesAPI } from '@/services/TemplatesAPI';
import store from '@/store/index';
import {
  GET_DELIVERY_PROFILES,
  LOAD_ALL_DELIVERY_PROFILES,
} from '@/store/Shipping/constants';
import { SUBMIT_TEMPLATE } from '@/store/Templates/constants';
import { isPriceField, isWeightField, maxLength, requiredField } from '@/utils/FieldValidationRules';
import { InputFileEvent } from './types';

const useUploadPage = () => {
  const { t } = useI18n();
  const files = ref([] as File[]);
  const uploadPercentage = ref(0);
  const overlayFileName = ref('');
  const overlayUploadPercentage = ref(0);
  const representations = ref(new Array<Representation>());
  const uploadJsonError = '';
  const name = ref('');
  const collectionId = ref();
  const collections = ref([] as any[]);
  const model3dPath = ref(null);
  const price = ref('0');
  const weight = ref(0);
  const sku = ref('');
  const priceRules = [ requiredField, isPriceField ];
  const weightRules = [ requiredField, isWeightField ];
  const currentDeliveryProfileId = ref();
  const overlayImage = ref('');
  const fileForm = ref();
  const overlayForm = ref();
  const requiredFieldWithLengthRules = [ requiredField, maxLength(40) ];
  const isFormValid = ref(false);

  const convertPriceToDecimal = () => {
    const numberPrice = +price.value;
    if (!numberPrice || numberPrice % 1 !== 0) return;
    price.value = numberPrice.toFixed(2);
  };

  const dragAndDropCapable = computed((): boolean => {
    const div = document.createElement('div');
    return (
      ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
      'FormData' in window &&
      'FileReader' in window
    );
  });

  const deliveryProfiles = computed(
    (): boolean => 
      store.getters[GET_DELIVERY_PROFILES],
  );

  const noFilesUploaded = computed((): boolean => files.value.length === 0);

  const isDisableButton = computed((): boolean => {
    return (
      !isFormValid.value ||
      noFilesUploaded.value
    );
  });

  const isModelLoaded = computed(
    (): boolean => uploadPercentage.value === 100 && !!files.value[0],
  );
  
  const uploadingText = computed((): string => 
    isModelLoaded.value ? files.value[0].name : t('upload.uploading__text') as string);

  const upload3dModel = async (): Promise<void> => {
    const data = {
      collectionId: collectionId.value,
      coloringData: '',
      deliveryProfileId: currentDeliveryProfileId.value,
      name: name.value,
      nodes: model3dPath.value,
      outlineLink: overlayImage.value,
      price: +price.value,
      sku: sku.value,
      weight: weight.value,
      weightUnit: WeightUnits.GRAMS,
    };

    await store.dispatch(SUBMIT_TEMPLATE, data);
  };

  const submitFiles = (): void => {
    const formData = new FormData();
    formData.append('file', files.value[0]);
    representations.value = [];

    submit3dModel(formData);
  };

  const getFileType = (name: string): string => {
    const splitedName = name.split('.');
    return splitedName[splitedName.length - 1];
  };

  const submit3dModel = async (formData: FormData): Promise<void> => {
    try {
      const { data: responce3dPath } = await TemplatesAPI.fetchUpload3dModel(
        formData,
        uploadPercentage,
      );
      model3dPath.value = responce3dPath;
    } catch (e) {
      console.log(e);
    }
  };

  const submitOverlay = async (formData: FormData): Promise<void> => {
    try {
      const response = await TemplatesAPI.uploadOverlayImage(
        formData,
        overlayUploadPercentage,
      );
      overlayImage.value = response.data.imagePath;
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileChange = (event: InputFileEvent): void => {
    if (event.target.files) {
      files.value.push(event.target.files[0]);
      submitFiles();
    }
  };

  const handleOverlayUpload = async (event: InputFileEvent): Promise<void> => {
    if (event.target.files) {
      const neededFile = event.target.files[0];
      overlayFileName.value = neededFile.name;
      const typeFile = getFileType(neededFile.name);

      const data = new FormData();
      data.append('file', neededFile);
      data.append('typeFile', typeFile);

      submitOverlay(data);
    }
  };

  const chooseFile = (): void => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: Event): Promise<void> => 
      handleOverlayUpload(event as InputFileEvent);
    input.click();
  };

  onMounted(async (): Promise<void> => {
    await store.dispatch(LOAD_ALL_DELIVERY_PROFILES);
    if (dragAndDropCapable.value) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(event => {
        fileForm.value.addEventListener(
          event,
          (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false,
        );

        overlayForm.value.addEventListener(
          event,
          (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false,
        );
      });

      fileForm.value.addEventListener('drop', (e: DragEvent) => {
        files.value.push(e.dataTransfer!.files[0]);
        submitFiles();
      });

      overlayForm.value.addEventListener('drop', (e: DragEvent) => {
        const neededFile = e.dataTransfer!.files[0];
        overlayFileName.value = neededFile.name;
        const typeFile = getFileType(neededFile.name);

        const data = new FormData();
        data.append('file', neededFile);
        data.append('typeFile', typeFile);
        submitOverlay(data);
      });

      const { collections: userCollections } =
        await CollectionsAPI.getAllUserCollections();
      collections.value = userCollections;
    }
  });

  return {
    chooseFile,
    collectionId,
    collections,
    convertPriceToDecimal,
    currentDeliveryProfileId,
    deliveryProfiles,
    fileForm,
    fileIcon,
    files,
    handleFileChange,
    handleOverlayUpload,
    isDisableButton,
    isFormValid,
    isModelLoaded,
    name,
    noFilesUploaded,
    overlayFileName,
    overlayForm,
    overlayImage,
    overlayUploadPercentage,
    price,
    priceRules,
    primaryViolet,
    requiredField,
    requiredFieldWithLengthRules,
    sku,
    upload3dModel,
    uploadedGLBIcon,
    uploadFileIcon,
    uploadingText,
    uploadJsonError,
    uploadOutlineIcon,
    uploadPercentage,
    weight,
    weightRules,
    white87,
  };
};

export default useUploadPage;
