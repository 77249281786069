<!-- eslint-disable max-len -->
<template>
  <div>
    {{ firstPartOfSearchString }}<span class="bold-text">{{ highlightedSearch }}</span>{{ secondPartOfSearchString }}
  </div>
</template>

<script lang="ts">
import useHighlightedName from './HighlightedName';

export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    search: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return useHighlightedName(props);
  },
};
</script>
