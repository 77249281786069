<template>
  <div class="base-tooltip">
    <v-tooltip
      :top="top"
      :bottom="bottom"
      :max-width="contentClass || maxWidth"
      :content-class="contentClass"
    >
      <template #activator="{ on, attrs }">
        <v-icon 
          v-if="mdiIcon"
          v-bind="attrs"
          v-on="on"  
        >
          {{ icon }}
        </v-icon>
        <img
          v-else
          :src="icon"
          v-bind="attrs"
          v-on="on"
        >
      </template>
      <span>
        <slot />
      </span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    bottom: {
      required: false,
      type: Boolean,
    },
    contentClass: {
      required: false,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    maxWidth: {
      default: 230,
      required: false,
      type: Number,
    },
    mdiIcon: {
      required: false,
      type: Boolean,
    },
    top: {
      required: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'BaseTooltip';
</style>
