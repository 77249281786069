import { OBJECT_CUSTOM_ATTRIBUTES } from '@/components/Editor/constants/customConfigs';
import { hideExcessedControls } from '@/components/Editor/helpers/fabric/controls/hideControls';
import {
  FabricObject,
  FabricObjectType,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import {
  GET_IS_IFRAME,
  TRIGGER_UPDATE_OBJECT,
} from '@/store/Editor/constants';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';
import { defaultLockObject, LockedObjectInfo } from './types';

const setLockedFabricProps = ({
  isLocked,
  object,
}: {
  isLocked: boolean;
  object: FabricObject;
}): void => {
  const template = store.getters[GET_CURRENT_TEMPLATE];
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedTemplateId = isLocked ? template.id : null;

  setObjectMovement(object, !isLocked);
  setObjectRotation(object, !isLocked);
  setObjectScaling(object, !isLocked);
  setObjectDeletable(object, !isLocked);
};

export const disableUnlock = (object: FabricObject) => {
  setObjectMovement(object, false);
  setObjectRotation(object, false);
  setObjectScaling(object, false);
  setObjectDeletable(object, false);
  setObjectLock(object, false);
};

export const freezeFabricObject = (object: FabricObject) => {
  setObjectMovement(object, false);
  setObjectRotation(object, false);
  setObjectScaling(object, false);
  setObjectSelectable(object, false);
  setObjectDeletable(object, false);
};

export const setObjectLock = (
  object: FabricObject,
  isLocked: boolean,
): void => {
  object.setControlsVisibility({
    // @ts-ignore
    lockControl: isLocked,
  });
};

export const setObjectDeletable = (
  object: FabricObject,
  isDeletable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedDeletion = !isDeletable;
  object.setControlsVisibility({
    // @ts-ignore
    closeControl: isDeletable,
  });
};

export const setObjectSelectable = (
  object: FabricObject,
  isSelectable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedSelection = !isSelectable;
  object.selectable = isSelectable;
  object.evented = isSelectable;
};

export const setObjectEditable = (
  object: FabricObject,
  isEditable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedWriting = !isEditable;
  object.editable = isEditable;
};

export const setObjectMovement = (
  object: FabricObject,
  isMovable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedMovement = !isMovable;
  object.lockMovementX = !isMovable;
  object.lockMovementY = !isMovable;
};

export const setObjectScaling = (
  object: FabricObject,
  isScalable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedScaling = !isScalable;
  object.lockScalingX = !isScalable;
  object.lockScalingY = !isScalable;
  object.setControlsVisibility({
    bl: isScalable,
    br: isScalable,
    ml: isScalable,
    mr: isScalable,
    // @ts-ignore
    scaleControl: isScalable,
  });
};

export const setObjectRotation = (
  object: FabricObject,
  isRotatable: boolean,
): void => {
  if (!object.lockedInfo) object.lockedInfo = {} as LockedObjectInfo;
  object.lockedInfo.lockedRotation = !isRotatable;
  object.lockRotation = !isRotatable;
  object.setControlsVisibility({
    mtr: isRotatable,
  });
};

export const isObjectLocked = (object: FabricObject): boolean => {
  return !!object.lockedInfo?.lockedTemplateId;
};

export const isLockedObjectEditable = (object: FabricObject) => {
  const isLocked = isObjectLocked(object);
  if (!isLocked) return true;
  if (object.type === FabricObjectType.activeSelection) {
    const hasOnlyEditableObjects = !!object?._objects
      ?.every(object => isLockedObjectEditable(object as FabricObject));
    return hasOnlyEditableObjects;
  }
  const isIframe = store.getters[GET_IS_IFRAME];
  const {
    id: templateId,
    userId: templateUserId,
  } = store.getters[GET_CURRENT_TEMPLATE];
  const {
    id: userId,
  } = store.getters[GET_USER];

  const templateBelongsToUser = userId === templateUserId;
  const lockedTemplateId = object?.lockedInfo?.lockedTemplateId;
  const objectLockedByCurrentTemplate = templateId
      && templateId === lockedTemplateId;
  const isLockEditable = Boolean(
    templateBelongsToUser && objectLockedByCurrentTemplate && !isIframe,
  );
  return isLockEditable;
};

export const setObjectLocked = ({
  isLockChildren,
  isLocked,
  object,
  shouldSaveInStore = false,
}: {
  isLockChildren?: boolean;
  isLocked: boolean;
  object: FabricObject;
  shouldSaveInStore: boolean;
}): void => {
  if (!isLockedObjectEditable(object)) return disableUnlock(object);

  if (!object?.lockedInfo?.lockedTemplateId)
    object.lockedInfo = { ...defaultLockObject };

  setLockedFabricProps({
    object,
    isLocked,
  });
  if (isLockChildren && object.type === FabricObjectType.activeSelection) {
    (object?._objects as FabricObject[])
      .forEach((selectedObject: FabricObject): void => {
        setLockedFabricProps({
          object: selectedObject,
          isLocked,
        });
      });
  }

  shouldSaveInStore && store.dispatch(
    TRIGGER_UPDATE_OBJECT, {
      changes: object.toObject(OBJECT_CUSTOM_ATTRIBUTES),
      isUpdateHistory: true,
    },
  );

  hideExcessedControls(object);
};

export const lockActiveSelectionWithLockedObject = (
  object: FabricObject,
): void => {
  if (object.type !== FabricObjectType.activeSelection) return;

  const hasLockedObjects = !!object._objects
    ?.some(object=> isObjectLocked(object as FabricObject));

  if (!hasLockedObjects) return;

  setObjectLocked({
    isLocked: true,
    object,
    shouldSaveInStore: false,
  });
};
