import axios from 'axios';
import * as PromiseBird from 'bluebird';
import { FontTypes } from '@/components/Editor/types/editor';
import { GoogleFontsList } from '@/store/Editor/types';

export const loadGoogleFontsList = async (): Promise<{
  fontName: string;
  link: string;
}[]> => {
  const googleFontsList = [] as GoogleFontsList[];
  const uninterceptedAxiosInstance = axios.create();
  const allFontsRequest = await uninterceptedAxiosInstance.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.VUE_APP_GOOGLE_FONTS_API_KEY}`,
  );
  await PromiseBird.map(allFontsRequest.data.items, font => {
    const fontVariant: FontTypes = font.variants[0];
    const fontData = {
      fontName: font.family,
      link: font.files[FontTypes.regular]
        || font.files[FontTypes.fontWeight500]
        || font.files[FontTypes.fontWeight600]
        || font.files[FontTypes.fontWeight700]
        || font.files[fontVariant],
    };
    googleFontsList.push(fontData);
  });
  return googleFontsList;
};
