const useHighlightedName = ({ name, search }) => {
  const regex = new RegExp(search, 'gi');
  const indexOfFoundName = name.search(regex);
  const highlightedSearch = name.slice(
    indexOfFoundName,
    indexOfFoundName + search.length,
  );

  const firstPartOfSearchString = name.slice(0, indexOfFoundName);
  const secondPartOfSearchString = name.slice(
    indexOfFoundName + search.length,
  );

  return {
    highlightedSearch,
    firstPartOfSearchString,
    secondPartOfSearchString,
  };
};

export default useHighlightedName;
