import { computed, ref, toRef } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { useI18n } from 'vue-i18n-composable';
import defaultBox from '@/assets/images/default-template-plug.png';
import { black50, primaryBlack, primaryViolet } from '@/constants/colors';
import { UserRoles } from '@/constants/roles';
import router from '@/router';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import { User } from '@/store/Authorization/types';
import {
  CHANGE_TEMPLATE_COVER,
  COPY_TEMPLATE,
  EDIT_TEMPLATE_NAME,
  GET_IS_TEMPLATE_IMAGE_LOADING,
  GET_TEMPLATES_SELECTED_SORT,
  INIT_TEMPLATES,
} from '@/store/Templates/constants';
import { AmountOfCards, MenuItem } from '@/types';
import {
  maxLength,
  requiredField,
} from '@/utils/FieldValidationRules';
import { isTabletAndLower } from '@/utils/Mobile';
import { CollectionRouteTypes, CollectionTypes } from '@/views/Collections/types';
import { InputFileEvent } from '@/views/UploadPage/types';

const useTemplateCard = (props, { emit }) => {
  const template = toRef(props, 'template');
  const collection = toRef(props, 'collection');
  const isEditingRights = toRef(props, 'isEditingRights');
  const { t } = useI18n();
  const templateDeliveryProfileName = computed((): string =>
    template.value.productDetails.deliveryProfile.name);
  const templateDeliveryPrice = computed((): number =>
    +template.value.productDetails.price);
  
  const isCoverImageLoading = computed((): boolean =>
    store.getters[GET_IS_TEMPLATE_IMAGE_LOADING]);
  
  const copyToCollectionText = computed((): TranslateResult => {
    const fromQuery = router.currentRoute.query.from;
    return fromQuery === CollectionTypes.privateCollections
      ? t('templates.copyToAnotherCollection__text')
      : t('templates.copyToMyCollection__text');
  });

  const menuItems = computed((): MenuItem[] => 
    [
      {
        action: () => removeTemplate(),
        isVisible: isEditingRights.value,
        roles: [ UserRoles.User, UserRoles.Admin ],
        title: t('templates.remove__text'),
      },
      {
        action: () => duplicateTemplate(),
        isVisible: isEditingRights.value,
        roles: [ UserRoles.User, UserRoles.Admin ],
        title: t('templates.duplicateTemplate__text'),
      },
      {
        action: () => emit('openAddToMyCollectionsModal', template),
        isVisible: true,
        roles: [ UserRoles.User, UserRoles.Admin ],
        title: copyToCollectionText.value,
      },
      {
        action: () => chooseTemplateImage(),
        disabled: isCoverImageLoading.value,
        isVisible: isEditingRights.value,
        roles: [ UserRoles.User, UserRoles.Admin ],
        title: t('templates.changeCoverImage__text'),
      },
      {
        action: () => emit('openEditDetailsWindow', template.value),
        isVisible: isEditingRights.value,
        roles: [UserRoles.Admin],
        title: t('templates.editDetails__text'),
      },
    ]);

  const user = computed((): User => store.getters[GET_USER]);
  const menuItemsList = computed((): MenuItem[] =>
    menuItems.value.filter(({ roles }) => roles.includes(user.value.role)));
  const editNameRules = [ requiredField, maxLength(40) ];
  const fieldEditName = ref(template.value.name);
  const isValidName = ref(false);
  const isEditTemplateName = ref(false);
  const imageInput = ref<HTMLInputElement>();
  const previewImage = computed((): string =>
    template.value.imagePath || defaultBox);
  const isPreviewImageDisplayed = computed((): boolean =>
    template.value.imagePath === previewImage.value);
  const ignorableClickOutsideElements = [document.querySelector('.global-alert')];
  
  const chooseTemplateImage = (): void => {
    imageInput.value?.click();
  };
  
  const changeTemplateImage = (event: InputFileEvent): void => {
    const image = event.target.files?.[0];
    store.dispatch(CHANGE_TEMPLATE_COVER, {
      collectionId: template.value.collectionId,
      id: template.value.id,
      image,
    });
  };
  
  const toggleEditField = (): boolean =>
    isEditTemplateName.value = !isEditTemplateName.value;

  const saveEditedName = async (): Promise<void | boolean> => {
    if (!isValidName.value) return;
    if (template.value.name === fieldEditName.value) return toggleEditField();
    await store.dispatch(EDIT_TEMPLATE_NAME,
      {
        id: template.value.id,
        name: fieldEditName.value,
      });
    toggleEditField();
  };

  const openTemplate = (): void => {
    const isFromPublic = router.currentRoute.query.from === 'public';
    const collectionsRoute =
      collection.value.isPublic && isFromPublic
        ? CollectionRouteTypes.publicCollections
        : CollectionRouteTypes.privateCollections;
    
    const editorRoute =
      collection.value.isPublic && isFromPublic
        ? '3dview'
        : 'editor';
    
    const editorUrl =
      `/${collectionsRoute}/${template.value.collectionId}/templates/${template.value.id}/${editorRoute}`;
    
    router.push(editorUrl);
  };

  const removeTemplate = (): Promise<void> =>
    emit('openConfirmRemoveModal', template.value);
  
  const duplicateTemplate = async () => {
    await store.dispatch(COPY_TEMPLATE, {
      collection: collection.value,
      newTemplateName: template.value.name,
      template: template.value,
    });
    await store.dispatch(INIT_TEMPLATES, {
      collectionId: collection.value.id,
      limit: isTabletAndLower
        ? AmountOfCards.mobile
        : AmountOfCards.desktop,
      page: +router.currentRoute.query.page || 1,
      selectedSort: store.getters[GET_TEMPLATES_SELECTED_SORT],
    });
  };

  return {
    black50,
    changeTemplateImage,
    editNameRules,
    fieldEditName,
    ignorableClickOutsideElements,
    imageInput,
    isEditTemplateName,
    isPreviewImageDisplayed,
    isValidName,
    menuItems,
    menuItemsList,
    openTemplate,
    previewImage,
    primaryBlack,
    primaryViolet,
    saveEditedName,
    templateDeliveryPrice,
    templateDeliveryProfileName,
    toggleEditField,
  };
};

export default useTemplateCard;
