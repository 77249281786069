<template>
  <div
    class="copy"
  >
    <span
      class="copy-icon"
      @click.stop="copy"
      @mouseover="reset"
    >
      <BaseTooltip
        top
        mdi-icon
        icon="mdi-content-copy"
      >
        {{ tooltipText }}
      </BaseTooltip>
    </span>
    <slot />
  </div>
</template>
<script lang="ts">
import BaseTooltip from '../Common/BaseTooltip/BaseTooltip.vue';
import { useCopyToClickboard } from './CopyToClickBoard';

export default {
  components: { BaseTooltip },
  props: {
    value: {
      type: [ String, Number ],
      required: true,
    },
  },
  setup(props) {
    return useCopyToClickboard(props);
  },
};
</script>
<style lang="scss" scoped>
  @import './CopyToClickboard.scss';
</style>
