import {
  modifyDefaultFabricControls,
} from '@/components/Editor/helpers/fabric/controls/modifyDefaultFabricControls';
import { addGuidelinesToObject } from '@/components/Editor/helpers/Guidelines';
import { FabricObject } from '@/components/Editor/types/fabric';

export const setCustomPropsToText = (
  fabricText: FabricObject,
): void => {
  modifyDefaultFabricControls(fabricText);
  addGuidelinesToObject(fabricText);
};
