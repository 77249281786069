export const parseDate = (value: string | Date) => {
  const dateToParse =
      value instanceof Date ? value : new Date(value);
  
  const fullDate = new Date(dateToParse).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [ date, time ] = fullDate.split(', ');

  return {
    date,
    time,
    fullDate,
  };
};
