<template>
  <div class="shipping-table__wrapper">
    <v-data-table
      class="shipping-table"
      :headers="tableHeaders"
      hide-default-header
      hide-default-footer
      disable-sort
      disable-filtering
      :items="deliveryProfiles"
    >
      <template #header="{ props: { headers } }">
        <thead class="shipping-table__header">
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="shipping-table__header-cell"
          >
            <span>{{ header.title }}</span>
          </th>
        </thead>
      </template>
      <template #item="{ item }">
        <tr
          class="shipping-table__item"
          @click="openDeliveryProfile(item.id)"
        >
          <td class="shipping-table__item-cell">
            {{ item.name }}
          </td>
          <td class="shipping-table__item-cell shipping-table__item-zones">
            {{ getZones(item.zones, 3) }}
            <v-menu
              :nudge-width="174"
              rounded="lg"
              offset-y
              left
              right
              bottom
              attach
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item 
                  @click.stop="$emit('removeDeliveryProfile', item.id)"
                >
                  <v-list-item-title>
                    {{ $t('shipping.remove__text') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-if="isShowPagination"
      class="shipping-table__pagination"
      :value="currentPage"
      :length="amountOfPages"
      :color="secondaryViolet"
      :total-visible="7"
      circle
      @input="$emit('pageChange', $event)"
    />
  </div>
</template>

<script lang="ts">
import useShippingTable from './ShippingTable';

export default {
  props: {
    amountOfPages: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
    deliveryProfiles: {
      required: true,
      type: Array,
    },
    isShowPagination: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useShippingTable();
  },
};
</script>

<style lang="scss">
@import 'ShippingTable';
</style>
