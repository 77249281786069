// @ts-nocheck

import { fabric } from 'fabric';
import {
  ControlActionNames,
  FabricControl,
} from '@/components/Editor/types/fabric';
import { getCloseControl } from './closeControl';
import { getDefaultControl } from './defaultControl';
import { getLockControl } from './lockControl';
import { getRotationControl } from './rotationControl';
import { getScaleControl } from './scaleControl';

export const getModifiedFabricControls = (): FabricControl => {
  return {
    bl: getDefaultControl({
      actionHandler: fabric.controlsUtils.scalingEqually,
      actionName: ControlActionNames.scale,
      x: -0.5,
      y: 0.5,
    }),
    br: getDefaultControl({
      actionHandler: fabric.controlsUtils.scalingEqually,
      actionName: ControlActionNames.scale,
      x: 0.5,
      y: 0.5,
    }),
    lockControl: getLockControl(),
    mb: null,
    ml: getDefaultControl({
      actionHandler: fabric.controlsUtils.changeWidth,
      x: -0.5,
      y: 0,
      actionName: ControlActionNames.width,
    }),
    mr: getDefaultControl({
      actionHandler: fabric.controlsUtils.changeWidth,
      actionName: ControlActionNames.width,
      x: 0.5,
      y: 0,
    }),
    mt: null,
    mtr: getRotationControl(),
    tl: null,
    tr: null,
    scaleControl: getScaleControl(),
    closeControl: getCloseControl(),
  };
};
