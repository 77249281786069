<template>
  <v-card 
    class="template-card" 
    :ripple="false"
    @click.stop="openTemplate"
  >
    <v-img
      :contain="isPreviewImageDisplayed"
      :src="previewImage"
      class="preview-image"
    >
      <div
        class="preview-image__menu"
        @click.stop
      >
        <BaseMenu>
          <template #content="{ closeMenu }">
            <v-list dark>
              <v-list-item
                v-for="(item, i) in menuItemsList"
                v-show="item.isVisible"
                :key="i"
                height="100"
                :disabled="item.disabled"
                @click.stop="item.action(); closeMenu();"
              >
                <v-list-item-title class="menu-item__title">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </BaseMenu>
      </div>
    </v-img>
    <v-card-title 
      v-if="!isEditTemplateName"
      class="template-card__title no-edit"
    >
      <div class="template-card__title-text">
        {{ template.name }}
      </div>
      <BaseButton
        v-if="isEditingRights"
        light
        icon="mdi-pencil"
        class="template-card__title-edit"
        no-margin-top
        :color="black50"
        @click="toggleEditField"
      />
    </v-card-title>
    <v-form
      v-else
      v-model="isValidName"
      class="template-card__form"
    >
      <v-card-title 
        v-click-outside="{
          handler: saveEditedName,
          include: () => ignorableClickOutsideElements
        }"
        class="template-card__title" 
      >
        <TextField
          v-model.trim="fieldEditName"
          autofocus
          class="edit-field"
          :rules="editNameRules"
          :color="primaryViolet"
          :outlined="false"
          @keydown.enter.stop.prevent="saveEditedName"
        />
        <BaseButton
          icon="mdi-check"
          class="close-field"
          color="white"
          apply-color-to-text
          no-margin-top
          :disabled="!isValidName"
          @click="saveEditedName"
        />
      </v-card-title>
    </v-form>
    <div class="delivery-profile">
      <div class="delivery-profile__name">
        <img
          src="@/assets/icons/shipping-truck.svg"
          alt="shipping truck"
        >
        <span>{{ templateDeliveryProfileName }}</span>
      </div>
      <div class="delivery-profile__price">
        <img
          src="@/assets/icons/price-label.svg"
          alt="price label"
        >
        <span>${{ templateDeliveryPrice }}</span>
      </div>
    </div>
    <input
      ref="imageInput"
      class="image-input"
      type="file"
      accept="image/*"
      @change.stop="changeTemplateImage"
      @click.stop
    >
  </v-card>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useTemplateCard from './TemplateCard';

export default {
  components: {
    BaseButton,
    BaseMenu,
    TextField,
  },
  props: {
    collection: Object,
    isEditingRights: Boolean,
    template: Object,
  },
  setup(props, context) {
    return useTemplateCard(props, context);
  },
};
</script>

<style lang="scss" scoped>
@import 'TemplateCard';
</style>
