import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import ShopifyIcon from '@/assets/icons/shopify-icon-colored.svg';
import { GET_USER } from '@/store/Authorization/constants';
import { User } from '@/store/Authorization/types';
import store from '@/store/index';
import { GET_STORE, RESET_STORE } from '@/store/Shopify/constants';
import { GET_THEMES, UPDATE_THEME } from '@/store/Shopify/constants';
import { ShopifyState } from '@/store/Shopify/types';
import { IntegrationSystem } from '@/types/integrationSystems';

const useProfile = () => {
  const user = computed((): User => store.getters[GET_USER]);
  const shopifyStore = computed(
    (): ShopifyState['shopifyStore'] =>
      store.getters[GET_STORE],
  );
  const { t } = useI18n();
  const isLiveTheme = ({ role }: { role: string }): boolean => role === 'main';

  const themes = computed(() => store.getters[GET_THEMES] || []);
  const currentThemeId = ref(-1);

  const currentThemeName = computed((): string => {
    const theme = themes.value?.find(
      (theme): boolean => theme.id === currentThemeId.value,
    );
    return theme ? theme.name : '';
  });

  const shopifySystem = computed(() => ({
    currentTheme: 'default',
    isDeactivated: shopifyStore.value.isDeactivated,
    name: 'Shopify',
    website: shopifyStore.value && `${shopifyStore.value.store}.myshopify.com`,
  }));

  const integrationSystemsList = computed((): IntegrationSystem[] => [
    {
      description: t('integrationSystems.shopify.description__text') as string,
      icon: ShopifyIcon,
      isAdded: !!shopifyStore.value,
      name: 'Shopify',
      tooltip: t('integrationSystems.shopify.addSystem__tooltip') as string,
    },
  ]);

  const getThemes = async (): Promise<void> => {
    const storeId = shopifyStore.value?.id;
    if (!storeId) return;
    const themes = await store.dispatch(GET_THEMES, { storeId });
    currentThemeId.value = themes.find(theme => isLiveTheme(theme))?.id;
  };

  watch(
    user.value,
    async ({ id: userId }): Promise<void> => {
      if (!userId) return;
      await store.dispatch(GET_STORE, { userId });
    },
    { immediate: true },
  );

  watch(shopifyStore, getThemes);

  const setCurrentThemeId = (id: number): void => {
    currentThemeId.value = id;
  };

  const updateTheme = async (): Promise<void> => {
    if (!currentThemeId.value) return;
    await store.dispatch(UPDATE_THEME, {
      storeId: shopifyStore.value?.id,
      themeId: currentThemeId.value,
    });
  };

  onBeforeUnmount(() => store.dispatch(RESET_STORE));

  return {
    currentThemeId,
    currentThemeName,
    integrationSystemsList,
    setCurrentThemeId,
    shopifySystem,
    themes,
    updateTheme,
    shopifyStore,
  };
};

export default useProfile;
