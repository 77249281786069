<template>
  <div>
    <TextAlignmentsDesktop
      v-if="!isMobile"
      v-bind="textAlignmentsProps"
      v-on="textAlignmentsEmits"
    />
    <TextAlignmentsMobile
      v-else
      v-bind="textAlignmentsProps"
      v-on="textAlignmentsEmits"
    />
  </div>
</template>

<script lang="ts">
import useAlignments from '@/components/Editor/TextAlignments/TextAlignments';
import TextAlignmentsDesktop from './TextAlignmentsDesktop/TextAlignmentsDesktop.vue';
import TextAlignmentsMobile from './TextAlignmentsMobile/TextAlignmentsMobile.vue';

export default {
  components: {
    TextAlignmentsDesktop,
    TextAlignmentsMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useAlignments();
  },
};
</script>
