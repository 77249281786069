import { computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { black54 } from '@/constants/colors';
import router from '@/router';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import { GET_CURRENT_ORDER, RESET_ORDERS } from '@/store/Orders/constants';
import { OrderState } from '@/store/Orders/types';
import { parseDate } from '@/utils/date';
import { getCustomerName } from '../utils';

export const useSingleOrder = () => {
  const { t } = useI18n();

  const tableHeaders = [
    { title: t('orders.products__text'), width: '50%' },
    { title: t('orders.quantity__text'), width: '40%' },
    { title: '', width: '10%' },
  ];
  const order = computed(
    (): OrderState['currentOrder'] => store.getters[GET_CURRENT_ORDER],
  );
  const user = computed(() => store.getters[GET_USER]);

  const isOrderBelongsToUser = computed(
    () => user.value.id === order.value?.userId,
  );

  const orderDetails = computed(() => {
    if (!order.value) return;
    const { date, time } = parseDate(order.value.createdAt);

    const defaultOrderInfo = {
      id: order.value.id,
      status: order.value.status,
      date,
      time,
    };

    const adminOrderInfo = {
      store: order.value?.shopifyStore?.store,
    };

    const userOrderInfo = {
      price: +order.value.totalPrice,
      shopifyOrderId: +order.value.shopifyOrderId,
      country: order.value.country,
      customerName: getCustomerName(order.value),
      store: null,
    };

    const details = isOrderBelongsToUser.value
      ? { ...defaultOrderInfo, ...userOrderInfo }
      : { ...defaultOrderInfo, ...adminOrderInfo };

    return details;
  });

  onMounted(() => {
    const orderId = router.currentRoute.params.id;
    store.dispatch(GET_CURRENT_ORDER, orderId);
  });

  onUnmounted(() => {
    store.commit(RESET_ORDERS);
  });

  return {
    orderDetails,
    order,
    parseDate,
    black54,
    tableHeaders,
  };
};
