<template>
  <AuthLayout>
    <SignInForm />
  </AuthLayout>
</template>

<script lang="ts">
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import SignInForm from './SignInForm/SignInForm.vue';

export default {
  components: {
    AuthLayout,
    SignInForm,
  },
};
</script>
