import { reactive } from 'vue';
import { getAlignCoordinates } from '@/components/Editor/helpers/fabric/alignmentModifiers/getAlignCoordinates';
import { AlignDirections } from '@/components/Editor/types/editor';
import AlignBottomIcon from '@/components/icons/AlignBottomIcon.vue';
import AlignHorizontalCenterIcon from '@/components/icons/AlignHorizontalCenterIcon.vue';
import AlignLeftIcon from '@/components/icons/AlignLeftIcon.vue';
import AlignRightIcon from '@/components/icons/AlignRightIcon.vue';
import AlignTopIcon from '@/components/icons/AlignTopIcon.vue';
import AlignVerticalCenterIcon from '@/components/icons/AlignVerticalCenterIcon.vue';
import store from '@/store';
import { TRIGGER_UPDATE_OBJECT } from '@/store/Editor/constants';

const useAlignments = () => {
  const triggerAlignment = (alignment: AlignDirections): void => {
    const alignCoordinates = getAlignCoordinates(alignment);
    store.dispatch(TRIGGER_UPDATE_OBJECT, {
      changes: alignCoordinates,
      isUpdateHistory: true,
    });
  };

  const horizontalAlignments = [
    {
      icon: AlignLeftIcon,
      name: 'left',
    },
    {
      icon: AlignHorizontalCenterIcon,
      name: 'horizontalCenter',
    },
    {
      icon: AlignRightIcon,
      name: 'right',
    },
  ];

  const verticalAlignments = [
    {
      icon: AlignTopIcon,
      name: 'top',
    },
    {
      icon: AlignVerticalCenterIcon,
      name: 'verticalCenter',
    },
    {
      icon: AlignBottomIcon,
      name: 'bottom',
    },
  ];

  const alignmentsProps = reactive({
    horizontalAlignments,
    verticalAlignments,
  });

  const alignmentsEmits = {
    triggerAlignment,
  };

  return {
    alignmentsEmits,
    alignmentsProps,
  };
};

export default useAlignments;
