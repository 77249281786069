import { computed, reactive, toRef } from 'vue';
import { FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_ACTIVE_OBJECT, TRIGGER_DELETE_OBJECT } from '@/store/Editor/constants';

const useImageSettings = props => {
  const activeImage = computed(
    (): FabricObject | null => store.getters[GET_ACTIVE_OBJECT],
  );

  const imageSettingsProps = reactive({
    activeImage,
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const imageSettingsEmits = {
    deleteSelectedObject: () => store.dispatch(TRIGGER_DELETE_OBJECT),
  };

  return {
    imageSettingsEmits,
    imageSettingsProps,
  };
};

export default useImageSettings;
