import { Route } from 'vue-router';
import router from '@/router';

const useTemplatesNotExists = () => {
  const redirectToPublicCollections = (): Promise<Route> => 
    router.push({ name: 'public-collections' });
  
  return {
    redirectToPublicCollections,
  };
};

export default useTemplatesNotExists;
