<template>
  <div class="deactivated-store">
    <i18n
      class="deactivated-store__text"
      path="integrationSystems.shopify.storeIsDeactivated__text"
      tag="div"
    >
      <template #url>
        <a
          :href="website"
          class="deactivated-store__link"
          target="_blank"
        >
          Shopify
        </a>
      </template>
    </i18n>
    <BaseButton
      class="deactivated-store__restore-btn"
      :button-text="$t('integrationSystems.shopify.restorePlugin__text')"
      :is-loading="isDeactivatingLoading"
      @click="$emit('activateStore')"
    />
  </div>
</template>
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { useShopifyDeactivatedStore } from './ShopifyDeactivatedStore';
export default {
  components: { BaseButton },
  props: {
    website: {
      required: true,
      type: String,
    },
  },
  setup() {
    return useShopifyDeactivatedStore();
  },
};
</script>
<style lang="scss" scoped>
  @import 'ShopifyDeactivatedStore.scss';
</style>
