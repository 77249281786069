export class UnitsConverter {
  static mmToCm(value: number) {
    return value / 10;
  }

  static cmToInch(value: number) {
    return value * 0.3937;
  }

  static mmToInch(value: number) {
    return this.cmToInch(this.mmToCm(value));
  }
}
