import { OrdersAPI } from '@/services/OrdersAPI';
import { handleResponse } from '@/utils/axios/handleResponse';
import {
  GET_ALL_ORDERS,
  GET_CURRENT_ORDER,
  GET_ORDERS,
  SET_CURRENT_ORDER,
  SET_ORDER_LOADING,
  SET_ORDERS,
  SET_ORDERS_AMOUNT_OF_PAGES,
} from './constants';
import { Context, Order } from './types';

export const actions = {
  [GET_ORDERS]: async (
    { commit }: Context,
    params: { limit?: number; page?: number },
  ) => {
    commit(SET_ORDER_LOADING, true);
    const { limit = 8, page = 1 } = params || {};
    const response = await OrdersAPI.getOrders({ limit, page });

    const handleSuccessStatus = ({
      amountOfPages,
      orders,
    }: {
      amountOfPages: number;
      orders: Order[];
    }): void => {
      commit(SET_ORDERS, orders);
      commit(SET_ORDERS_AMOUNT_OF_PAGES, amountOfPages);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
    commit(SET_ORDER_LOADING, false);
  },

  [GET_ALL_ORDERS]: async (
    { commit }: Context,
    params: { limit?: number; page?: number },
  ) => {
    commit(SET_ORDER_LOADING, true);
    const { limit = 8, page = 1 } = params || {};

    const response = await OrdersAPI.getAllOrders({ limit, page });

    const handleSuccessStatus = ({
      amountOfPages,
      orders,
    }: {
      amountOfPages: number;
      orders: Order[];
    }): void => {
      commit(SET_ORDERS, orders);
      commit(SET_ORDERS_AMOUNT_OF_PAGES, amountOfPages);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
    commit(SET_ORDER_LOADING, false);
  },

  [GET_CURRENT_ORDER]: async ({ commit }: Context, id: Order['id']) => {
    commit(SET_ORDER_LOADING, true);
    const response = await OrdersAPI.getSingleOrder(id);

    const handleSuccessStatus = (order: Order): void => {
      commit(SET_CURRENT_ORDER, order);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
    commit(SET_ORDER_LOADING, false);
  },
};
