<template>
  <header
    class="header"
    :class="{'search-open': isShowSearch}"
  >
    <router-link
      class="header-logo"
      :to="linkToMyCollections"
    >
      <img
        :src="logoDefault"
        :alt="$t('header.logoImage__alt')"
      >
    </router-link>
    <v-tabs
      :value="currentTab"
      class="header-nav"
      :color="black87"
      :height="headerTabsHeight"
      hide-slider
      @input="$emit('changeTab', $event)"
    >
      <v-tab
        v-for="(item, index) in navItems"
        :key="index"
        :to="item.to"
        class="header-nav__tab"
        :class="{ 'header-nav__tab_active': item.isActive }"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <div class="header-actions">
      <SearchField
        :is-show-search="isShowSearch"
        @closeSearch="closeSearch"
        @openSearch="openSearch"
      />
      <ProfileMenu
        @openPrivacyPolicyModal="$emit('openPrivacyPolicyModal')"
        @openContactSupportModal="$emit('openContactSupportModal')"
        @openPricingModal="$emit('openPricingModal')"
        @openReturnsPolicyModal="$emit('openReturnsPolicyModal')"
      />
    </div>
    <PrivacyPolicyModal
      :is-open="isPrivacyPolicyModalOpen"
      @close="$emit('closePrivacyPolicyModal')"
    />
    <ContactSupportModal
      :is-open="isContactSupportModalOpen"
      @close="$emit('closeContactSupportModal')"
    />
    <PricingModal
      :is-open="isPricingModalOpen"
      @close="$emit('closePricingModal')"
    />
    <ReturnsPolicyModal
      :is-open="isReturnsPolicyModalOpen"
      @close="$emit('closeReturnsPolicyModal')"
    />
  </header>
</template>

<script lang="ts">
import ContactSupportModal from '@/components/ContactSupportModal/ContactSupportModal.vue';
import useHeaderDesktop from '@/components/Header/HeaderDesktop/HeaderDesktop';
import PricingModal from '@/components/PricingModal/PricingModal.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu.vue';
import ReturnsPolicyModal from '@/components/ReturnsPolicyModal/ReturnsPolicyModal.vue';
import SearchField from '@/components/SearchField/SearchField.vue';

export default {
  components: {
    ContactSupportModal,
    PricingModal,
    ProfileMenu,
    SearchField,
    PrivacyPolicyModal,
    ReturnsPolicyModal,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    isContactSupportModalOpen: {
      required: true,
      type: Boolean,
    },
    isPricingModalOpen: {
      required: true,
      type: Boolean,
    },
    isPrivacyPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    isReturnsPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    linkToMyCollections: {
      required: true,
      type: String,
    },
    logoDefault: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useHeaderDesktop();
  },
};
</script>

<style lang="scss" scoped>
@import 'HeaderDesktop';
</style>
