import { nextTick, Ref } from 'vue';

export const forceUpdateRef = async <T>(
  ref: Ref<T>,
  newValue: T,
  startValue: T,
) => {
  ref.value = startValue;
  await nextTick();
  ref.value = newValue;
};
