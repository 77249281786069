import { ZoomVariants } from '@/components/Editor/types/editor';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';
import { zoomCanvas } from '../canvasModifiers/zoomCanvas';

const zoomCanvasByMouseWheel = ({ e }) => {
  e.preventDefault();
  e.stopPropagation();
  const delta = e.deltaY;
  if (delta < 0) zoomCanvas(ZoomVariants.zoomIn);
  if (delta > 0) zoomCanvas(ZoomVariants.zoomOut);
};

export const mouseWheel = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('mouse:wheel', zoomCanvasByMouseWheel);
};
