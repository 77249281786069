import { computed, ref } from 'vue';
import { primaryViolet } from '@/constants/colors';
import { googleFontsLink } from '@/constants/editor';
import store from '@/store';
import {
  GET_GOOGLE_FONTS_LIST,
  LOAD_GOOGLE_FONT,
  SET_IS_ADD_FONT_MODAL_OPEN,
} from '@/store/Editor/constants';
import { GoogleFontsList } from '@/store/Editor/types';
import { CHECK_FONT } from '@/store/Templates/constants';

const useAddFontModal = () => {
  const newFont = ref('');
  const agreement = ref(true);
  const googleFontsList = computed(
    (): GoogleFontsList[] => store.getters[GET_GOOGLE_FONTS_LIST],
  );
  const googleFonts = computed(
    (): string[] => googleFontsList.value.map(fontData => fontData.fontName),
  );
  const fontLink = computed(
    (): string | undefined =>
      googleFontsList.value.find(
        ({ fontName }) => fontName === newFont.value,
      )?.link,
  );

  const closeAddFontModal = (): void => {
    store.commit(SET_IS_ADD_FONT_MODAL_OPEN, false);
  };

  const updateNewFont = (updatedFont: string): void => {
    newFont.value = updatedFont;
  };

  const addFont = async (): Promise<void> => {
    const fontName = newFont.value.trim();
    const link = fontLink.value;
    if (fontLink.value) {
      const fontIsValid = await store.dispatch(CHECK_FONT, {
        link,
      });
      if (fontIsValid) {
        store.dispatch(LOAD_GOOGLE_FONT, fontName);
      }
      closeAddFontModal();
    }
  };

  const toggleAgreement = (): boolean => agreement.value = !agreement.value;

  return {
    addFont,
    closeAddFontModal,
    fontLink,
    googleFonts,
    googleFontsLink,
    newFont,
    primaryViolet,
    updateNewFont,
    agreement,
    toggleAgreement,
  };
};
  
export default useAddFontModal;
