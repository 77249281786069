<template>
  <div class="delivery-profile__rate">
    <div class="delivery-profile__rate-title">
      <div class="delivery-profile__rate-title__content">
        <span class="delivery-profile__rate-title__content-text">
          {{ zone.name }}
        </span>
        <span class="delivery-profile__rate-title__content-subtitle">
          {{ getZoneCountries(zone.countries) }}
        </span>
      </div>
      <BaseMenu>
        <template #content="{ closeMenu }">
          <v-list dark>
            <v-list-item
              v-for="(item, index) in menuItemsList"
              :key="index"
              @click="item.action(); closeMenu();"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </BaseMenu>
    </div>
    <div class="delivery-profile__rate-table">
      <RateTable
        :zone="zone"
      />
    </div>
    <BaseButton
      class="delivery-profile__rate-button"
      button-text="Add Rate"
      prepend-icon="mdi-plus"
      no-margin-top
      outlined
      dark
      large
      @click="addLocalRate"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import useDeliveryProfileZone from './DeliveryProfileZone';
import RateTable from './RateTable/RateTable.vue';

export default {
  components: {
    BaseButton,
    BaseMenu,
    RateTable,
  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    return useDeliveryProfileZone(props, context);
  },
};
</script>

<style lang="scss">
@import 'DeliveryProfileZone';
</style>
