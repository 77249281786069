<template>
  <ModalWindow
    :is-open="isOpen"
    content-class="change-info__card"
    @close="$emit('close')"
  >
    <div class="change-info__card-tabs-wrapper">
      <h2
        v-if="isTabletAndLower" 
        class="profile-form__tab-title__mobile"
      >
        {{ $t('profile.edit-profile__text') }}
      </h2>
      <v-tabs
        v-model="currentTab"
        class="change-info__card-tabs"
        :vertical="!isTabletAndLower"
        rounded
        :grow="isTabletAndLower"
        background-color="transparent"
        active-class="active-tab"
        color="black"
        :slider-color="secondaryViolet"
        :hide-slider="isTabletAndLower"
        :height="isTabletAndLower
          ? profileModalMobileTabsHeight
          : profileModalTabsHeight"
      >
        <v-tab 
          v-for="tab in tabs" 
          :key="tab.index" 
          rounded
          class="change-info__card-tabs__tab"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items 
      v-model="currentTab" 
      class="profile-form"
    >
      <v-tab-item class="profile-form__tab">
        <v-form
          v-model="isValidProfileInfoForm"
        >
          <h2 class="profile-form__tab-title">
            {{ $t('profile.edit-profile__text') }}
          </h2>
          <div class="profile-form__tab-text-fields">
            <div class="profile-form__tab-text-fields__row">
              <TextField 
                v-model="newFirstName"
                required
                :rules="nameRules"
                input-type="text"
                :label="$t('textField.firstName__placeholder')"
              />
              <TextField 
                v-model="newLastName"
                required
                :rules="nameRules"
                input-type="text"
                :label="$t('textField.lastName__placeholder')"
              />
            </div>
            <div class="profile-form__tab-text-fields__row">
              <TextField
                v-model="newEmail"
                required
                input-type="email"
                :label="$t('textField.email__placeholder')"
                disabled
                @keydown.space.prevent
              />
            </div>
          </div>
          <BaseButton
            has-background
            class="profile-form__tab-save-btn"
            :disabled="!(isValidProfileInfoForm && isValuesChanged)"
            :button-text="$t('profile.save__button')"
            :color="primaryViolet"
            large
            @click="updateCredentials"
          />
        </v-form>
      </v-tab-item>
      <v-tab-item class="profile-form__tab">
        <v-form
          ref="passwordsForm"
          v-model="isValidPasswordsForm"
        >
          <h2 class="profile-form__tab-title">
            {{ $t('profile.edit-profile__text') }}
          </h2>
          <div class="profile-form__tab-text-fields">
            <div class="profile-form__tab-text-fields__row">
              <TextField 
                v-model="password"
                :rules="passwordRules"
                input-type="password"
                :label="$t('textField.password__placeholder')"
                @keydown.space.prevent
              />
            </div>
            <div class="profile-form__tab-text-fields__row">
              <TextField 
                v-model="repeatPassword"
                :rules="repeatPasswordRules"
                input-type="password"
                :label="$t('textField.repeatPassword__placeholder')"
                @keydown.space.prevent
              />
            </div>
          </div>
          <BaseButton
            has-background
            class="profile-form__tab-save-btn"
            :disabled="!isValidPasswordsForm"
            :button-text="$t('profile.save__button')"
            :color="primaryViolet"
            large
            @click="updatePassword"
          />
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useProfileCardModal from './ProfileCardModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
    TextField,
  },
  props: {
    isOpen: Boolean,
  },
  setup() {
    return useProfileCardModal();
  },
};
</script>

<style lang="scss">
@import 'ProfileCardModal.scss';
</style>
