// black shades

export const black87 = 'rgba(0, 0, 0, 0.87)';
export const black72 = 'rgba(0, 0, 0, 0.72)';
export const black60 = 'rgba(0, 0, 0, 0.60)';
export const black54 = 'rgba(0, 0, 0, 0.54)';
export const black50 = 'rgba(0, 0, 0, 0.50)';
export const black38 = 'rgba(0, 0, 0, 0.38)';
export const black26 = 'rgba(0, 0, 0, 0.26)';
export const black16 = 'rgba(0, 0, 0, 0.16)';
export const black06 = 'rgba(0, 0, 0, 0.06)';

// white shades

export const white87 = 'rgba(255, 255, 255, 0.87)';
export const white72 = 'rgba(255, 255, 255, 0.72)';
export const white60 = 'rgba(255, 255, 255, 0.60)';
export const white54 = 'rgba(255, 255, 255, 0.54)';
export const white50 = 'rgba(255, 255, 255, 0.50)';
export const white38 = 'rgba(255, 255, 255, 0.38)';
export const white26 = 'rgba(255, 255, 255, 0.26)';
export const white16 = 'rgba(255, 255, 255, 0.16)';

// primary colors

export const primaryBlack = '#18191F';
export const primaryViolet = '#9055FF';

// secondary colors

export const secondaryViolet = '#DAC9FB';
export const secondaryMagenta = '#C2A5F9';

// status colors

export const redDanger = '#FF5252';
export const success = '#4CAF50';
export const warning = '#FB8C00';
export const info = '#2196F3';

// other

export const silver = '#CCCCCC';
export const lightCyan = '#DDE6E8';
export const lightGrey = '#E5E5E5';
export const whiteLighten = '#F4F4F4';
export const dimGrey = '#636363';
export const whiteSmoke = '#F0F0F0';
export const mistGrey = 'rgba(235, 236, 238, 0.4)';
export const disabledMeshColor = '#909090';
export const ruby = '#E83F93';
export const tangerine = '#F18600';
export const shimmeringBlue = '#2FAD96';
export const azure = '#387EFD';
export const coral = '#FF7B45';
