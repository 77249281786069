import { computed, ref } from 'vue';
import { useStopScroll } from '@/composables/useStopScroll';
import {
  primaryBlack,
  primaryViolet,
  secondaryViolet,
} from '@/constants/colors';
import store from '@/store';
import { GET_STORE } from '@/store/Shopify/constants';
import {
  ACTIVATE_STORE,
  GET_IS_DEACTIVATING_LOADING,
  GET_IS_THEMES_LOADING,
} from '@/store/Shopify/constants';
import { RESET_CHANGES } from '@/store/Shopify/constants';
import { isTabletAndLower } from '@/utils/Mobile';

const useShopifyThemeSettings = props => {
  const { disableScroll, enableScroll } = useStopScroll();
  const isModalOpen = ref(false);
  const isThemeSelectorOpen = ref(false);
  const isLoading = computed(
    (): boolean => store.getters[GET_IS_THEMES_LOADING],
  );
  const isDeactivatingLoading = computed(
    (): boolean => store.getters[GET_IS_DEACTIVATING_LOADING],
  );
  const isStoreDeactivated = computed(
    (): boolean => store.getters[GET_STORE].isDeactivated,
  );

  const toggleMenu = (): void => {
    isModalOpen.value = !isModalOpen.value;
  };

  const toggleThemeSelector = (): void => {
    isThemeSelectorOpen.value = !isThemeSelectorOpen.value;
    isThemeSelectorOpen.value ? disableScroll() : enableScroll();
  };

  const resetChanges = async (): Promise<void> => {
    store.dispatch(RESET_CHANGES, {
      currentThemeId: props.currentThemeId,
      storeId: props.userStore?.id,
      themes: props.themes,
    });
  };

  const activateStore = async (): Promise<void> => {
    await store.dispatch(ACTIVATE_STORE, { storeId: props.userStore?.id });
  };

  const menuSettings = {
    bottom: true,
    color: primaryViolet,
    rounded: 'lg',
  };

  return {
    activateStore,
    isDeactivatingLoading,
    isLoading,
    isModalOpen,
    isStoreDeactivated,
    isTabletAndLower,
    isThemeSelectorOpen,
    menuSettings,
    primaryBlack,
    primaryViolet,
    resetChanges,
    secondaryViolet,
    toggleMenu,
    toggleThemeSelector,
  };
};

export default useShopifyThemeSettings;
