<template>
  <div class="profile-menu">
    <div class="profile-menu__avatar">
      <v-avatar
        size="40"
      >
        <img
          :src="profileImg"
          alt="avatar"
        >
      </v-avatar>
    </div>
    <BaseMenu
      icon="mdi-chevron-down"
      :nudge-width="210"
      :attach="false"
    >
      <template #content="{ closeMenu }">
        <v-list>
          <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            height="100"
            class="profile-menu__list-item"
            :disabled="item.disabled"
            @click="item.action(); closeMenu();"
          >
            <v-list-item-title
              class="profile-menu__list-item__title"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </BaseMenu>
  </div>
</template>

<script lang="ts">
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import useProfileMenu from './ProfileMenu';

export default {
  components: {
    BaseMenu,
  },
  setup(_, context) {
    return useProfileMenu(context);
  },
};
</script>

<style lang="scss" scoped>
@import "ProfileMenu";
</style>
