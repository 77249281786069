import { computed, ref } from 'vue';
import {
  UndoRedoIconsReturn,
} from '@/components/Editor/UndoRedoIcons/types';
import store from '@/store';
import {
  GET_IS_IFRAME,
  GET_IS_MOBILE,
  GET_IS_REDO_DISABLED,
  GET_IS_UNDO_DISABLED,
  GET_IS_UNDO_REDO_HIDDEN,
  LOAD_REDO_CANVAS_STATE,
  LOAD_UNDO_CANVAS_STATE,
} from '@/store/Editor/constants';

export const useUndoRedoIcons = (): UndoRedoIconsReturn => {
  const undoRedoIcons = ref();
  const isIframe = computed(
    (): boolean => store.getters[GET_IS_IFRAME],
  );
  const isMobile = computed((): boolean => {
    return store.getters[GET_IS_MOBILE];
  });
  const isRedoDisabled = computed(
    (): boolean => store.getters[GET_IS_REDO_DISABLED],
  );
  const isUndoDisabled = computed(
    (): boolean => store.getters[GET_IS_UNDO_DISABLED],
  );
  const isUndoRedoHidden = computed(
    (): boolean => store.getters[GET_IS_UNDO_REDO_HIDDEN],
  );

  const redo = async (): Promise<void> => {
    await store.dispatch(LOAD_REDO_CANVAS_STATE);
  };

  const undo = async (): Promise<void> => {
    await store.dispatch(LOAD_UNDO_CANVAS_STATE);
  };

  return {
    isIframe,
    isMobile,
    isRedoDisabled,
    isUndoDisabled,
    isUndoRedoHidden,
    redo,
    undo,
    undoRedoIcons,
  };
};
