import { fabric } from 'fabric';
import {
  drawCircleWithInnerBorder,
} from '@/components/Editor/helpers/fabric/controls/drawCircleWithInnerBorder';
import { FabricControl, FabricObject } from '@/components/Editor/types/fabric';
import { primaryViolet } from '@/constants/colors';
import { getFabricObjectColorByDPI } from '@/utils/dpi';

export const getDefaultControl = ({
  actionHandler,
  actionName,
  offsetY = 0,
  size = 8,
  x,
  y,
}): FabricControl => {
  return new fabric.Control({
    actionHandler,
    actionName,
    cursorStyle: 'pointer',
    offsetY,
    render: (
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      fabricObject: FabricObject,
    ) => {
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject?.angle || 0));
      const color = 'white';
      const borderColor = fabricObject?.dpi
        ? getFabricObjectColorByDPI(fabricObject.dpi)
        : primaryViolet;

      drawCircleWithInnerBorder({
        borderColor,
        borderWidth: size / 2,
        color,
        ctx,
        size,
      });
      ctx.restore();
    },
    sizeX: size,
    sizeY: size,
    x,
    y,
  });
};
