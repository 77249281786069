<template>
  <div class="auth-layout">
    <AuthBackground />
    <div class="auth-layout__form">
      <div
        :class="[
          'auth-layout__slot',
          { 'auth-layout__slot-full-height': isFullHeight }
        ]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { OverflowValues } from '@/types';
import { setBodyOverflowY } from '@/utils';
import { isTabletAndLower } from '@/utils/Mobile';
import AuthBackground from './AuthBackground/AuthBackground.vue';

export default {
  components: {
    AuthBackground,
  },
  props: {
    isFullHeight: {
      required: false,
      type: Boolean,
    },
  },
  beforeDestroy() {
    !isTabletAndLower && setBodyOverflowY(OverflowValues.initial);
  },
  mounted() {
    !isTabletAndLower && setBodyOverflowY(OverflowValues.hidden);
  },
};
</script>

<style lang="scss" scoped>
  @import 'AuthLayout';
</style>
