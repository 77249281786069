import { FabricObject } from '@/components/Editor/types/fabric';
import { isFabricText } from './checkObjectType';

export const getObjectsFonts = (
  objects: FabricObject[],
): (string | undefined)[] => {
  return objects
    .filter(object => isFabricText(object) && object.fontFamily)
    .map(({ fontFamily }) => fontFamily);
};
