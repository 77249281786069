import {
  actualCanvasIconOffset,
  actualCanvasIconSize,
} from '@/components/Editor/helpers/fabric/controls/renderIcons';
import { FabricObject, FabricObjectScale } from '@/components/Editor/types/fabric';
import { IMAGE_OFFSET_FROM_CANVAS_BORDER_MULTIPLIER } from '@/constants/editor';

interface FitImageToCanvasParams {
  canvasSize: { height: number, width: number },
  canvasZoomForDefault: number,
  imageAsHtml: HTMLImageElement,
  imageSettings: FabricObject,
}

const canvasIconSize = actualCanvasIconSize();
const canvasIconOffset = actualCanvasIconOffset();
const imageOffset = (canvasIconSize + canvasIconOffset)
  * IMAGE_OFFSET_FROM_CANVAS_BORDER_MULTIPLIER;

const fitImageByWidth = (
  imageSettings: FabricObject,
  canvasWidth: number,
  imageWidth: number,
): FabricObjectScale => {
  const {
    isScalingChanged,
    scaleX: imageScaleX,
    scaleY: imageScaleY,
  } = imageSettings;
  const imageFitWidth = canvasWidth - imageOffset;
  const scaleX = isScalingChanged
    ? imageScaleX
    : imageFitWidth / imageWidth;
  const scaleY = isScalingChanged ? imageScaleY : scaleX;
  return (scaleX && scaleY)
    ? { scaleX, scaleY }
    : { scaleX: 1, scaleY: 1 };
};

const fitImageByHeight = (
  imageSettings: FabricObject,
  canvasHeight: number,
  imageHeight: number,
): FabricObjectScale => {
  const {
    isScalingChanged,
    scaleX: imageScaleX,
    scaleY: imageScaleY,
  } = imageSettings;
  const imageFitHeight = canvasHeight - imageOffset;
  const scaleY = isScalingChanged
    ? imageScaleY
    : imageFitHeight / imageHeight;
  const scaleX = isScalingChanged ? imageScaleX : scaleY;
  return (scaleX && scaleY)
    ? { scaleX, scaleY }
    : { scaleX: 1, scaleY: 1 };
};

const fitScaleToDefaultZoom = (
  { scaleX, scaleY }: FabricObjectScale,
  canvasZoomForDefault: number,
): FabricObjectScale => {
  return {
    scaleX: scaleX / canvasZoomForDefault,
    scaleY: scaleY / canvasZoomForDefault,
  };
};

export const fitImageToCanvas = ({
  canvasSize,
  canvasZoomForDefault,
  imageAsHtml,
  imageSettings,
}: FitImageToCanvasParams): FabricObjectScale => {
  const { height: imageHeight, width: imageWidth } = imageAsHtml;
  const { height: canvasHeight, width: canvasWidth } = canvasSize;
  const { scaleX: imageScaleX, scaleY: imageScaleY } = imageSettings;

  if (imageScaleX && imageScaleY) {
    let fitImageScale = { scaleX: imageScaleX, scaleY: imageScaleY };

    const imageScaledHeight = imageHeight * imageScaleY;
    const imageScaledWidth = imageWidth * imageScaleX;

    const initialCanvasHeight = canvasHeight / canvasZoomForDefault;
    const initialCanvasWidth = canvasWidth / canvasZoomForDefault;

    const imageToCanvasHeightsRatio = imageScaledHeight / initialCanvasHeight;
    const imageToCanvasWidthsRatio = imageScaledWidth / initialCanvasWidth;

    const isImageExceedsCanvas = imageToCanvasHeightsRatio > 1
      || imageToCanvasWidthsRatio > 1;
    
    const isImageHeightExceeds =
      imageToCanvasHeightsRatio >= imageToCanvasWidthsRatio;
    const isImageWidthExceeds =
      imageToCanvasWidthsRatio > imageToCanvasHeightsRatio;

    if (isImageExceedsCanvas) {
      if (isImageHeightExceeds) {
        fitImageScale = fitImageByHeight(
          imageSettings,
          canvasHeight,
          imageHeight,
        );
      }
      if (isImageWidthExceeds) {
        fitImageScale = fitImageByWidth(
          imageSettings,
          canvasWidth,
          imageWidth,
        );
      }
    }
    
    return fitScaleToDefaultZoom(fitImageScale, canvasZoomForDefault);
  }
  return { scaleX: 1, scaleY: 1 };
};
