<template>
  <MainLayout class="user-guide__wrapper">
    <div
      class="user-guide"
      :class="{'overflow-hidden': !isPageOpen}"
    >
      <v-tabs
        v-model="currentTab"
        :slider-color="secondaryViolet"
        class="user-guide__tabs"
        background-color="transparent"
        active-class="user-guide__tabs-tab_active"
        color="black"
        vertical
        optional
      >
        <v-tab
          v-for="tab in userGuideTabs"
          :key="tab.name"
          class="user-guide__tabs-tab"
          :class="{'user-guide__tabs-tab_styled': !tab.isHeadingTab}"
          :href="`#${tab.name}`"
          @click.stop="navigateToTab(tab.name)"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items 
        :value="currentTab"
        class="user-guide__content"
        :class="{'slide-content': isTabletAndLower && isPageOpen}"
      >
        <v-tab-item
          v-for="tab in userGuideTabs"
          :key="tab.name"
          :value="tab.name"
          transition="fade-transition"
          class="user-guide__content-item"
        >
          <div
            v-if="isTabletAndLower"
            class="user-guide__content-item-header"
          >
            <BaseButton
              class="back-button"
              icon="mdi-arrow-left"
              no-margin-top
              dark
              @click="closePage"
            />
            <div class="breadcrumbs">
              <span @click.stop="closePage">
                {{ $t('menu.menu__text') }}
              </span>
              /
              <span class="underscored-text">{{ tab.title }}</span>
            </div>
          </div>
          <keep-alive>
            <component
              :is="tab.component"
              :title="tab.title"
              :tabs="userGuideTabs"
              @navigateToTab="navigateToTab"
              @closePage="closePage"
            />
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <BackToTopButton />
  </MainLayout>
</template>

<script lang="ts">
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useUserGuide from './UserGuide';

export default {
  components: {
    BackToTopButton,
    BaseButton,
    MainLayout,
  },
  setup() {
    return useUserGuide();
  },
};
</script>

<style lang="scss">
@import 'UserGuide';
</style>
