import { Breakpoints } from '@/constants/breakpoints';
import {
  screenCanvasDeltaDesktop,
  screenCanvasDeltaMobile,
  screenCanvasDeltaTablet,
} from '@/constants/editor';
import store from '@/store';
import { SET_BASE_CANVAS_HEIGHT } from '@/store/Editor/constants';
import { isTouchDevice } from '@/utils/isTouchDevice';

export const changeBaseCanvasHeight = (): void => {
  const screenHeight = document.documentElement.clientHeight;
  let screenCanvasDelta = 
      isTouchDevice()
        ? screenCanvasDeltaTablet
        : screenCanvasDeltaDesktop;
  if (window.innerWidth < Breakpoints.maxMobileWidth) {
    screenCanvasDelta = screenCanvasDeltaMobile;
  }
  const canvasHeight = screenHeight - screenCanvasDelta;
  store.commit(SET_BASE_CANVAS_HEIGHT, canvasHeight);
};
