/* eslint-disable max-lines */
import { Ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { ActionContext } from 'vuex';
import { maxUploadCoverImageSizeMB } from '@/constants';
import router from '@/router';
import { ShopifyAPI } from '@/services/ShopifyAPI';
import { TemplatesAPI } from '@/services/TemplatesAPI';
import { Collection } from '@/store/Collections/types';
import { SET_NOTIFICATION } from '@/store/constants';
import { DeliveryProfile } from '@/store/Shipping/types';
import {
  ADD_TEMPLATES,
  CHANGE_TEMPLATE_COVER,
  CHECK_FONT,
  CLEAR_TEMPLATES,
  COPY_TEMPLATE,
  EDIT_TEMPLATE_NAME,
  GET_SHOPIFY_PRODUCT,
  INIT_TEMPLATES,
  LOAD_CURRENT_TEMPLATE,
  LOAD_MORE_TEMPLATES,
  REMOVE_TEMPLATE,
  RESET_TEMPLATE_SORTING,
  SAVE_EDITED_TEMPLATE,
  SET_CURRENT_TEMPLATE,
  SET_IS_EDITING_RIGHTS_TEMPLATES,
  SET_IS_TEMPLATE_IMAGE_LOADING,
  SET_SELECTED_COLLECTION,
  SET_TEMPLATE_COVER,
  SET_TEMPLATE_NAME,
  SET_TEMPLATE_PRODUCT_DETAILS,
  SET_TEMPLATES,
  SET_TEMPLATES_AMOUNT_OF_PAGES,
  SET_TEMPLATES_LOADING,
  SET_TEMPLATES_SELECTED_SORT,
  SUBMIT_TEMPLATE,
  UPLOAD_IMAGE,
} from '@/store/Templates/constants';
import {
  Context,
  ProductDetails,
  Template,
  TemplatesState,
  UpdateTemplateDetails,
} from '@/store/Templates/types';
import { SortItems } from '@/types';
import { NotificationType } from '@/types/notification';
import { handleResponse } from '@/utils/axios/handleResponse';
import { checkMaxImageSize } from '@/utils/checkMaxImageSize';
import { CollectionRouteTypes } from '@/views/Collections/types';
import { model3dData } from '@/views/UploadPage/types';
import {
  CLEAR_CANVAS_HISTORY,
  SET_IS_EDITOR_LOADING,
  SET_IS_MODEL_READY_TO_LOAD,
} from '../Editor/constants';
import { UPDATE_TEMPLATE_DETAILS } from './constants';

export const actions = {
  [ADD_TEMPLATES]: (
    { commit }: Context,
    templates: Template[],
  ) => {
    commit(ADD_TEMPLATES, templates);
  },
  [CHANGE_TEMPLATE_COVER]: async (
    { commit }: Context,
    { collectionId, id, image }: {
      collectionId: number,
      id: number,
      image: File
    },
  ) => {
    commit(SET_IS_TEMPLATE_IMAGE_LOADING, true);

    const isImageSizeValid = checkMaxImageSize(
      image,
      maxUploadCoverImageSizeMB,
      () => commit(SET_IS_TEMPLATE_IMAGE_LOADING, false),
    );
    
    if (!isImageSizeValid) return;

    const response = await TemplatesAPI.changeTemplateCover({
      collectionId,
      id,
      image,
    });

    const handleSuccessStatus = (
      { imagePath }: { imagePath: string }): void => {
      commit(SET_TEMPLATE_COVER, { id, imagePath });
      commit(SET_IS_TEMPLATE_IMAGE_LOADING, false);
    };
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [CHECK_FONT]: async (
    _,
    data: {
        link: string,
      },
  ): Promise<boolean> => {
    const response = await TemplatesAPI.checkGoogleFont(data.link);
    const handleSuccessStatus = (): boolean => true;
    return handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [CLEAR_TEMPLATES]: (
    { commit }: Context,
  ) => {
    commit(CLEAR_TEMPLATES);
  },
  [COPY_TEMPLATE]: async (
    { dispatch }: Context,
    { collection, newTemplateName, template }: {
      collection: Collection,
      newTemplateName: string
      template: Template
    },
  ) => {
    const response = await TemplatesAPI.copyTemplate({
      collectionId: collection.id as number,
      newTemplateName,
      templateId: template.id,
    });

    const handleSuccessStatus = ({ collectionId, name }): void =>
      dispatch(SET_NOTIFICATION, {
        localizationData: {
          path: 'notifications.templateIsCopied',
          slots: [
            { content: `"${name}"`, key: 'templateName' },
            {
              content: `"${collection.name}"`,
              key: 'collectionName',
              link: `/${CollectionRouteTypes.privateCollections}/${collectionId}/templates`,
            },
          ],
        },
        type: NotificationType.Success,
      });
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [EDIT_TEMPLATE_NAME]: async (
    { commit }: Context,
    data: {
      id: number,
      name: string
    },
  ) => {
    const response = await TemplatesAPI.editTemplateName(data);

    const handleSuccessStatus = (): void => {
      commit(SET_TEMPLATE_NAME, data);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [GET_SHOPIFY_PRODUCT]: async (
    { commit }: Context,
    { productId, storeId, variantId }: {
      productId: number, storeId: number, variantId: number
    },
  ) => {
    commit(SET_TEMPLATES_LOADING, true);
    const { data: product, status } = await ShopifyAPI.getStoreProduct(
      storeId, productId, variantId,
    );
    if (status === 200) {
      if (product) {
        const currentTemplate = product.template;
        currentTemplate.canvasData = JSON.parse(currentTemplate.canvasData);
        commit(SET_CURRENT_TEMPLATE, currentTemplate);
      }
      commit(SET_IS_MODEL_READY_TO_LOAD, true);
    }

    commit(SET_TEMPLATES_LOADING, false);
  },
  [INIT_TEMPLATES]: async (
    { commit }: Context,
    data: {
      collectionId: number,
      limit: number,
      page: number,
      selectedSort: SortItems
    },
  ) => {
    commit(SET_TEMPLATES_LOADING, true);

    const response = await TemplatesAPI.initTemplates(data);

    const handleSuccessStatus = (
      { amountOfPages, collection, isEditingRights, templates }: {
        amountOfPages: number,
        collection: Collection,
        isEditingRights: boolean,
        templates: Template[]
      },
    ): void => {
      commit(SET_TEMPLATES_AMOUNT_OF_PAGES, amountOfPages);
      commit(SET_SELECTED_COLLECTION, collection);
      commit(SET_TEMPLATES, templates);
      commit(SET_IS_EDITING_RIGHTS_TEMPLATES, isEditingRights);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });

    commit(SET_TEMPLATES_LOADING, false);
  },
  [LOAD_MORE_TEMPLATES]: async (
    { commit }: Context,
    data: {
      collectionId: number,
      limit: number,
      page: number,
      selectedSort: SortItems,
    },
  ) => {
    commit(SET_TEMPLATES_LOADING, true);

    const response = await TemplatesAPI.initTemplates(data);

    const handleSuccessStatus = (
      { amountOfPages, collection, isEditingRights, templates }: {
        amountOfPages: number,
        collection: Collection,
        isEditingRights: boolean,
        templates: Template[],
      },
    ): void => {
      commit(SET_TEMPLATES_AMOUNT_OF_PAGES, amountOfPages);
      commit(SET_SELECTED_COLLECTION, collection);
      commit(ADD_TEMPLATES, templates);
      commit(SET_IS_EDITING_RIGHTS_TEMPLATES, isEditingRights);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });

    commit(SET_TEMPLATES_LOADING, false);
  },
  [REMOVE_TEMPLATE]: async ({ commit }: Context, id: number) => {
    const response = await TemplatesAPI.removeTemplate(id);
    
    const handleSuccessStatus = (): void => commit(REMOVE_TEMPLATE, id);

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });
  },
  [RESET_TEMPLATE_SORTING]: (
    { commit }: Context,
  ) => {
    commit(SET_TEMPLATES_SELECTED_SORT, SortItems.New);
  },
  [SAVE_EDITED_TEMPLATE]: async (
    {
      commit,
      dispatch,
      rootState,
      state,
    }: ActionContext<TemplatesState, any>,
  ) => {
    commit(CLEAR_CANVAS_HISTORY);
    const { t } = useI18n();
    const { user: { customFonts }} = rootState;
    if (state.currentTemplate) {
      const { currentTemplate: { canvasData, id }} = state;
      const response = await TemplatesAPI.saveEditedTemplate({
        id,
        customFonts,
        canvasData: JSON.stringify(canvasData),
      });
      const handleSuccessStatus = (): void => {
        dispatch(SET_NOTIFICATION, {
          text: t('notifications.changesHaveBeenSaved'),
          type: NotificationType.Success,
        });
      };

      handleResponse({
        handleSuccessStatus,
        response,
        successStatus: 200,
      });
    }
  },
  [SET_TEMPLATES_SELECTED_SORT]: (
    { commit }: Context,
    selectedSort: SortItems,
  ) => {
    commit(SET_TEMPLATES_SELECTED_SORT, selectedSort);
  },
  [SUBMIT_TEMPLATE]: async (
    _,
    data: model3dData,
  ) => {
    const response = await TemplatesAPI.submitTemplate(data);

    const handleSuccessStatus = (
      { collectionId, templateId }: {
        collectionId: number,
        templateId: number
      },
    ): void => {
      router.push(`/${CollectionRouteTypes.privateCollections}/${collectionId}/templates/${templateId}/editor`);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [UPDATE_TEMPLATE_DETAILS]: async (
    { commit }: Context,
    { id, productDetails, template }: UpdateTemplateDetails,
  ) => {
    const response = await TemplatesAPI.updateProductDetails({
      id, productDetails, template,
    });

    const handleSuccessStatus = (
      {
        deliveryProfile,
        productDetails,
        template,
      }:
        {
          deliveryProfile: DeliveryProfile
          productDetails: ProductDetails,
          template: Template
        },
    ): void => {
      commit(SET_TEMPLATE_NAME, template);
      commit(SET_TEMPLATE_PRODUCT_DETAILS, {
        id,
        productDetails: {
          ...productDetails,
          deliveryProfile,
        },
      });
    };
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [UPLOAD_IMAGE]: async (
    _,
    { data, percents }: {data: FormData, percents: Ref<number>},
  ) => {
    const response = await TemplatesAPI.uploadOverlayImage(data, percents);
    
    const handleSuccessStatus = (
      { imagePath }: { imagePath: string },
    ): string => {
      return imagePath;
    };

    return handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [LOAD_CURRENT_TEMPLATE]: async (
    { commit }: ActionContext<TemplatesState, any>,
    templateId: number,
  ): Promise<void> => {
    commit(SET_IS_EDITOR_LOADING, true);

    const response = await TemplatesAPI.getTemplate(templateId);

    const handleSuccessStatus = (
      data: Template & { canvasData: string },
    ): void => {
      const currentTemplate = data;
      currentTemplate.canvasData = JSON.parse(currentTemplate.canvasData);
      commit(SET_CURRENT_TEMPLATE, currentTemplate);
      commit(SET_IS_MODEL_READY_TO_LOAD, true);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
};
