import { OverflowValues } from '@/types';
import { isTabletAndLower } from './Mobile';

const scrollToTop = (): void => {
  isTabletAndLower || window.scrollTo(0, 0);
};

const smoothScrollToTop = (element?: HTMLElement): void => {
  element
    ? element.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
    : window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
};

const setBodyOverflowY = (value: OverflowValues): void => {
  document.body.style.overflowY = (value as unknown as string);
};

export {
  scrollToTop,
  setBodyOverflowY,
  smoothScrollToTop,
};
