<template>
  <div class="text-settings-mobile">
    <TextAlignments
      v-if="isTextAlignmentsActive"
      v-click-outside="closeTextAlignments"
      :is-mobile="isMobile"
    />
    <Alignments
      v-if="isAlignmentsActive"
      v-click-outside="closeAlignments"
      :is-mobile="isMobile"
    />
    <Rotation
      v-if="isRotationActive"
      v-click-outside="closeRotation"
      :is-mobile="isMobile"
    />
    <TextFontSize
      v-if="isFontSizeActive"
      v-click-outside="closeFontSize"
      :is-mobile="isMobile"
    />
    <div class="horizontal-scroll">
      <div 
        class="font-selector"
        :class="{'font-selector_isIframe': isIframe}"
      >
        <BaseButton
          v-if="!isIframe"
          class="font-selector__add-font"
          :color="black54"
          icon="mdi-plus"
          no-margin-top
          dark
          @click="$emit('openAddFontModal')"
        />
        <div
          v-for="fontName in fontsToRender"
          :key="fontName"
          class="font-selector__option"
          :style="`font-family: ${fontName}`"
          :class="{
            'font-selector__option_selected': font === fontName
          }"
          @click="changeTextFont(fontName)"
        >
          <span>
            {{ fontName }}
          </span>
          <BaseButton 
            v-if="userFonts.includes(fontName) && !isIframe"
            no-margin-top
            @click.stop="openRemoveFontModal(fontName)"
          >
            <template #prependSvg>
              <img
                src="@/components/Editor/assets/icons/Delete.svg"
                :alt="$t('redactor.delete__button')"
              >
            </template>
          </BaseButton>
        </div>
      </div>
    </div>
    <v-divider v-if="!isiOS()" />
    <div
      ref="textControls"
      class="horizontal-scroll"
    >
      <div
        class="text-settings-mobile-panel"
      >
        <div
          :class="{'disabled': lockInfo.lockedDeletion}"
          @click="$emit('deleteSelectedObject')"
        >
          <BaseIcon>
            <TrashcanIcon />
          </BaseIcon>
          <span>
            {{ $t('redactor.delete__button') }}
          </span>
        </div>
        <div @click="toggleFontSize">
          <BaseIcon>
            <FontSizeIcon />
          </BaseIcon>
          <span>
            {{ $t('redactor.fontSize__label') }}
          </span>
        </div>
        <div
          :class="{disabled: lockInfo.lockedMovement}"
          @click="toggleAlignments"
        >
          <div class="text-settings-icon">
            <BaseIcon>
              <AlignHorizontalCenterIcon />
            </BaseIcon>
            <BaseIcon
              class="arrow-icon"
              :class="{ 'arrow-active': isAlignmentsActive }"
            >
              <ChevronIcon />
            </BaseIcon>
          </div>
          <span>
            {{ $t('alignments.alignment__label') }}
          </span>
        </div>
        <div
          :class="{disabled: lockInfo.lockedRotation}"
          @click="toggleRotation"
        >
          <img
            src="@/components/Editor/assets/icons/Rotate.svg"
            :alt="$t('redactor.rotation__label')"
          >
          <span>{{ $t('redactor.rotation__label') }}</span>
        </div>
        <div @click="toggleTextAlignments">
          <div class="text-settings-icon">
            <BaseIcon>
              <TextAlignLeftIcon />
            </BaseIcon>
            <BaseIcon
              class="arrow-icon"
              :class="{ 'arrow-active': isTextAlignmentsActive }"
            >
              <ChevronIcon />
            </BaseIcon>
          </div>
          <span>{{ $t('alignments.textAlign__label') }}</span>
        </div>
        <div>
          <ColorPicker
            :color-picker-type="ColorPickerType.text"
            :is-mobile="isMobile"
            :is-label-visible="false"
          />
          <span>{{ $t('redactor.color__label') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import TextAlignments from '@/components/Editor/TextAlignments/TextAlignments.vue';
import TextFontSize from '@/components/Editor/TextFrontSize/TextFontSize.vue';
import useTextSettingsMobile from '@/components/Editor/TextSettings/TextSettingsMobile/TextSettingsMobile';
import AlignHorizontalCenterIcon from '@/components/icons/AlignHorizontalCenterIcon.vue';
import ChevronIcon from '@/components/icons/ChevronIcon.vue';
import FontSizeIcon from '@/components/icons/FontSizeIcon.vue';
import TextAlignLeftIcon from '@/components/icons/TextAlignLeftIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';

export default {
  components: {
    AlignHorizontalCenterIcon,
    Alignments,
    BaseButton,
    BaseIcon,
    ChevronIcon,
    ColorPicker,
    FontSizeIcon,
    Rotation,
    TextAlignLeftIcon,
    TextAlignments,
    TextFontSize,
    TrashcanIcon,
  },
  props: {
    font: {
      required: true,
      type: String,
    },
    isIframe: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
      default: () => defaultLockObject,
    },
  },
  setup(props) {
    return useTextSettingsMobile(props);
  },
};
</script>

<style lang="scss" scoped>
@import 'TextSettingsMobile';
</style>
