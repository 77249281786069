import * as BlueBird from 'bluebird';
import { LayerNumberModes } from '@/components/Editor/helpers/layers/types';
import { updateLayerNumber } from '@/components/Editor/helpers/layers/updateLayerNumber';
import { updateCanvasTexture } from '@/components/Editor/helpers/Three/updateCanvasTexture';
import { MeshData } from '@/components/Editor/types/editor';
import store from '@/store';
import { GET_MESHS, SET_LAYER_NUMBER } from '@/store/Editor/constants';

export const createCanvasesForAllMeshs = async (): Promise<void> => {
  const meshs = store.getters[GET_MESHS];

  await BlueBird.map(meshs, async (mesh: MeshData): Promise<void> => {
    if (!mesh.disabled) {
      await updateCanvasTexture(mesh);
    }
  });

  store.commit(SET_LAYER_NUMBER, updateLayerNumber(LayerNumberModes.default));
};
