<template>
  <div>
    <div class="image-settings__control">
      <Alignments
        :is-mobile="isMobile"
        :is-disabled="lockInfo.lockedMovement"
      />
    </div>
    <div>
      <v-divider />
      <Rotation
        :is-mobile="isMobile"
        :is-disabled="lockInfo.lockedRotation"
      />
    </div>
    <div v-if="activeImage.dpi">
      <v-divider />
      <Resolution
        :dpi="activeImage.dpi"
        :is-mobile="isMobile"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Resolution from '@/components/Editor/Resolution/Resolution.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import { secondaryViolet } from '@/constants/colors';

export default {
  components: {
    Alignments,
    Resolution,
    Rotation,
  },
  props: {
    activeImage: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
    },
  },
  setup() {
    return { secondaryViolet };
  },
};
</script>

<style lang="scss" scoped>
  @import "ImageSettingsDesktop";
</style>
