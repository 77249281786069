import { ref } from 'vue';

const useBaseSelect = () => {
  const baseSelect = ref();
  const isMenuOpened = ref(false);

  const closeMenu = (): boolean =>
    isMenuOpened.value = false;

  const toggleMenu = (): void => {
    isMenuOpened.value = !isMenuOpened.value;
    if (!isMenuOpened.value) baseSelect.value.blur();
  };

  return {
    baseSelect,
    closeMenu,
    isMenuOpened,
    toggleMenu,
  };
};

export default useBaseSelect;
