import { computed, reactive } from 'vue';
import { FabricObjectAttributes, TextAlignments } from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_ACTIVE_OBJECT,
  TRIGGER_UPDATE_OBJECT,
} from '@/store/Editor/constants';

const useAlignments = () => {
  const textAlignment = computed(
    (): TextAlignments => store.getters[GET_ACTIVE_OBJECT]?.textAlign,
  );

  const textAlignments = [
    {
      active: true,
      name: TextAlignments.left,
    },
    {
      active: false,
      name: TextAlignments.center,
    },
    {
      active: false,
      name: TextAlignments.right,
    },
    {
      active: false,
      name: TextAlignments.justify,
    },
  ];

  const changeBtnActive = (name: string) => {
    textAlignments.find(
      ({ name: alignmentName }) => alignmentName === name,
    )!.active = true;
  };

  const textAlignmentsProps = reactive({
    textAlignment,
    textAlignments,
  });

  const triggerTextAlignment = (textAlign: TextAlignments): void => {
    store.dispatch(TRIGGER_UPDATE_OBJECT, {
      changes: { [FabricObjectAttributes.textAlign]: textAlign },
      isUpdateHistory: true,
    });
  };

  const textAlignmentsEmits = {
    triggerTextAlignment,
  };

  return {
    changeBtnActive,
    textAlignments,
    textAlignmentsEmits,
    textAlignmentsProps,
  };
};

export default useAlignments;
