import { computed, onMounted, onUnmounted, ref } from 'vue';
import { black26, black38, black50, primaryViolet } from '@/constants/colors';
import store from '@/store';
import {
  APPLY_DESIGN,
  EDIT_DESIGN,
  GET_TEMPLATE_DESIGNS,
  REMOVE_TEMPLATE_DESIGN,
  SET_IS_DESIGN_MODE,
} from '@/store/Editor/constants';
import { SET_TEMPLATE_DESIGNS } from '@/store/Editor/constants';
import { getDefaultEditorState } from '@/store/Editor/helpers';
import { TemplateDesign } from '@/store/Editor/types';
const { templateDesigns: defaultTemplateDesigns } = getDefaultEditorState();

export const useTemplateDesignSidebar = () => {
  const designSearch = ref('');
  const templateDesigns = computed(
    (): TemplateDesign[] => store.getters[GET_TEMPLATE_DESIGNS],
  );

  const searchTemplateDesigns = (): Promise<void> =>
    store.dispatch(GET_TEMPLATE_DESIGNS, { name: designSearch.value });

  const editDesignId = ref<number | null>(null);

  const editDesign = async ({ id, name }: TemplateDesign): Promise<void> => {
    const templateDesign = templateDesigns.value.find(
      design => design.id === id,
    );
    if (templateDesign?.name !== name)
      await store.dispatch(EDIT_DESIGN, {
        templateDesignId: id,
        name,
      });
    editDesignId.value = null;
  };

  const removeDesign = (templateDesignId: number): Promise<void> =>
    store.dispatch(REMOVE_TEMPLATE_DESIGN, {
      templateDesignId,
    });

  const applyDesign = (templateDesign: TemplateDesign): Promise<void> =>
    store.dispatch(APPLY_DESIGN, { templateDesign });

  const close = (): Promise<void> =>
    store.dispatch(SET_IS_DESIGN_MODE, { isActive: false });

  onMounted(() => {
    store.dispatch(GET_TEMPLATE_DESIGNS, {});
  });

  onUnmounted(() => {
    store.commit(SET_TEMPLATE_DESIGNS, defaultTemplateDesigns);
  });

  return {
    editDesign,
    close,
    designSearch,
    templateDesigns,
    black38,
    black26,
    removeDesign,
    applyDesign,
    primaryViolet,
    editDesignId,
    black50,
    searchTemplateDesigns,
  };
};
