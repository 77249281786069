<template>
  <div
    class="summary"
  >
    <TextToolIcon
      v-if="object.type === 'textbox'"
      :color="isActive ? primaryViolet : 'currentColor'"
    />
    <ImageToolIcon
      v-if="object.type === 'image'"
      :color="isActive ? primaryViolet : 'currentColor'"
    />
    <v-form
      v-if="isEditing"
      class="summary__textfield"
    >
      <TextField
        v-model.trim="layerName"
        class="edit-field"
        input-type="text"
        :color="primaryViolet"
        :outlined="false"
        hide-details
        @keydown.enter.prevent="$emit('saveSummary', layerName)"
      />
      <BaseButton
        icon="mdi-check"
        :color="primaryViolet"
        light
        apply-color-to-text
        no-margin-top
        @click="$emit('saveSummary', layerName)"
      />
    </v-form>

    <template
      v-else
    >
      <div
        class="summary__text"
        :class="{'summary__text_active': isActive}"
      >
        {{ object.layerName }}
      </div>

      <div class="summary__buttons">
        <BaseButton
          light
          icon="mdi-pencil"
          no-margin-top
          :color="isActive ? primaryViolet : black50"
          @click="$emit('openEditSummary')"
        />
        <BaseButton
          light
          icon="mdi-trash-can"
          no-margin-top
          :color="isActive ? primaryViolet : black50"
          @click="$emit('remove')"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import ImageToolIcon from '@/components/icons/ImageToolIcon.vue';
import TextToolIcon from '@/components/icons/TextToolIcon.vue';
import { useEditorSideSummary } from './EditorSideSummary';

export default {
  components: {
    ImageToolIcon,
    TextToolIcon,
    TextField,
    BaseButton,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'saveSummary', 'openEditSummary', 'remove' ],
  setup(props) {
    return useEditorSideSummary(props);
  },
};
</script>
<style lang="scss" scoped>
 @import './EditorSideSummary.scss'
</style>
