<template>
  <div
    v-if="foundProducts.isEmpty"
    class="search-wrapper__list search-plug"
  >
    <img
      :src="require('@/assets/images/search-plug.svg')"
      :alt="$t('search.searchPlug__alt')"
    >
  </div>
  <div
    v-else-if="!foundProducts.notFound"
    class="search-wrapper__list"
  >
    <div
      v-for="(key, index) in Object.keys(foundProducts)"
      v-show="foundProducts[key].length > 0"
      :key="index"
    >
      <div class="search-wrapper__list-title">
        {{ $t(`search.${key}__title`) }}
      </div>
      <div
        v-for="(item, idx) in foundProducts[key]"
        :key="idx"
        class="search-wrapper__list-found_objects__item"
        @click="$emit('handleRedirect', item, key === 'publicCollections')"
      >
        <img
          class="search-wrapper__list-found_objects__item-image"
          :src="item.imagePath
            || require('@/assets/images/search-default-box.svg')"
          :alt="$t('search.defaultImage__alt')"
        >
        <div 
          class="search-wrapper__list-found_objects__item-name" 
        >
          <HighlightedName
            :name="item.name"
            :search="search"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="search-wrapper__list not-found"
  >
    <div class="not-found__title">
      {{ $t('search.yourSearch__text') }}
      - <span class="bold-text">{{ search }}</span> -
      {{ $t('search.didntMatch__text') }}
    </div>
    <img
      :src="require('@/assets/images/search-plug.svg')"
      :alt="$t('search.searchPlug__alt')"
    >
  </div>
</template>

<script lang="ts">
import HighlightedName from '../HighlightedName/HighlightedName.vue';
export default {
  components: {
    HighlightedName,
  },
  props: {
    foundProducts: Object,
    isSearchOpen: {
      required: false,
      type: Boolean,
    },
    isShowSearchList: {
      required: false,
      type: Boolean,
    },
    search: {
      required: true,
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'SearchListMobile';
</style>
