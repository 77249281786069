import { computed, ref, watch } from 'vue';
import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import {
  GET_IS_EDITOR_ACTIVE,
  GET_IS_MESH_SELECTION_REMOVED,
  GET_IS_MOBILE,
  GET_IS_SIDE_SELECTION_ENABLED,
  SET_IS_MESH_SELECTION_REMOVED,
  SET_IS_SIDE_SELECTION_ENABLED,
} from '@/store/Editor/constants';

export const useSceneControls = () => {
  const sceneControls = ref();

  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);
  const isSideSelectionEnabled = computed(
    (): boolean => store.getters[GET_IS_SIDE_SELECTION_ENABLED],
  );
  const isMeshSelectionRemoved = computed(
    (): boolean => store.getters[GET_IS_MESH_SELECTION_REMOVED],
  );
  const isEditorActive = computed(
    (): boolean => store.getters[GET_IS_EDITOR_ACTIVE],
  );

  const toggleSideSelection = (flag: boolean): void => {
    store.commit(SET_IS_SIDE_SELECTION_ENABLED, flag);
  };
  const removeMeshSelection = (): void => {
    store.commit(SET_IS_MESH_SELECTION_REMOVED, true);
  };

  const updateSceneControlsStyles = () => {
    if (!sceneControls.value || !isMobile.value) return;
    const screenHeight = document.documentElement.clientHeight;
    const controlsTransformStyle = `translateX(-50%) translateY(calc((-100vh + ${screenHeight}px))`;
    sceneControls.value.style.transform = controlsTransformStyle;
  };

  watch(isMeshSelectionRemoved, updateSceneControlsStyles, { immediate: true });

  return {
    isMobile,
    isMeshSelectionRemoved,
    isSideSelectionEnabled,
    toggleSideSelection,
    removeMeshSelection,
    primaryViolet,
    isEditorActive,
    sceneControls,
  };
};
