import FontFaceObserver from 'fontfaceobserver';

export const loadFonts = async (fonts: string[]): Promise<void> => {
  const observers: FontFaceSetLoadEvent[] = [];
  fonts.forEach(family => {
    const obs = new FontFaceObserver(family);
    observers.push(obs.load());
  });
  await Promise.all(observers);
};
