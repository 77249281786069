import {
  discardActiveObjectInStore,
} from '@/components/Editor/helpers/fabric/activeObjects/modifyActiveObjectInStore';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';

export const beforeSelectionCleared = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('before:selection:cleared', discardActiveObjectInStore);
};
