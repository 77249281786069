import { computed } from 'vue';

const useBaseButton = ({ color, dark, light }) => {

  const defaultStyle = computed(() => ((!dark && !light) || (light && !color)) && 'color: #fff');

  return {
    defaultStyle,
  };
};

export default useBaseButton;
