import { useI18n } from 'vue-i18n-composable';
import { ruleValidationResult } from '@/utils/FieldValidationRules/types';
import { isPriceValid, isWeightValid } from '@/utils/FieldValidationRules/validations';

export const requiredField = (v: string | number): ruleValidationResult => {
  const { t } = useI18n();
  if (typeof v === 'string') v = v.trim();
  return !!v || t('validationRules.fieldIsRequired__text');
};

export const checkboxRequired = (v: string): ruleValidationResult => {
  const { t } = useI18n();
  return !!v || t('validationRules.youMustAgreeToContinue__text');
};

export const minLength = (length: number) =>
  (v: string): ruleValidationResult => {
    const { t } = useI18n();
    return v.length >= length || t('validationRules.minLength__text', { length });
  };

export const validEmail = (v: string): ruleValidationResult => {
  const { t } = useI18n();
  return /.+@.+\..+/.test(v) || t('validationRules.emailMustBeValid__text');
};

export const isPriceField = (v: string): ruleValidationResult => {
  const { t } = useI18n();
  return isPriceValid(v) || t('validationRules.priceMustBeValid__text');
};

export const isGreater = (min: number) =>
  (v: string | number): ruleValidationResult => {
    const { t } = useI18n();
    if (typeof v !== 'number') return true;
    return v > min || t('validationRules.numberGreater__text', { min });
  };

export const isLower = (max: number) =>
  (v: string | number): ruleValidationResult => {
    const { t } = useI18n();
    if (typeof v !== 'number') return true;
    return v < max || t('validationRules.numberLower__text', { max });
  };

export const isWeightField = (v: string): ruleValidationResult => {
  const { t } = useI18n();
  if (v === '') return true;
  return isWeightValid(v) || t('validationRules.weightMustBeValid__text');
};

export const maxLength = (length: number) =>
  (v: string): ruleValidationResult => {
    const { t } = useI18n();
    return v.length <= length || t('validationRules.maxCharacters__text', { length });
  };

export const maxLengthEmail = (v: string) => {
  const { t } = useI18n();
  const [ mailUserName = '', mailServer = '' ] = v.split('@');
  if (mailUserName.length >= 64) return t('validationRules.tooLongEmailUsername__text');
  if (mailServer.length >= 255) return t('validationRules.tooLongEmailServer__text');
  return true;
};

export const isPasswordStrong = (v: string) => {
  const { t } = useI18n();
  const has8Characters = v.length >= 8;
  const hasNumber = /\d/.test(v);
  const hasLowercase = /[a-z]/.test(v);
  const hasUppercase = /[A-Z]/.test(v);
  const hasSpecial = /[!@#$%^&*)(+=._-]/.test(v);
  const isPasswordStrong = has8Characters
    && hasNumber
    && hasLowercase
    && hasUppercase
    && hasSpecial;
  return isPasswordStrong || t('validationRules.passwordNotStrong__text');
};
