import { ref } from 'vue';
import { primaryViolet } from '@/constants/colors';

const useThemeSelectorMobile = (props, { emit }) => {
  const selectedThemeId = ref(props.currentThemeId);

  const selectThemeId = (id: number): void => {
    selectedThemeId.value = id;
  };

  const updateCurrentThemeId = (): void => {
    emit('setCurrentThemeId', selectedThemeId.value);
    emit('close');
  };

  return {
    primaryViolet,
    selectedThemeId,
    selectThemeId,
    updateCurrentThemeId,
  };
};

export default useThemeSelectorMobile;
