import { computed, onMounted, ref } from 'vue';
import router from '@/router';
import store from '@/store';
import {
  GET_CURRENT_DELIVERY_PROFILE, GET_DELIVERY_PROFILE, GET_SHIPPING_REGIONS,
} from '@/store/Shipping/constants';
import { ShippingZone } from '@/store/Shipping/types';

const useDeliveryProfile = () => {
  const isModalOpen = ref(false);
  const zoneToEdit = ref();
  const deliveryProfileId = +router.currentRoute.params.id; 
  const deliveryProfile = computed(() =>
    store.getters[GET_CURRENT_DELIVERY_PROFILE]);
  
  const zones = computed(() => deliveryProfile.value?.zones);

  const setZoneToEdit = (zone: ShippingZone | null) => {
    zoneToEdit.value = zone;
    isModalOpen.value = true;
  };

  const loadDeliveryProfile = () => {
    store.dispatch(GET_DELIVERY_PROFILE, deliveryProfileId);
    store.dispatch(GET_SHIPPING_REGIONS);
  };

  onMounted(loadDeliveryProfile);
  
  return {
    deliveryProfile,
    isModalOpen,
    setZoneToEdit,
    zones,
    zoneToEdit,
  };
};

export default useDeliveryProfile;
