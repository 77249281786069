import { computed, ref } from 'vue';
import profileImg from '@/assets/images/test-profile-img.png';
import { black54 } from '@/constants/colors';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import { isTabletAndLower } from '@/utils/Mobile';

const useProfileCard = () => {
  const isModalOpen = ref(false);
  const email = computed((): string => store.getters[GET_USER].email);
  const firstName = computed((): string =>
    store.getters[GET_USER].forename);
  const lastName = computed((): string =>
    store.getters[GET_USER].surname);

  const toggleMenu = (): void => {
    isModalOpen.value = !isModalOpen.value;
  };

  return {
    black54,
    email,
    firstName,
    isModalOpen,
    isTabletAndLower,
    lastName,
    profileImg,
    toggleMenu,
  };
};

export default useProfileCard;
