<template>
  <v-data-table
    class="table"
    :headers="headers"
    :loading="isLoading"
    :items-per-page="itemsPerPage"
    :items="items"
    hide-default-header
    hide-default-footer
    disable-sort
    disable-filtering
  >
    <template #header="{ props: { headers: tableHeaders } }">
      <thead class="table__header">
        <th
          v-for="(header, index) in tableHeaders"
          :key="index"
          class="table__header-cell"
          :width="header.width"
        >
          <span>
            {{ header.title }}
          </span>
        </th>
      </thead>
    </template>
    <template #item="{ item }">
      <tr
        :key="item.id"
        class="table__row"
        @click="$emit('onRowClick', item)"
      >
        <slot
          name="content"
          :item="item"
        />
      </tr>
    </template>

    <template #footer>
      <v-pagination
        v-if="pageCount"
        class="table__pagination"
        :value="page"
        :length="pageCount"
        :color="secondaryViolet"
        :total-visible="paginationPageCount"
        circle
        @input="$emit('update:page', $event)"
      />
    </template>
  </v-data-table>
</template>
<script lang="ts">
import { black54, black60, secondaryViolet } from '@/constants/colors';

export default {
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    pageCount: {
      type: Number,
    },
    page: {
      type: Number,
    },
    paginationPageCount: {
      type: Number,
      default: 7,
    },
  },
  setup() {
    return {
      secondaryViolet,
      black54,
      black60,
    };
  },
};
</script>
<style lang="scss" scoped>
 @import './BaseTable.scss'
</style>
