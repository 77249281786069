import { isEqual } from 'lodash';
import { computed, DefineComponent, ref, watch } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { useI18n } from 'vue-i18n-composable';
import { profileModalMobileTabsHeight, profileModalTabsHeight } from '@/constants';
import { primaryViolet, secondaryViolet } from '@/constants/colors';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';
import {
  isPasswordStrong,
  maxLength,
  requiredField,
} from '@/utils/FieldValidationRules';
import { isTabletAndLower } from '@/utils/Mobile';

const nameRules = [ requiredField, maxLength(40) ];
const passwordRules = [ isPasswordStrong, maxLength(127) ];

const useProfileCardModal = () => {
  const { t } = useI18n();
  const user = computed(() => store.getters[GET_USER]);
  const email = computed(() => user.value.email);
  const forename = computed(() => user.value.forename);
  const surname = computed(() => user.value.surname);

  const newEmail = ref(email.value);
  const newFirstName = ref(forename.value);
  const newLastName = ref(surname.value);
  const passwordsForm = ref();
  const password = ref('');
  const repeatPassword = ref('');
  const isValidProfileInfoForm = ref(false);
  const isValidPasswordsForm = ref(false);

  const isValuesChanged = computed((): boolean =>
    !isEqual(
      [ newEmail.value, newFirstName.value, newLastName.value ],
      [ email.value, forename.value, surname.value ],
    ));

  const passwordConfirmation = (v: string): boolean | TranslateResult => {
    return (!!v && v) === password.value
    || t('validationRules.passwordsDoNotMatch__text');
  };

  const repeatPasswordRules = [ isPasswordStrong, passwordConfirmation ];    
    
  const tabs = [
    {
      index: 0,
      title: t('profile.modal-tabs__personal_info'),
    },
    {
      index: 1,
      title: t('profile.modal-tabs__password'),
    },
  ];
  const currentTab = ref(0);

  const updateCredentials = (): void => {
    store.dispatch('UPDATE_CREDENTIALS', {
      email: email.value,
      newEmail: newEmail.value,
      newFirstName: newFirstName.value,
      newLastName: newLastName.value,
    });       
  };

  const updatePassword = async (): Promise<void> => {
    store.dispatch('UPDATE_PASSWORD', {
      email: email.value,
      password: password.value,
    });
  };

  watch([ password, repeatPassword ], () => {
    passwordsForm.value.inputs.forEach((element: DefineComponent): void => {
      if (element.$options.propsData.rules.includes(passwordConfirmation)) {
        element.validate();
      }
    });
  });

  return {
    currentTab,
    isTabletAndLower,
    isValidPasswordsForm,
    isValidProfileInfoForm,
    isValuesChanged,
    nameRules,
    newEmail,
    newFirstName,
    newLastName,
    password,
    passwordRules,
    primaryViolet,
    profileModalMobileTabsHeight,
    profileModalTabsHeight,
    repeatPassword,
    repeatPasswordRules,
    secondaryViolet,
    passwordsForm,
    tabs,
    updateCredentials,
    updatePassword,
  };
};

export default useProfileCardModal;
