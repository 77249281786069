<template>
  <div>
    <v-menu
      :value="isPickerActive"
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <template #activator="{attrs}">
        <div
          v-bind="attrs"
          class="color-picker"
          @click.self="$emit('togglePicker')"
        >
          <div
            class="color-demo"
            :style="{ 'background-color': color || 'none' }"
            @click.self="$emit('togglePicker')"
          />
          <img
            src="@/assets/icons/arrow.svg"
            :alt="$t('redactor.toggleColorPicker_alt')"
            :class="['arrow-icon', {'color-picker-active': isPickerActive }]"
            @click.self="$emit('togglePicker')"
          >
        </div>
      </template>
      <div
        v-if="isPickerActive"
        v-click-outside="() => $emit('closePicker')"
        class="color-picker-menu"
      >
        <v-color-picker
          :value="color"
          class="color-input"
          dot-size="20"
          swatches-max-height="250"
          elevation="10"
          @input="$emit('applyColor', $event)"
        />
        <ColorPickerPipette
          @onActivate="$emit('onPipetteActivate')"
          @onDeactivate="$emit('onPipetteDeactivate')"
          @applyColor="$emit('applyColor', $event)"
        />
      </div>
    </v-menu>
    <v-overlay
      :value="isOverlayActive"
      z-index="1"
    />
  </div>
</template>

<script lang="ts">
import ColorPickerPipette from '../ColorPickerPipette/ColorPickerPipette.vue';

export default {
  components: {
    ColorPickerPipette,
  },
  props: {
    color: {
      required: true,
      type: String,
    },
    isPickerActive: {
      required: true,
      type: Boolean,
    },
    isOverlayActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "ColorPickerDesktop";
</style>
