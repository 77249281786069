<template>
  <div class="font-size">
    <span>
      {{ $t("redactor.fontSize__label") }}
    </span>
    <v-combobox
      :value="fontSize"
      :items="DEFAULT_FONT_SIZES"
      class="font-size__input"
      solo
      type="number"
      @change="$emit('changeTextFontSize', $event)"
    />
  </div>
</template>

<script lang="ts">
import { DEFAULT_FONT_SIZES } from '@/components/Editor/constants/defaultConfigs';

export default {
  props: {
    fontSize: {
      required: true,
      type: Number,
    },
  },
  setup() {
    return { DEFAULT_FONT_SIZES };
  },
};
</script>

<style lang="scss" scoped>
@import "TextFontSizeDesktop";
</style>
