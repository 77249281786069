<template>
  <div class="template-design__card">
    <v-hover v-slot="{ hover }">
      <div class="template-design__card-template">
        <img
          class="template-design__card-image"
          :src="templateDesign.img"
          :alt="$t('templates.templateDesign__alt')"
        >
        <BaseButton
          v-if="hover"
          :disabled="isApplyingDesign"
          class="template-design__card-apply"
          color="transparent"
          height="56"
          width="56"
          :class="{'disabled': isApplyingDesign}"
          no-margin-top
          has-background
          @click="$emit('applyDesign', templateDesign)"
        >
          <template #prependSvg>
            <v-icon class="template-design__card-apply-icon">
              mdi-plus
            </v-icon>
          </template>
        </BaseButton>

        <BaseButton
          v-if="hover"
          class="template-design__card-remove"
          color="transparent"
          height="20"
          width="20"
          no-padding
          no-margin-top
          has-background
          :ripple="false"
          @click="$emit('removeDesign', templateDesign.id)"
        >
          <template #prependSvg>
            <BaseIcon class="template-design__card-remove-icon">
              <TrashcanIcon />
            </BaseIcon>
          </template>
        </BaseButton>
      </div>
    </v-hover>

    <v-form
      v-if="isActive"
      v-model="isValidName"
      class="template-design__card-name"
    >
      <TextField
        v-model.trim="name"
        class="edit-field"
        input-type="text"
        :color="primaryViolet"
        :outlined="false"
        :rules="nameRules"
        hide-details
        @keydown.enter.prevent="
          $emit('editDesign', {
            id: templateDesign.id,
            name: name,
          })
        "
      />
      <BaseButton
        icon="mdi-check"
        :color="primaryViolet"
        :disabled="!isValidName"
        light
        apply-color-to-text
        no-margin-top
        @click="
          $emit('editDesign', {
            id: templateDesign.id,
            name,
          })
        "
      />
    </v-form>

    <div
      v-else
      class="template-design__card-name"
    >
      <div>
        {{ templateDesign.name }}
      </div>
      <BaseButton
        light
        icon="mdi-pencil"
        no-margin-top
        :color="black50"
        @click="openEditMode"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import { TemplateDesign } from '@/store/Editor/types';
import { useTemplateDesignCard } from './TemplateDesignCard';
export default {
  components: { BaseButton, TrashcanIcon, BaseIcon, TextField },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    templateDesign: {
      type: Object as PropType<TemplateDesign>,
      required: true,
    },
  },
  setup(props, context) {
    return useTemplateDesignCard(props, context);
  },
};
</script>
<style lang="scss" scoped>
  @import './TemplateDesignCard';
</style>
