import axios from 'axios';

import router from '@/router/index';
import { lang } from '@/types/localization';

export const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  withCredentials: true,
});

const setupConfig = config => {
  // Browser throw error when trying to get localStorage in Iframe in incognito 
  try {
    const localization = router.app.$store.getters.GET_LOCALIZATION
      || lang.en;
    config.headers.Localization = localization;
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  } catch (error) {
    return config;
  }
};

instance.interceptors.request.use(setupConfig);

// remove when all the services on front will be rewritten on instances
// #1202509130635771
axios.interceptors.request.use(setupConfig);
