<template>
  <div class="templates-list">
    <div class="templates-list__grid-wrapper">
      <TemplateCard
        v-for="template in templates"
        :key="template.imagePath
          ? generateTemplateKey(template)
          : template.id"
        :template="template"
        :is-editing-rights="isEditingRights"
        :collection="collection"
        @openAddToMyCollectionsModal="openAddToMyCollectionsModal"
        @openEditDetailsWindow="openEditDetailsWindow"
        @openConfirmRemoveModal="openConfirmRemoveModal"
      />
    </div>
    <AddToMyCollectionsModal 
      v-if="isAddToMyCollectionModalOpen" 
      :is-open="isAddToMyCollectionModalOpen"
      :template="selectedTemplate"
    />
    <EditDetailsWindow
      v-if="isEditDetailsWindowOpen"
      :is-open="isEditDetailsWindowOpen"
      :template-id="selectedTemplate.id"
      @close="isEditDetailsWindowOpen = false"
    />
    <ConfirmRemoveTemplateModal
      v-if="isConfirmRemoveModalOpen"
      :is-open="isConfirmRemoveModalOpen"
      :template="selectedTemplate"
      @triggerInitTemplates="$emit('triggerInitTemplates')"
      @close="closeConfirmRemoveModal"
    />
  </div>
</template>

<script lang="ts">
import AddToMyCollectionsModal from '@/components/AddToMyCollectionsModal/AddToMyCollectionsModal.vue';
import EditDetailsWindow from '@/components/EditDetailsWindow/EditDetailsWindow.vue';
import ConfirmRemoveTemplateModal from './ConfirmRemoveTemplateModal/ConfirmRemoveTemplateModal.vue';
import TemplateCard from './TemplateCard/TemplateCard.vue';
import useTemplatesList from './TemplatesList';

export default {
  components: {
    AddToMyCollectionsModal,
    ConfirmRemoveTemplateModal,
    EditDetailsWindow,
    TemplateCard,
  },
  props: {
    collection: {
      required: true,
      type: Object,
    },
    isEditingRights: {
      required: false,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    templates: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useTemplatesList();
  },
};
</script>

<style lang="scss" scoped>
@import "TemplatesList";
</style>
