<template>
  <div
    :class="['base-button', { 'no-margin-top': noMarginTop }]" 
    @click.stop.prevent
  >
    <v-btn
      :width="width"
      :height="height"
      :light="light"
      :dark="dark"
      :icon="!!icon"
      :text="applyColorToText"
      :type="type"
      :color="color"
      :disabled="disabled"
      :style="defaultStyle"
      :large="large"
      :fab="fab"
      :loading="isLoading"
      :tile="tile"
      :plain="plain"
      :outlined="outlined"
      :depressed="hasBackground"
      :ripple="ripple"
      v-bind="vActivatorProps"
      block
      rounded
      v-on="vActivatorEmits"
      @click.stop.prevent="$emit('click', $event)"
    >
      <slot name="prependSvg" />
      <v-icon v-if="prependIcon">
        {{ prependIcon }}
      </v-icon>
      <span class="base-button__text">
        {{ buttonText }}
      </span>
      <v-icon
        v-if="icon"
        :size="iconSize"
      >
        {{ icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import useBaseButton from '@/components/Common/BaseButton/BaseButton';
import { black87 } from '@/constants/colors';

export default {
  props: {
    applyColorToText: {
      required: false,
      type: Boolean,
    },
    buttonText: {
      required: false,
      type: String,
    },
    color: {
      default: black87,
      required: false,
      type: String,
    },
    dark: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    fab: {
      required: false,
      type: Boolean,
    },
    hasBackground: {
      default: false,
      required: false,
      type: Boolean,
    },
    height: {
      required: false,
      type: [ String, Number ],
    },
    icon: {
      required: false,
      type: String,
    },
    iconSize: {
      required: false,
      type: [ Number, String ],
    },
    isLoading: {
      required: false,
      type: Boolean,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    large: {
      required: false,
      type: Boolean,
    },
    light: {
      required: false,
      type: Boolean,
    },
    noMarginTop: {
      required: false,
      type: Boolean,
    },
    outlined: {
      required: false,
      type: Boolean,
    },
    plain: {
      required: false,
      type: Boolean,
    },
    prependIcon: {
      required: false,
      type: String,
    },
    svgIcon: {
      required: false,
      type: Boolean,
    },
    tile: {
      required: false,
      type: Boolean,
    },
    type: {
      default: 'button',
      required: false,
      type: String,
    },
    vActivatorEmits: {
      type: Object,
    },
    vActivatorProps: {
      type: Object,
    },
    width: {
      required: false,
      type: [ String, Number ],
    },
  },
  setup(props) {
    return useBaseButton(props);
  },
};
</script>

<style lang="scss" scoped>
  @import 'BaseButton';
</style>
