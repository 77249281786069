/* eslint-disable max-lines */
import lodash from 'lodash';
import {
  updateObjectsDataFromSelection,
} from '@/components/Editor/helpers/fabric/activeObjects/updateSelectedObjectsData';
import {
  isFabricActiveSelection,
  isFabricImage,
  isFabricText,
} from '@/components/Editor/helpers/fabric/objectModifiers/checkObjectType';
import {
  getDefaultImageObject,
  getDefaultTextObject,
} from '@/components/Editor/helpers/fabric/objectModifiers/getDefaultObjects';
import { HEXColor } from '@/components/Editor/types/editor';
import {
  _Object,
  FabricObject,
  FabricObjectProps,
} from '@/components/Editor/types/fabric';
import {
  CLEAR_CANVAS_HISTORY,
  CLEAR_CANVAS_STATE,
  CLEAR_LAST_UNDO,
  CLEAR_REDO,
  CLEAR_UNDO,
  DELETE_OBJECT,
  DISABLE_UNDO_REDO,
  REDO_CANVAS_STATE,
  SET_ACTIVE_OBJECT,
  SET_BACKGROUND_COLOR,
  SET_DEFAULT_IMAGE_OBJECT,
  SET_DEFAULT_TEXT_OBJECT,
  SET_FABRIC_OBJECTS,
  SET_IMAGE_OBJECT,
  SET_IS_REDO_DISABLED,
  SET_IS_UNDO_DISABLED,
  SET_REDO,
  SET_TEXT_OBJECT,
  SET_UNDO,
  UNDO_CANVAS_STATE,
  UPDATE_ACTIVE_OBJECT,
  UPDATE_FABRIC_OBJECTS,
} from '../constants';
import {
  getDefaultCanvasHistoryState,
  getDefaultCanvasState,
} from '../helpers';
import { CanvasState, EditorState } from '../types';

export const canvasHistoryMutations = {
  [CLEAR_CANVAS_HISTORY]: (state: EditorState): void => {
    state.canvasHistory = Object.assign({}, getDefaultCanvasHistoryState());
  },
  [CLEAR_REDO]: (state: EditorState): void => {
    state.canvasHistory.redo = [];
  },
  [CLEAR_UNDO]: (state: EditorState): void => {
    state.canvasHistory.undo = [];
  },
  [DISABLE_UNDO_REDO]: (state: EditorState): void => {
    state.canvasHistory.isRedoDisabled = true;
    state.canvasHistory.isUndoDisabled = true;
  },
  [REDO_CANVAS_STATE]: (state: EditorState): void => {
    state.canvasHistory.undo.push({ ...lodash.cloneDeep(state.canvasState) });
    state.canvasState = { ...state.canvasHistory.redo.pop()! };
  },
  [SET_IS_REDO_DISABLED]: (state: EditorState, flag: boolean): void => {
    state.canvasHistory.isRedoDisabled = flag;
  },
  [SET_IS_UNDO_DISABLED]: (state: EditorState, flag: boolean): void => {
    state.canvasHistory.isUndoDisabled = flag;
  },
  [SET_REDO]: (state: EditorState, redoState: CanvasState): void => {
    state.canvasHistory.redo.push({ ...lodash.cloneDeep(redoState) });
  },
  [SET_UNDO]: (state: EditorState, undoState: CanvasState): void => {
    state.canvasHistory.undo.push({ ...lodash.cloneDeep(undoState) });
  },
  [UNDO_CANVAS_STATE]: (state: EditorState): void => {
    state.canvasHistory.redo.push({ ...lodash.cloneDeep(state.canvasState) });
    state.canvasState = { ...state.canvasHistory.undo.pop()! };
  },
  [UPDATE_FABRIC_OBJECTS]: (
    state: EditorState,
    object?: FabricObject,
  ): void => {
    const objectToUpdate = object
      || lodash.cloneDeep(state.canvasState.activeObject);
    const fabricObjects = lodash.cloneDeep(state.canvasState.fabricObjects);
    if (objectToUpdate) {
      switch (true) {
      case isFabricImage(objectToUpdate):
      case isFabricText(objectToUpdate):
        fabricObjects.forEach((object: FabricObject, index: number): void => {
          fabricObjects[index] = (object.id === objectToUpdate.id)
            ? objectToUpdate
            : object;
        });
        break;
      case isFabricActiveSelection(objectToUpdate): {
        updateObjectsDataFromSelection(fabricObjects, objectToUpdate);
        break;
      }
      default: break;
      }
    }
    state.canvasState.fabricObjects = fabricObjects;
  },
  [UPDATE_ACTIVE_OBJECT]: (
    state: EditorState,
    changes: FabricObjectProps,
  ): void => {
    state.canvasState.activeObject = {
      ...lodash.cloneDeep(state.canvasState.activeObject),
      ...changes,
    } as FabricObject;
  },
  [SET_TEXT_OBJECT]: (state: EditorState, fabricText: FabricObject): void => {
    const fabricObjects = state.canvasState.fabricObjects
      .map((object: FabricObject): FabricObject => {
        return object.id === fabricText.id
          ? fabricText
          : object;
      });
    state.canvasState.fabricObjects = fabricObjects;
  },
  [SET_FABRIC_OBJECTS]: (state: EditorState, objects: FabricObject[]): void => {
    state.canvasState.fabricObjects = objects;
  },
  [SET_IMAGE_OBJECT]: (state: EditorState, fabricImage: FabricObject): void => {
    const fabricObjects = state.canvasState.fabricObjects
      .map((object: FabricObject): FabricObject => {
        return object.id === fabricImage.id
          ? fabricImage
          : object;
      });
    state.canvasState.fabricObjects = fabricObjects;
  },
  [SET_BACKGROUND_COLOR]: (state: EditorState, color: HEXColor): void => {
    state.canvasState.backgroundColor = color;
  },
  [SET_DEFAULT_IMAGE_OBJECT]: (state: EditorState, file: File): void => {
    const fabricObjects = [...state.canvasState.fabricObjects];
    fabricObjects.push(getDefaultImageObject(file));
    state.canvasState.fabricObjects = fabricObjects;
  },
  [SET_DEFAULT_TEXT_OBJECT]: (state: EditorState): void => {
    const fabricObjects = [...state.canvasState.fabricObjects];
    fabricObjects.push(getDefaultTextObject());
    state.canvasState.fabricObjects = fabricObjects;
  },
  [SET_ACTIVE_OBJECT]: (state: EditorState, object: FabricObject): void => {
    state.canvasState.activeObject = lodash.cloneDeep(object);
  },
  [DELETE_OBJECT]: (state: EditorState, deletedObject: FabricObject): void => {
    let fabricObjects = [...state.canvasState.fabricObjects];
    switch (true) {
    case isFabricImage(deletedObject):
    case isFabricText(deletedObject):
      fabricObjects = state.canvasState.fabricObjects
        .filter((object: FabricObject): boolean => {
          return object.id !== deletedObject.id;
        });
      break;
    case isFabricActiveSelection(deletedObject):
      deletedObject._objects?.forEach((selectedObject: _Object): void => {
        lodash.remove(fabricObjects, (objectInStore: FabricObject): boolean => {
          return objectInStore.id === selectedObject.id;
        });
      });
      break;
    default: break;
    }
    state.canvasState.fabricObjects = fabricObjects;
  },
  [CLEAR_CANVAS_STATE]: (state: EditorState): void => {
    state.canvasState = Object.assign({}, getDefaultCanvasState());
    state.editorConfigs.isImageSettingsActive = false;
    state.editorConfigs.isTextSettingsActive = false;
  },
  [CLEAR_LAST_UNDO]: (state: EditorState): void => {
    state.canvasHistory.undo.pop();
    if (!state.canvasHistory.undo.length) {
      state.canvasHistory.isUndoDisabled = true;
    }
  },
};
