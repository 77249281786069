import { fabric } from 'fabric';
import {
  isObjectLocked,
  setObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import {
  FabricControl,
  FabricObject,
  FabricTransform,
} from '@/components/Editor/types/fabric';
import { actualCanvasIconSize, drawLockIcon } from './renderIcons';

export const getLockControl = (): FabricControl => {
  const canvasIconSize = actualCanvasIconSize();

  return new fabric.Control({
    cursorStyle: 'pointer',
    mouseDownHandler: (_, { target }: FabricTransform): boolean => {
      const isLocked = isObjectLocked(target as FabricObject);
      setObjectLocked({
        isLocked: !isLocked,
        object: target as FabricObject,
        shouldSaveInStore: true,
        isLockChildren: true,
      });

      target.canvas?.requestRenderAll();
      return true;
    },
    offsetY: canvasIconSize,
    render: drawLockIcon,
    sizeX: canvasIconSize,
    sizeY: canvasIconSize,
    x: 0,
    y: 0.5,
  });
};
