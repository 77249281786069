import { Group, Mesh, Object3D, Scene } from 'three';

export const removeMeshProperly = (
  object: Mesh | Group | Scene,
  scene: Scene,
): void => {
  if (!object.children) return;
  object.children.forEach((child: Object3D | null): void => {
    if (child) scene.remove(child);
    if (child instanceof Mesh) {
      child.geometry.dispose();
      child.material.dispose();
      child.geometry = null;
      child.material = null;
      child = null;
    }
    if (child instanceof Group)
      removeMeshProperly(child, scene);
  });
  object.children.length = 0;
};
