<template>
  <div
    ref="sceneControls"
    class="scene-controls"
    :class="{'scene-controls_mobile': isMobile}"
  >
    <v-tooltip
      :open-delay="2000"
      right
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <BaseButton
            v-if="!isMobile"
            color="transparent"
            class="scene-controls__hand"
            :class="{'scene-controls__hand_active': isSideSelectionEnabled}"
            :width="40"
            :height="40"
            no-margin-top
            has-background
            @click="toggleSideSelection(true)"
          >
            <template #prependSvg>
              <BaseIcon
                :color="isSideSelectionEnabled ? primaryViolet : '#000000'"
                :size="40"
              >
                <PointerIcon />
              </BaseIcon>
            </template>
          </BaseButton>
        </div>
      </template>
      {{ $t("redactor.pointerCursorTooltip__text") }}
    </v-tooltip>
    <v-tooltip
      :open-delay="2000"
      right
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <BaseButton
            v-if="!isMobile"
            color="transparent"
            class="scene-controls__pointer"
            :class="{'scene-controls__pointer_active': !isSideSelectionEnabled}"
            :width="40"
            :height="40"
            no-margin-top
            has-background
            @click="toggleSideSelection(false)"
          >
            <template #prependSvg>
              <BaseIcon
                :color="isSideSelectionEnabled ? '#000000' : primaryViolet"
                :size="40"
              >
                <HandIcon />
              </BaseIcon>
            </template>
          </BaseButton>
        </div>
      </template>
      {{ $t("redactor.handCursorTooltip__text") }}
    </v-tooltip>
    <BaseButton
      v-if="isMobile && !isMeshSelectionRemoved && !isEditorActive"
      rounded
      large
      color="transparent"
      class="scene-controls__deselect"
      no-margin-top
      has-background
      :apply-color-to-text="true"
      :button-text="$t('redactor.deselect__button')"
      icon="mdi-close"
      @click="removeMeshSelection"
    />
  </div>
</template>
    
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import HandIcon from '@/components/icons/HandIcon.vue';
import PointerIcon from '@/components/icons/PointerIcon.vue';
import { useSceneControls } from './SceneControls';

export default {
  components: {
    HandIcon,
    BaseButton,
    PointerIcon,
    BaseIcon,
  },
  props: {
    isSideSelectionEnabled: {
      default: false,
      type: Boolean,
    },
    isMeshSelectionRemoved: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    return useSceneControls();
  },
};
</script>

<style lang="scss" scoped>
@import "SceneControls";
</style>
