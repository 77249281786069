import {
  Box3, Vector3,
} from 'three';
import {
  CAMERA_FRUSTUM_NEAR_PLANE,
  CONTROLS_MAX_ZOOM_MULTIPLIER,
  CONTROLS_MIN_ZOOM_MULTIPLIER,
  desktopCameraOffset,
  MAX_CAMERA_OFFSET_FACTOR_MOBILE,
  mobileCameraOffset,
} from '@/constants/editor';
import { GET_IS_MOBILE } from '@/store/Editor/constants';
import store from '@/store/index';

export const fitCameraToObject = (
  // camera: PerspectiveCamera,
  // object: Group,
  // offset: number | null,
  // controls: OrbitControls
  camera,
  object,
  offset, 
  controls,
) => {
  const isMobile = store.getters[GET_IS_MOBILE];
  offset = isMobile ? mobileCameraOffset : desktopCameraOffset;

  const boundingBox = new Box3();

  // get bounding box of object - this will be used to setup controls and camera
  boundingBox.setFromObject(object);

  const center = new Vector3();
  const size = new Vector3();

  boundingBox.getCenter(center);
  boundingBox.getSize(size);

  // get the max side of the bounding box (fits to width OR height as needed )
  const maxDimension = Math.max(size.x, size.y, size.z);
  const fov = camera.fov * (Math.PI / 180);
  let cameraZ = Math.abs((maxDimension / 4) * Math.tan(fov * 2));
  if (isMobile) {
    const aspectRatio = size.x / size.y;
    offset *= Math.min(
      MAX_CAMERA_OFFSET_FACTOR_MOBILE * aspectRatio,
      MAX_CAMERA_OFFSET_FACTOR_MOBILE,
    );
  } 
  cameraZ *= offset; // zoom out a little so that objects don't fill the screen
  camera.position.z = cameraZ;
  const minZ = boundingBox.min.z;
  const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

  camera.far = cameraToFarEdge * 30;
  camera.near = CAMERA_FRUSTUM_NEAR_PLANE;

  if (controls) {
    // set camera to rotate around center of loaded object
    controls.target = center;

    // prevent camera from zooming out far enough to create far plane cutoff
    controls.maxDistance = cameraToFarEdge * CONTROLS_MAX_ZOOM_MULTIPLIER;
    controls.minDistance = cameraToFarEdge * CONTROLS_MIN_ZOOM_MULTIPLIER;

    controls.saveState();
    controls.update();
  } else {
    camera.lookAt(center);
  }
};
