import store from '@/store';
import { GET_IS_MOBILE } from '@/store/Editor/constants';

export const resizeRendererToDisplaySize = renderer => {
  const canvas = renderer.domElement;
  const isMobile = store.getters[GET_IS_MOBILE];
  const width = isMobile ? window.outerWidth : window.innerWidth;
  const height = isMobile ? window.outerHeight : window.innerHeight;
  const canvasPixelWidth = canvas.width / window.devicePixelRatio;
  const canvasPixelHeight = canvas.height / window.devicePixelRatio;

  const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height;
  if (needResize) {
    renderer.setSize(width, height);
  }
  return needResize;
};
