import {
  AlignCoordinates,
  AlignDirections,
} from '@/components/Editor/types/editor';
import store from '@/store';
import {
  GET_ACTIVE_CANVAS,
  GET_CANVAS_ZOOM,
  GET_ZOOM_TO_DEFAULT,
} from '@/store/Editor/constants';
import { roundToFixed } from '@/utils/math/roundToFixed';

export const getAlignCoordinates = (
  alignment: AlignDirections,
): AlignCoordinates => {
  const canvas = store.getters[GET_ACTIVE_CANVAS];
  const zoomToDefault = store.getters[GET_ZOOM_TO_DEFAULT];
  const canvasZoom = store.getters[GET_CANVAS_ZOOM];
  const zoom = canvasZoom * zoomToDefault;
  const activeObject = canvas.getActiveObject();
  const canvasHeight = canvas.getHeight() / zoomToDefault;
  const canvasWidth = canvas.getWidth() / zoomToDefault;

  const { height, width } = activeObject.getBoundingRect();
  const objectHeight = roundToFixed(height / zoom, 2);
  const objectWidth = roundToFixed(width / zoom, 2);

  const result = {
    left: activeObject.left,
    top: activeObject.top,
  };

  switch (alignment) {
  case AlignDirections.left:
    result.left = objectWidth / 2;
    break;
  case AlignDirections.right:
    result.left = canvasWidth - objectWidth / 2;
    break;
  case AlignDirections.top:
    result.top = objectHeight / 2;
    break;
  case AlignDirections.bottom:
    result.top = canvasHeight - objectHeight / 2;
    break;
  case AlignDirections.horizontalCenter:
    result.left = canvasWidth / 2;
    break;
  case AlignDirections.verticalCenter:
    result.top = canvasHeight / 2;
    break;
  default: break;
  }

  return result;
};
