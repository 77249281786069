import { computed } from 'vue';
import { headerTabsHeight } from '@/constants';
import { black87, secondaryMagenta } from '@/constants/colors';
import store from '@/store';
import { GET_IS_SHOW_SEARCH_FIELD, SET_IS_SHOW_SEARCH_FIELD } from '@/store/constants';

const useHeaderDesktop = () => {
  const isShowSearch = computed((): boolean =>
    store.getters[GET_IS_SHOW_SEARCH_FIELD]);

  const closeSearch = (): void =>
    store.commit(SET_IS_SHOW_SEARCH_FIELD, false);
  const openSearch = (): void =>
    store.commit(SET_IS_SHOW_SEARCH_FIELD, true);
  
  return {
    black87,
    closeSearch,
    headerTabsHeight,
    isShowSearch,
    openSearch,
    secondaryMagenta,
  };
};

export default useHeaderDesktop;
