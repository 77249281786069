export const GET_DELIVERY_PROFILES = 'GET_DELIVERY_PROFILES';
export const SET_DELIVERY_PROFILES = 'SET_DELIVERY_PROFILES';
export const LOAD_DELIVERY_PROFILES = 'LOAD_DELIVERY_PROFILES';
export const LOAD_ALL_DELIVERY_PROFILES = 'LOAD_ALL_DELIVERY_PROFILES';
export const GET_TABLE_CURRENT_PAGE = 'GET_TABLE_CURRENT_PAGE';
export const SET_TABLE_CURRENT_PAGE = 'SET_TABLE_CURRENT_PAGE';
export const GET_TABLE_AMOUNT_OF_PAGES = 'GET_TABLE_AMOUNT_OF_PAGES';
export const SET_TABLE_AMOUNT_OF_PAGES = 'SET_TABLE_AMOUNT_OF_PAGES';
export const REMOVE_DELIVERY_PROFILE = 'REMOVE_DELIVERY_PROFILE';
export const CREATE_DELIVERY_PROFILE = 'CREATE_DELIVERY_PROFILE';
export const ADD_DELIVERY_PROFILE = 'ADD_DELIVERY_PROFILE';
export const SET_CURRENT_DELIVERY_PROFILE = 'SET_CURRENT_DELIVERY_PROFILE';
export const GET_DELIVERY_PROFILE = 'GET_DELIVERY_PROFILE';
export const GET_CURRENT_DELIVERY_PROFILE = 'GET_CURRENT_DELIVERY_PROFILE';
export const EDIT_DELIVERY_PROFILE_NAME = 'EDIT_DELIVERY_PROFILE_NAME';
export const SET_DELIVERY_PROFILE_NAME = 'SET_DELIVERY_PROFILE_NAME';
export const GET_SHIPPING_REGIONS = 'GET_SHIPPING_REGIONS';
export const SET_SHIPPING_REGIONS = 'SET_SHIPPING_REGIONS';
export const ADD_DELIVERY_ZONE='ADD_DELIVERY_ZONE';
export const UPDATE_DELIVERY_ZONE='UPDATE_DELIVERY_ZONE';
export const REMOVE_DELIVERY_ZONE='REMOVE_DELIVERY_ZONE';
export const ADD_DELIVERY_ZONE_LOCAL_RATE = 'ADD_DELIVERY_ZONE_LOCAL_RATE';
export const REMOVE_DELIVERY_ZONE_LOCAL_RATE='REMOVE_DELIVERY_ZONE_LOCAL_RATE';
export const ADD_DELIVERY_ZONE_RATE = 'ADD_DELIVERY_ZONE_RATE';
export const UPDATE_DELIVERY_ZONE_RATE = 'UPDATE_DELIVERY_ZONE_RATE';
export const REMOVE_DELIVERY_ZONE_RATE = 'REMOVE_DELIVERY_ZONE_RATE';
export const GET_DELIVERY_LOADING='GET_DELIVERY_LOADING';
export const SET_DELIVERY_LOADING='SET_DELIVERY_LOADING';
export const DELIVERY_PROFILE_SHOPIFY_UPDATE='DELIVERY_PROFILE_SHOPIFY_UPDATE';
