<template>
  <ModalWindow
    :is-open="isOpen"
    override-with-class="support-modal__wrapper"
    @close="$emit('close')"
  >
    <div class="support-modal">
      <h1 class="support-modal__heading">
        {{ $t('menu.support__text') }}
      </h1>
      <div class="support-modal__image">
        <img
          src="@/assets/images/support-img-headphones.svg"
          :alt="$t('menu.support__text')"
        >
      </div>
      <div class="support-modal__content">
        <div class="support-modal__content-info">
          <div class="support-modal__content-info__title">
            {{ $t('supportModal.company__title') }}
          </div>
          <div class="support-modal__content-info__text">
            {{ ProductInfo.CompanyName }}
          </div>
        </div>
        <div class="support-modal__content-info">
          <div class="support-modal__content-info__title">
            {{ $t('supportModal.address__title') }}
          </div>
          <div class="support-modal__content-info__text">
            {{ ProductInfo.Address }}
          </div>
        </div>
        <div class="support-modal__content-info">
          <div class="support-modal__content-info__title">
            {{ $t('supportModal.email__title') }}
          </div>
          <div class="support-modal__content-info__text">
            <a 
              class="support-modal__content-info__text-link"
              :href="`mailto:${ProductInfo.Email}`"
            >
              {{ ProductInfo.Email }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { ProductInfo } from '@/constants/productInfo';

export default {
  components: {
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      ProductInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'ContactSupportModal';
</style>
