import { computed } from 'vue';
import store from '@/store';
import { GET_IS_SHOW_SEARCH_FIELD } from '@/store/constants';
import { isTabletAndLower } from '@/utils/Mobile';

export const useMainLayout = () => {
  const isShowSearch = computed((): boolean =>
    store.getters[GET_IS_SHOW_SEARCH_FIELD]);

  const isShowMainLayout = computed((): boolean => 
    !isShowSearch.value || !isTabletAndLower);

  return { isShowMainLayout };
};
