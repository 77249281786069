import { fabric } from 'fabric';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { Size } from '@/components/Editor/types/editor';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { CanvasBGOffset } from '@/constants/editor';
import store from '@/store';
import { SET_BACKGROUND_COLOR } from '@/store/Editor/constants';
import { CanvasType } from './createCanvas';
import { getCanvasZoomToDefault } from './getCanvasZoomToDefault';

const getSizeToDefault = (
  size: Size['width'] | Size['height'],
  zoomToDefault: number,
): number => {
  return size / zoomToDefault + CanvasBGOffset * 2;
};

export const addBackgroundElementToCanvas = ({
  backgroundColor,
  canvas,
  canvasSize: { height, width },
  canvasType,
}: {
  backgroundColor: string
  canvas: FabricCanvas
  canvasSize: Size,
  canvasType: CanvasType
}): void => {
  const zoomToDefault = getCanvasZoomToDefault(height);
  canvas.add(
    new fabric.Rect({
      fill: backgroundColor,
      height: getSizeToDefault(height, zoomToDefault),
      hoverCursor: 'default',
      // @ts-ignore
      id: BACKGROUND_OBJECT_ID,
      left: -CanvasBGOffset,
      selectable: false,
      top: -CanvasBGOffset,
      width: getSizeToDefault(width, zoomToDefault),
    }),
  );
  if (canvasType === CanvasType.render) {
    store.commit(SET_BACKGROUND_COLOR, backgroundColor);
  }
};
