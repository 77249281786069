import {
  changeActiveObjectPropsOnScaled,
} from '@/components/Editor/helpers/fabric/activeObjects/changeActiveObjectProps';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';

export const objectScaled = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('object:scaled', changeActiveObjectPropsOnScaled);
};
