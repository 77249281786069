<template>
  <div
    ref="editorControls"
    :class="['editor-controls', {
      'is-shopify-page': isShopifyPage,
      'disabled': isSettingsDisabled,
    }]"
    @click.stop
  >
    <BaseButton
      color="transparent"
      no-margin-top
      has-background
      :width="40"
      :height="40"
      class="editor-controls__button"
      @click.stop="addText"
    >
      <template #prependSvg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="TextToolIconData1"
            :fill="textToolColor"
          />
          <path
            :d="TextToolIconData2"
            :fill="textToolColor"
          />
          <path
            :d="TextToolIconData3"
            :fill="textToolColor"
          />
          <path
            :d="TextToolIconData4"
            :fill="textToolColor"
          />
          <path
            :d="TextToolIconData5"
            :fill="textToolColor"
          />
        </svg>
      </template>
    </BaseButton>
    <BaseButton
      color="transparent"
      no-margin-top
      has-background
      :width="40"
      :height="40"
      class="editor-controls__button"
      @click.stop="selectFile"
    >
      <template #prependSvg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="ImageToolIconData1"
            :fill="imgToolColor"
          />
          <path
            :d="ImageToolIconData2"
            :fill="imgToolColor"
          />
          <path
            :d="ImageToolIconData3"
            :fill="imgToolColor"
          />
          <path
            :d="ImageToolIconData4"
            :fill="imgToolColor"
          />
          <path
            :d="ImageToolIconData5"
            :fill="imgToolColor"
          />
        </svg>
      </template>
    </BaseButton>
    <BaseButton
      v-if="!isIframe"
      color="transparent"
      no-margin-top
      has-background
      :width="40"
      :height="40"
      class="editor-controls__button"
      :class="{ 'editor-controls__button_disabled': isSettingsDisabled }"
      :disabled="isSettingsDisabled"
      @click.stop="saveDesign"
    >
      <template #prependSvg>
        <PhotoAlbum :color="designModeToolColor" />
      </template>
    </BaseButton>
    <input
      ref="imageInput"
      class="image-input"
      type="file"
      accept="image/*"
      @change="uploadImage"
    >
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useControls from '@/components/Editor/EditorControls/EditorControls';
import PhotoAlbum from '@/components/icons/PhotoAlbum.vue';
export default {
  components: { BaseButton, PhotoAlbum },
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useControls();
  },
};
</script>

<style lang="scss" scoped>
@import "EditorControls";
</style>
