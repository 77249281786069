import { onMounted } from 'vue';
import designModeIcon from '@/components/Editor/assets/icons/openDesignMode.svg';
import { mobileChangeModeDuration } from '@/constants/editor';

const useChangeModeNoteMobile = ({ emit }) => {
  const delayedRemoveNotification = (): void => {
    setTimeout((): void => emit('closeNote'), mobileChangeModeDuration);
  };

  onMounted(delayedRemoveNotification);

  return {
    designModeIcon,
  };
};

export default useChangeModeNoteMobile;
