<template>
  <MainLayout>
    <div class="shipping-wrapper">
      <ShippingHeading
        @createDeliveryProfile="createDeliveryProfile"
      />
      <ShippingTable
        :delivery-profiles="deliveryProfiles"
        :is-show-pagination="isShowPagination"
        :current-page="currentPage"
        :amount-of-pages="amountOfPages"
        @pageChange="handlePageChange"
        @removeDeliveryProfile="removeDeliveryProfile"
      />
    </div>
  </MainLayout>
</template>

<script lang="ts">
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useShipping from './Shipping';
import ShippingHeading from './ShippingHeading/ShippingHeading.vue';
import ShippingTable from './ShippingTable/ShippingTable.vue';

export default {
  components: {
    MainLayout,
    ShippingHeading,
    ShippingTable,
  },
  setup() {
    return useShipping();
  },
};
</script>

<style lang="scss">
@import 'Shipping';
</style>
