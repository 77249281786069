<template>
  <div>
    <div
      class="text-settings__control"
    >
      <Alignments
        :is-mobile="isMobile"
        :is-disabled="lockInfo.lockedMovement"
      />
    </div>
    <div>
      <v-divider />
      <Rotation
        :is-mobile="isMobile"
        :is-disabled="lockInfo.lockedRotation"
      />
    </div>
    <v-divider />
    <div class="text-settings__control text-settings__text-font">
      <span class="text-settings__control-label">
        {{ $t("redactor.text-settings-font__label") }}
        <BaseButton
          v-if="!isIframe"
          icon="mdi-plus"
          no-margin-top
          dark
          @click="$emit('openAddFontModal')"
        />
      </span>
      <div
        class="text-settings__text-font-select"
        @click="$store.commit(SET_IS_FONT_SELECT_OPEN, true)"
      >
        <span>
          {{ font }}
        </span>
        <img
          src="@/assets/icons/arrow.svg"
          :alt="font"
          class="arrow-icon"
          @click.self="$store.commit(SET_IS_FONT_SELECT_OPEN, true)"
        >
      </div>
    </div>
    <TextFontSize :is-mobile="isMobile" />
    <div class="text-settings__control">
      <span class="text-settings__control-label">
        {{ $t("redactor.color__label") }}
      </span>
      <ColorPicker
        class="text-settings__control-color-picker"
        :color-picker-type="ColorPickerType.text"
        :is-mobile="isMobile"
      />
    </div>
    <TextAlignments :is-mobile="isMobile" />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import TextAlignments from '@/components/Editor/TextAlignments/TextAlignments.vue';
import TextFontSize from '@/components/Editor/TextFrontSize/TextFontSize.vue';
import { ColorPickerType } from '@/components/Editor/types/editor';
import { secondaryViolet } from '@/constants/colors';
import { SET_IS_FONT_SELECT_OPEN } from '@/store/Editor/constants';

export default {
  components: {
    Alignments,
    BaseButton,
    ColorPicker,
    Rotation,
    TextAlignments,
    TextFontSize,
  },
  props: {
    font: {
      required: true,
      type: String,
    },
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isIframe: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
    },
  },
  setup() {
    return {
      ColorPickerType,
      secondaryViolet,
      SET_IS_FONT_SELECT_OPEN,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "TextSettingsDesktop";
</style>
