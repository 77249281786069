<template>
  <v-form
    v-model="isValidForm"
    class="sign-in-form"
    @submit.prevent="logIn"
  >
    <BaseTitle>
      {{ $t('signIn.signIn__text') }}
    </BaseTitle>
    <AuthWithSocial
      auth-with="SignInWithEmail"
      :store-connection-token="storeConnectionToken"
    />
    <TextField
      v-model="email"
      required
      autofocus
      input-type="email"
      :rules="emailRules"
      :label="$t('textField.email__placeholder')"
      @keydown.space.prevent
    />
    <TextField
      v-model="password"
      required
      input-type="password"
      :rules="passwordRules"
      :label="$t('textField.password__placeholder')"
      @keydown.space.prevent
    />
    <div class="sign-in-form__forgot-password">
      <router-link to="/forgot-password">
        <span class="underline-animate">
          {{ $t('signIn.forgotPassword__text') }}
        </span>
      </router-link>
    </div>
    <BaseButton
      class="sign-in-form__sign-in-button"
      :class="{'disabled': !isValidForm}"
      type="submit"
      has-background
      :button-text="$t('signIn.signIn__text')"
      :large="isMobile"
      @click="logIn"
    />
    <DontHaveAccount :route-query="$router.currentRoute.query" />
  </v-form>
</template>

<script lang="ts">
import AuthWithSocial from '@/components/AuthWithSocial/AuthWithSocial.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import DontHaveAccount from '@/components/Common/DontHaveAccount/DontHaveAccount.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useSignInForm from './SignInForm';

export default {
  components: {
    AuthWithSocial,
    BaseButton,
    BaseTitle,
    DontHaveAccount,
    TextField,
  },
  setup() {
    return useSignInForm();
  },
};
</script>

<style lang="scss" scoped>
  @import 'SignInForm';
</style>
