import { FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_ACTIVE_CANVAS } from '@/store/Editor/constants';

export const deleteFabricObject = (_: any, { target }): void => {
  const obj = target as FabricObject;
  const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
  const fabricObject = (activeCanvas
    .getObjects() as FabricObject[])
    .find((object: FabricObject): boolean | undefined => {
      if (obj.id) {
        return object.id === obj.id;
      }
    });
  if (!fabricObject) return;
  fabricObject.deleted = true;
  activeCanvas.remove(fabricObject).renderAll();
};
