<template>
  <v-card
    class="collection-card"
    :ripple="false"
    @click.stop="openCollection"
  >
    <v-img
      :contain="isPreviewImageDisplayed"
      :src="previewImage"
      class="preview-image"
    >
      <div
        class="preview-image__menu"
        @click.stop
      >
        <BaseButton
          v-if="isLocalCollection"
          class="menu-close__btn"
          icon="mdi-close"
          dark
          no-margin-top
          icon-size="22"
          :color="black54"
          @click="removeLocalCollection"
        />
        <BaseMenu
          v-if="isEditingRights && !isLocalCollection"
          min-width="210"
        >
          <template #content="{ closeMenu }">
            <v-list dark>
              <v-list-item
                v-for="(item, i) in menuItemsList"
                :key="i"
                height="100"
                :disabled="item.disabled"
                @click.stop="item.action(); closeMenu();"
              >
                <v-list-item-title class="menu-item__title">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </BaseMenu>
      </div>
    </v-img>
    <v-form
      v-if="isLocalCollection"
      v-model="isValidName"
    >
      <v-card-title 
        v-click-outside="{
          handler: () => editCollection(EditTypes.collectionCreate),
          include: () => ignorableClickOutsideElements
        }"
        class="collection-card__title" 
      >
        <TextField
          v-model.trim="fieldEditName"
          autofocus
          class="edit-field"
          input-type="text"
          :placeholder="$t('collections.newCollectionName__placeholder')"
          :rules="editNameRules"
          :color="primaryViolet"
          :outlined="false"
          @keydown.enter.stop.prevent="
            editCollection(EditTypes.collectionCreate)"
        />
        <BaseButton
          class="close-field"
          icon="mdi-check"
          color="white"
          apply-color-to-text
          no-margin-top
          :disabled="!isValidName"
          @click="editCollection(EditTypes.collectionCreate)"
        />
      </v-card-title>
    </v-form>
    <v-card-title 
      v-else-if="!isEditCollectionName"
      class="collection-card__title no-edit" 
    >
      <div class="title__text">
        {{ collection.name }}
      </div>
      <BaseButton
        v-if="isEditingRights"
        light
        class="title__edit"
        icon="mdi-pencil"
        no-margin-top
        :color="black50"
        @click="toggleEditField"
      />
    </v-card-title>
    <v-form 
      v-else
      v-model="isValidName"
    >
      <v-card-title
        v-click-outside="{
          handler: () => editCollection(EditTypes.collectionName),
          include: () => ignorableClickOutsideElements
        }"
        class="collection-card__title" 
      >
        <TextField
          v-model.trim="fieldEditName"
          autofocus
          class="edit-field"
          input-type="text"
          :rules="editNameRules"
          :color="primaryViolet"
          :outlined="false"
          @keydown.enter.stop.prevent="editCollection(EditTypes.collectionName)"
        />
        <BaseButton
          class="close-field"
          icon="mdi-check"
          color="white"
          apply-color-to-text
          no-margin-top
          :disabled="!isValidName"
          @click="editCollection(EditTypes.collectionName)"
        />
      </v-card-title>
    </v-form>
    <input
      ref="imageInput"
      class="image-input"
      type="file"
      accept="image/*"
      @change.stop="changeCollectionImage"
      @click.stop
    >
  </v-card>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useCollectionCard from './CollectionCard';

export default {
  components: {
    BaseButton,
    BaseMenu,
    TextField,
  },
  props: {
    collection: Object,
    collectionType: String,
    isEditingRights: Boolean,
  },
  setup(props, context) {
    return useCollectionCard(props, context);
  },
};
</script>

<style lang="scss" scoped>
@import 'CollectionCard';
</style>
