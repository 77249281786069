import { OverflowValues } from '@/types';
import { setBodyOverflowY } from '@/utils';

export const useStopScroll = () => {
  const disableScroll = (): void => {
    setBodyOverflowY(OverflowValues.hidden);
  };
  
  const enableScroll = (): void => {
    setBodyOverflowY(OverflowValues.initial);
  };
  
  return {
    disableScroll,
    enableScroll,
  };
};
