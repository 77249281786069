import { v4 as UUID } from 'uuid';
import { getLayerName } from '@/components/Editor/helpers/layers/layerName';
import { LayerNumberModes } from '@/components/Editor/helpers/layers/types';
import { updateLayerNumberForNewObjects } from '@/components/Editor/helpers/layers/updateLayerNumber';
import { /////
  FabricObject,
  FabricObjectType,
} from '@/components/Editor/types/fabric';

export const getDefaultImageObject = (file: File): FabricObject => {
  const layerNumber = updateLayerNumberForNewObjects(LayerNumberModes.increase);

  return {
    file,
    id: UUID(),
    isDefault: true,
    type: FabricObjectType.image,
    layerNumber,
    layerName: getLayerName(FabricObjectType.image),
  } as FabricObject;
};

export const getDefaultTextObject = (): FabricObject => {
  const layerNumber = updateLayerNumberForNewObjects(LayerNumberModes.increase);

  return {
    id: UUID(),
    isDefault: true,
    type: FabricObjectType.text,
    layerNumber,
    layerName: getLayerName(FabricObjectType.text),
  } as FabricObject;
};
