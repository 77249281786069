<template>
  <ImageSettingsMobile
    v-if="isMobile"
    v-bind="imageSettingsProps"
    v-on="imageSettingsEmits"
  />
  <ImageSettingsDesktop
    v-else
    v-bind="imageSettingsProps"
    v-on="imageSettingsEmits"
  />
</template>

<script lang="ts">
import useImageSettings from './ImageSettings';
import ImageSettingsDesktop from './ImageSettingsDesktop/ImageSettingsDesktop.vue';
import ImageSettingsMobile from './ImageSettingsMobile/ImageSettingsMobile.vue';

export default {
  components: {
    ImageSettingsDesktop,
    ImageSettingsMobile,
  },
  props:{
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      required: false,
      type: Object,
    },
  },
  setup(props) {
    return useImageSettings(props);
  },
};
</script>
