import {
  deleteActiveObject,
} from '@/components/Editor/helpers/fabric/activeObjects/deleteActiveObject';
import { ToggleTypes } from '@/types';

export const keyDownDelete = (toggle: ToggleTypes): void => {
  switch (toggle) {
  case ToggleTypes.on:
    document.addEventListener('keydown', deleteActiveObject);
    break;
  case ToggleTypes.off:
    document.removeEventListener('keydown', deleteActiveObject);
    break;
  default: break;
  }
};
