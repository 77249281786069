<template>
  <div class="user-guide-page">
    <h1 class="user-guide-page__title">
      {{ title }}
    </h1>
    <div class="user-guide-page__content">
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.printingAndShippingPage.weTakeResponsibility__text') }}
      </p>
      <p class="user-guide-page__content-text">
        <i18n
          path="userGuide.printingAndShippingPage.contactUs__text"
          tag="span"
        >
          <template #email>
            <a 
              class="user-guide-page__content-list__item-link"
              :href="`mailto:${ProductInfo.Email}`"
            >
              {{ ProductInfo.Email }}
            </a>
          </template>
        </i18n>
      </p>
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.printingAndShippingPage.letsCreate__text') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { ProductInfo } from '@/constants/productInfo';

export default {
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },
  setup() {
    return {
      ProductInfo,
    };
  },
};
</script>
