<template>
  <div
    v-click-outside="{
      handler: () => closeSearch(),
      include: () => getElementsToIgnoreClickOutside()
    }"
    class="search-wrapper"
  >
    <v-form
      v-if="isSearchOpen"
      v-model="isSearchValid"
    >
      <TextField
        v-debounce:400="findProduct"
        :value="search"
        class="search-wrapper__field"
        :rules="searchRules"
        :loading="isLoading"
        :placeholder="$t('search.textfield__placeholder')"
        input-type="search"
        dense
        autofocus
        @input="onSearchInput"
      />
    </v-form>
    <BaseButton
      v-else
      dark
      icon="mdi-magnify"
      class="search-wrapper__search-button"
      @click="$emit('openSearch')"
    />
    <SearchListDesktop
      v-if="isShowSearchList && !isTabletAndLower && isSearchValid" 
      v-bind="searchListProps"
      v-on="searchListEmits"
    />
    <SearchListMobile
      v-if="isSearchOpen && isTabletAndLower"
      :is-show-search-list="isShowSearchList"
      v-bind="searchListProps"
      v-on="searchListEmits"
    />
  </div>
</template>

<script lang="ts">
import { getDirective } from 'vue-debounce';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useSearchField from '@/components/SearchField/SearchField';
import SearchListDesktop from './SearchListDesktop/SearchListDesktop.vue';
import SearchListMobile from './SearchListMobile/SearchListMobile.vue';
    
export default {
  components: {
    BaseButton,
    SearchListDesktop,
    SearchListMobile,
    TextField,
  },
  directives: {
    debounce: getDirective(),
  },
  props: {
    isShowSearch: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useSearchField(props, context);
  },
};
</script>

<style lang="scss">
@import 'SearchField.scss';
</style>
