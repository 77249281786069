import { canvasConfigsActions } from './canvasConfigsActions';
import { canvasHistoryActions } from './canvasHistoryActions';
import { editorConfigsActions } from './editorConfigsActions';
import { fontsConfigsActions } from './fontsConfigsActions';
import { templateDesignActions } from './templateDesignActions';

export const actions = {
  ...editorConfigsActions,
  ...canvasHistoryActions,
  ...canvasConfigsActions,
  ...templateDesignActions,
  ...fontsConfigsActions,
};
