<template>
  <v-fade-transition>
    <v-btn
      v-if="isTabletAndLower"
      v-show="showButton"
      fab
      dark
      depressed
      class="back-to-top"
      :color="secondaryViolet"
      @click.stop="smoothScrollToTop(scrollableElement)"
    >
      <div class="back-to-top-content">
        <img src="@/assets/icons/arrow-up.svg">
        <span class="back-to-top-content__text">
          {{ $t('placeholders.backToTopButton__placeholder') }}
        </span>
      </div>
    </v-btn>
  </v-fade-transition>
</template>

<script lang="ts">
import useBackToTopButton from './BackToTopButton';

export default {
  props: {
    scrollableElement: {
      required: false,
      type: [ HTMLElement, Boolean ],
    },
  },
  setup(props) {
    return useBackToTopButton(props);
  },
};
</script>

<style lang="scss" scoped>
@import 'BackToTopButton';
</style>
