export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

interface NotificationLocalizationSlot {
  content: string;
  key: string;
  link?: string;
}

interface NotificationLocalizationData {
  path?: string | null;
  slots?: NotificationLocalizationSlot[];
}

export const initialNotificationLocalization: NotificationLocalizationData = {
  path: null,
  slots: [],
};

export interface Notification {
  localizationData: NotificationLocalizationData;
  text?: string;
  type: NotificationType;
}
