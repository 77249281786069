<template>
  <div class="profile-info">
    <div class="profile-info__heading">
      <h2>{{ $t('profile.systems__text') }}</h2>
    </div>
    <div class="cards-wrapper">
      <v-card
        class="profile-info__card"
        :flat="isTabletAndLower"
      >
        <div class="profile-info__card-description">
          <div class="profile-info__card-description__img">
            <img
              :src="system.icon"
              :alt="system.name"
            >
          </div>
          <div class="card-info">
            <v-card-title class="card-info__title name">
              {{ system.name }}
            </v-card-title>
            <v-card-subtitle class="card-info__subtitle description">
              {{ system.description }}
            </v-card-subtitle>
          </div>
        </div>
        <v-tooltip
          top
          :disabled="!system.tooltip || system.isAdded"
          max-width="305"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :disabled="system.isAdded"
              :dark="!system.isAdded"
              class="profile-info__card-add__system-btn"
              href="https://apps.shopify.com/customis3d"
              target="_blank"
              large
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ 
                  system.isAdded
                    ? 'mdi-check'
                    : 'mdi-plus'
                }}
              </v-icon>
              {{ system.isAdded
                ? $t('profile.systemsAdded__text')
                : $t('profile.systemsAdd__text') }}
            </v-btn>
          </template>   
          <span>{{ system.tooltip }}</span>
        </v-tooltip>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { black06, black87 } from '@/constants/colors';
import { isTabletAndLower } from '@/utils/Mobile';

export default {
  props: {
    system: {
      required: true,
      type: Object,
    },
  },
  setup() {
    return {
      black06,
      black87,
      isTabletAndLower,
    };
  },
};
</script>
