import {
  saveActiveObjectInHistory,
} from '@/components/Editor/helpers/fabric/activeObjects/modifyActiveObjectInStore';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';

export const textChanged = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('text:changed', saveActiveObjectInHistory);
};
