import {
  GET_FONT_TO_REMOVE,
  GET_FONTS_TO_RENDER,
  GET_GOOGLE_FONTS_LIST,
  GET_IS_ADD_FONT_MODAL_OPEN,
  GET_IS_FONT_SELECT_OPEN,
  GET_IS_REMOVE_FONT_MODAL_OPEN,
} from '../constants';
import { EditorState, GoogleFontsList } from '../types';

export const fontsConfigsGetters = {
  [GET_GOOGLE_FONTS_LIST]: (state: EditorState): GoogleFontsList[] => {
    return state.fontsConfigs.googleFontsList;
  },
  [GET_FONTS_TO_RENDER]: (state: EditorState): string[] => {
    return state.fontsConfigs.fontsToRender.sort();
  },
  [GET_IS_ADD_FONT_MODAL_OPEN]: (state: EditorState): boolean => {
    return state.fontsConfigs.isAddFontModalOpen;
  },
  [GET_IS_FONT_SELECT_OPEN]: (state: EditorState): boolean => {
    return state.fontsConfigs.isFontSelectOpen;
  },
  [GET_IS_REMOVE_FONT_MODAL_OPEN]: (state: EditorState): boolean => {
    return state.fontsConfigs.isRemoveFontModalOpen;
  },
  [GET_FONT_TO_REMOVE]: (state: EditorState): string => {
    return state.fontsConfigs.fontToRemove;
  },
};
