<template>
  <MainLayout>
    <div class="profile">
      <ProfileCard />
      <ShopifyThemeSettings
        v-if="shopifyStore"
        :item="shopifySystem"
        :themes="themes"
        :current-theme-id="currentThemeId"
        :current-theme-name="currentThemeName"
        :user-store="shopifyStore"
        @updateTheme="updateTheme"
        @setCurrentThemeId="setCurrentThemeId"
      />
      <ProfileSystem
        v-for="system in integrationSystemsList"
        :key="system.name"
        :system="system"
      />
    </div>
  </MainLayout>
</template>

<script lang="ts">
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useProfile from './Profile';
import ProfileCard from './ProfileCard/ProfileCard.vue';
import ProfileSystem from './ProfileSystem/ProfileSystem.vue';
import ShopifyThemeSettings from './ShopifyThemeSettings/ShopifyThemeSettings.vue';

export default {
  components: {
    MainLayout,
    ProfileCard,
    ProfileSystem,
    ShopifyThemeSettings,
  },
  setup() {
    return useProfile();
  },
};
</script>

<style lang="scss">
@import "Profile";
</style>
