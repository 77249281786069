import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { Route } from 'vue-router';
import { secondaryViolet } from '@/constants/colors';
import router from '@/router';
import {
  CLEAR_COLLECTIONS,
  CREATE_LOCAL_COLLECTION,
  GET_COLLECTIONS,
  GET_COLLECTIONS_AMOUNT_OF_PAGES,
  GET_COLLECTIONS_SELECTED_SORT,
  GET_IS_COLLECTIONS_LOADING,
  GET_IS_EDITING_RIGHTS_COLLECTIONS,
  GET_PUBLIC_COLLECTIONS,
  INIT_COLLECTIONS,
  LOAD_MORE_COLLECTIONS,
  RESET_COLLECTIONS_SORTING,
  SET_COLLECTIONS_SELECTED_SORT,
} from '@/store/Collections/constants';
import { Collection } from '@/store/Collections/types';
import store from '@/store/index';
import {
  AmountOfCards,
  SkeletonCards,
  SkeletonTypes,
  SortItems,
} from '@/types';
import { scrollToTop } from '@/utils';
import { isTabletAndLower } from '@/utils/Mobile';
import {
  CollectionRouteTypes,
  CollectionTypes,
} from '@/views/Collections/types';

const useCollections = () => {
  const isLoading = computed((): boolean =>
    store.getters[GET_IS_COLLECTIONS_LOADING]);
  const isEditingRights = computed((): boolean =>
    store.getters[GET_IS_EDITING_RIGHTS_COLLECTIONS]);

  const isPublic = computed((): boolean =>
    router.currentRoute.name === CollectionRouteTypes.publicCollections);
  
  const collectionType = computed((): CollectionTypes => isPublic.value
    ? CollectionTypes.publicCollections
    : CollectionTypes.privateCollections);
    
  const collections = computed((): Collection[] => isPublic.value
    ? store.getters[GET_PUBLIC_COLLECTIONS]
    : store.getters[GET_COLLECTIONS]);
    
  const isCollectionExists = computed((): boolean =>
    !!collections.value.length);
  
  const sort = computed((): SortItems =>
    store.getters[GET_COLLECTIONS_SELECTED_SORT]);
  
  const currentPage = computed((): number =>
    +router.currentRoute.query.page);
  
  const amountOfPages = computed((): number =>
    store.getters[GET_COLLECTIONS_AMOUNT_OF_PAGES]);
  
  const isShowPagination = computed((): boolean =>
    amountOfPages.value > 1 &&
    isCollectionExists.value &&
    !isLoading.value &&
    !isTabletAndLower,
  );
  const isShowMoreButtonActive = computed((): boolean =>
    amountOfPages.value > 1 &&
    currentPage.value < amountOfPages.value &&
    isCollectionExists.value &&
    !isLoading.value &&
    isTabletAndLower,
  );
  const isCollectionsListVisible = computed((): boolean =>
    isTabletAndLower
      ? !!collections.value.length
      : !!collections.value.length && !isLoading.value);

  const initCollections = async (isLoadMore?: boolean): Promise<void> => {
    const loadPreviousCollections = isTabletAndLower
      && currentPage.value && currentPage.value > 1;
    
    const numberOfCardsToLoad = isTabletAndLower
      ? AmountOfCards.mobile
      : AmountOfCards.desktop;
    
    const limit = loadPreviousCollections
      ? currentPage.value * AmountOfCards.mobile
      : numberOfCardsToLoad;
    
    const defaultCollectionsPayload = {
      collectionType: collectionType.value,
      selectedSort: sort.value,
    };
    
    const initCollectionsPayload = {
      ...defaultCollectionsPayload,
      limit,
      page: loadPreviousCollections ? 1 : currentPage.value,
    };

    const loadMoreCollectionsPayload = {
      ...defaultCollectionsPayload,
      limit: numberOfCardsToLoad,
      page: currentPage.value,
    };

    const shouldLoadMoreItems = isLoadMore && isTabletAndLower;
    
    shouldLoadMoreItems
      ? await store.dispatch(LOAD_MORE_COLLECTIONS, loadMoreCollectionsPayload)
      : await store.dispatch(INIT_COLLECTIONS, initCollectionsPayload);
    
    const shouldCheckPageExceedMaxPages =
      !isLoadMore && !isTabletAndLower && amountOfPages.value > 0;
    
    if (shouldCheckPageExceedMaxPages &&
      currentPage.value > amountOfPages.value)
      setCurrentPage(1, true);
    
    scrollToTop();
  };

  const handleShowMoreButtonClick = (): void => {
    setCurrentPage(currentPage.value + 1);
  };

  const addCollection = (): Promise<void> =>
    store.dispatch(CREATE_LOCAL_COLLECTION);
    
  const handleSortChange = (value: SortItems): void => {
    if (currentPage.value !== 1 || isTabletAndLower) {
      store.dispatch(CLEAR_COLLECTIONS);
      setCurrentPage(1);
    }
    store.dispatch(SET_COLLECTIONS_SELECTED_SORT, value);
    initCollections();
  };
    
  const handlePageChange = (value: number): void => {
    if (value === currentPage.value) return;
    setCurrentPage(value);
  };

  const setCurrentPage = (
    newPage: number,
    replaceRoute?: boolean,
  ): Promise<Route> => {
    const routeQuery = {
      query: {
        page: newPage.toString(),
      },
    };

    return replaceRoute
      ? router.replace(routeQuery)
      : router.push(routeQuery);
  };

  const loadCollections = (newPage?: number): void => {
    if (!currentPage.value) setCurrentPage(1, true);
    else initCollections(Boolean(newPage));
  };

  const resetState = (): void => {
    store.dispatch(RESET_COLLECTIONS_SORTING);
    isTabletAndLower && store.dispatch(CLEAR_COLLECTIONS);
  };

  watch(currentPage, (newPage): void => loadCollections(newPage));
  onMounted((): void => loadCollections());
  onBeforeUnmount(resetState);

  return {
    addCollection,
    amountOfPages,
    collections,
    collectionType,
    currentPage,
    handlePageChange,
    handleShowMoreButtonClick,
    handleSortChange,
    initCollections,
    isCollectionExists,
    isCollectionsListVisible,
    isEditingRights,
    isLoading,
    isShowMoreButtonActive,
    isShowPagination,
    isTabletAndLower,
    secondaryViolet,
    SkeletonCards,
    SkeletonTypes,
    sort,
  };
};

export default useCollections;
