import { DEFAULT_FONT } from './defaultSettings';

export const BACKGROUND_OBJECT_ID = 'background';

export const DEFAULT_TEXT_COLOR = '#000000';

export const DEFAULT_TEXT_SETTINGS = {
  angle: 0,
  centeredScaling: true,
  cornerSize: 8,
  cornerStyle: 'circle',
  fill: DEFAULT_TEXT_COLOR,
  fontFamily: DEFAULT_FONT,
  fontSize: 30,
  height: 60,
  lockedInfo: {},
  originX: 'center',
  originY: 'center',
  scaleX: 1,
  scaleY: 1,
  text: 'New text',
  textAlign: 'center',
  width: 110,
};

export const DEFAULT_IMAGE_SETTINGS = {
  angle: 0,
  centeredScaling: true,
  cornerSize: 8,
  cornerStyle: 'circle',
  flip: false,
  isScalingChanged: false,
  lockedInfo: {},
  lockScalingFlip: true,
  originX: 'center',
  originY: 'center',
  scaleX: 1,
  scaleY: 1,
};

export const DEFAULT_FONTS = [
  'Times new Roman',
  'Arial',
  'Satisfy',
  'Audiowide',
  'Sacramento',
  'Amelline',
  'Lofty Goals',
  'Anti Corona',
  'Blackout',
  'Brush King',
  'Brush Signature',
  'Darkline',
  'Georgia Brush',
  'Gloss And Bloom',
  'Heaters',
  'Restollia Script',
  'Signature December',
  'Strawberry Blossom',
];

export const DEFAULT_FONT_SIZES = [
  6, 8, 10, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96,
];

export const DEFAULT_FRACTION_DIGITS = 4;
