import { controlsStyleMods } from '@/components/Editor/types/editor';
import store from '@/store';
import { GET_BASE_CANVAS_WIDTH } from '@/store/Editor/constants';

export const getcontrolsStyleMods = (
  width: number,
  height: number,
): controlsStyleMods => {
  const canvasWidthFromStore = store.getters[GET_BASE_CANVAS_WIDTH];
  const canvasWidthForStyles = width;
  const canvasHeightForStyles = canvasWidthFromStore ? (width - height) / 2 : 0;

  const controlsStyle = 
    `translateX(-${canvasWidthForStyles / 2}px) translateX(-100%) translateY(${canvasHeightForStyles}px)`;

  const scaleControlsStyle = 
    `translateX(${canvasWidthForStyles / 2}px) translateY(-100%) translateY(-${canvasHeightForStyles}px)`;

  return { controlsStyle, scaleControlsStyle };
};
