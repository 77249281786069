import { FabricObjectAttributes } from '@/components/Editor/types/fabric';
import store from '@/store';
import { TRIGGER_UPDATE_OBJECT } from '@/store/Editor/constants';

export const changeTextFont = (font: string): void => {
  store.dispatch(TRIGGER_UPDATE_OBJECT, {
    changes: { [FabricObjectAttributes.fontFamily]: font },
    isUpdateHistory: true,
  });
};
