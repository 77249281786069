<template>
  <AuthLayout>
    <CreatePasswordForm />
  </AuthLayout>
</template>

<script lang="ts">
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import CreatePasswordForm from './CreatePasswordForm/CreatePasswordForm.vue';

export default {
  components: {
    AuthLayout,
    CreatePasswordForm,
  },
};
</script>
