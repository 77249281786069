import { computed, reactive, ref, toRef } from 'vue';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { ColorPickerType } from '@/components/Editor/types/editor';
import { FabricObject } from '@/components/Editor/types/fabric';
import {
  black50,
  black87,
  primaryViolet,
  secondaryViolet,
} from '@/constants/colors';
import store from '@/store';
import {
  GET_ACTIVE_OBJECT,
  GET_FABRIC_OBJECTS,
  REORDER_FABRIC_OBJECTS,
  TRIGGER_DELETE_OBJECT,
  TRIGGER_UPDATE_OBJECT,
} from '@/store/Editor/constants';

const useSideControlsDesktop = (props, { emit }) => {
  const currentTab = ref();
  const objects = computed(() =>
    store.getters[GET_FABRIC_OBJECTS].filter(
      ({ id }) => id !== BACKGROUND_OBJECT_ID,
    ).reverse(),
  );
  const activeObjectId = computed(() => store.getters[GET_ACTIVE_OBJECT]?.id);
  const editLayerId = ref<number | null>(null);
  const isLayerEditMode = ref(false);

  const saveSummary = (id: number, layerName: string): void => {
    store.dispatch(
      TRIGGER_UPDATE_OBJECT,
      { id, changes: { layerName }, shouldSaveInStore: true },
    );
    editLayerId.value = null;
  };
  
  const reverseIndex = (index: number, arr: any[]) => {
    return arr.length - 1 - index;
  };

  const reorder = (event, objects: FabricObject[]): void => {
    const from = reverseIndex(event.from, objects);
    const to = reverseIndex(event.to, objects);

    event.apply(objects);
    store.dispatch(REORDER_FABRIC_OBJECTS, { from, to });
  };

  const textControlsProps = reactive({
    font: toRef(props, 'font'),
    fontSize: toRef(props, 'fontSize'),
    isCustomizationFrame: toRef(props, 'isCustomizationFrame'),
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const imageSettingsProps = reactive({
    isCustomizationFrame: toRef(props, 'isCustomizationFrame'),
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const textControlsEmits = {
    addToShopifyOrder: () => emit('addToShopifyOrder'),
    deleteSelectedObject: () => emit('deleteSelectedObject'),
    selectFile: () => emit('selectFile'),
  };

  const deleteObjectById = (id: number): void => {
    store.dispatch(TRIGGER_DELETE_OBJECT, id);
  };

  return {
    ColorPickerType,
    imageSettingsProps,
    textControlsEmits,
    textControlsProps,
    secondaryViolet,
    currentTab,
    primaryViolet,
    black87,
    objects,
    isLayerEditMode,
    black50,
    editLayerId,
    deleteObjectById,
    saveSummary,
    reorder,
    activeObjectId,
  };
};

export default useSideControlsDesktop;
