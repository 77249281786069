<template>
  <div
    v-if="noteVisible"
    class="change-mode-note"
    @click.stop
  >
    <span>{{ $t('redactor.note-mobile__text') }}</span>
    <img
      class="change-mode-note__icon"
      :src="designModeIcon"
      :alt="$t('redactor.noteInfo__text')"
    >
    <span 
      class="change-mode-note__link"
      @click.stop="$emit('goToEditorView')"
    >
      {{ $t('redactor.note-mobile__button') }}
    </span>
  </div>
</template>

<script lang="ts">
import useChangeModeNoteMobile 
  from '@/components/Editor/ChangeModeNote/ChangeModeNoteMobile/ChangeModeNoteMobile';

export default {
  props: {
    isEditorActive: {
      required: true,
      type: Boolean,
    },
    noteVisible: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useChangeModeNoteMobile(context);
  },
};
</script>

<style lang="scss" scoped>
@import "ChangeModeNoteMobile";
</style>
