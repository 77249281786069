import { fabric } from 'fabric';
import close from '@/components/Editor/assets/icons/objectControls/closeButton.svg';
import openLock from '@/components/Editor/assets/icons/objectControls/lockButton.svg';
import resizeFromCenter from '@/components/Editor/assets/icons/objectControls/resizeFromCenter.svg';
import resizeFromEdge from '@/components/Editor/assets/icons/objectControls/resizeFromEdge.svg';
import rotation from '@/components/Editor/assets/icons/objectControls/rotateButton.svg';
import closeLock from '@/components/Editor/assets/icons/objectControls/unlockButton.svg';
import { isObjectLocked } from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import { FabricObject } from '@/components/Editor/types/fabric';
import {
  canvasIconOffset,
  canvasIconOffsetMobile,
  canvasIconSize,
  canvasIconSizeMobile,
} from '@/constants/editor';
const isMobile = false;
const resizeFromCenterIcon = document.createElement('img');
const resizeFromEdgeIcon = document.createElement('img');
const closeLockIcon = document.createElement('img');
const openLockIcon = document.createElement('img');
const rotationIcon = document.createElement('img');
const closeIcon = document.createElement('img');

export const actualCanvasIconSize = () => {
  return isMobile ? canvasIconSizeMobile : canvasIconSize;
};

export const actualCanvasIconOffset = () => {
  return isMobile ? canvasIconOffsetMobile : canvasIconOffset;
};

export const loadIcons = () => {
  resizeFromCenterIcon.src = resizeFromCenter;
  resizeFromEdgeIcon.src = resizeFromEdge;
  closeLockIcon.src = closeLock;
  openLockIcon.src = openLock;
  rotationIcon.src = rotation;
  closeIcon.src = close;
};

const drawIcon = (
  icon: HTMLImageElement,
  size: number = actualCanvasIconSize(),
) => async (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  styleOverride: object,
  fabricObject: FabricObject,
): Promise<void> => {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject?.angle || 0));
  ctx.drawImage(icon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

export const drawCloseButton = drawIcon(closeIcon);
export const renderRotationIcon = drawIcon(rotationIcon);
export const renderScaleFromCenterIcon = drawIcon(resizeFromCenterIcon);
export const renderScaleFromEdgeIcon = drawIcon(resizeFromEdgeIcon);
export const renderCloseLockIcon = drawIcon(closeLockIcon);
export const renderOpenLockIcon = drawIcon(openLockIcon);

export const drawLockIcon = async (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  styleOverride: object,
  fabricObject: FabricObject,
): Promise<void> => {
  isObjectLocked(fabricObject)
    ? renderCloseLockIcon(ctx, left, top, styleOverride, fabricObject)
    : renderOpenLockIcon(ctx, left, top, styleOverride, fabricObject);
};
