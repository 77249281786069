import {
  changeActiveObjectPropsOnSelected,
} from '@/components/Editor/helpers/fabric/activeObjects/changeActiveObjectProps';
import {
  saveUpdatedActiveObjectInStore,
} from '@/components/Editor/helpers/fabric/activeObjects/modifyActiveObjectInStore';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';

export const selectionCreated = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('selection:created', changeActiveObjectPropsOnSelected);
  canvas[toggle]('selection:created', saveUpdatedActiveObjectInStore);
};
