<template>
  <div 
    :class="[
      'view-switcher',
      {
        'is-shopify-page': isShopifyPage,
        'disabled': isSettingsDisabled,
        'view-switcher_editor-active-iframe': editorActive && isIframe,
      }]" 
    @click.stop
  >
    <v-tooltip
      bottom
      :disabled="!disabled"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <div 
            :class="{
              'active': editorActive,
              'disabled': disabled,
            }"
            @click.stop="goToEditorView"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :d="EditorViewIconData"
                :fill="editorViewColor"
              />
            </svg>
          </div>
        </div>
      </template>
      <p
        v-if="isMobile" 
        class="view-switcher__tooltip"
      >
        {{ $t("redactor.disabledSwitcherTooltipMobile__text") }}
      </p>
      <p
        v-else 
        class="view-switcher__tooltip"
      >
        {{ $t("redactor.disabledSwitcherTooltipFirst__text") }}
        <img
          class="view-switcher__cursor-icon"
          :src="cursorLogo"
          :alt="$t('redactor.cusrsorIcon__alt')"
        >
        {{ $t("redactor.disabledSwitcherTooltipSecond__text") }}
      </p> 
    </v-tooltip>
    <div 
      :class="{'active': editor3DViewActive}"
      @click.stop="goTo3DView"
    >
      <svg
        v-if="isTextureUpdated"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :d="Editor3DViewIconData"
          :fill="Editor3DViewColor"
        />
      </svg>
      <v-progress-circular
        v-else
        :value="50"
        :color="primaryViolet"
        indeterminate
      />
    </div>
  </div>
</template>

<script lang="ts">
import useViewSwitcher from '@/components/Editor/ViewSwitcher/ViewSwitcher';

export default {
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useViewSwitcher();
  },
};
</script>

<style lang="scss" scoped>
@import "ViewSwitcher";
</style>
