<template>
  <div class="delivery-profile__heading">
    <div class="delivery-profile__heading-links">
      <router-link to="/shipping">
        {{ $t('shipping.title__text') }}
      </router-link> / 
      <span class="current-route">
        {{ currentDeliveryProfile && currentDeliveryProfile.name }}
      </span>
    </div>
    <div class="delivery-profile__heading-title">
      <div
        v-if="!isEditingName"
        class="delivery-profile__heading-title__name"
      >
        <h2 class="delivery-profile__heading-title__name-text">
          {{ currentDeliveryProfile && currentDeliveryProfile.name }}
        </h2>
        <BaseButton
          class="delivery-profile__heading-title__name-button"
          icon="mdi-pencil"
          :color="black54"
          no-margin-top
          dark
          @click="openEditNameForm"
        />
      </div>
      <v-form
        v-else
        v-model="isValidName"
        v-click-outside="{
          handler: saveEditedName,
          include: () => ignorableClickOutsideElements
        }"
        class="delivery-profile__heading-title__name"
      >
        <TextField
          v-model.trim="name"
          class="delivery-profile__heading-title__name-text-field"
          input-type="text"
          :rules="nameFieldRules"
          :outlined="false"
          autofocus
          @keydown.enter.stop.prevent="saveEditedName"
        />
        <BaseButton
          class="delivery-profile__heading-title__name-check-button"
          icon="mdi-check"
          color="white"
          no-margin-top
          text
          @click="saveEditedName"
        />
      </v-form>
      <div class="delivery-profile__heading-title__actions">
        <span
          class="delivery-profile__heading-title__actions-create-zone"
          @click="$emit('openShippingZoneModal')"
        >
          {{ $t('shipping.createShippingZone__text') }}
        </span>
        <BaseButton
          v-if="currentDeliveryProfile && currentDeliveryProfile.zones.length"
          class="delivery-profile__heading-title__actions-update-shopify"
          :button-text="$t('shipping.saveOnShopify__text')"
          :is-loading="isLoading"
          no-margin-top
          dark
          outlined
          @click="saveShopify"
        >
          <template #prependSvg>
            <img
              class="shopify-icon"
              src="@/assets/icons/shopify-icon-black.svg"
              alt="shopify-icon"
            >
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useDeliveryProfileHeading from './DeliveryProfileHeading';

export default {
  components: {
    BaseButton,
    TextField,
  },
  props: {
    deliveryProfileId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    return useDeliveryProfileHeading(props);
  },
};
</script>

<style lang="scss">
@import 'DeliveryProfileHeading';
</style>
