import { AxiosResponse } from 'axios';

import {
  NewCredentials,
  NewPassword,
  SignUpData,
} from '@/store/Authorization/types';
import { instance } from '@/utils/axios/instance';

export const UserAPI = {
  getCustomFonts: async (
    data: { id: number },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`users/${data.id}/custom-fonts`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getStore: async (id: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/users/${id}/stores`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  patchUser: async (
    data: { customFontList: string, id: number },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.patch(`users/${data.id}`, data);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  resetPassword: async (
    data: { password: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('reset-password', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  sendActivationEmail: async (
    data: { email: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('send-activation-mail', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  sendResetEmail: async (
    data: { email: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('send-reset-mail', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  signIn: async (
    data: { email: string, password: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('sign-in', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  signInWithSocials: async (
    data: { idToken: string, storeConnectionToken?: string },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('sign-in-socials', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  signUp: async (
    data: SignUpData,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('sign-up', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  updateCredentials: async (
    data: NewCredentials,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('update-credentials', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  updatePassword: async (
    data: NewPassword,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('update-password', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
