<template>
  <div class="image-settings-mobile">
    <Alignments
      v-if="isAlignmentsActive"
      v-click-outside="closeAlignments"
      :is-mobile="isMobile"
      :is-disabled="lockInfo.lockedMovement"
    />
    <Rotation
      v-if="isRotationActive"
      v-click-outside="closeRotation"
      :is-mobile="isMobile"
      :is-disabled="lockInfo.lockedRotation"
    />
    <div class="image-settings-mobile__panel">
      <div
        :class="{'disabled': lockInfo.lockedDeletion}"
        class="image-settings-mobile__option"
        @click="$emit('deleteSelectedObject')"
      >
        <BaseIcon>
          <TrashcanIcon />
        </BaseIcon>
        <span>{{ $t('redactor.delete__button') }}</span>
      </div>
      <div
        class="image-settings-mobile__option"
        :class="{disabled: lockInfo.lockedRotation}"
        @click="toggleRotation"
      >
        <BaseIcon>
          <RotationIcon />
        </BaseIcon>
        <span>{{ $t('redactor.rotation__label') }}</span>
      </div>
      <div
        class="image-settings-mobile__option"
        :class="{disabled: lockInfo.lockedMovement}"
        @click="toggleAlignments"
      >
        <div
          class="image-settings__icon"
        >
          <BaseIcon>
            <AlignHorizontalCenterIcon />
          </BaseIcon>
          <BaseIcon
            class="arrow-icon"
            :class="{'arrow-active': isAlignmentsActive }"
          >
            <ChevronIcon />
          </BaseIcon>
        </div>
        <span>{{ $t('alignments.alignment__label') }}</span>
      </div>
      <Resolution
        v-if="activeImage.dpi"
        class="image-settings-mobile__option"
        :dpi="activeImage.dpi"
        is-mobile
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import useImageSettingsMobile from '@/components/Editor/ImageSettings/ImageSettingsMobile/ImageSettingsMobile';
import Resolution from '@/components/Editor/Resolution/Resolution.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import AlignHorizontalCenterIcon from '@/components/icons/AlignHorizontalCenterIcon.vue';
import ChevronIcon from '@/components/icons/ChevronIcon.vue';
import RotationIcon from '@/components/icons/RotationIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';

export default {
  components: {
    AlignHorizontalCenterIcon,
    Alignments,
    BaseIcon,
    ChevronIcon,
    Resolution,
    Rotation,
    RotationIcon,
    TrashcanIcon,
  },
  props: {
    activeImage: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
      default: () => defaultLockObject,
    },
  },
  setup() {
    return useImageSettingsMobile();
  },
};
</script>

<style lang="scss" scoped>
@import "ImageSettingsMobile";
</style>
