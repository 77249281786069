import { Size } from '@/components/Editor/types/editor';

export const centerObjectPosition = (
  { height, width }: Size,
  zoomToDefault: number,
): { left: number, top: number } => {
  return {
    left: width / zoomToDefault / 2,
    top: height / zoomToDefault / 2,
  };
};
