import { AxiosResponse } from 'axios';
import { AmountOfCards, SortItems } from '@/types';
import { instance } from '@/utils/axios/instance';
import { isTabletAndLower } from '@/utils/Mobile';

export const CollectionsAPI = {
  getAllUserCollections: async (
    { selectedSort = SortItems.New }: { selectedSort: SortItems } = {
      selectedSort: SortItems.New,
    },
  ): Promise<any> => {
    try {
      const response = await instance.get('/collections/all', {
        params: {
          selectedSort,
        },
      });
      return response.data;
    } catch (e) {
      return e.response;
    }
  },
  changeCollectionImage: async (
    { id, image }: {
      id: number,
      image: File
    },
  ): Promise<AxiosResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', image);
            
      const response = await instance.put(
        `/collection/change-preview-collection/${id}`,
        formData,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  createNewCollection: async (name: string): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('/collection', { name });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  editCollectionName: async (
    { id, name }: {
      id: number,
      name: string
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/collection/${id}`, { name });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getCollections: async (
    {
      collectionType,
      limit,
      page,
      selectedSort,
      userId,
    }: {
      collectionType: string,
      limit: number,
      page: number,
      selectedSort: SortItems,
      userId: number
    },
  ): Promise<AxiosResponse> => {
    try {
      const numberOfItemsPerDevice = isTabletAndLower
        ? AmountOfCards.mobile
        : AmountOfCards.desktop;
      const response = await instance.get('/collections',
        {
          params: {
            limit,
            page,
            selectedSort,
            collectionType,
            userId,
            numberOfItemsPerDevice,
          },
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  publishCollection: async (id: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/collection/${id}/activate`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  removeCollection: async (id: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(`/collection/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
