import { actions } from '@/store/Shipping/actions';
import { getters } from '@/store/Shipping/getters';
import { mutations } from '@/store/Shipping/mutations';
import { ShippingState } from '@/store/Shipping/types';

const state: ShippingState = {
  amountOfPages: 1,
  currentDeliveryProfile: null,
  currentPage: 1,
  deliveryProfiles: [],
  isLoading: false,
  shippingRegions: [],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
