import { TranslateResult } from 'vue-i18n';
import { UserRoles } from '@/constants/roles';
import { Collection } from '@/store/Collections/types';
import { Template } from '@/store/Templates/types';

declare global {
  interface Window {
    addToOrder: (data: string) => void;
  }
}

export enum SortItems {
  New = 'Date - new first',
  Old = 'Date - old first',
  Alphabetically = 'Name - A ➝ Z',
  ReverseAlphabetically = 'Name - Z ➝ A'
}

export enum SkeletonTypes {
  cards = 'cards',
  upload = 'upload',
}

export enum SkeletonCards {
  mobile = 4,
  desktop = 8,
}

export interface SearchFoundProducts {
  isEmpty: boolean;
  myCollections: Collection[];
  myTemplates: Template[];
  notFound: boolean;
  publicCollections: Collection[];
  publicTemplates: Template[];
}

export enum AmountOfCards {
  mobile = 8,
  desktop = 12
}

export interface MenuItem {
  action: () => void;
  disabled?: boolean;
  isVisible?: boolean;
  roles: UserRoles[];
  title: TranslateResult;
}

export enum OverflowValues {
  hidden = 'hidden',
  initial = 'initial'
}

export enum ToggleTypes {
  on = 'on',
  off = 'off',
}
