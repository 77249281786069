export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_SELECTED_COLLECTION = 'SET_SELECTED_COLLECTION';
export const SET_TEMPLATE_COVER = 'SET_TEMPLATE_COVER';
export const SET_TEMPLATE_NAME = 'SET_TEMPLATE_NAME';
export const EDIT_TEMPLATE_NAME = 'EDIT_TEMPLATE_NAME';
export const INIT_TEMPLATES = 'INIT_TEMPLATES';
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const CHANGE_TEMPLATE_COVER = 'CHANGE_TEMPLATE_COVER';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_SELECTED_COLLECTION = 'GET_SELECTED_COLLECTION';
export const GET_IS_TEMPLATES_LOADING = 'GET_IS_TEMPLATES_LOADING';
export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING';
export const SAVE_EDITED_TEMPLATE = 'SAVE_EDITED_TEMPLATE';
export const UPDATE_TEMPLATE_DETAILS = 'UPDATE_TEMPLATE_DETAILS';
export const GET_TEMPLATES_SELECTED_SORT = 'GET_TEMPLATES_SELECTED_SORT';
export const GET_TEMPLATES_AMOUNT_OF_PAGES = 'GET_TEMPLATES_AMOUNT_OF_PAGES';
export const SET_TEMPLATES_AMOUNT_OF_PAGES = 'SET_TEMPLATES_AMOUNT_OF_PAGES';
export const SET_TEMPLATES_SELECTED_SORT = 'SET_TEMPLATES_SELECTED_SORT';
export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES';
export const ADD_TEMPLATES = 'ADD_TEMPLATES';
export const RESET_TEMPLATE_SORTING = 'RESET_TEMPLATE_SORTING';
export const LOAD_MORE_TEMPLATES = 'LOAD_MORE_TEMPLATES';
export const GET_SHOPIFY_PRODUCT = 'GET_SHOPIFY_PRODUCT';
export const SUBMIT_TEMPLATE = 'SUBMIT_TEMPLATE';
export const COPY_TEMPLATE = 'COPY_TEMPLATE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SET_TEMPLATE_PRODUCT_DETAILS = 'SET_TEMPLATE_PRODUCT_DETAILS';
export const CHECK_FONT = 'CHECK_FONT';
export const GET_IS_TEMPLATE_IMAGE_LOADING = 'GET_IS_TEMPLATE_IMAGE_LOADING';
export const SET_IS_TEMPLATE_IMAGE_LOADING = 'SET_IS_TEMPLATE_IMAGE_LOADING';
export const GET_IS_EDITING_RIGHTS_TEMPLATES = 'GET_IS_EDITING_RIGHTS_TEMPLATES';
export const SET_IS_EDITING_RIGHTS_TEMPLATES = 'SET_IS_EDITING_RIGHTS_TEMPLATES';
export const GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN = 'GET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN';
export const SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN = 'SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN';
export const GET_CURRENT_TEMPLATE = 'GET_CURRENT_TEMPLATE';
export const UPDATE_CANVAS_DATA_IN_TEMPLATE = 'UPDATE_CANVAS_DATA_IN_TEMPLATE';
export const UPDATE_CANVAS_DATA = 'UPDATE_CANVAS_DATA';
export const LOAD_CURRENT_TEMPLATE = 'LOAD_CURRENT_TEMPLATE';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
