import {
  BACKGROUND_OBJECT_ID,
} from '@/components/Editor/constants/defaultConfigs';
import {
  FabricObject,
  FabricObjectType,
} from '@/components/Editor/types/fabric';

export const isFabricImage = (object: FabricObject): boolean => {
  return object?.type === FabricObjectType.image || !!object?.src;
};

export const isFabricText = (object: FabricObject): boolean => {
  return object?.type === FabricObjectType.text || !!object?.text;
};

export const isFabricActiveSelection = (object: FabricObject): boolean => {
  return object?.type === FabricObjectType.activeSelection
    || !!object?._objects?.length;
};

export const isFabricBackground = (object: FabricObject): boolean => {
  return object?.type === FabricObjectType.rect
    || object.id === BACKGROUND_OBJECT_ID;
};
