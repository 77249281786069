import { render, staticRenderFns } from "./EditorSideControlsMobile.vue?vue&type=template&id=46ff8804&scoped=true"
import script from "./EditorSideControlsMobile.vue?vue&type=script&lang=ts"
export * from "./EditorSideControlsMobile.vue?vue&type=script&lang=ts"
import style0 from "./EditorSideControlsMobile.vue?vue&type=style&index=0&id=46ff8804&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ff8804",
  null
  
)

export default component.exports