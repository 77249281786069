import { actions } from '@/store/Templates/actions';
import { getters } from '@/store/Templates/getters';
import { mutations } from '@/store/Templates/mutations';
import { TemplatesState } from '@/store/Templates/types';
import { SortItems } from '@/types';

const state: TemplatesState = {
  amountOfPages: 0,
  isCoverImageLoading: false,
  isTemplatesLoading: true,
  localTemplates: [],
  selectedCollection: null,
  selectedSort: SortItems.New,
  isEditingRights: false,
  isAddToMyCollectionsModalOpen: false,
  currentTemplate: null,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
