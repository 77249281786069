import { drawGuidelines } from '@/components/Editor/helpers/Guidelines';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { ToggleTypes } from '@/types';

export const objectMoving = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  canvas[toggle]('object:moving', drawGuidelines);
};
