var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"collection-card",attrs:{"ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.openCollection.apply(null, arguments)}}},[_c('v-img',{staticClass:"preview-image",attrs:{"contain":_vm.isPreviewImageDisplayed,"src":_vm.previewImage}},[_c('div',{staticClass:"preview-image__menu",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.isLocalCollection)?_c('BaseButton',{staticClass:"menu-close__btn",attrs:{"icon":"mdi-close","dark":"","no-margin-top":"","icon-size":"22","color":_vm.black54},on:{"click":_vm.removeLocalCollection}}):_vm._e(),(_vm.isEditingRights && !_vm.isLocalCollection)?_c('BaseMenu',{attrs:{"min-width":"210"},scopedSlots:_vm._u([{key:"content",fn:function({ closeMenu }){return [_c('v-list',{attrs:{"dark":""}},_vm._l((_vm.menuItemsList),function(item,i){return _c('v-list-item',{key:i,attrs:{"height":"100","disabled":item.disabled},on:{"click":function($event){$event.stopPropagation();item.action(); closeMenu();}}},[_c('v-list-item-title',{staticClass:"menu-item__title"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)]}}],null,false,2819536175)}):_vm._e()],1)]),(_vm.isLocalCollection)?_c('v-form',{model:{value:(_vm.isValidName),callback:function ($$v) {_vm.isValidName=$$v},expression:"isValidName"}},[_c('v-card-title',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        handler: () => _vm.editCollection(_vm.EditTypes.collectionCreate),
        include: () => _vm.ignorableClickOutsideElements
      }),expression:"{\n        handler: () => editCollection(EditTypes.collectionCreate),\n        include: () => ignorableClickOutsideElements\n      }"}],staticClass:"collection-card__title"},[_c('TextField',{staticClass:"edit-field",attrs:{"autofocus":"","input-type":"text","placeholder":_vm.$t('collections.newCollectionName__placeholder'),"rules":_vm.editNameRules,"color":_vm.primaryViolet,"outlined":false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.editCollection(_vm.EditTypes.collectionCreate)}},model:{value:(_vm.fieldEditName),callback:function ($$v) {_vm.fieldEditName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"fieldEditName"}}),_c('BaseButton',{staticClass:"close-field",attrs:{"icon":"mdi-check","color":"white","apply-color-to-text":"","no-margin-top":"","disabled":!_vm.isValidName},on:{"click":function($event){return _vm.editCollection(_vm.EditTypes.collectionCreate)}}})],1)],1):(!_vm.isEditCollectionName)?_c('v-card-title',{staticClass:"collection-card__title no-edit"},[_c('div',{staticClass:"title__text"},[_vm._v(" "+_vm._s(_vm.collection.name)+" ")]),(_vm.isEditingRights)?_c('BaseButton',{staticClass:"title__edit",attrs:{"light":"","icon":"mdi-pencil","no-margin-top":"","color":_vm.black50},on:{"click":_vm.toggleEditField}}):_vm._e()],1):_c('v-form',{model:{value:(_vm.isValidName),callback:function ($$v) {_vm.isValidName=$$v},expression:"isValidName"}},[_c('v-card-title',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        handler: () => _vm.editCollection(_vm.EditTypes.collectionName),
        include: () => _vm.ignorableClickOutsideElements
      }),expression:"{\n        handler: () => editCollection(EditTypes.collectionName),\n        include: () => ignorableClickOutsideElements\n      }"}],staticClass:"collection-card__title"},[_c('TextField',{staticClass:"edit-field",attrs:{"autofocus":"","input-type":"text","rules":_vm.editNameRules,"color":_vm.primaryViolet,"outlined":false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.editCollection(_vm.EditTypes.collectionName)}},model:{value:(_vm.fieldEditName),callback:function ($$v) {_vm.fieldEditName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"fieldEditName"}}),_c('BaseButton',{staticClass:"close-field",attrs:{"icon":"mdi-check","color":"white","apply-color-to-text":"","no-margin-top":"","disabled":!_vm.isValidName},on:{"click":function($event){return _vm.editCollection(_vm.EditTypes.collectionName)}}})],1)],1),_c('input',{ref:"imageInput",staticClass:"image-input",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){$event.stopPropagation();return _vm.changeCollectionImage.apply(null, arguments)},"click":function($event){$event.stopPropagation();}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }