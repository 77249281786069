import { computed } from 'vue';

export const useBaseIcon = props => {
  const iconStyles = computed(() => ({
    color: props.color,
    height: `${props.size}px`,
    width: `${props.size}px`,
  }));

  return {
    iconStyles,
  };
};
