import { UserRoles } from '@/constants/roles';

export interface User {
  customFonts: string;
  email: string;
  exp: number | null;
  forename: string;
  iat: number | null;
  id: number | null;
  isActivated: boolean;
  role: UserRoles;
  surname: string;
}

export interface UserState {
  isAuthorized: boolean;
  localization: string;
  signInWithType: SignInType;
  user: User;
}

export enum SignInType {
  default = 'default',
  socials = 'socials'
}

export interface NewPassword {
  email: string,
  password: string
}

export interface NewCredentials {
  email: string;
  newEmail: string;
  newFirstName: string;
  newLastName: string;
}

export interface ResetPasswordData {
  email: string;
  password: string;
  resetToken: string;
}

type payloadType = number
  | string
  | object
  | boolean
  | (number
  | string
  | object)[];

export interface Context {
  commit: (type: string, payload: payloadType) => void;
  dispatch: (type: string, payload: payloadType) => void;
  state: UserState;
}

export interface UserData {
  data: {
    email: string,
    idToken: string,
    password: string,
    storeConnectionToken?: string;
  },
  type: SignInType
}

export interface SignUpData {
  email: string;
  forename: string;
  password: string;
  storeConnectionToken?: string;
  surname: string;
}
