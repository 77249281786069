import { Vector2 } from 'three';

export const getEventPositionOnCanvas = (event, renderer): Vector2 => {
  const rect = renderer.domElement.getBoundingClientRect();
  let x: number;
  let y: number;

  if (event.touches?.length) {
    x = event.touches[0].pageX - rect.left;
    y = event.touches[0].pageY - rect.top;
  } else {
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
  }

  return new Vector2(x, y);
};
