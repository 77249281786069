import { AxiosResponse } from 'axios';
import { useI18n } from 'vue-i18n-composable';
import router from '@/router';
import store from '@/store';
import { SET_NOTIFICATION } from '@/store/constants';
import { NotificationType } from '@/types/notification';

interface HandleResponse {
  handleSuccessStatus?: (arg0: any) => any;
  response: AxiosResponse;
  successStatus: number;
}

export const handleResponse = ({
  handleSuccessStatus,
  response,
  successStatus,
}: HandleResponse) => {
  const { t } = useI18n();
  const { data, status } = response;
  switch (status) {
  case successStatus:
    return handleSuccessStatus
        && handleSuccessStatus(data);
  case 401:
    router.push('/sign-in');
    break;
  case 404:
    router.replace({ name: 'not-found' });
    break;
  case 500:
    store.dispatch(SET_NOTIFICATION, {
      text: t('notifications.somethingWentWrong'),
      type: NotificationType.Error,
    });
    break;
  default:
    store.dispatch(SET_NOTIFICATION, {
      text: response.data,
      type: NotificationType.Error,
    });
  }
};
