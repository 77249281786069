<template>
  <ModalWindow
    :is-open="isOpen"
    override-with-class="reset-modal-wrapper"
    content-class="reset-modal-card"
    @close="$emit('close')"
  >
    <div
      v-if="!isResettingThemes && !isResetedThemes"
      class="before-reset"
    >
      <h2>{{ $t('profile.resetChanges__text') }}</h2>
      <div>
        <p>{{ $t('profile.sureWantToReset__text') }}</p>
        <p>
          {{ $t('profile.resettingWillRemove__text') }}
          <ul>
            <li>{{ $t('profile.removeList.pluginChanges__text') }}</li>
            <li>{{ $t('profile.removeList.shopifyThemes__text') }}</li>
            <li>{{ $t('profile.removeList.customOrders__text') }}</li>
            <li>{{ $t('profile.removeList.collections__text') }}</li>
          </ul>
        </p>
      </div>
    </div>
    <div
      v-if="isResettingThemes"
      class="resetting"
    >    
      <h2>{{ $t('profile.resetChanges__text') }}</h2>
      <div class="resetting-content">
        <img
          class="resetting-content__spinner"
          src="@/assets/loading-icon.svg"
          :alt="$t('profile.spinner__alt')"
        >
        <p class="resetting-content__text">
          {{ $t('profile.resettingChanges__text') }}
        </p>
      </div>
    </div>
    <div
      v-if="isResetedThemes"
      class="reseted"
    >
      <h2>{{ $t('profile.resetChanges__text') }}</h2>
      <div class="reseted-content">
        <img
          class="reseted-content__image"
          src="@/assets/success-check.svg"
          :alt="$t('profile.successCheck__alt')"
        >
        <p class="reseted-content__text">
          {{ $t('profile.successfulReset__text') }}
        </p>
      </div>
    </div>
    <BaseButton
      v-if="!isResetedThemes"
      class="reset-modal-btn"
      :color="primaryViolet"
      :disabled="isResettingThemes"
      :button-text="$t('profile.resetChanges__text')"
      large
      @click="$emit('resetChanges')"
    />
    <BaseButton
      v-if="isResetedThemes"
      class="reset-modal-btn okay-btn"
      :color="primaryViolet"
      :button-text="$t('profile.okay__text')"
      large
      @click="$emit('close')"
    />
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { primaryViolet } from '@/constants/colors';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    isOpen: Boolean,
    isResetedThemes: Boolean,
    isResettingThemes: Boolean,
  },
  setup() {
    return {
      primaryViolet,
    };
  },
};
</script>
