<template>
  <div class="delivery-profile__rates">
    <DeliveryProfileZone
      v-for="zone in zones"
      :key="zone.id"
      :zone="zone"
      @openShippingZoneModal="zone => $emit('openShippingZoneModal', zone)"
    />
  </div>
</template>

<script lang="ts">
import DeliveryProfileZone from './DeliveryProfileZone/DeliveryProfileZone.vue';

export default {
  components: {
    DeliveryProfileZone,
  },
  props: {
    zones: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss">
@import 'DeliveryProfileZones';
</style>
