<template>
  <div class="confirm-email">
    <BaseTitle>
      {{ $t('confirmEmail.checkYourEmail__title') }}
    </BaseTitle>
    <div class="confirmEmail__text confirmEmail__text--bold">
      {{ email }}
    </div>
    <div class="confirm-email__text">
      {{ $t('confirmEmail.checkYourEmail__text') }}
    </div>
    <BaseButton
      class="confirm-email__button"
      has-background
      :button-text="$t('confirmEmail.gotEmailThanks__button')"
      :large="isMobile"
      @click="redirectToSignIn"
    />
    <div class="confirm-email__text confirm-email__text--black">
      {{ $t('confirmEmail.didntGetTheEmail__text') }}
      <span
        class="underline-animate"
        @click="sendEmailAgain"
      >
        {{ $t('confirmEmail.sendMeAnotherEmail__text') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import useConfirmEmailForm from './ConfirmEmailForm';

export default {
  components: {
    BaseButton,
    BaseTitle,
  },
  setup() {
    return useConfirmEmailForm();
  },
};
</script>

<style lang="scss" scoped>
  @import 'ConfirmEmailForm';
</style>
