<template>
  <div class="user-guide-page">
    <h1 class="user-guide-page__title">
      {{ title }}
    </h1>
    <div class="user-guide-page__content">
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.welcomePage.dearUser__text') }},
      </p>
      <p class="user-guide-page__content-text">
        {{ $t('userGuide.welcomePage.belowisDescribedMainFunc__text') }}
      </p>
      <ul class="user-guide-page__content-list">
        <li 
          v-for="tab in tabs"
          v-show="!tab.hideOnWelcomePage"
          :key="tab.name"
          class="user-guide-page__content-list__item"
        >
          <a 
            class="user-guide-page__content-list__item-link" 
            @click.stop="$emit('navigateToTab', tab.name)"
          >
            {{ tab.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>
