import {
  GET_CURRENT_ORDER,
  GET_ORDER_LOADING,
  GET_ORDERS,
  GET_ORDERS_AMOUNT_OF_PAGES,
} from './constants';
import { OrderState } from './types';

export const getters = {
  [GET_ORDERS]: (state: OrderState): OrderState['orders'] => state.orders,
  [GET_ORDER_LOADING]: (state: OrderState): OrderState['isLoading'] => state.isLoading,
  [GET_CURRENT_ORDER]: (state: OrderState): OrderState['currentOrder'] =>
    state.currentOrder,
  [GET_ORDERS_AMOUNT_OF_PAGES]: (
    state: OrderState,
  ): OrderState['amountOfPages'] => state.amountOfPages,
};
