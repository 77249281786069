import {
  updateCanvasDataByMesh,
} from '@/components/Editor/helpers/fabric/canvasModifiers/updateCanvasData';
import { Collection } from '@/store/Collections/types';
import {
  ADD_TEMPLATES,
  CLEAR_TEMPLATES,
  REMOVE_TEMPLATE,
  SET_CURRENT_TEMPLATE,
  SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN,
  SET_IS_EDITING_RIGHTS_TEMPLATES,
  SET_IS_TEMPLATE_IMAGE_LOADING,
  SET_SELECTED_COLLECTION,
  SET_TEMPLATE_COVER,
  SET_TEMPLATE_NAME,
  SET_TEMPLATE_PRODUCT_DETAILS,
  SET_TEMPLATES,
  SET_TEMPLATES_AMOUNT_OF_PAGES,
  SET_TEMPLATES_LOADING,
  SET_TEMPLATES_SELECTED_SORT,
  UPDATE_CANVAS_DATA,
} from '@/store/Templates/constants';
import {
  ProductDetails,
  Template,
  TemplateFields,
  TemplatesState,
} from '@/store/Templates/types';
import { SortItems } from '@/types';

const findTemplate = (
  state: TemplatesState,
  field: string,
  value: number | string | boolean,
) => state.localTemplates.find(template => template[field] === value);

export const mutations = {
  [SET_IS_EDITING_RIGHTS_TEMPLATES]: (
    state: TemplatesState,
    isEditingRights: boolean,
  ) => {
    state.isEditingRights = isEditingRights;
  },
  [ADD_TEMPLATES]: (
    state: TemplatesState,
    templates: Template[],
  ) => {
    state.localTemplates = [ ...state.localTemplates, ...templates ];
  },
  [CLEAR_TEMPLATES]: (
    state: TemplatesState,
  ) => {
    state.localTemplates = [];
  },
  [REMOVE_TEMPLATE]: (
    state: TemplatesState,
    id: number,
  ) => {
    state.localTemplates =
      state.localTemplates.filter(template => template.id !== id);
  },
  [SET_IS_TEMPLATE_IMAGE_LOADING]: (
    state: TemplatesState,
    isLoading: boolean,
  ) => {
    state.isCoverImageLoading = isLoading;
  },
  [SET_SELECTED_COLLECTION]: (
    state: TemplatesState,
    collection: Collection,
  ) => {
    state.selectedCollection = collection;
  },
  [SET_TEMPLATE_COVER]: (
    state: TemplatesState,
    {
      id,
      imagePath,
    }:
      {
        id: number,
        imagePath: string
      },
  ) => {
    const foundTemplate = findTemplate(state, TemplateFields.id, id);
    if (foundTemplate) foundTemplate.imagePath = imagePath;
  },
  [SET_TEMPLATE_NAME]: (
    state: TemplatesState,
    {
      id,
      name,
    }:
      {
        id: number,
        name: string
      },
  ) => {
    const foundTemplate = findTemplate(state, TemplateFields.id, id);
    if (foundTemplate) foundTemplate.name = name;
    if (state.currentTemplate) state.currentTemplate.name = name;
  },
  [SET_TEMPLATE_PRODUCT_DETAILS]: (
    state: TemplatesState,
    { id, productDetails }: {
      id: number,
      productDetails: ProductDetails,
    },
  ) => {
    const template = findTemplate(state, TemplateFields.id, id);
    if (template) template.productDetails = productDetails;
  },
  [SET_TEMPLATES]: (
    state: TemplatesState,
    templates: Template[],
  ) => {
    state.localTemplates = templates;
  },
  [SET_TEMPLATES_AMOUNT_OF_PAGES]: (
    state: TemplatesState,
    amountOfPages: number,
  ) => {
    state.amountOfPages = amountOfPages;
  },
  [SET_TEMPLATES_LOADING]: (state: TemplatesState, isLoading: boolean) => {
    state.isTemplatesLoading = isLoading;
  },
  [SET_TEMPLATES_SELECTED_SORT]: (
    state: TemplatesState,
    sort: SortItems,
  ) => {
    state.selectedSort = sort;
  },
  [SET_IS_ADD_TO_MY_COLLECTIONS_MODAL_OPEN]: (
    state: TemplatesState,
    flag: boolean,
  ): void => {
    state.isAddToMyCollectionsModalOpen = flag;
  },
  [UPDATE_CANVAS_DATA]: (
    state: TemplatesState,
  ): void => {
    if (state.currentTemplate) {
      const updatedCanvasData = updateCanvasDataByMesh();
      state.currentTemplate.canvasData = updatedCanvasData;
    }
  },
  [SET_CURRENT_TEMPLATE]: (state: TemplatesState, template: Template): void => {
    state.currentTemplate = template;
  },
};
