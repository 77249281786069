export const SEND_TO_SHOPIFY = 'SEND_TO_SHOPIFY';

export const GET_IS_THEMES_LOADING = 'GET_IS_THEMES_LOADING';
export const SET_IS_THEMES_LOADING = 'SET_IS_THEMES_LOADING';

export const GET_THEMES = 'GET_THEMES';
export const SET_THEMES = 'SET_THEMES';
export const UPDATE_THEME = 'UPDATE_THEME';
export const REVERT_THEME = 'REVERT_THEME';

export const REVERT_STORE = 'REVERT_STORE';
export const ACTIVATE_STORE = 'ACTIVATE_STORE';

export const RESET_CHANGES = 'RESET_CHANGES';
export const GET_IS_DEACTIVATING_LOADING = 'GET_IS_DEACTIVATING_LOADING';
export const SET_IS_DEACTIVATING_LOADING = 'SET_IS_DEACTIVATING_LOADING';

export const GET_SHOPIFY_STORE_INFO = 'GET_SHOPIFY_STORE_INFO';
export const SET_SHOPIFY_STORE_INFO = 'SET_SHOPIFY_STORE_INFO';

export const GET_STORE_WEBSITE = 'GET_STORE_WEBSITE';

export const GET_STORE = 'GET_STORE';
export const SET_STORE = 'SET_STORE';

export const GET_IS_DEACTIVATED_STORE = 'GET_IS_DEACTVATED_STORE';
export const SET_IS_DEACTIVATED_STORE = 'SET_IS_DEACTVATED_STORE';

export const RESET_STORE = 'RESET_STORE';
