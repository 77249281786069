import axios from 'axios';

export const requestScan = async data => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/api/v1/download/scan`,
      { ...data },
      {
        responseType: data.typeScan === 'file' ? 'blob' : 'text',
      },
    );

    return response;
  } catch ({ response }) {
    return response;
  }
};
