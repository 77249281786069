import { computed } from 'vue';
import { primaryViolet } from '@/constants/colors';
import { googleFontsLink } from '@/constants/editor';
import store from '@/store';
import {
  GET_FONT_TO_REMOVE,
  GET_IS_MOBILE,
  GET_IS_REMOVE_FONT_MODAL_OPEN,
  REMOVE_FONT,
  SET_IS_REMOVE_FONT_MODAL_OPEN,
} from '@/store/Editor/constants';

const useRemoveFontModal = () => {
  const fontToRemove = computed((): string => {
    return store.getters[GET_FONT_TO_REMOVE];
  });
  const isRemoveFontModalOpen = computed((): boolean => {
    return store.getters[GET_IS_REMOVE_FONT_MODAL_OPEN];
  });

  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);

  const removeFont = (): void => {
    store.dispatch(REMOVE_FONT, fontToRemove.value);
  };

  const closeRemoveFontModal = (): void => {
    store.commit(SET_IS_REMOVE_FONT_MODAL_OPEN, false);
  };

  return {
    closeRemoveFontModal,
    fontToRemove,
    googleFontsLink,
    primaryViolet,
    removeFont,
    isRemoveFontModalOpen,
    isMobile,
  };
};
  
export default useRemoveFontModal;
