
import { disposeCanvas } from '@/components/Editor/helpers/fabric/canvasModifiers/disposeCanvas';
import { MeshData, Size } from '@/components/Editor/types/editor';
import {
  CLEAR_TEXTURE_CANVASES,
  PUSH_MESH,
  SET_ACTIVE_MESH,
  SET_IS_MESH_SELECTION_REMOVED,
  SET_IS_MODEL_READY,
  SET_IS_MODEL_READY_TO_LOAD,
  SET_IS_SIDE_SELECTION_ENABLED,
  SET_IS_TEXTURE_UPDATED,
  SET_MODEL_SIZE_IN_MM,
  SET_SINGLE_EDITABLE_MESH,
  SET_TEXTURES_TO_UPDATE,
  UPDATE_TEXTURE_CANVASES,
} from '../constants';
import { EditorState, TextureCanvas, TextureToUpdate } from '../types';
  
export const sceneMutations = {
  [SET_IS_MODEL_READY]: (state: EditorState, flag: boolean): void => {
    state.modelData.isModelReady = flag;
  },
  [PUSH_MESH]: (state: EditorState, mesh: MeshData): void => {
    state.modelData.meshs.push(mesh);
  },
  [SET_IS_SIDE_SELECTION_ENABLED]: (
    state: EditorState,
    flag: boolean,
  ): void => {
    state.modelData.isSideSelectionEnabled = flag;
  },
  [SET_IS_MESH_SELECTION_REMOVED]: (
    state: EditorState,
    flag: boolean,
  ): void => {
    state.modelData.isMeshSelectionRemoved = flag;
  },
  [SET_SINGLE_EDITABLE_MESH]: (
    state: EditorState,
    mesh: MeshData,
  ): void => {
    state.modelData.singleEditableMesh = mesh;
  },
  [SET_MODEL_SIZE_IN_MM]: (
    state: EditorState,
    size: Size | null,
  ): void => {
    state.modelData.modelSizeInMM = size;
  },
  [SET_TEXTURES_TO_UPDATE]: (
    state: EditorState,
    texture: TextureToUpdate,
  ): void => {
    state.modelData.texturesToUpdate = state
      .modelData
      .texturesToUpdate
      .filter(({ meshName }) => meshName !== texture.meshName);
    state.modelData.texturesToUpdate.push(texture);
  },
  [SET_ACTIVE_MESH]: (state: EditorState, mesh: MeshData): void => {
    state.modelData.activeMesh = mesh;
  },
  [SET_IS_MODEL_READY_TO_LOAD]: (state: EditorState, flag: boolean): void => {
    state.modelData.isModelReadyToLoad = flag;
  },
  [UPDATE_TEXTURE_CANVASES]: (
    state: EditorState,
    textureCanvasToUpdate: TextureCanvas,
  ): void => {
    const textureCanvases = state.modelData.textureCanvases;
    const textureCanvasIndex = textureCanvases.findIndex(
      (textureCanvas: TextureCanvas): boolean => {
        return textureCanvas.meshName === textureCanvasToUpdate.meshName;
      },
    );
    if (textureCanvasIndex !== -1) {
      disposeCanvas(textureCanvases[textureCanvasIndex].canvas);
      textureCanvases[textureCanvasIndex] = textureCanvasToUpdate;
    } else {
      textureCanvases.push(textureCanvasToUpdate);
    }
  },
  [CLEAR_TEXTURE_CANVASES]: (state: EditorState): void => {
    state.modelData.textureCanvases.forEach(
      (textureCanvas: TextureCanvas): void => {
        disposeCanvas(textureCanvas.canvas);
      },
    );
    state.modelData.texturesToUpdate.forEach(textureData => {
      textureData.texture.dispose();
    });
  },
  [SET_IS_TEXTURE_UPDATED]: (state: EditorState, flag: boolean): void => {
    state.modelData.isTextureUpdated = flag;
  },
};
  
