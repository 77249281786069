<template>
  <header
    class="header"
    :class="{ 'search-open': isShowSearch }"
  >
    <router-link
      v-if="!isShowSearch"
      class="header-logo"
      :to="linkToMyCollections"
    >
      <img
        :src="logoDefault"
        :alt="$t('header.logoImage__alt')"
      >
    </router-link>
    <div class="header-actions">
      <SearchField
        :is-show-search="isShowSearch"
        @closeSearch="closeSearch"
        @openSearch="openSearch"
      />
      <BaseButton
        v-if="isShowSearch"
        no-margin-top
        icon="mdi-close"
        icon-size="22"
        dark
        @click="closeSearch"
      />
      <BurgerMenu
        v-else
        :nav-items="navItems"
        :current-tab="currentTab"
        @changeTab="$emit('changeTab', $event)"
      />
    </div>
    <PricingModal
      :is-open="isPricingModalOpen"
      @close="$emit('closePricingModal')"
    />
    <PrivacyPolicyModal
      :is-open="isPrivacyPolicyModalOpen"
      @close="$emit('closePrivacyPolicyModal')"
    />
    <ContactSupportModal
      :is-open="isContactSupportModalOpen"
      @close="$emit('closeContactSupportModal')"
    />
    <ReturnsPolicyModal
      :is-open="isReturnsPolicyModalOpen"
      @close="$emit('closeReturnsPolicyModal')"
    />
  </header>
</template>

<script lang="ts">
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ContactSupportModal from '@/components/ContactSupportModal/ContactSupportModal.vue';
import useHeaderMobile from '@/components/Header/HeaderMobile/HeaderMobile';
import PricingModal from '@/components/PricingModal/PricingModal.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import ReturnsPolicyModal from '@/components/ReturnsPolicyModal/ReturnsPolicyModal.vue';
import SearchField from '@/components/SearchField/SearchField.vue';

export default {
  components: {
    BaseButton,
    BurgerMenu,
    ContactSupportModal,
    PricingModal,
    SearchField,
    PrivacyPolicyModal,
    ReturnsPolicyModal,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    isContactSupportModalOpen: {
      required: true,
      type: Boolean,
    },
    isPricingModalOpen: {
      required: true,
      type: Boolean,
    },
    isPrivacyPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    isReturnsPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    linkToMyCollections: {
      required: true,
      type: String,
    },
    logoDefault: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useHeaderMobile();
  },
};
</script>

<style lang="scss" scoped>
@import 'HeaderMobile';
</style>
