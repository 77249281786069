import { ref, watch } from 'vue';
import { TabItem } from '@/components/Header/types';
import { useStopScroll } from '@/composables/useStopScroll';
import { primaryBlack } from '@/constants/colors';
import router from '@/router';
import store from '@/store';
import { LOG_OUT } from '@/store/Authorization/constants';

const useBurgerMenu = () => {
  const { disableScroll, enableScroll } = useStopScroll();
  const isMenuOpen = ref(false);
  const handleLogOut = (): Promise<void> => store.dispatch(LOG_OUT);

  const toggleMenu = (): void => {
    isMenuOpen.value = !isMenuOpen.value;
  };

  const getLinkToRedirect = (item: TabItem): string => {
    return router.currentRoute.path !== item.to
      ? item.to
      : '';
  };

  watch(isMenuOpen, (): void => {
    isMenuOpen.value ? disableScroll() : enableScroll();
  });

  return {
    handleLogOut,
    isMenuOpen,
    primaryBlack,
    toggleMenu,
    getLinkToRedirect,
  };
};

export default useBurgerMenu;
