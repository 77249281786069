<template>
  <div id="burger-menu">
    <v-overlay :value="isMenuOpen" />
    <BaseButton
      class="burger-menu__open-button"
      icon="mdi-menu"
      no-margin-top
      dark
      @click="toggleMenu"
    />
    <v-menu
      v-model="isMenuOpen"
      content-class="burger-menu"
      :close-on-content-click="false"
      left
    >
      <v-card class="burger-menu">
        <div class="burger-menu__header">
          <BaseButton
            icon="mdi-close"
            no-margin-top
            dark
            @click="toggleMenu"
          />
        </div>
        <div class="burger-menu__content">
          <v-tabs
            :value="currentTab"
            class="content-nav"
            hide-slider
            vertical
            @input="$emit('changeTab', $event)"
          >
            <v-tab
              v-for="(item, index) in navItems"
              :key="index"
              :to="getLinkToRedirect(item)"
              class="content-nav__item"
              :class="{ 'content-nav__item_active': item.isActive }"
              @click.stop="item.to || item.action(); toggleMenu();"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div class="burger-menu__footer">
          <div
            v-ripple
            class="footer-button"
            @click.stop="handleLogOut"
          >
            {{ $t('menu.logOut__text') }}
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import useBurgerMenu from '@/components/BurgerMenu/BurgerMenu';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useBurgerMenu();
  },
};
</script>

<style lang="scss">
@import 'BurgerMenu';
</style>
