<template>
  <AuthLayout>
    <ConfirmEmailForm />
  </AuthLayout>
</template>

<script lang="ts">
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import ConfirmEmailForm from './ConfirmEmailForm/ConfirmEmailForm.vue';

export default {
  components: {
    AuthLayout,
    ConfirmEmailForm,
  },
};
</script>
