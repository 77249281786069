import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_AMOUNT_OF_PAGES,
  GET_COLLECTIONS_SELECTED_SORT,
  GET_IS_COLLECTION_IMAGE_LOADING,
  GET_IS_COLLECTIONS_LOADING,
  GET_IS_EDITING_RIGHTS_COLLECTIONS,
  GET_PUBLIC_COLLECTIONS,
} from '@/store/Collections/constants';
import { Collection, CollectionState } from '@/store/Collections/types';
import { SortItems } from '@/types';

export const getters = {
  [GET_COLLECTIONS]: (state: CollectionState): Collection[] =>
    state.localCollections,
  [GET_COLLECTIONS_AMOUNT_OF_PAGES]: (state: CollectionState): number =>
    state.amountOfPages,
  [GET_COLLECTIONS_SELECTED_SORT]: (state: CollectionState): SortItems =>
    state.selectedSort,
  [GET_IS_COLLECTION_IMAGE_LOADING]: (state: CollectionState): boolean =>
    state.isCoverImageLoading,
  [GET_IS_COLLECTIONS_LOADING]: (state: CollectionState): boolean =>
    state.isCollectionsLoading,
  [GET_PUBLIC_COLLECTIONS]: (state: CollectionState): Collection[] =>
    state.localCollections.filter(collection => collection.isPublic),
  [GET_IS_EDITING_RIGHTS_COLLECTIONS]: (state: CollectionState): boolean =>
    state.isEditingRights,
};
