import { ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { localRate } from '@/constants/shipping';
import store from '@/store';
import { REMOVE_DELIVERY_ZONE_LOCAL_RATE } from '@/store/Shipping/constants';
import { ShippingZone } from '@/store/Shipping/types';

const useRateTable = (props: { zone: ShippingZone}) => {
  const { t } = useI18n();
  const tableHeaders = [
    { title: t('shipping.rateName__text') },
    { title: t('shipping.condition__text') },
    {
      icon: '$',
      title: t('shipping.price__text'),
    },
  ];

  const editingItemId = ref();
  const isValidForm = ref(false);
  const removeLocalRate = () =>
    store.dispatch(REMOVE_DELIVERY_ZONE_LOCAL_RATE, props.zone.id);

  const setEditingItemId = (id: number): void => {
    id !== localRate.id && removeLocalRate();
    editingItemId.value = id;
  };

  const closeEditingMode = (): void => {
    removeLocalRate();
    editingItemId.value = null;
  };

  return {
    closeEditingMode,
    editingItemId,
    setEditingItemId,
    tableHeaders,
    isValidForm,
  };
};

export default useRateTable;
