<template>
  <ModalWindow
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <div
      ref="returnsPolicyModal"
      class="returns-policy__content"
    >
      <h1 class="returns-policy__content-heading">
        {{ $t('returnsPolicy.title') }}
      </h1>
      <div class="returns-policy__content-items pretty-scroll">
        <div class="content-item">
          <p class="content-item__text">
            {{ $t('returnsPolicy.paragraph-1') }}
          </p>
          <p class="content-item__text">
            {{ $t('returnsPolicy.paragraph-2') }}
          </p>
          <p class="content-item__text">
            {{ $t('returnsPolicy.paragraph-3') }}
          </p>
          <div
            class="content-item__text"
          >
            <strong>{{ $t('returnsPolicy.paragraph-4.bold-text') }}</strong>
            {{ $t('returnsPolicy.paragraph-4.regular-text') }}
          </div>
          <div
            class="content-item__text"
          >
            <strong>{{ $t('returnsPolicy.paragraph-5.bold-text') }}</strong>
            {{ $t('returnsPolicy.paragraph-5.regular-text') }}
          </div>
          <div
            class="content-item__text"
          >
            <strong>{{ $t('returnsPolicy.paragraph-6.bold-text') }}</strong>
            {{ $t('returnsPolicy.paragraph-6.regular-text') }}
          </div>
        </div>
        <div class="content-item">
          <div
            class="content-item__text"
          >
            <strong>{{ $t('returnsPolicy.paragraph-7.bold-text') }}</strong>
            {{ $t('returnsPolicy.paragraph-7.regular-text') }}
          </div>
          <ol class="content-item__list">
            <li class="list-item">
              {{ $t('returnsPolicy.list.item-1') }}
            </li>
            <li class="list-item">
              {{ $t('returnsPolicy.list.item-2') }}
            </li>
          </ol>
          <p class="content-item__text">
            {{ $t('returnsPolicy.paragraph-8') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__text">
            {{ $t('returnsPolicy.paragraph-9') }}
          </p>
        </div>
      </div>
      <BackToTopButton
        :scrollable-element="returnsPolicyModal"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import { ref } from 'vue';
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { ProductInfo } from '@/constants/productInfo';

export default {
  components: {
    BackToTopButton,
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    const returnsPolicyModal = ref();
    return {
      ProductInfo,
      returnsPolicyModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'ReturnsPolicyModal';
</style>
