var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-design__card"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"template-design__card-template"},[_c('img',{staticClass:"template-design__card-image",attrs:{"src":_vm.templateDesign.img,"alt":_vm.$t('templates.templateDesign__alt')}}),(hover)?_c('BaseButton',{staticClass:"template-design__card-apply",class:{'disabled': _vm.isApplyingDesign},attrs:{"disabled":_vm.isApplyingDesign,"color":"transparent","height":"56","width":"56","no-margin-top":"","has-background":""},on:{"click":function($event){return _vm.$emit('applyDesign', _vm.templateDesign)}},scopedSlots:_vm._u([{key:"prependSvg",fn:function(){return [_c('v-icon',{staticClass:"template-design__card-apply-icon"},[_vm._v(" mdi-plus ")])]},proxy:true}],null,true)}):_vm._e(),(hover)?_c('BaseButton',{staticClass:"template-design__card-remove",attrs:{"color":"transparent","height":"20","width":"20","no-padding":"","no-margin-top":"","has-background":"","ripple":false},on:{"click":function($event){return _vm.$emit('removeDesign', _vm.templateDesign.id)}},scopedSlots:_vm._u([{key:"prependSvg",fn:function(){return [_c('BaseIcon',{staticClass:"template-design__card-remove-icon"},[_c('TrashcanIcon')],1)]},proxy:true}],null,true)}):_vm._e()],1)]}}])}),(_vm.isActive)?_c('v-form',{staticClass:"template-design__card-name",model:{value:(_vm.isValidName),callback:function ($$v) {_vm.isValidName=$$v},expression:"isValidName"}},[_c('TextField',{staticClass:"edit-field",attrs:{"input-type":"text","color":_vm.primaryViolet,"outlined":false,"rules":_vm.nameRules,"hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.$emit('editDesign', {
          id: _vm.templateDesign.id,
          name: _vm.name,
        })}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}}),_c('BaseButton',{attrs:{"icon":"mdi-check","color":_vm.primaryViolet,"disabled":!_vm.isValidName,"light":"","apply-color-to-text":"","no-margin-top":""},on:{"click":function($event){return _vm.$emit('editDesign', {
          id: _vm.templateDesign.id,
          name: _vm.name,
        })}}})],1):_c('div',{staticClass:"template-design__card-name"},[_c('div',[_vm._v(" "+_vm._s(_vm.templateDesign.name)+" ")]),_c('BaseButton',{attrs:{"light":"","icon":"mdi-pencil","no-margin-top":"","color":_vm.black50},on:{"click":_vm.openEditMode}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }