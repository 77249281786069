<template>
  <div class="alignments-mobile">
    <BaseButton
      v-for="{ name, icon } in horizontalAlignments"
      :key="name"
      class="alignments__option"
      @click="$emit('triggerAlignment', name)"
    >
      <template #prependSvg>
        <BaseIcon class="option__icon">
          <component :is="icon" />
        </BaseIcon>
      </template>
    </BaseButton>
    <BaseButton
      v-for="{ name, icon } in verticalAlignments"
      :key="name"
      class="alignments__option"
      @click="$emit('triggerAlignment', name)"
    >
      <template #prependSvg>
        <BaseIcon class="option__icon">
          <component :is="icon" />
        </BaseIcon>
      </template>
    </BaseButton>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';

export default {
  components: { BaseButton, BaseIcon },
  props: {
    horizontalAlignments: {
      required: true,
      type: Array,
    },
    verticalAlignments: {
      required: true,
      type: Array,
    },
  },
  emits: ['triggerAlignment'],
};
</script>

<style lang="scss" scoped>
@import "AlignmentsMobile";
</style>
