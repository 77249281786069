import { throttle } from 'lodash';
import {
  setActiveSelectionId,
} from '@/components/Editor/helpers/fabric/activeObjects/setActiveSelectionId';
import {
  validateActiveObjectProps,
} from '@/components/Editor/helpers/fabric/activeObjects/validateActiveObjectProps';
import {
  modifyDefaultFabricControls,
} from '@/components/Editor/helpers/fabric/controls/modifyDefaultFabricControls';
import {
  lockActiveSelectionWithLockedObject,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import {
  getFontSizeOnResize,
} from '@/components/Editor/helpers/fabric/textObject/getFontSizeOnResize';
import { FabricCanvas, FabricEvent, FabricObject } from '@/components/Editor/types/fabric';
import {
  activeObjectCenterScaling,
} from '@/components/Editor/utils/activeObjectCenterScaling';
import {
  addDPIToFabricObject,
  setFabricObjectBorderByDpi,
} from '@/utils/dpi';

const calculateDpi = throttle(addDPIToFabricObject, 100);

export const changeActiveObjectPropsOnScaled = (
  { target }: FabricEvent,
): void => {
  const obj = target as FabricObject;
  validateActiveObjectProps(obj);
  addDPIToFabricObject(obj.canvas as FabricCanvas, obj);
  setFabricObjectBorderByDpi(obj);
};

export const changeActiveObjectPropsOnScaling = (
  { target }: FabricEvent,
): void => {
  const obj = target as FabricObject;
  validateActiveObjectProps(obj);
  calculateDpi(obj.canvas as FabricCanvas, obj);
  setFabricObjectBorderByDpi(obj);
};

export const changeActiveObjectPropsOnSelected = (
  { target }: FabricEvent,
): void => {
  const obj = target as FabricObject;
  getFontSizeOnResize({ target: obj } as any);
  lockActiveSelectionWithLockedObject(obj);
  setActiveSelectionId(obj);
  modifyDefaultFabricControls(obj);
  activeObjectCenterScaling(target);
};
