<template>
  <ModalWindow
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <div
      ref="privacyPolicyModal"
      class="privacy-policy__content"
    >
      <h1 class="privacy-policy__content-heading">
        {{ $t('privacyPolicy.title') }}
      </h1>
      <div class="privacy-policy__content-items pretty-scroll">
        <div class="content-item">
          <p class="content-item__text">
            {{ $t('privacyPolicy.appIsCommittedToProtect__text') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.personalData.title') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-1') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-2') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-3') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-4') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-5') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-6') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-7') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-8') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.personalData.text.paragraph-9') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.purposeAndUseOfPersonalData.title') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.purposeAndUseOfPersonalData.text.paragraph-1') }}
          </p>
          <ul class="content-item__list">
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-1') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-2') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-3') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-4') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-5') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-6') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.purposeAndUseOfPersonalData.list.item-7') }}
            </li>
          </ul>
          <p class="content-item__text">
            {{ $t('privacyPolicy.purposeAndUseOfPersonalData.text.paragraph-2') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.sharePersonalData.title') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-1') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-1') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-2') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-2') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-3') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-3') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-4') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-4') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-5') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-5') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-6') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-6') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-7') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-7') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-8') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-8') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-9') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.sharePersonalData.text.subtitle-9') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.sharePersonalData.text.paragraph-10') }}
          </p>
          <ul class="content-item__list">
            <li class="list-item">
              {{ $t('privacyPolicy.sharePersonalData.list.item-1') }}
            </li>
            <li class="list-item">
              {{ $t('privacyPolicy.sharePersonalData.list.item-2') }}
            </li>
          </ul>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.yourRights.title') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.yourRights.text.subtitle-1') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.yourRights.text.paragraph-1') }}
          </p>
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.yourRights.text.subtitle-2') }}
          </p>
          <p class="content-item__text">
            <i18n
              path="privacyPolicy.yourRights.text.paragraph-2"
              tag="span"
            >
              <template #email>
                <a
                  :href="`mailto:${ProductInfo.Email}`"
                  class="content-item__text-link"
                >{{ ProductInfo.Email }}</a>
              </template>
            </i18n>
          </p>          
          <p class="content-item__subtitle">
            {{ $t('privacyPolicy.yourRights.text.subtitle-3') }}
          </p>
          <p class="content-item__text">
            <i18n
              path="privacyPolicy.yourRights.text.paragraph-3"
              tag="span"
            >
              <template #email>
                <a
                  :href="`mailto:${ProductInfo.Email}`"
                  class="content-item__text-link"
                >{{ ProductInfo.Email }}</a>
              </template>
            </i18n>
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.dataControllersAndProcessors.title') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.dataControllersAndProcessors.text') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.changesToPrivacyPolicy.title') }}
          </p>
          <p class="content-item__text">
            {{ $t('privacyPolicy.changesToPrivacyPolicy.text') }}
          </p>
        </div>
        <div class="content-item">
          <p class="content-item__heading">
            {{ $t('privacyPolicy.dataProtectionOfficer.title') }}
          </p>
          <div class="content-item__product-info">
            <p class="product-info__title">
              {{ ProductInfo.CompanyName }}
            </p>
            <a
              :href="`mailto:${ProductInfo.Email}`"
              class="content-item__text-link"
            >{{ ProductInfo.Email }}</a>
            <p class="product-info__text">
              U.K.
            </p>
          </div>
          <p class="content-item__text">
            {{ $t('privacyPolicy.dataProtectionOfficer.text') }}
          </p>
        </div>
      </div>
      <BackToTopButton
        :scrollable-element="privacyPolicyModal"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import { ref } from 'vue';
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { ProductInfo } from '@/constants/productInfo';

export default {
  components: {
    BackToTopButton,
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    const privacyPolicyModal = ref();
    return {
      ProductInfo,
      privacyPolicyModal,
    };
  },
};
</script>

<style lang="scss">
@import 'PrivacyPolicyModal';
</style>
