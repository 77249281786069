import { useI18n } from 'vue-i18n-composable';
import store from '@/store';
import { ADD_DELIVERY_ZONE_LOCAL_RATE, REMOVE_DELIVERY_ZONE } from '@/store/Shipping/constants';
import {
  Country, ShippingZone,
} from '@/store/Shipping/types';

const useDeliveryProfileZone = (props: { zone: ShippingZone }, { emit }) => {
  const { t } = useI18n();
  const menuItemsList = [
    {
      action: () => emit('openShippingZoneModal', props.zone),
      title: t('shipping.editZoneInfo__text'),
    },
    {
      action: null,
      title: t('shipping.saveOnShopify__text'),
    },
    {
      action: () => store.dispatch(
        REMOVE_DELIVERY_ZONE,
        { id: props.zone.deliveryProfileId, zoneId: props.zone.id },
      ),
      title: t('shipping.remove__text'),
    },
  ];

  const getZoneCountries = (countries: Country[]): string =>
    countries.map((country: Country) => country.name).join(', ');

  const addLocalRate = () =>
    store.dispatch(ADD_DELIVERY_ZONE_LOCAL_RATE, props.zone.id);
  
  return {
    addLocalRate,
    getZoneCountries,
    menuItemsList,
  };
};

export default useDeliveryProfileZone;
