import * as PromiseBird from 'bluebird';
import { fabric } from 'fabric';
import { enlargeCanvas } from '@/components/Editor/helpers/fabric/canvasModifiers/enlargeCanvas';
import { MeshData, ScanTypes } from '@/components/Editor/types/editor';
import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import { resizeCoefficient } from '@/constants/editor';
import { requestScan } from '@/services/Editor/getScan';
import store from '@/store';
import { GET_MESHS, GET_MODEL_SIZE_IN_MM, GET_SINGLE_EDITABLE_MESH } from '@/store/Editor/constants';
import { CanvasType, createCanvas } from '../fabric/canvasModifiers/createCanvas';
import {
  canvasAsSvgMultiside,
  getCanvasPdfParams,
  multisideScanOrderTypes,
  pdfSize,
  pdfSizeUnit,
} from '../types';
import { getApproximatePdfSize, getPdfSize } from './getPdfSize';

const cheangeOutlineVisibility = async (
  canvas: FabricCanvas,
  visible: boolean,
) => {
  if (canvas.overlayImage) {
    canvas.overlayImage.opacity = visible ? 0.6 : 0;
    await canvas.renderAll();
  }
};

const downloadCanvasPdf = (pdfData: string) => {
  const fileLink = document.createElement('a');
  fileLink.href = window.URL.createObjectURL(new Blob([pdfData]));
  fileLink.setAttribute('download', 'print (Customis3d).pdf');
  document.body.appendChild(fileLink);
  fileLink.click();
};

const getPdfSizeWithModelDepth = (
  canvasSize: THREE.Vector3,
  pdfSize: pdfSize,
): pdfSize => {
  const modelDepth = canvasSize.x * resizeCoefficient;
  return {
    height: pdfSize.height + modelDepth * 2,
    width: pdfSize.width + modelDepth * 2,
  };
};

const getCanvasPdf = async ({
  canvasForPdf,
  canvasSize,
  customFontList,
  names,
  pdfSizeInMm,
  printOrder,
  typeScan,
}: getCanvasPdfParams): Promise<string | void> => {
  let pdfSizeWidthModelDepth!: pdfSize;
  let canvasesAsSvg: canvasAsSvgMultiside[] | string = '';
  const modelSizeInMM = store.getters[GET_MODEL_SIZE_IN_MM];
  const isMultisideScan = canvasForPdf instanceof Array && names;
  if (isMultisideScan) {
    canvasesAsSvg = [] as canvasAsSvgMultiside[];
    canvasForPdf.forEach((canvas, index) => {
      if (canvasesAsSvg instanceof Array) {
        canvasesAsSvg.push({ svg: canvas.toSVG(), name: names[index] });
      }
    });
    canvasesAsSvg.sort(({ name: name1 }, { name: name2 }) => {
      return name2.localeCompare(name1);
    });
  } else {
    canvasesAsSvg = (canvasForPdf as FabricCanvas).toSVG();
  }

  if (!modelSizeInMM) {
    pdfSizeWidthModelDepth = getPdfSizeWithModelDepth(canvasSize, pdfSizeInMm);
  }

  const { data: pdfData } = await requestScan({
    canvasesAsSvg,
    customFontList,
    pdfSizeInMm: pdfSizeWidthModelDepth || pdfSizeInMm,
    typeScan,
    printOrder,
  });

  switch (typeScan) {
  case ScanTypes.file: {
    downloadCanvasPdf(pdfData);
    break;
  }
  case ScanTypes.link:
    return pdfData;
  }
};

export const createPdfDataFromCanvas = async (
  canvasFromEditor: FabricCanvas,
  editableMesh: MeshData,
) => {
  const modelSizeInMM = store.getters[GET_MODEL_SIZE_IN_MM];
  const canvasForPdf = new fabric.Canvas('PDF');
  const {
    y: approximateHeight,
    z: approximateWidth,
  } = editableMesh.size;

  const approximatePdfSizeInPx = getApproximatePdfSize(
    approximateWidth,
    approximateHeight,
    pdfSizeUnit.px,
  );
  const approximatePdfSizeInMm = getApproximatePdfSize(
    approximateWidth,
    approximateHeight,
    pdfSizeUnit.mm,
  );

  const pdfSizeByMeshSizeInPx=
    modelSizeInMM && getPdfSize(modelSizeInMM, pdfSizeUnit.px);
  const pdfSizeByMeshSizeInMm =
    modelSizeInMM && getPdfSize(modelSizeInMM, pdfSizeUnit.mm);

  const pdfSizeInMm = pdfSizeByMeshSizeInMm || approximatePdfSizeInMm;
  const pdfSizeInPx = pdfSizeByMeshSizeInPx || approximatePdfSizeInPx;

  enlargeCanvas({
    canvasFromEditor,
    isForScan: true,
    pdfSizeInPx,
    targetCanvas: canvasForPdf,
  });

  return { canvasForPdf, pdfSizeInMm };
};

export const createScan = async (
  typeScan: ScanTypes,
): Promise<string | void> => {
  const editableMesh = store.getters[GET_SINGLE_EDITABLE_MESH];
  if (editableMesh) {
    const customFontList: string[] = [];
    const canvasFromEditor = await createCanvas(editableMesh, CanvasType.scan);
    await cheangeOutlineVisibility(canvasFromEditor, false);
    const objects = await canvasFromEditor.getObjects() as FabricObject[];
    await collectFonts(objects, customFontList);

    const canvasToJson = JSON.stringify(canvasFromEditor);
    const {
      canvasForPdf,
      pdfSizeInMm,
    } = await createPdfDataFromCanvas(canvasFromEditor, editableMesh);
    
    await cheangeOutlineVisibility(canvasFromEditor, true);
    return new Promise(resolve => canvasForPdf.loadFromJSON(
      canvasToJson,
      async () => resolve(await getCanvasPdf({
        canvasForPdf,
        canvasSize: editableMesh.size,
        customFontList,
        pdfSizeInMm,
        typeScan,
      })),
      () => canvasForPdf.renderAll(),
    ));
  }
};

export const multisideScan = async (
  typeScan: ScanTypes,
): Promise<string | void> => {
  const customFontList: string[] = [];
  const meshs = store.getters[GET_MESHS];
  let pdfSize = meshs[0].size;
  const names: string[] = [];
  
  const pdfCanvases: FabricCanvas[] = [];
  await PromiseBird.each(
    meshs,
    async (mesh: MeshData): Promise<void> => {
      if (mesh.name.includes(multisideScanOrderTypes.order)) return;
      if (mesh.name.includes(multisideScanOrderTypes.size)) return;
      const fabricCanvas = await createCanvas(mesh, CanvasType.scan);
      await cheangeOutlineVisibility(fabricCanvas, false);
      const objects = await fabricCanvas.getObjects() as FabricObject[];
      await collectFonts(objects, customFontList);
      
      const canvasToJson = JSON.stringify(fabricCanvas);
      const {
        canvasForPdf,
        pdfSizeInMm,
      } = await createPdfDataFromCanvas(fabricCanvas, mesh);
      await cheangeOutlineVisibility(fabricCanvas, true);
      pdfSize = pdfSizeInMm;
      names.push(mesh.name);
      const canvas = await new Promise(resolve => canvasForPdf.loadFromJSON(
        canvasToJson,
        () => resolve(canvasForPdf),
        () => canvasForPdf.renderAll(),
      )) as FabricCanvas;
      pdfCanvases.push(canvas);
    },
  );

  await getCanvasPdf({
    canvasForPdf: pdfCanvases,
    canvasSize: meshs[0].size,
    customFontList,
    pdfSizeInMm: pdfSize,
    typeScan,
    names,
    printOrder: getPrintOrder(),
  });
};

const collectFonts = async (
  objects: FabricObject[],
  fontlist: string[],
) => {
  await Promise.all(
    objects.map(async (
      object: FabricObject,
    ) => {
      if (object.fontFamily) {
        fontlist.push(object.fontFamily);
      }
    }));
};

const getPrintOrder = () => {
  return store
    .getters[GET_MESHS]
    .find(({ name }) => name.includes(multisideScanOrderTypes.order))
    .name
    .includes(multisideScanOrderTypes.portrait) 
    ? multisideScanOrderTypes.portrait
    : multisideScanOrderTypes.landscape;
};

export const getScan = async (
  typeScan: ScanTypes,
): Promise<string | void> => {
  const singleEditableMesh = store.getters[GET_SINGLE_EDITABLE_MESH];
  if (singleEditableMesh) {
    return await createScan(typeScan);
  } else {
    return await multisideScan(typeScan);
  }
};
