import { computed, ref } from 'vue';
import { primaryViolet } from '@/constants/colors';

const useTextField = ({ inputType }) => {
  const isShowPassword = ref(false);

  const fieldType = computed(() => {
    switch (inputType) {
    case 'text':
    case 'email':
    case 'number':
      return inputType;
    case 'price':
      return 'number';
    case 'password':
      return isShowPassword.value ? 'text' : 'password';
    default: return;
    }
  });

  const fieldIcon = computed(() => {
    switch (inputType) {
    case 'password':
      return isShowPassword.value ? 'mdi-eye' : 'mdi-eye-off';
    case 'search':
      return 'mdi-magnify';
    case 'price':
      return 'mdi-currency-usd';
    default:
      return null;
    }
  });

  const toggleShowPassword = () => {
    isShowPassword.value = !isShowPassword.value;
  };

  return {
    controlColor: primaryViolet,
    fieldIcon,
    fieldType,
    toggleShowPassword,
  };
};

export default useTextField;
