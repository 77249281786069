<template>
  <div
    v-if="isiOS()"
    class="color-picker"
  >
    <input 
      class="color-demo_iOS"
      type="color"
      :value="color" 
      @change="$emit('applyColor', $event.target.value)"
    >
    <span 
      v-if="isLabelVisible"
      class="color-picker__label"
    >
      {{ $t('redactor.objectColor__label') }}
    </span>
  </div>
  <div
    v-else
    v-click-outside="() => $emit('closePicker')"
    class="color-picker"
    @click.self="$emit('togglePicker')"
  >
    <div
      class="color-demo"
      :style="{ 'background-color': color || 'none' }"
      @click.self="$emit('togglePicker')"
    />
    <span 
      v-if="isLabelVisible"
      class="color-picker__label"
    >
      {{ $t('redactor.objectColor__label') }}
    </span>
    <AndroidPicker
      v-if="isPickerActive"
      :disable-alpha="true"
      class="color-input"
      :value="color"
      @input="$emit('applyColor', $event.hex)"
    />
  </div>
</template>

<script lang="ts">
import { Chrome } from 'vue-color';
import { isiOS } from '@/utils/isiOS';

export default {
  components: {
    AndroidPicker: Chrome,
  },
  props: {
    color: {
      required: true,
      type: String,
    },
    isLabelVisible: {
      default: true,
      required: false,
      type: Boolean,
    },
    isPickerActive: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return { isiOS };
  },
};
</script>

<style lang="scss" scoped>
@import "ColorPickerMobile";
</style>
