<template>
  <ModalWindow
    :is-open="isModalOpen"
    override-with-class="shipping-zone-modal"
    content-class="shipping-zone-modal__card"
    @close="$emit('close')"
  >
    <div class="shipping-zone-modal__card-heading">
      <h1 class="shipping-zone-modal__card-heading__title">
        {{ zoneToEdit ? 'Edit zone info' : 'Create shipping zone' }}
      </h1>
      <TextField
        v-model="zoneName"
        class="shipping-zone-modal__card-heading__field"
        label="Zone name"
        input-type="text"
        :rules="nameFieldRules"
        :hide-details="false"
      />
    </div>
    <div class="shipping-zone-modal__card-regions">
      <TextField
        v-model="search"
        clearable
        class="shipping-zone-modal__card-regions__field"
        prepend-inner-icon="mdi-magnify"
        label="Search..."
        input-type="text"
        :outlined="false"
        @click:clear="clearSearch"
      />
      <div class="shipping-zone-modal__card-regions__list">
        <v-list>
          <v-list-item
            class="shipping-zone-modal__card-regions__list-region"
            @click="toggleAllCountries"
          >
            <v-list-item-action>
              <BaseCheckbox
                no-margin-top
                :value="isSelectedAll"
              />
            </v-list-item-action>
            <v-list-item-content>
              All countries
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group
            v-for="region in filteredRegions"
            :key="region.id"
            multiple
          >
            <template v-if="region.countries.length > 0">
              <v-list-item
                inactive
                class="shipping-zone-modal__card-regions__list-region"
                @click="toggleRegion(region)"
              >
                <v-list-item-action>
                  <BaseCheckbox
                    no-margin-top
                    :value="isRegionSelected(region)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  {{ region.name }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="country in region.countries"
                :key="country.code"
                inactive
                class="shipping-zone-modal__card-regions__list-country"
                @click="toggleCountry(region, country)"
              >
                <v-list-item-action>
                  <BaseCheckbox
                    no-margin-top
                    :value="isCountrySelected(region, country)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ country.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <div class="shipping-zone-modal__card-buttons">
      <BaseButton
        large
        no-margin-top
        button-text="Save"
        class="shipping-zone-modal__card-buttons__save"
        :color="primaryViolet"
        @click="onSubmit"
      />
      <BaseButton
        dark
        large
        no-margin-top
        button-text="Cancel"
        apply-color-to-text
        class="shipping-zone-modal__card-buttons__cancel"
        :color="primaryViolet"
        @click="$emit('close')"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useShippingZoneModal from './ShippingZoneModal';

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    ModalWindow,
    TextField,
  },
  props: {
    deliveryProfile: {
      required: true,
      type: Object,
    },
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    zoneToEdit: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return useShippingZoneModal(props, context);
  },
};
</script>

<style lang="scss">
@import 'ShippingZoneModal';
</style>
