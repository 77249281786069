import router from '@/router/index';
import store from '@/store/index';
import { isMobile } from '@/utils/Mobile';

const useConfirmaEmailForm = () => {
  const email = router.currentRoute.params.email;

  const redirectToSignIn = () => router.push('/sign-in');

  const sendEmailAgain = () => store.dispatch('SEND_ACTIVATION_MAIL', { email });

  return {
    email,
    isMobile,
    redirectToSignIn,
    sendEmailAgain,
  };
};

export default useConfirmaEmailForm;
