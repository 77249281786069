import { Mesh } from 'three';
import { computed, reactive, toRef } from 'vue';
import { ColorPickerType } from '@/components/Editor/types/editor';
import { FabricObject } from '@/components/Editor/types/fabric';
import { secondaryViolet } from '@/constants/colors';
import {
  GET_ACTIVE_MESH,
  GET_ACTIVE_OBJECT,
  GET_IS_FONT_SELECT_OPEN,
  GET_IS_IMAGE_LOADING,
  GET_IS_IMAGE_SETTINGS_ACTIVE,
  GET_IS_MOBILE,
  GET_IS_SETTINS_DISABLED,
  GET_IS_TEXT_SETTINGS_ACTIVE,
  TRIGGER_DELETE_OBJECT,
} from '@/store/Editor/constants';
import store from '@/store/index';

const useSideControls = (props, { emit }) => {
  const isFontSelectOpen = computed((): boolean => {
    return store.getters[GET_IS_FONT_SELECT_OPEN];
  });
  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );
  const isImageActive = computed(
    (): boolean => store.getters[GET_IS_IMAGE_SETTINGS_ACTIVE],
  );
  const isTextActive = computed(
    (): boolean => store.getters[GET_IS_TEXT_SETTINGS_ACTIVE],
  );
  const isImageLoading = computed(
    (): boolean => store.getters[GET_IS_IMAGE_LOADING],
  );
  const isMobile = computed((): FabricObject => store.getters[GET_IS_MOBILE]);
  const isSettingsDisabled = computed((): boolean =>
    store.getters[GET_IS_SETTINS_DISABLED],
  );

  const activeMesh = computed((): Mesh | null => {
    return store.getters[GET_ACTIVE_MESH];
  });

  const isSideControlsDisabled = computed((): boolean => {
    return isSettingsDisabled.value;
  });

  const lockInfo = computed(() => {
    return activeObject.value?.lockedInfo;
  });

  const deleteActiveObject = (): void => {
    store.dispatch(TRIGGER_DELETE_OBJECT);
  };

  const editorSideControlsProps = reactive({
    isSideControlsDisabled,
    isCustomizationFrame: toRef(props, 'isCustomizationFrame'),
    isFontSelectOpen,
    isImageActive,
    isMobile,
    isTextActive,
    secondaryViolet,
    activeMesh,
    isImageLoading,
    activeObject,
    lockInfo,
  });

  const editorSideControlsEmits = {
    addToShopifyOrder: () => emit('addToShopifyOrder'),
    deleteActiveObject,
  };

  return {
    isMobile,
    ColorPickerType,
    editorSideControlsEmits,
    editorSideControlsProps,
  };
};

export default useSideControls;
