import { AxiosResponse } from 'axios';
import { Template } from '@/store/Templates/types';
import { instance } from '@/utils/axios/instance';

export const ShopifyAPI = {
  activateStore: async (storeId: number) => {
    try {
      const response = await instance.post(`/shopify/store/${storeId}/activate`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  addToShopify: async (
    {
      scanLink,
      templateId,
    }:
    {
      scanLink: string | void,
      templateId: Template['id']
    },
  ) => {
    try {
      const response = await instance.post(
        '/shopify/product/template',
        {
          scanLink,
          templateId,
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  createOrder: async (
    storeId: string,
    productId: string,
    variantId: string,
    preview: string,
    scanLink: string,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(
        `/shopify/stores/${storeId}/products/${productId}/variants/${variantId}/order`,
        {
          preview,
          scanLink,
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getStoreInfo: async (storeId: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/shopify/stores/${storeId}/info`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getStoreProduct: async (
    storeId: number,
    productId: number,
    variantId: number,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(
        `/shopify/stores/${storeId}/products/${productId}/variants/${variantId}`,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getThemes: async (storeId: number) => {
    try {
      const response = await instance.get(`/shopify/store/${storeId}/themes`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  revertStore: async (storeId: number) => {
    try {
      const response = await instance.delete(`/shopify/store/${storeId}/revert`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  revertTheme: async (storeId: number, themeId: number) => {
    try {
      const response = await instance.delete(`/shopify/store/${storeId}/themes/${themeId}/revert`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  updateTheme: async (storeId: number, themeId: number) => {
    try {
      const response = await instance.put(`/shopify/store/${storeId}/themes/${themeId}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
