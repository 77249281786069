<template>
  <div class="font-size">
    <span>
      {{ $t("redactor.fontSize__label") }}
    </span>
    <TextField
      :value="fontSize"
      input-type="number"
      class="font-size__input"
      outlined
      @input="$emit('changeTextFontSize', $event)"
    />
    <v-slider
      class="font-size__select"
      max="100"
      min="1"
      :color="primaryViolet"
      :track-color="black06"
      :value="fontSize"
      @change="$emit('changeTextFontSize', $event)"
      @click.stop
    />
  </div>
</template>

<script lang="ts">
import TextField from '@/components/Common/TextField/TextField.vue';
import { black06, primaryViolet } from '@/constants/colors';

export default {
  components: {
    TextField,
  },
  props: {
    fontSize: {
      required: true,
      type: Number,
    },
  },
  setup() {
    return {
      black06,
      primaryViolet,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "TextFontSizeMobile";
</style>
