<template>
  <div class="main-layout">
    <Header />
    <div
      v-show="isShowMainLayout"
      class="main-layout__slot"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Header from '@/components/Header/Header.vue';
import { useMainLayout } from '@/layouts/MainLayout/MainLayout';

export default {
  components: {
    Header,
  },
  setup() {
    return useMainLayout();
  },
};
</script>

<style lang="scss" scoped>
  @import 'MainLayout';
</style>
