export function getCanvasBlob(
  canvas: HTMLCanvasElement,
  type: string,
): Promise<Blob | null> {
  return new Promise(function(resolve) {
    canvas.toBlob(blob => {
      resolve(blob);
    }, type);
  });
}
