import {
  modifyDefaultFabricControls,
} from '@/components/Editor/helpers/fabric/controls/modifyDefaultFabricControls';
import { addGuidelinesToObject } from '@/components/Editor/helpers/Guidelines';
import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import { DPIBreakpoints } from '@/constants/dpi';
import { addDPIToFabricObject, setFabricObjectBorderByDpi } from '@/utils/dpi';
import { warnImageDPI } from './imageDPI';

export const setCustomPropsToImage = (
  canvas: FabricCanvas,
  fabricImage: FabricObject,
  isNewImage: boolean,
): void => {
  addDPIToFabricObject(canvas, fabricImage);
  setFabricObjectBorderByDpi(fabricImage);
  modifyDefaultFabricControls(fabricImage);
  addGuidelinesToObject(fabricImage);
  isNewImage && warnImageDPI({
    dpi: fabricImage.dpi || 1,
    minDpi: DPIBreakpoints.EXCELLENT,
  });
};
