import { Mesh } from 'three';
import store from '@/store';
import {
  GET_ACTIVE_MESH,
  GET_MESHS,
  SET_ACTIVE_MESH,
  SET_IS_MESH_SELECTION_REMOVED,
} from '@/store/Editor/constants';
import { highlightSelectedMesh } from './highlightSelectedMesh';

export const selectMesh = (mesh: Mesh, meshList: Mesh[]): void => {
  const activeMesh = store.getters[GET_ACTIVE_MESH];
  const meshsData = store.getters[GET_MESHS];
  const meshData = meshsData.find(({ name }) => name === mesh.name);
  if (meshData) {
    if (!meshData.disabled && activeMesh?.name !== mesh.name) {
      store.commit(SET_ACTIVE_MESH, meshData);
      highlightSelectedMesh(meshList);
    }
    store.commit(SET_IS_MESH_SELECTION_REMOVED, false);
  }
};
