import { CanvasHeights } from '@/components/Editor/types/editor';
import { FabricObject } from '@/components/Editor/types/fabric';
import { UnitsConverter } from '@/utils/converter';
import { dpiIndicatorColorsBreakpoints } from './constants';
import { AddDPIToFabricObject } from './types';

export const calculateDPI = ({
  heightInches,
  heightPx,
  widthInches,
  widthPx,
}: {
  heightInches: number;
  heightPx: number;
  widthInches: number;
  widthPx: number;
}) => {
  const inchDiagonal = Math.hypot(widthInches, heightInches);
  const pxDiagonal = Math.hypot(widthPx, heightPx);
  return pxDiagonal / inchDiagonal;
};

export const calculateImageDPI = ({
  heightPx,
  pxToMmMultiplier = 1,
  scaleX = 1,
  scaleY = 1,
  widthPx,
}: {
  heightPx: number;
  pxToMmMultiplier: number;
  scaleX: number;
  scaleY: number;
  widthPx: number;
}) => {
  const imageScreenWidthPx = widthPx * scaleX;
  const imageScreenHeightPx = heightPx * scaleY;

  const imageScreenWidthMM = imageScreenWidthPx * pxToMmMultiplier;
  const imageScreenHeightMM = imageScreenHeightPx * pxToMmMultiplier;

  const realWidthInch = UnitsConverter.mmToInch(imageScreenWidthMM);
  const realHeightInch = UnitsConverter.mmToInch(imageScreenHeightMM);

  return calculateDPI({
    heightInches: realHeightInch,
    heightPx,
    widthInches: realWidthInch,
    widthPx,
  });
};

export const addDPIToFabricObject: AddDPIToFabricObject = (canvas, object) => {
  const modelHeight = canvas?.realSize?.height;
  if (object.type !== 'image' || !modelHeight || !canvas) return;
  const pxToMmMultiplier = modelHeight / CanvasHeights.default;
  const { height, scaleX, scaleY, width } = object;
  const dpi = calculateImageDPI({
    heightPx: height || 0,
    pxToMmMultiplier,
    scaleX: scaleX || 1,
    scaleY: scaleY || 1,
    widthPx: width || 0,
  });
  object.dpi = dpi;

  return dpi;
};

export const setFabricObjectBorderByDpi = (object: FabricObject): void => {
  if (!object?.dpi) return;
  const color = getFabricObjectColorByDPI(object.dpi);
  object.borderColor = color;
};

export const getFabricObjectColorByDPI = (dpi: number): string => {
  let objectColor = '';
  dpiIndicatorColorsBreakpoints.forEach(([ breakpoint, color ]) => {
    if (dpi >= breakpoint) objectColor = color;
  });
  return objectColor;
};
