import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { black54, black60, secondaryViolet } from '@/constants/colors';
import router from '@/router';
import store from '@/store';
import {
  GET_ORDER_LOADING,
  GET_ORDERS,
  GET_ORDERS_AMOUNT_OF_PAGES,
  RESET_ORDERS,
} from '@/store/Orders/constants';
import { Order, OrderState } from '@/store/Orders/types';
import { copyToClipboard } from '@/utils/clipboard';
import { parseDate } from '@/utils/date';
import { getCustomerName } from '../utils';

export const useUserOrders = () => {
  const { t } = useI18n();

  const tableHeaders = [
    { title: t('orders.orderId__text'), width: '10%' },
    { title: t('orders.status__text'), width: '20%' },
    { title: t('orders.createdAt__text'), width: '12%' },
    { title: t('orders.shopifyOrderId__text'), width: '14%' },
    { title: t('orders.customerName__text'), width: '12%' },
    { title: t('orders.totalPrice__text'), width: '12%' },
    { title: t('orders.products__text'), width: '20%' },
  ];

  const limit = 8;

  const currentPage = ref(1);

  const orders = computed<OrderState['orders']>(
    () => store.getters[GET_ORDERS],
  );

  const isLoading = computed<OrderState['isLoading']>(
    () => store.getters[GET_ORDER_LOADING],
  );

  const amountOfPages = computed<OrderState['amountOfPages']>(
    () => store.getters[GET_ORDERS_AMOUNT_OF_PAGES],
  );

  onMounted(() => {
    store.dispatch(GET_ORDERS, { page: currentPage.value, limit });
  });

  const getProducts = (order: Order): string => {
    if (!order?.products?.length) return '';
    return order?.products?.map(({ template }) => template.name).join(', ');
  };

  const changePage = (page: number): void => {
    store.dispatch(GET_ORDERS, { page, limit });
    currentPage.value = page;
  };

  const openOrder = (order: Order): void => {
    router.push({ name: 'single-order', params: { id: order.id }});
  };

  onUnmounted(() => {
    store.commit(RESET_ORDERS);
  });

  return {
    openOrder,
    changePage,
    orders,
    getProducts,
    parseDate,
    secondaryViolet,
    amountOfPages,
    currentPage,
    black54,
    tableHeaders,
    isLoading,
    black60,
    copyToClipboard,
    limit,
    getCustomerName,
  };
};
