import { ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { black60 } from '@/constants/colors';
import { copyToClipboard } from '@/utils/clipboard';

export const useCopyToClickboard = (props: { value: number | string }) => {
  const { t } = useI18n();
  const initialText = t('common.copy__text');
  const copiedText = t('common.copied__text');

  const tooltipText = ref(initialText);

  const reset = () => tooltipText.value = initialText;

  const copy = () => {
    copyToClipboard(props.value);
    tooltipText.value = copiedText;
  };

  return {
    copy,
    copyToClipboard,
    black60,
    tooltipText,
    reset,
  };
};
