import { fabric } from 'fabric';

import {
  actualCanvasIconOffset,
  actualCanvasIconSize,
  drawCloseButton,
} from '@/components/Editor/helpers/fabric/controls/renderIcons';
import { FabricControl } from '@/components/Editor/types/fabric';
import store from '@/store';
import { TRIGGER_DELETE_OBJECT } from '@/store/Editor/constants';

export const getCloseControl = (): FabricControl => {
  const canvasIconSize = actualCanvasIconSize();
  const canvasIconOffset = actualCanvasIconOffset();
  const deleteObject = (): boolean => {
    store.dispatch(TRIGGER_DELETE_OBJECT);
    return true;
  };

  return new fabric.Control({
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    offsetX: canvasIconOffset + canvasIconSize,
    offsetY: -canvasIconSize,
    render: drawCloseButton,
    sizeX: canvasIconSize,
    sizeY: canvasIconSize,
    x: 0,
    y: -0.5,
  });
};
