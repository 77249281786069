import { localRate } from '@/constants/shipping';
import {
  ADD_DELIVERY_PROFILE,
  ADD_DELIVERY_ZONE,
  ADD_DELIVERY_ZONE_LOCAL_RATE,
  ADD_DELIVERY_ZONE_RATE,
  REMOVE_DELIVERY_PROFILE,
  REMOVE_DELIVERY_ZONE,
  REMOVE_DELIVERY_ZONE_LOCAL_RATE,
  REMOVE_DELIVERY_ZONE_RATE,
  SET_CURRENT_DELIVERY_PROFILE,
  SET_DELIVERY_LOADING,
  SET_DELIVERY_PROFILE_NAME,
  SET_DELIVERY_PROFILES,
  SET_SHIPPING_REGIONS,
  SET_TABLE_AMOUNT_OF_PAGES,
  SET_TABLE_CURRENT_PAGE,
  UPDATE_DELIVERY_ZONE,
  UPDATE_DELIVERY_ZONE_RATE,
} from '@/store/Shipping/constants';
import { DeliveryProfile, Rate, Region, ShippingState, ShippingZone } from '@/store/Shipping/types';

export const mutations = {
  [ADD_DELIVERY_PROFILE]: (
    state: ShippingState,
    deliveryProfile: DeliveryProfile,
  ) => {
    state.deliveryProfiles.unshift(deliveryProfile);
  },
  [ADD_DELIVERY_ZONE]: (
    state: ShippingState,
    zone : ShippingZone,
  ) => {
    state.currentDeliveryProfile?.zones.push(zone);
  },
  [ADD_DELIVERY_ZONE_LOCAL_RATE]: 
  (
    state: ShippingState,
    zoneId: ShippingZone['id'],
  ) => {
    const zone = state.currentDeliveryProfile?.zones.find(
      ({ id }) => id === zoneId,
    );
    if (!zone) return;
    zone.rates.push(localRate);
  },
  [ADD_DELIVERY_ZONE_RATE]: (
    state: ShippingState,
    rate: Rate,
  ) => {
    if (!state.currentDeliveryProfile) return;
    const zone = state.currentDeliveryProfile.zones.find(
      zone => zone.id === rate.deliveryZoneId,
    );
    zone?.rates.push(rate);
  },
  [REMOVE_DELIVERY_PROFILE]: (
    state: ShippingState,
    id: number,
  ) => {
    state.deliveryProfiles = state.deliveryProfiles.filter(
      (deliveryProfile: DeliveryProfile) => deliveryProfile.id !== id,
    );
  },
  [REMOVE_DELIVERY_ZONE]: (
    state: ShippingState,
    zoneId: ShippingZone['id'],
  ) => {
    if (!state.currentDeliveryProfile?.zones) return;

    state.currentDeliveryProfile.zones = 
      state.currentDeliveryProfile.zones.filter(
        zone => zone.id !== zoneId,
      );
  },
  [REMOVE_DELIVERY_ZONE_LOCAL_RATE]: (
    state: ShippingState,
    zoneId: ShippingZone['id'],
  ) => {
    const zone = state.currentDeliveryProfile?.zones.find(
      zone => zone.id === zoneId,
    );
    if (!zone?.rates) return;
    zone.rates = zone.rates.filter(rate => rate.id !== localRate.id);
  },
  [REMOVE_DELIVERY_ZONE_RATE]: (
    state: ShippingState,
    {
      rateId,
      zoneId,
    }:
    {
      rateId: Rate['id'],
      zoneId: ShippingZone['id'],
    },
  ) => {
    const zone = state.currentDeliveryProfile?.zones.find(
      zone => zone.id === zoneId,
    );
    if (!zone?.rates) return;
    zone.rates = zone.rates.filter(rate => rate.id !== rateId);
  }, 
  [SET_CURRENT_DELIVERY_PROFILE]: (
    state: ShippingState,
    deliveryProfile: DeliveryProfile,
  ) => {
    state.currentDeliveryProfile = deliveryProfile;
  },
  [SET_DELIVERY_LOADING]: (
    state: ShippingState,
    flag : boolean,
  ) => {
    state.isLoading = flag;
  },
  [SET_DELIVERY_PROFILE_NAME]: (
    state: ShippingState,
    { name }: { id: number, name: string },
  ) => {
    if (!state.currentDeliveryProfile) return;
    state.currentDeliveryProfile.name = name;
  },
  [SET_DELIVERY_PROFILES]: (
    state: ShippingState,
    deliveryProfiles: DeliveryProfile[],
  ) => {
    state.deliveryProfiles = deliveryProfiles;
  },
  [SET_SHIPPING_REGIONS]: (
    state: ShippingState,
    shippingRegions: Region[],
  ) => {
    state.shippingRegions = shippingRegions;
  },
  [SET_TABLE_AMOUNT_OF_PAGES]: (
    state: ShippingState,
    amountOfPages: number,
  ) => {
    state.amountOfPages = amountOfPages;
  },
  [SET_TABLE_CURRENT_PAGE]: (
    state: ShippingState,
    currentPage: number,
  ) => {
    state.currentPage = currentPage;
  },
  [UPDATE_DELIVERY_ZONE]: (
    state: ShippingState,
    zone : ShippingZone,
  ) => {
    if (!state.currentDeliveryProfile?.zones) return;
    state.currentDeliveryProfile.zones = 
      state.currentDeliveryProfile.zones.map(
        oldZone => oldZone.id === zone.id ? zone : oldZone,
      );
  },
  [UPDATE_DELIVERY_ZONE_RATE]: (
    state: ShippingState,
    {
      rate,
      zoneId,
    }:
    {
      rate: Rate,
      zoneId : ShippingZone['id']
    },
  ) => {
    if (!state.currentDeliveryProfile?.zones) return;
    const zone = state.currentDeliveryProfile.zones.find(
      zone => zone.id === zoneId,
    );
    if (!zone?.rates) return;
    zone.rates = zone.rates.map(
      oldRate => oldRate.id === rate.id ? rate : oldRate,
    );
  },
};
