import { TranslateResult } from 'vue-i18n';

export enum Tabs {
    MyCollections = '/my-collections',
    PublicCollections = '/public-collections',
    Upload = '/upload',
    Shipping = '/shipping',
    Collection = '/collection',
    MyProfile = '/profile',
    Orders = '/orders',
    UserGuide = '/user-guide'
}

export interface TabItem {
    action?: (...args: any) => any,
    isActive?: boolean,
    title: TranslateResult,
    to: string,
}
