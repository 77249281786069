<template>
  <ColorPickerMobile
    v-if="isMobile"
    v-bind="colorPickerProps"
    v-on="colorPickerEmits"
  />
  <ColorPickerDesktop
    v-else
    v-bind="colorPickerProps"
    v-on="colorPickerEmits"
  />
</template>

<script lang="ts">
import useColorPicker from '@/components/Common/ColorPicker/ColorPicker';
import ColorPickerDesktop from './ColorPickerDesktop/ColorPickerDesktop.vue';
import ColorPickerMobile from './ColorPickerMobile/ColorPickerMobile.vue';

export default {
  components: {
    ColorPickerDesktop,
    ColorPickerMobile,
  },
  props: {
    colorPickerType: {
      required: true,
      type: String,
    },
    isLabelVisible: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  setup(props) {
    return { ...useColorPicker(props) };
  },
};
</script>
