import {
  SET_IS_DEACTIVATED_STORE,
  SET_IS_DEACTIVATING_LOADING,
  SET_IS_THEMES_LOADING,
  SET_STORE,
  SET_THEMES,
} from '@/store/Shopify/constants';
import { ShopifyState, ShopifyStore } from '@/store/Shopify/types';
import { SET_SHOPIFY_STORE_INFO } from './constants';

export const mutations = {
  [SET_IS_DEACTIVATING_LOADING]: (
    state: ShopifyState,
    isStoreResetting: boolean,
  ) => {
    state.isStoreResetting = isStoreResetting;
  },
  [SET_IS_DEACTIVATED_STORE]: (state: ShopifyState, isDeactivated: boolean) => {
    if (!state.shopifyStore) return;
    state.shopifyStore.isDeactivated = isDeactivated;
  },
  [SET_IS_THEMES_LOADING]: (state: ShopifyState, isThemesLoading: boolean) => {
    state.isThemesLoading = isThemesLoading;
  },
  [SET_SHOPIFY_STORE_INFO]: (
    state: ShopifyState,
    { website }: { website: string },
  ) => {
    state.website = website;
  },
  [SET_THEMES]: (state: ShopifyState, themes: []) => {
    state.themes = themes;
  },
  [SET_STORE]: (state: ShopifyState, store: ShopifyStore) => {
    state.shopifyStore = store;
  },
};
