<template>
  <MainLayout>
    <div
      v-if="deliveryProfile"
      class="delivery-profile-wrapper"
    >
      <DeliveryProfileHeading
        :delivery-profile-id="deliveryProfile.id"
        @openShippingZoneModal="setZoneToEdit(null)"
      />
      <DeliveryProfileZones
        v-if="zones && zones.length"
        :zones="zones"
        @openShippingZoneModal="setZoneToEdit"
      />
      <div
        v-else
        class="delivery-profile-wrapper__empty-zones"
      >
        <span class="delivery-profile-wrapper__empty-zones-text">
          {{ $t('shipping.noShippingZones__text') }}
        </span>
      </div>
      <ShippingZoneModal
        v-if="isModalOpen"
        :zone-to-edit="zoneToEdit"
        :delivery-profile="deliveryProfile"
        :is-modal-open="isModalOpen"
        @close="isModalOpen = false"
      />
    </div>
  </MainLayout>
</template>

<script lang="ts">
import ShippingZoneModal from '@/components/ShippingZoneModal/ShippingZoneModal.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useDeliveryProfile from '@/views/DeliveryProfile/DeliveryProfile';
import DeliveryProfileHeading from './DeliveryProfileHeading/DeliveryProfileHeading.vue';
import DeliveryProfileZones from './DeliveryProfileZones/DeliveryProfileZones.vue';

export default {
  components: {
    DeliveryProfileHeading,
    DeliveryProfileZones,
    MainLayout,
    ShippingZoneModal,
  },
  setup() {
    return useDeliveryProfile();
  },
};
</script>

<style lang="scss">
@import '@/views/DeliveryProfile/DeliveryProfile.scss';
</style>
