<template>
  <ModalWindow
    :is-open="isOpen"
    class="add-custom-font__modal-window"
    @close="closeAddFontModal"
  >
    <div class="add-custom-font">
      <span class="add-custom-font__header">
        {{ $t("redactor.addFont__label") }}
      </span>
      <span class="add-custom-font__tip">
        {{ $t("redactor.addFontsFrom__text") }}
        <span>
          <a 
            :href="googleFontsLink"
            target="blank"
            class="add-custom-font__link"
          >
            {{ $t("redactor.addFontsFrom__link") }}
          </a>
        </span>
      </span>
      <span class="add-custom-font__description">
        {{ $t("redactor.addFontSuggestion__text") }}
      </span>
      <v-combobox
        :v-model="newFont"
        :items="googleFonts"
        :placeholder="$t('redactor.fontName__text')"
        :color="primaryViolet"
        class="add-custom-font__input"
        solo
        outlined
        @input.native="newFont=$event.srcElement.value"
        @change="updateNewFont"
      />
      <div
        class="add-custom-font__agreement"
        @click="toggleAgreement"
      >
        <BaseCheckbox
          class="add-custom-font__agreement-checkbox"
          no-margin-top
          :value="agreement"
        />
        <span>{{ $t('redactor.gdprAgreement__text') }}</span>
      </div>
      <BaseButton 
        class="add-custom-font__button" 
        :button-text="$t('redactor.add__button')"
        :disabled="!fontLink || !agreement" 
        :color="primaryViolet"
        @click="addFont"
      />
    </div>
  </ModalWindow>
</template>
  
<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useAddFontModal from '@/components/Editor/AddFontModal/AddFontModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
    BaseCheckbox,
  },
  props: {
    googleFontsList: Array,
    isOpen: Boolean,
  },
  setup() {
    return useAddFontModal();
  },
};
</script>
  
<style lang="scss" scoped>
@import "AddFontModal";
</style>
