import {
  isObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import store from '@/store';
import {
  GET_ACTIVE_CANVAS,
  TRIGGER_DELETE_OBJECT,
} from '@/store/Editor/constants';

export const deleteActiveObject = ({ key, shiftKey }: KeyboardEvent): void => {
  if (shiftKey && key === 'Delete') {
    const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
    const activeObject = activeCanvas.getActiveObject();
    if (!activeObject) return;
    if (isObjectLocked(activeObject)) return;
    store.dispatch(TRIGGER_DELETE_OBJECT);
  }
};
