<template>
  <ModalWindow
    :is-open="isOpen"
    content-class="mobile-radio-selector"
    @close="$emit('close')"
  >
    <template #header>
      <div class="mobile-radio-selector__heading">
        {{ $t('profile.changeCurrentTheme__text') }}
      </div>
    </template>
    <div class="mobile-radio-selector__list pretty-scroll__overlaying">
      <v-radio-group 
        :value="selectedThemeId" 
        @change="selectThemeId"
      >
        <v-radio
          v-for="{id, name} in themes"
          :key="id"
          v-ripple
          class="mobile-radio-selector__list-item"
          :label="name"
          :value="id"
          :color="primaryViolet"
        />
      </v-radio-group>
    </div>
    <div class="mobile-radio-selector__save">
      <BaseButton
        large
        no-margin-top
        class="mobile-radio-selector__save-btn"
        :color="primaryViolet"
        :button-text="$t('profile.change__label')"
        @click="updateCurrentThemeId"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useThemeSelectorMobile from './ThemeSelectorMobile';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    currentThemeId: {
      required: false,
      type: [ String, Number ],
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    themes: {
      required: false,
      type: Array,
    },
  },
  setup(props, context) {
    return useThemeSelectorMobile(props, context);
  },
};
</script>

<style lang="scss">
@import 'ThemeSelectorMobile';
</style>
