import { isiOS } from '@/utils/isiOS';

export const scrollToBottom = (): void => {
  if (isiOS()) {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = document.body.scrollHeight;
    }
  } else {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: document.body.scrollHeight,
    });
  }
};

export const scrollToTop = (): void => {
  if (isiOS()) {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  } else {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }
};
