import { computed, nextTick, reactive, ref, watch } from 'vue';
import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import { ADD_DELIVERY_ZONE, GET_SHIPPING_REGIONS, UPDATE_DELIVERY_ZONE } from '@/store/Shipping/constants';
import { Country, DeliveryProfile, Region, ShippingZone } from '@/store/Shipping/types';
import { maxLength, requiredField } from '@/utils/FieldValidationRules';

const useShippingZoneModal = (
  props: {
    deliveryProfile: DeliveryProfile,
    zoneToEdit: ShippingZone | null
  }, { emit }) => {
  const nameFieldRules = [ requiredField, maxLength(30) ];
  
  const regions = computed<Region[]>(() => store.getters[GET_SHIPPING_REGIONS]);
  const initialRegions = Object.fromEntries(
    regions.value.map(region => {
      const regionCountries = props.zoneToEdit?.countries.filter(
        ({ regionId }) => regionId === region.id,
      ) || [];
      return [ region.name, regionCountries ];
    }),
  );
  const filteredRegions = ref(regions.value);
  const selectedRegions = reactive(initialRegions);
  const zoneName = ref(props.zoneToEdit?.name || '');
  const search = ref('');

  const isSelectedAll = computed(() => {
    const selectedCountries = Object.values(selectedRegions).flat();
    const allCountries = regions.value.map(region => region.countries).flat();
    return selectedCountries.length === allCountries.length;
  });

  watch(search, () => {
    filteredRegions.value = regions.value.map(region => {
      const filteredCountries = region.countries.filter(
        country => country.name
          .toLowerCase()
          .includes(search.value.toLowerCase()),
      );
      return { ...region, countries: filteredCountries };
    });
  });
  
  const toggleCountry = (region: Region, country: Country) => {
    const currentRegion = selectedRegions[region.name];
    const selectedCountryIndex = currentRegion.findIndex(
      ({ name }) => country.name === name,
    );
    const isCountrySelected = selectedCountryIndex > -1;
    isCountrySelected 
      ? currentRegion.splice(selectedCountryIndex, 1)
      : currentRegion.push({ ...country });
  };

  const toggleRegion = ({ countries, name }: Region) => {
    const isEmpty = !selectedRegions[name].length;
    isEmpty 
      ? selectedRegions[name] = [...countries]
      : selectedRegions[name] = [];
  };

  const toggleAllCountries = () => {
    isSelectedAll.value ? removeAllCountries() : selectAllCountries();
  };

  const removeAllCountries = () => {
    Object.keys(selectedRegions).forEach(key => {
      selectedRegions[key] = [];
    });
  };

  const selectAllCountries = () => {
    regions.value.forEach(({ countries, name }) => {
      selectedRegions[name] = [...countries];
    }); 
  };

  const isRegionSelected = (region: Region) => {
    const selectedRegion = selectedRegions[region.name];
    const selectedCountries = selectedRegion.map(country => country.name);
    return region.countries.every(
      ({ name }) => selectedCountries.includes(name),
    );
  };

  const isCountrySelected = (region: Region, country: Country) => {
    return !!selectedRegions[region.name].find(
      ({ name }) => name === country.name,
    );
  };
  const clearSearch = async () => {
    await nextTick();
    search.value = '';
  };

  const onSubmit = async (): Promise<void> => {
    const zoneCountries = Object.values(selectedRegions).flat();
    const zone = {
      ...props.zoneToEdit,
      countries: zoneCountries, 
      name: zoneName.value,
    };
    const isZoneExists = !!zone.id;
    if (isZoneExists) {
      await store.dispatch(UPDATE_DELIVERY_ZONE, {
        id: zone.deliveryProfileId, zone,
      });
    } else {
      await store.dispatch(ADD_DELIVERY_ZONE, {
        id: props.deliveryProfile.id,
        zone,
      });
    }
    emit('close');
  };

  return {
    clearSearch,
    filteredRegions,
    isCountrySelected,
    isRegionSelected,
    isSelectedAll,
    nameFieldRules,
    onSubmit,
    primaryViolet,
    regions,
    search,
    selectedRegions,
    toggleAllCountries,
    toggleCountry,
    toggleRegion,
    zoneName,
  };
};

export default useShippingZoneModal;
