import { fabric } from 'fabric';

export type Guideline = fabric.Line;

export enum EditorVerticalGuidelinesDirection {
  verticalCenter = 'verticalCenter',
  verticalLeft = 'verticalLeft',
  verticalRight = 'verticalRight',
}

export enum EditorHorizontalGuidelinesDirection {
  horizontalBottom = 'horizontalBottom',
  horizontalCenter = 'horizontalCenter',
  horizontalTop = 'horizontalTop',
}

type EditorGuidelinesDirection =
  | EditorVerticalGuidelinesDirection
  | EditorHorizontalGuidelinesDirection;

export type EditorGuidelines = Record<EditorGuidelinesDirection, Guideline>;

export type EditorGuidelinesVisibility = Record<
  EditorGuidelinesDirection,
  boolean
>;

export type EditorHorizontalLinesNames = Partial<
  EditorHorizontalGuidelinesDirection
>;
export type EditorVerticalLinesNames = Partial<
  EditorVerticalGuidelinesDirection
>;

export type DrawableGuidelines = Record<
  keyof EditorGuidelines,
  { left: number; lineDots: LineDots; top: number }
>;

export type LineDots = [number, number, number, number];
