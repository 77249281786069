import { CanvasTexture } from 'three';
import { MeshData, Size } from '@/components/Editor/types/editor';
import { HEXColor } from '@/components/Editor/types/editor';
import {
  FabricCanvas,
  FabricObject,
  FabricObjectProps,
} from '@/components/Editor/types/fabric';

export interface TextureToUpdate {
  meshName: string,
  texture: CanvasTexture,
}

export interface CanvasSize {
  meshName: string,
  size: Size
}

export interface CanvasConfigs {
  baseHeight: number,
  baseWidth: number,
  canvasSizes: CanvasSize[],
  canvasZoom: number,
  isEditorActive: boolean,
  isObjectsRestored: boolean,
  isUndoRedoHidden: boolean,
  layerName: string,
  layerNumber: number;
  zoomToDefault: number,
}

export interface CanvasState {
  activeObject: FabricObject | null,
  backgroundColor: HEXColor,
  fabricObjects: FabricObject[],
}

export interface CanvasHistory {
  isRedoDisabled: boolean,
  isUndoDisabled: boolean,
  redo: CanvasState[],
  undo: CanvasState[],
}

export interface EditorConfigs {
  isApplyingDesign: boolean,
  isDesignMode: boolean,
  isEditorLoading: boolean,
  isIframe: boolean,
  isImageLoading: boolean,
  isImageSettingsActive: boolean,
  isLoadedFromHistory: boolean,
  isMobile: boolean,
  isSaveDesignDisabled: boolean,
  isSettingsDisabled: boolean,
  isTextSettingsActive: boolean,
}

export interface TemplateDesign {
  background: string;
  id: number;
  img: string;
  name: string
  objects: string;
}

export interface ModelData {
  activeMesh: MeshData | null;
  isMeshSelectionRemoved: boolean;
  isModelReady: boolean;
  isModelReadyToLoad: boolean;
  isSideSelectionEnabled: boolean;
  isTextureUpdated: boolean;
  meshs: MeshData[];
  modelSizeInMM: Size | null;
  singleEditableMesh: MeshData | null,
  textureCanvases: TextureCanvas[],
  texturesToUpdate: TextureToUpdate[],
}

export interface GoogleFontsList {
  fontName: string,
  link: string,
}

export interface FontsConfigsState {
  fontsToRender: string[],
  fontToRemove: string,
  googleFontsList: GoogleFontsList[],
  isAddFontModalOpen: boolean,
  isFontSelectOpen: boolean,
  isRemoveFontModalOpen: boolean,
}

export interface EditorState {
  activeCanvas: FabricCanvas,
  canvasConfigs: CanvasConfigs,
  canvasHistory: CanvasHistory,
  canvasState: CanvasState,
  editorConfigs: EditorConfigs,
  fontsConfigs: FontsConfigsState,
  modelData: ModelData,
  sceneScan: string,
  templateDesigns: TemplateDesign[],
}

export enum HistoryActions {
  redo = 'redo',
  undo = 'undo',
}

export interface TriggerSetActiveObjectParams {
  isUpdateHistory: boolean,
  object: FabricObject,
}

export interface TriggerUpdateActiveObjectParams {
  changes: FabricObjectProps,
  isUpdateHistory: boolean,
}

export interface CanvasDataByMesh {
  backgroundColor: string,
  canvasData: FabricObject[],
  meshName: string
}

export type CanvasData = FabricObject[];

export interface FabricObjectToUpdate {
  meshName: string,
  object: FabricObject,
}

export interface TextureCanvas {
  canvas: FabricCanvas,
  meshName: string,
}
