<template>
  <div class="dont-have-account">
    {{ $t('signIn.dontHaveAccount__text') }}
    <router-link :to="{path: '/sign-up', query: routeQuery}">
      <span class="underline-animate">
        {{ $t('signUp.signUp__text') }}
      </span>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    routeQuery: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
  @import 'DontHaveAccount';
</style>
