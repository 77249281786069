<template>
  <ModalWindow
    :is-open="isOpen"
    @close="closeAddToMyCollectionsModal"
  >
    <div class="add-to-my-collections-modal">
      <div class="add-to-my-collections-modal__title">
        {{ title }}
      </div>
      <div class="add-to-my-collections-modal__body">
        <v-img
          class="add-to-my-collections-modal__preview-image"
          :contain="isPreviewImageDisplayed"
          :src="previewImage"
          :alt="template.name"
        />
        <div class="add-to-my-collections-modal__form">
          <div class="form__title">
            <TextField
              v-model="newTemplateName"
              :label="$t('templates.productName__placeholder')"
              :rules="templateNameRules"
            />
          </div>
          <div
            v-if="!isTabletAndLower"
            class="form__selector"
          >
            <BaseSelect
              background-color="white"
              item-text="name"
              item-value="id"
              return-object
              :value="selectedCollection"
              :items="collections"
              :menu-props="menuSettings"
              :dense="false"
              :height="56"
              :placeholder="
                $t('addToMyCollectionsModal.collectionName__placeholder')"
              attach
              @input="changeSelectedCollection"
            />
          </div>
          <FormSelectorMobile
            v-else
            :selected-collection="selectedCollection"
            @toggleCollectionsSelector="toggleCollectionsSelector"  
          />
          <BaseButton
            large
            has-background
            class="form__submit"
            :color="primaryViolet"
            :disabled="!selectedCollection"
            :button-text="$t('addToMyCollectionsModal.copy-button__text')"
            @click="copySelectedTemplate"
          />
        </div>
      </div>
      <CollectionsSelectorMobile
        v-if="isTabletAndLower"
        :is-open="isCollectionsSelectorOpen"
        :collections="collections"
        :selected-collection="selectedCollection"
        @changeSelectedCollection="changeSelectedCollection"
        @close="toggleCollectionsSelector"
      />
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import useAddToMyCollectionsModal from '@/components/AddToMyCollectionsModal/AddToMyCollectionsModal';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import CollectionsSelectorMobile from './CollectionsSelectorMobile/CollectionsSelectorMobile.vue';
import FormSelectorMobile from './FormSelectorMobile/FormSelectorMobile.vue';

export default {
  components: {
    BaseButton,
    BaseSelect,
    CollectionsSelectorMobile,
    FormSelectorMobile,
    ModalWindow,
    TextField,
  },
  props: {
    isOpen: {
      required: false,
      type: Boolean,
    },
    template: {
      required: false,
      type: Object,
    },
  },
  setup(props) {
    return useAddToMyCollectionsModal(props);
  },
};
</script>

<style lang="scss" scoped>
@import 'AddToMyCollectionsModal';
</style>
