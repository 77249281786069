<template>
  <MainLayout>
    <div
      id="file-drag-drop"
      class="upload"
    >
      <div class="object-mapper-container">
        <form 
          ref="fileForm"
          class="file-form"
        >
          <img
            :src="noFilesUploaded ? uploadFileIcon : uploadedGLBIcon"
            alt="uploadIcon__alt"
          >
          <span
            v-if="noFilesUploaded"
            class="form__label"
          >{{
            $t("upload.drop__label")
          }}</span>
          <span
            v-if="noFilesUploaded"
            class="form__labelOR"
          >{{ $t('upload.or__text') }}</span>
          <label
            v-if="noFilesUploaded"
            class="file-select__label"
          >
            <div>
              <span>{{ $t("upload.chooseFile__text") }}</span>
            </div>
            <input
              type="file"
              accept=".glb"
              enctype="multipart/form-data"
              @change="handleFileChange"
            >
          </label>
          <div
            v-if="!noFilesUploaded"
            class="progress"
          >
            <div class="progress__data">
              <span>{{ uploadingText }}</span>
              <span class="progress-data__percentage">
                {{ uploadPercentage }}%
              </span>
            </div>
            <v-progress-linear
              class="progress-bar"
              :value="uploadPercentage"
              :color="primaryViolet"
              rounded
              height="10"
            />
          </div>
        </form>
      </div>
      <div class="upload-json">
        <v-form v-model="isFormValid">
          <span class="upload-json__label">
            {{ $t('upload.fileInfo__label') }}
          </span>
          <BaseSelect
            v-model="collectionId"
            :items="collections"
            :label="$t('upload.collectionName__label')"
            :height="56"
            :rules="[requiredField]"
            item-text="name"
            item-value="id"
            background-color="transparent"
            attach
          />

          <TextField
            v-model.trim="name"
            :label="$t('upload.templateName__label')"
            :rules="requiredFieldWithLengthRules"
            input-type="text"
            outlined
          />

          <span class="upload-json__heading">
            {{ $t("upload.productDetails__text") }}
          </span>
          <TextField
            v-model.trim="sku"
            :label="$t('upload.sku__label')"
            :rules="requiredFieldWithLengthRules"
            input-type="text"
            outlined
          />
          <TextField
            v-model.number="price"
            v-click-outside="convertPriceToDecimal"
            :label="$t('upload.price__label')"
            :rules="priceRules"
            input-type="number"
            outlined
          />
          <TextField
            v-model.number="weight"
            :label="$t('upload.weight__label')"
            :rules="weightRules"
            input-type="number"
            outlined
          />
          <BaseSelect
            v-model.number="currentDeliveryProfileId"
            :items="deliveryProfiles"
            :label="$t('upload.deliveryProfile__label')"
            :height="56"
            :rules="[requiredField]"
            item-text="name"
            item-value="id"
            background-color="transparent"
            attach
          />
        </v-form>
        <div class="outline-upload">
          <span class="upload-json__heading">
            {{ $t('upload.outline__label') }}
          </span>
          <form
            ref="overlayForm"
            class="outline-upload__form"
          >
            <div
              v-if="!overlayImage"
              class="unuploaded__data"
            >
              <img
                :src="uploadOutlineIcon"
                alt="uploadIcon__alt"
              >
              <span>{{ $t('upload.dragAndDrop__text') }}</span>
              <span
                class="select-overlay"
                @click="chooseFile"
              >{{ $t('upload.chooseFile__text') }}</span>
            </div>
            <div
              v-if="overlayImage"
              class="form__data"
            >
              <div class="overlay__data">
                <img
                  :src="fileIcon"
                  alt="uploadIcon__alt"
                >
                <span>{{ overlayFileName }}</span>
                <span class="overlay__percentage">
                  {{ overlayUploadPercentage }}%
                </span>
              </div>
              <v-progress-linear
                class="progress-bar"
                :value="overlayUploadPercentage"
                :color="primaryViolet"
                rounded
                height="10"
              />
            </div>
          </form>
        </div>
        <BaseButton
          class="upload-json__btn"
          :class="{'disabled': isDisableButton}"
          :color="primaryViolet"
          :disabled="isDisableButton"
          :button-text="$t('upload.upload__button')"
          @click.prevent="upload3dModel"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useUploadPage from './UploadPage';

export default {
  components: {
    BaseButton,
    BaseSelect,
    MainLayout,
    TextField,
  },
  setup() {
    return useUploadPage();
  },
};
</script>

<style scoped lang="scss">
@import "UploadPage";
</style>
