import { CanvasType } from './createCanvas';

export const createHTMLCanvas = (
  container: HTMLDivElement,
  meshName: string,
): void => {
  while (container.lastElementChild) {
    container.lastElementChild.remove();
  }
  const htmlCanvas = document.createElement('canvas');
  htmlCanvas.id = `${CanvasType.render}-${meshName}`;
  container.appendChild(htmlCanvas);
};
