<template>
  <div :class="{disabled: isDisabled}">
    <RotationMobile
      v-if="isMobile"
      v-bind="rotationProps"
      v-on="rotationEmits" 
    />
    <RotationDesktop
      v-else
      v-bind="rotationProps"
      v-on="rotationEmits" 
    />
  </div>
</template>

<script lang="ts">
import useRotation from '@/components/Editor/Rotation/Rotation';
import RotationDesktop from './RotationDesktop/RotationDesktop.vue';
import RotationMobile from './RotationMobile/RotationMobile.vue';

export default {
  components: {
    RotationDesktop,
    RotationMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useRotation();
  },
};
</script>
