import { pdfSize, pdfSizeUnit } from '@/components/Editor/helpers/types';
import { Size } from '@/components/Editor/types/editor';
import { mmToPx, resizeCoefficient } from '@/constants/editor';

export const getPdfSize = (
  {
    height: pdfHeightInMM,
    width: pdfWidthInMM,
  }: Size,
  pdfUnit: pdfSizeUnit,
): pdfSize | undefined => {
  switch (pdfUnit) {
  case pdfSizeUnit.px:
    return {
      height: pdfHeightInMM * mmToPx,
      width: pdfWidthInMM * mmToPx,
    };
  case pdfSizeUnit.mm:
    return {
      height: pdfHeightInMM,
      width: pdfWidthInMM,
    };
  }
};

export const getApproximatePdfSize = (
  approximateWidth: number,
  approximateHeight: number,
  pdfUnit: pdfSizeUnit,
): pdfSize => {
  switch (pdfUnit) {
  case pdfSizeUnit.px:
    return {
      height: approximateHeight * resizeCoefficient * mmToPx,
      width: approximateWidth * resizeCoefficient * mmToPx,
    };
  case pdfSizeUnit.mm:
    return {
      height: approximateHeight * resizeCoefficient,
      width: approximateWidth * resizeCoefficient,
    };
  }
};
