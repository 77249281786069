import {
  ADD_TEMPLATE_DESIGN,
  REMOVE_TEMPLATE_DESIGN,
  SET_TEMPLATE_DESIGNS,
  UPDATE_TEMPLATE_DESIGN,
} from '../constants';
import { EditorState, TemplateDesign } from '../types';

export const templateDesignMutations = {
  [SET_TEMPLATE_DESIGNS]: (
    state: EditorState,
    templateDesigns: TemplateDesign[],
  ): void => {
    state.templateDesigns = templateDesigns;
  },
  [REMOVE_TEMPLATE_DESIGN]: (state: EditorState, id: TemplateDesign['id']): void => {
    const templateDesigns = state.templateDesigns.filter(
      templateDesign => templateDesign.id !== id,
    );
    state.templateDesigns = templateDesigns;
  },
  [ADD_TEMPLATE_DESIGN]: (
    state: EditorState,
    templateDesign: TemplateDesign,
  ): void => {
    state.templateDesigns.unshift(templateDesign);
  },
  [UPDATE_TEMPLATE_DESIGN]: (
    state: EditorState,
    templateDesign: TemplateDesign,
  ): void => {
    const editSaveDesignIndex = state.templateDesigns.findIndex(
      design => design.id === templateDesign.id,
    );
    state.templateDesigns.splice(editSaveDesignIndex, 1, templateDesign);
  },  
};
