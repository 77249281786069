import { ShippingAPI } from '@/services/ShippingAPI';
import {
  ADD_DELIVERY_PROFILE,
  ADD_DELIVERY_ZONE,
  ADD_DELIVERY_ZONE_LOCAL_RATE,
  ADD_DELIVERY_ZONE_RATE,
  CREATE_DELIVERY_PROFILE,
  DELIVERY_PROFILE_SHOPIFY_UPDATE,
  EDIT_DELIVERY_PROFILE_NAME,
  GET_DELIVERY_PROFILE,
  GET_SHIPPING_REGIONS,
  LOAD_ALL_DELIVERY_PROFILES,
  LOAD_DELIVERY_PROFILES,
  REMOVE_DELIVERY_PROFILE,
  REMOVE_DELIVERY_ZONE,
  REMOVE_DELIVERY_ZONE_LOCAL_RATE,
  REMOVE_DELIVERY_ZONE_RATE,
  SET_CURRENT_DELIVERY_PROFILE,
  SET_DELIVERY_LOADING,
  SET_DELIVERY_PROFILE_NAME,
  SET_DELIVERY_PROFILES,
  SET_SHIPPING_REGIONS,
  SET_TABLE_AMOUNT_OF_PAGES,
  UPDATE_DELIVERY_ZONE,
  UPDATE_DELIVERY_ZONE_RATE,
} from '@/store/Shipping/constants'; 
import {
  Context,
  CreateRate,
  DeliveryProfile,
  Rate,
  Region,
  ShippingZone,
} from '@/store/Shipping/types';
import { handleResponse } from '@/utils/axios/handleResponse';

export const actions = {
  [ADD_DELIVERY_ZONE]: async (
    { commit }: Context,
    { id, zone }: {id: DeliveryProfile['id'], zone: ShippingZone},
  ) => {
    const response = await ShippingAPI.createDeliveryZone(id, zone);
    const handleSuccessStatus = (
      { zone }: {
        zone: ShippingZone,
      },
    ): void => commit(ADD_DELIVERY_ZONE, zone);
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [ADD_DELIVERY_ZONE_LOCAL_RATE]: (
    { commit }: Context,
    zoneId: ShippingZone['id'],
  ): void => {
    commit(ADD_DELIVERY_ZONE_LOCAL_RATE, zoneId);
  },
  [ADD_DELIVERY_ZONE_RATE]:  async (
    { commit }: Context,
    { id, rate, zoneId }: { id: DeliveryProfile['id'], rate: CreateRate, zoneId: ShippingZone['id'] },
  ) => {
    const response = await ShippingAPI.createDeliveryZoneRate(id, zoneId, rate);
    const handleSuccessStatus = (
      { rate }: {
        rate: Rate,
      },
    ): void => commit(ADD_DELIVERY_ZONE_RATE, rate);
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [CREATE_DELIVERY_PROFILE]: async (
    { commit }: Context,
    name: string,
  ) => {
    const response = await ShippingAPI.createDeliveryProfile(name);
        
    const handleSuccessStatus = (
      { deliveryProfile }: {
        deliveryProfile: DeliveryProfile,
      },
    ): void => commit(ADD_DELIVERY_PROFILE, deliveryProfile);
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [DELIVERY_PROFILE_SHOPIFY_UPDATE]: async (
    { commit }: Context,
    id: DeliveryProfile['id'],
  ) => {
    commit(SET_DELIVERY_LOADING, true);
    const response = await ShippingAPI.updateShopify(id);

    handleResponse({
      response,
      successStatus: 204,
    });
    commit(SET_DELIVERY_LOADING, false);

  },
  [EDIT_DELIVERY_PROFILE_NAME]: async (
    { commit }: Context,
    data: { id: number, name: string },
  ) => {
    const response = await ShippingAPI.editDeliveryProfileName(data);
    
    const handleSuccessStatus = () =>
      commit(SET_DELIVERY_PROFILE_NAME, data);
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [GET_DELIVERY_PROFILE]: async (
    { commit }: Context,
    id: number,
  ): Promise<void> => {
    const response = await ShippingAPI.getDeliveryProfile(id);
    
    const handleSuccessStatus = ({ deliveryProfile }:
      { deliveryProfile: DeliveryProfile }): void => 
      commit(SET_CURRENT_DELIVERY_PROFILE, deliveryProfile);
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [GET_SHIPPING_REGIONS]: async (
    { commit }: Context,
  ) => {
    const response = await ShippingAPI.getShippingRegions();
    
    const handleSuccessStatus = ({ regions }:
      { regions: Region[] }): void => 
      commit(SET_SHIPPING_REGIONS, regions);
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [LOAD_ALL_DELIVERY_PROFILES]: async ({ commit }: Context) => {
    const response = await ShippingAPI.loadDeliveryProfiles(
      { limit: 1000, page: 1 },
    );
        
    const handleSuccessStatus = (
      {
        deliveryProfiles,
        pagesCount,
      }: {
        deliveryProfiles: DeliveryProfile[],
        pagesCount: number,
      },
    ): void => {
      commit(SET_DELIVERY_PROFILES, deliveryProfiles);
      commit(SET_TABLE_AMOUNT_OF_PAGES, pagesCount);
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [LOAD_DELIVERY_PROFILES]: async (
    { commit }: Context,
    data: {
      limit: number,
      page: number,
    }) => {
    const response = await ShippingAPI.loadDeliveryProfiles(data);
        
    const handleSuccessStatus = (
      {
        deliveryProfiles,
        pagesCount,
      }: {
        deliveryProfiles: DeliveryProfile[],
        pagesCount: number,
      },
    ): void => {
      commit(SET_DELIVERY_PROFILES, deliveryProfiles);
      commit(SET_TABLE_AMOUNT_OF_PAGES, pagesCount);
    };
        
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [REMOVE_DELIVERY_PROFILE]: async (
    { commit }: Context,
    id: number,
  ) => {
    const response = await ShippingAPI.removeDeliveryProfile(id);

    const handleSuccessStatus = (): void =>
      commit(REMOVE_DELIVERY_PROFILE, id);

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });
  },
  [REMOVE_DELIVERY_ZONE]: async (
    { commit }: Context,
    { id, zoneId }: {id: DeliveryProfile['id'], zoneId: ShippingZone['id']},
  ) => {
    commit(SET_DELIVERY_LOADING, true);
    const response = await ShippingAPI.removeDeliveryZone(id, zoneId);

    const handleSuccessStatus = (): void => 
      commit(REMOVE_DELIVERY_ZONE, zoneId);
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });

    commit(SET_DELIVERY_LOADING, false);
  },
  [REMOVE_DELIVERY_ZONE_LOCAL_RATE]: (
    { commit }: Context,
    zoneId: ShippingZone['id'],
  ): void => {
    commit(REMOVE_DELIVERY_ZONE_LOCAL_RATE, zoneId);
  },
  [REMOVE_DELIVERY_ZONE_RATE]: async (
    { commit }: Context,
    { id, rateId, zoneId }: {id: DeliveryProfile['id'], rateId: Rate['id'], zoneId: ShippingZone['id']},
  ) => {
    commit(SET_DELIVERY_LOADING, true);
    const response = await ShippingAPI.removeDeliveryZoneRate(
      id, zoneId, rateId,
    );

    const handleSuccessStatus = (): void => 
      commit(REMOVE_DELIVERY_ZONE_RATE, { rateId, zoneId });
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });
    commit(SET_DELIVERY_LOADING, false);
  },
  [UPDATE_DELIVERY_ZONE]: async (
    { commit }: Context,
    {
      id,
      zone,
    }: {
      id: DeliveryProfile['id']
      zone: ShippingZone
    },
  ): Promise<void> => {
    commit(SET_DELIVERY_LOADING, true);

    const response = await ShippingAPI.updateDeliveryZone(id, zone);

    const handleSuccessStatus = (): void => 
      commit(UPDATE_DELIVERY_ZONE, zone);
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });

    commit(SET_DELIVERY_LOADING, false);
  },
  [UPDATE_DELIVERY_ZONE_RATE]: async (
    { commit }: Context,
    {
      id,
      rate,
      zoneId,
    }: {
      id: DeliveryProfile['id']
      rate: Rate,
      zoneId: ShippingZone['id']
    },
  ): Promise<void> => {
    commit(SET_DELIVERY_LOADING, true);

    const response = await ShippingAPI.updateDeliveryZoneRate(id, zoneId, rate);

    const handleSuccessStatus = (): void => 
      commit(UPDATE_DELIVERY_ZONE_RATE, { rate, zoneId });
    
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });

    commit(SET_DELIVERY_LOADING, false);
  },
};
