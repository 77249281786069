import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { Route } from 'vue-router';
import { secondaryViolet } from '@/constants/colors';
import router from '@/router';
import { Collection } from '@/store/Collections/types';
import store from '@/store/index';
import {
  CLEAR_TEMPLATES,
  GET_IS_EDITING_RIGHTS_TEMPLATES,
  GET_IS_TEMPLATES_LOADING,
  GET_SELECTED_COLLECTION,
  GET_TEMPLATES,
  GET_TEMPLATES_AMOUNT_OF_PAGES,
  GET_TEMPLATES_SELECTED_SORT,
  INIT_TEMPLATES,
  LOAD_MORE_TEMPLATES,
  RESET_TEMPLATE_SORTING,
  SET_TEMPLATES_SELECTED_SORT,
} from '@/store/Templates/constants';
import { Template } from '@/store/Templates/types';
import {
  AmountOfCards,
  SkeletonCards,
  SkeletonTypes,
  SortItems,
} from '@/types';
import { scrollToTop } from '@/utils';
import { isTabletAndLower } from '@/utils/Mobile';

const useTemplates = () => {
  const collectionId: number = +router.currentRoute.params.collectionId;
  const isLoading = computed((): boolean =>
    store.getters[GET_IS_TEMPLATES_LOADING]);
  const collection = computed((): Collection =>
    store.getters[GET_SELECTED_COLLECTION]);
  const templates = computed((): Template[] => store.getters[GET_TEMPLATES]);
  const isEditingRights = computed((): boolean =>
    store.getters[GET_IS_EDITING_RIGHTS_TEMPLATES]);
  
  const isCollectionExists = computed((): boolean => !!collection.value);
  const sort = computed((): SortItems =>
    store.getters[GET_TEMPLATES_SELECTED_SORT]);
  
  const currentPage = computed((): number =>
    +router.currentRoute.query.page);
  
  const amountOfPages = computed((): number =>
    store.getters[GET_TEMPLATES_AMOUNT_OF_PAGES]);
  
  const isShowPagination = computed((): boolean =>
    amountOfPages.value > 1 &&
    isCollectionExists.value &&
    !isLoading.value &&
    !isTabletAndLower,
  );
  const isShowMoreButtonActive = computed((): boolean =>
    amountOfPages.value > 1 &&
    currentPage.value < amountOfPages.value &&
    isCollectionExists.value &&
    !isLoading.value &&
    isTabletAndLower,
  );
  const isTemplatesListVisible = computed((): boolean =>
    isCollectionExists.value &&   
    isTabletAndLower
      ? !!templates.value.length
      : !!templates.value.length && !isLoading.value);

  const setCurrentPage = (
    newPage: number,
    replaceRoute?: boolean,
  ): Promise<Route> | void => {
    const routeQuery = {
      query: {
        ...router.currentRoute.query,
        page: newPage.toString(),
      },
    };
    if (currentPage.value === newPage) return;
    return replaceRoute
      ? router.replace(routeQuery)
      : router.push(routeQuery);
  };

  const preparePage = async (): Promise<void> => {
    if (!currentPage.value)
      await setCurrentPage(1, true);
    await initTemplates();
  };
  
  const initTemplates = async (isLoadMore?: boolean): Promise<void> => {
    const loadPreviousTemplates = isTabletAndLower
      && currentPage.value && currentPage.value > 1;
    
    const numberOfCardsToLoad = isTabletAndLower
      ? AmountOfCards.mobile
      : AmountOfCards.desktop;
    
    const limit = loadPreviousTemplates
      ? currentPage.value * AmountOfCards.mobile
      : numberOfCardsToLoad;
    
    const defaultTemplatesPayload = {
      collectionId,
      selectedSort: sort.value,
    };

    const initTemplatesPayload = {
      ...defaultTemplatesPayload,
      limit,
      page: loadPreviousTemplates ? 1 : currentPage.value,
    };

    const loadMoreTemplatesPayload = {
      ...defaultTemplatesPayload,
      limit: numberOfCardsToLoad,
      page: currentPage.value,
    };

    const shouldLoadMoreItems = isLoadMore && isTabletAndLower;

    shouldLoadMoreItems
      ? await store.dispatch(LOAD_MORE_TEMPLATES, loadMoreTemplatesPayload)
      : await store.dispatch(INIT_TEMPLATES, initTemplatesPayload);
    
    const shouldCheckPageExceedMaxPages =
      !isLoadMore && !isTabletAndLower && amountOfPages.value > 0;
    
    if (shouldCheckPageExceedMaxPages &&
      currentPage.value > amountOfPages.value)
      setCurrentPage(1, true);
    
    scrollToTop();
  };

  const handleShowMoreButtonClick = (): void => {
    setCurrentPage(currentPage.value + 1);
  };

  const handleSortChange = (value: SortItems): void => {
    if (currentPage.value !== 1 || isTabletAndLower) {
      store.dispatch(CLEAR_TEMPLATES);
      setCurrentPage(1);
    }
    store.dispatch(SET_TEMPLATES_SELECTED_SORT, value);
    initTemplates();
  };
    
  const handlePageChange = (value: number): void => {
    if (value === currentPage.value) return;
    setCurrentPage(value);
  };

  const resetState = (): void => {
    store.dispatch(RESET_TEMPLATE_SORTING);
    isTabletAndLower && store.dispatch(CLEAR_TEMPLATES);
  };
  
  watch(currentPage, (newPage): Promise<void> =>
    initTemplates(Boolean(newPage)));
  onMounted(preparePage);
  onBeforeUnmount(resetState);

  return {
    amountOfPages,
    collection,
    currentPage,
    handlePageChange,
    handleShowMoreButtonClick,
    handleSortChange,
    isEditingRights,
    isLoading,
    isShowMoreButtonActive,
    isShowPagination,
    isTabletAndLower,
    isTemplatesListVisible,
    secondaryViolet,
    SkeletonCards,
    SkeletonTypes,
    sort,
    templates,
    initTemplates,
  };
};

export default useTemplates;
