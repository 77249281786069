import { reactive } from 'vue';
import { SkeletonTypes } from '@/types';

const useSkeletonLoader = ({ skeletonType }) => {
  const computedSkeleton = reactive({
    cards: false,
    upload: false,
  });

  switch (skeletonType) {
  case SkeletonTypes.cards:
    computedSkeleton.cards = true;
    break;
  case SkeletonTypes.upload:
    computedSkeleton.upload = true;
    break;
  default:
    computedSkeleton.cards = true;
    break;
  }

  return {
    computedSkeleton,
  };
};

export default useSkeletonLoader;
