<template>
  <div
    ref="editorContainer"
    class="object-wrapper"
    :class="{
      'object-wrapper_shopify': isCustomizationFrame,
    }"
  >
    <EditorHeader
      v-if="!isIframe"
      :is-public="isPublic"
      :is-loading="isEditorLoading"
      :is-scan-loading="isScanLoading"
      :is-shopify-loading="isShopifyLoading"
      @getScan="requestScan"
      @sendToShopify="sendToShopify"
    />
    <div
      v-if="isImageLoading"
      class="image-loader"
      :class="{
        'image-loader_shopify': isCustomizationFrame,
        'image-loader_mobile': isMobile,
        'image-loader_shopify-mobile': isMobile && isCustomizationFrame
      }"
    />
    <TemplateDesignSidebar
      v-if="isDesktopSidebarVisible && isDesignMode && !isIframe"
    />
    <EditorSideControls
      v-if="isDesktopSidebarVisible && !isDesignMode"
      :is-customization-frame="isCustomizationFrame"
    />
    <AddToOrderPanel
      v-if="isCustomizationFrame"
      :template-name="template.name"
      :is-loading="isAddToOrderLoading"
      :is-mobile="isMobile"
      :is-object-selected="(isTextActive || isImageActive) && isEditorActive"
      @addToShopifyOrder="addToShopifyOrder"
    />
    <div style="display: none !important">
      <canvas id="texture-canvas" />
    </div>
    <div
      class="editor3d__overflow-controls"
    >
      <AddToMyCollectionsModal 
        v-if="isAddToMyCollectionsModalOpen"
        :is-open="isAddToMyCollectionsModalOpen"
        :template="template"
      />
      <AddFontModal
        v-if="isAddFontModalOpen && !isIframe"
        :is-open="isAddFontModalOpen"
      />
      <RemoveFontModal
        v-if="isRemoveFontModalOpen && !isIframe"
      />
      <ViewSwitcher
        v-if="isControlsVisible"
        :is-shopify-page="isCustomizationFrame"
        :disabled="!activeMesh"
      />
      <SceneControls
        v-if="!singleEditableMesh && isControlsVisible"
      />
      <UndoRedoIcons v-if="isMobile" />
      <ChangeModeNote
        v-if="isControlsVisible && singleEditableMesh"
        :is-shopify-page="isCustomizationFrame"
      />
    </div>
    <div
      :class="['editor3d_container', {
        'height-mobile': isMobile && isEditorActive,
        'height-mobile_shopify':
          isMobile && isCustomizationFrame,
        'height-mobile_shopify-editor':
          isMobile && isEditorActive && isCustomizationFrame,
        'full-width': !isEditorActive || isMobile,
        'selected-textbox': isMobile && isTextActive && isEditorActive,
        'selected-image': isMobile && isImageActive && isEditorActive,
        'selected-textbox_shopify':
          isMobile && isTextActive && isCustomizationFrame && isEditorActive,
        'selected-image_shopify':
          isMobile && isImageActive && isCustomizationFrame && isEditorActive,
      }]"
    >
      <div 
        v-if="isEditorLoading"
        class="main-loader"
        :class="{'main-loader_shopify': isCustomizationFrame}"
      >
        <img 
          :src="editorLoader" 
          :alt="$t('redactor.loader_alt')"
        >
      </div>
      <div
        :class="['editor2d', {
          'editor2d_width-mobile': isMobile
        }]"
      >
        <EditorCanvas
          v-show="isEditorActive"
          :is-shopify-page="isCustomizationFrame"
          :is-image-loading="isImageLoading"
          :is-mobile="isMobile"
          :is-object-selected="(isTextActive || isImageActive)"
        >
          <template
            v-if="isDesignMode"
            #overlay
          >
            <div class="editor__save-template-btn">
              <BaseButton
                :color="primaryViolet"
                :button-text="$t('templates.saveTemplate__text')"
                :disabled="isSaveDesignDisabled"
                large
                no-margin-top
                @click="copyDesign"
              >
                <template
                  #prependSvg
                >
                  <BookMarkIcon
                    class="editor__save-template-btn-icon"
                  />
                </template>
              </BaseButton>
            </div>
          </template>
        </EditorCanvas>
        <div
          v-if="isControlsVisible"
        >
          <EditorControls
            v-if="isEditorActive && !isMobile"
            :is-shopify-page="isCustomizationFrame"
            :style="`transform: ${controlsStyleMods.controlsStyle}`"
          />
          <EditorScaleControls
            v-if="isEditorActive"
            :is-shopify-page="isCustomizationFrame"
            :style="`transform: ${controlsStyleMods.scaleControlsStyle}`"
          />
        </div>
      </div> 
      <Scene
        v-if="isModelReadyToLoad"
        ref="sceneRef"
        class="model-container"
        :class="{'full-height': isIframe}"
        :call-scene-scan="callSceneScan"
        :scene-background="sceneBackground"
        :is-controls-visible="isControlsVisible"
      />
    </div>
    <EditorSideControls
      v-if="isMobile && isEditorActive"
      :is-customization-frame="isCustomizationFrame"
    />
  </div>
</template>

<script lang="ts">
import AddToMyCollectionsModal from '@/components/AddToMyCollectionsModal/AddToMyCollectionsModal.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import AddFontModal from '@/components/Editor/AddFontModal/AddFontModal.vue';
import AddToOrderPanel from '@/components/Editor/AddToOrderPanel/AddToOrderPanel.vue';
import ChangeModeNote from '@/components/Editor/ChangeModeNote/ChangeModeNote.vue';
import EditorCanvas from '@/components/Editor/EditorCanvas/EditorCanvas.vue';
import EditorControls from '@/components/Editor/EditorControls/EditorControls.vue';
import EditorHeader from '@/components/Editor/EditorHeader/EditorHeader.vue';
import EditorScaleControls from '@/components/Editor/EditorScaleControls/EditorScaleControls.vue';
import EditorSideControls from '@/components/Editor/EditorSideControls/EditorSideControls.vue';
import RemoveFontModal from '@/components/Editor/RemoveFontModal/RemoveFontModal.vue';
import Scene from '@/components/Editor/Scene/Scene.vue';
import SceneControls from '@/components/Editor/SceneControls/SceneControls.vue';
import TemplateDesignSidebar from '@/components/Editor/TemplateDesignSidebar/TemplateDesignSidebar.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';
import ViewSwitcher from '@/components/Editor/ViewSwitcher/ViewSwitcher.vue';
import BookMarkIcon from '@/components/icons/BookMarkIcon.vue';
import useEditor from './Editor';

export default {
  components: {
    AddFontModal,
    AddToMyCollectionsModal,
    AddToOrderPanel,
    ChangeModeNote,
    EditorCanvas,
    Scene,
    EditorControls,
    EditorHeader,
    EditorScaleControls,
    EditorSideControls,
    RemoveFontModal,
    UndoRedoIcons,
    ViewSwitcher,
    TemplateDesignSidebar,
    BaseButton,
    BookMarkIcon,
    SceneControls,
  },
  props: {
    templateId: [ String, Number ],
  },
  setup() {
    return useEditor();
  },
};
</script>

<style lang="scss" scoped>
@import "Editor";
</style>
