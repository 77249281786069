export const drawCircleWithInnerBorder = ({
  borderColor,
  borderWidth,
  color,
  ctx,
  size,
}: {
  borderColor: string;
  borderWidth: number;
  color: string;
  ctx: CanvasRenderingContext2D;
  size: number;
}) => {
  const outerCircleRadius = size / 2;
  const innerCircleRadius = borderWidth / 2;

  ctx.fillStyle = borderColor;
  ctx.beginPath();
  ctx.ellipse(
    0,
    0,
    outerCircleRadius,
    outerCircleRadius,
    Math.PI / 4,
    0,
    2 * Math.PI,
  );
  ctx.fill();

  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.ellipse(
    0,
    0,
    innerCircleRadius,
    innerCircleRadius,
    Math.PI / 4,
    0,
    2 * Math.PI,
  );
  ctx.fill();
};
