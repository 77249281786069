<template>
  <MainLayout>
    <div class="templates-wrapper">
      <div class="templates-wrapper__templates-list">
        <SkeletonLoader
          v-if="!isTabletAndLower && isLoading"
          :cards-count="SkeletonCards.desktop"
          :skeleton-type="SkeletonTypes.cards" 
        />
        <TemplatesHeader
          v-if="!isLoading"
          :collection="collection"
          :sort="sort"
          :templates="templates"
          @sortChange="handleSortChange"
        />
        <TemplatesList
          v-if="isTemplatesListVisible"
          :is-loading="isLoading"
          :collection="collection"
          :templates="templates"
          :is-editing-rights="isEditingRights"
          @triggerInitTemplates="initTemplates"
        />
        <TemplatesNotExists v-if="!isLoading && !templates.length" />
        <SpinnerLoader
          v-if="isTabletAndLower && isLoading"
          class="templates-wrapper__templates-list__spinner"
        />
        <v-pagination
          v-if="isShowPagination"
          class="templates-wrapper__templates-list__pagination"
          :value="currentPage"
          :length="amountOfPages"
          :color="secondaryViolet"
          :total-visible="7"
          circle
          @input="handlePageChange"
        />
        <BaseButton
          v-if="isShowMoreButtonActive"
          class="templates-wrapper__templates-list__show-more__btn"
          :button-text="$t('collections.showMore__text')"
          light
          has-background
          color="white"
          @click="handleShowMoreButtonClick"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
import SpinnerLoader from '@/components/SpinnerLoader/SpinnerLoader.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useTemplates from './Templates';
import TemplatesHeader from './TemplatesHeader/TemplatesHeader.vue';
import TemplatesList from './TemplatesList/TemplatesList.vue';
import TemplatesNotExists from './TemplatesNotExists/TemplatesNotExists.vue';

export default {
  components: {
    BaseButton,
    MainLayout,
    SkeletonLoader,
    SpinnerLoader,
    TemplatesHeader,
    TemplatesList,
    TemplatesNotExists,
  },
  setup() {
    return useTemplates();
  },
};
</script>

<style lang="scss" scoped>
@import 'Templates';
</style>
