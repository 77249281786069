
import { canvasConfigsMutations } from './canvasConfigsMutations';
import { canvasHistoryMutations } from './canvasHistoryMutations';
import { editorConfigMutations } from './editorConfigMutations';
import { fontsConfigsMutations } from './fontsConfigsMutations';
import { sceneMutations } from './modelDataMutations';
import { templateDesignMutations } from './templateDesignMutations';

export const mutations = {
  ...editorConfigMutations,
  ...canvasHistoryMutations,
  ...canvasConfigsMutations,
  ...templateDesignMutations,
  ...sceneMutations,
  ...fontsConfigsMutations,
};
