<template>
  <div
    class="rotation-mobile"
    :class="{disabled: isDisabled}"
  >
    <span>
      {{ $t("redactor.rotation__label") }}
    </span>
    <TextField
      :value="rotation"
      input-type="number"
      class="rotation-input"
      outlined
      suffix=" °"
      @input.native="$emit('changeRotation', $event)"
      @focus="$emit('saveInitialRotationInHistory')"
    />
  </div>
</template>

<script lang="ts">
import TextField from '@/components/Common/TextField/TextField.vue';

export default {
  components: {
    TextField,
  },
  props: {
    rotation: {
      required: true,
      type: Number,
    },
    isDisabled: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "RotationMobile";
</style>
