import { useI18n } from 'vue-i18n-composable';
import { ShopifyAPI } from '@/services/ShopifyAPI';
import { UserAPI } from '@/services/UserAPI';
import store from '@/store';
import {
  ACTIVATE_STORE,
  GET_SHOPIFY_STORE_INFO,
  GET_STORE,
  GET_THEMES,
  RESET_CHANGES,
  RESET_STORE,
  REVERT_STORE,
  REVERT_THEME,
  SEND_TO_SHOPIFY,
  SET_IS_DEACTIVATED_STORE,
  SET_IS_DEACTIVATING_LOADING,
  SET_IS_THEMES_LOADING,
  SET_SHOPIFY_STORE_INFO,
  SET_STORE,
  SET_THEMES,
  UPDATE_THEME,
} from '@/store/Shopify/constants';
import { Context, ShopifyStore } from '@/store/Shopify/types';
import { Template } from '@/store/Templates/types';
import { NotificationType } from '@/types/notification';
import { handleResponse } from '@/utils/axios/handleResponse';
import { SET_NOTIFICATION } from '../constants';
import { defaultShopifyStore } from './types';

export const actions = {
  [ACTIVATE_STORE]: async (
    { commit }: Context,
    { 
      storeId,
    }: {
      storeId: number
    },
  ) => {
    commit(SET_IS_DEACTIVATING_LOADING, true);
    const { t } = useI18n();

    const response = await ShopifyAPI.activateStore(storeId);
    const successStatus = 204;
    const handleSuccessStatus = (): void => {
      commit(SET_IS_DEACTIVATED_STORE, false);
      commit(SET_IS_DEACTIVATING_LOADING, false);
      store.dispatch(SET_NOTIFICATION, {
        text: t('integrationSystems.shopify.successfullyRestored__text'),
        type: NotificationType.Success,
      });
    };
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus,
    });

    return response.status === successStatus;
  },
  [GET_STORE]: async (
    { commit }: Context,
    {
      userId,
    }: {
      userId: number
    },
  ) => {
    const response = await UserAPI.getStore(userId);

    const handleSuccessStatus = ({ store }: ShopifyStore) => {
      commit(SET_STORE, store);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [RESET_STORE]: async (
    { commit }: Context,
  ) => {
    commit(SET_STORE, defaultShopifyStore);
  },
  [GET_SHOPIFY_STORE_INFO]: async (
    { commit }: Context,
    storeId: number,
  ) => {
    const response = await ShopifyAPI.getStoreInfo(
      storeId,
    );

    const handleSuccessStatus = (
      data: {
        website: string,
      },
    ): void => commit(SET_SHOPIFY_STORE_INFO, data);
    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [GET_THEMES]: async (
    { commit }: Context,
    { 
      storeId,
    }: {
      storeId: number
    },
  ) => {
    const response = await ShopifyAPI.getThemes(storeId);
    const handleSuccessStatus = (themes): void => 
      commit(SET_THEMES, themes);

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
    
    return response.data;
  },
  [RESET_CHANGES]: async (
    { commit }: Context,
    {
      currentThemeId,
      storeId,
      themes,
    }) => {
    commit(SET_IS_DEACTIVATING_LOADING, true);
    
    if (!currentThemeId) return;
    const isStoreReverted = await store.dispatch(REVERT_STORE, {
      storeId,
    });

    if (!isStoreReverted) {
      commit(SET_IS_DEACTIVATING_LOADING, false);
      return;
    }

    for (const theme of themes) {
      const isThemeReverted = await store.dispatch(REVERT_THEME, {
        storeId,
        themeId: theme.id,
      });
      if (!isThemeReverted) {
        commit(SET_IS_DEACTIVATING_LOADING, false);
        return;
      }
    }

    commit(SET_IS_DEACTIVATED_STORE, true);
    commit(SET_IS_DEACTIVATING_LOADING, false);
  },
  [REVERT_STORE]: async (
    _,
    { 
      storeId,
    }: {
      storeId: number
    },
  ) => {
    const response = await ShopifyAPI.revertStore(storeId);
    const successStatus = 204;
    handleResponse({
      response,
      successStatus,
    });

    return response.status === successStatus;
  },
  [REVERT_THEME]: async (
    _,
    { 
      storeId,
      themeId,
    }: {
      storeId: number
      themeId: number
    },
  ) => {
    const response = await ShopifyAPI.revertTheme(storeId, themeId);
    const successStatus = 204;

    handleResponse({
      response,
      successStatus,
    });

    return response.status === successStatus;
  },
  [SEND_TO_SHOPIFY]: async (
    _,
    { 
      scanLink,
      templateId,
      templateName,
    }: {
      scanLink: string,
      templateId: Template['id'],
      templateName: string
    },
  ) => {
    const { t } = useI18n();
    const response = await ShopifyAPI.addToShopify({
      scanLink,
      templateId,
    });
    const handleSuccessStatus = (): void => {
      store.dispatch(SET_NOTIFICATION, {
        text: t('integrationSystems.shopify.productHasBeenAddedToShopify', { product: templateName }),
        type: NotificationType.Success,
      });
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
  },
  [UPDATE_THEME]: async (
    { commit }: Context,
    { 
      storeId,
      themeId,
    }: {
      storeId: number
      themeId: number
    },
  ) => {
    commit(SET_IS_THEMES_LOADING, true);

    const response = await ShopifyAPI.updateTheme(storeId, themeId);

    handleResponse({
      response,
      successStatus: 200,
    });
    commit(SET_IS_THEMES_LOADING, false);

    return response.data;
  },
};
