import { AxiosResponse } from 'axios';
import { Ref } from 'vue';
import { UpdateTemplateDetails } from '@/store/Templates/types';
import { AmountOfCards, SortItems } from '@/types';
import { instance } from '@/utils/axios/instance';
import { isTabletAndLower } from '@/utils/Mobile';
import { model3dData } from '@/views/UploadPage/types';

export const TemplatesAPI = {
  changeTemplateCover: async (
    { collectionId, id, image }: {
      collectionId: number,
      id: number,
      image: File
    },
  ): Promise<AxiosResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', image);
            
      const response = await instance.put(
        `/template/change-preview-template/${collectionId}/${id}`,
        formData,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  checkGoogleFont: async (
    link: string,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(
        'fonts',
        {
          fontLink: link,
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  copyTemplate: async(
    data: {
      collectionId: number,
      newTemplateName: string,
      templateId: number
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post('/template/copy', data);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  editTemplateName: async (
    { id, name }: {
      id: number,
      name: string
    },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/template/name/${id}`, { name });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  fetchUpload3dModel: async (
    data: FormData,
    persents: Ref<number>,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(
        'upload/3dModel',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: any) => {
            persents.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
          },
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getAllTemplatesInCollection: async(
    id: number,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/collection/${id}/templates/all`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getTemplate: async (id: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.get(`/template/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  initTemplates: async (
    { collectionId, limit, page, selectedSort }: {
      collectionId: number,
      limit: number,
      page: number,
      selectedSort: SortItems
    },
  ): Promise<AxiosResponse> => {
    try {
      const numberOfItemsPerDevice = isTabletAndLower
        ? AmountOfCards.mobile
        : AmountOfCards.desktop;
      const response = await instance.get(`/collection/${collectionId}/templates`, {
        params: {
          limit,
          page,
          selectedSort,
          numberOfItemsPerDevice,
        },
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  removeTemplate: async (id: number): Promise<AxiosResponse> => {
    try {
      const response = await instance.delete(`/template/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  saveEditedTemplate: async (
    {
      canvasData,
      customFonts,
      id,
    }: {
        canvasData: string,
        customFonts: string,
        id: number
      },
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(`/template/update3dModel/${id}`, {
        canvasData,
        customFonts,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  submitTemplate: async (
    data: model3dData,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(
        '/template',
        data,
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
  updateProductDetails: async (
    { id, productDetails, template }: UpdateTemplateDetails,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.put(`/template/${id}/details`, {
        productDetails,
        template,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  uploadOverlayImage: async (
    data: FormData,
    percents?: Ref<number>,
  ): Promise<AxiosResponse> => {
    try {
      const response = await instance.post(
        'upload/image',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: any) => {
            if (!percents) return;
            percents.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
          },
        },
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
