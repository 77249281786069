<template>
  <div class="not-exists_wrapper">
    <div class="not-exists_wrapper__title">
      <span class="not-exists_wrapper__title-text">
        {{ message }}
      </span>
    </div>
    <div class="not-exists_wrapper__image">
      <img 
        src="@/assets/images/no-collections-plug.png" 
        :alt="$t('collections.noCollections__alt')"
      >
    </div>
    <BaseButton
      v-if="!isPublic"
      class="not-exists_wrapper__button"
      :button-text="$t('collections.createNewCollection__text')"
      prepend-icon="mdi-plus"
      large
      @click="$emit('addCollection')"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useCollectionsNotExists from './CollectionsNotExists';

export default {
  components: {
    BaseButton,
  },
  props: { collectionType: String },
  setup(props) {
    return useCollectionsNotExists(props);
  },
};
</script>

<style lang="scss" scoped>
@import 'CollectionsNotExists';
</style>
