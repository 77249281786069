<template>
  <v-app>
    <v-main>
      <div class="global-alert">
        <Transition
          name="fade"
          appear
        >
          <v-alert
            v-if="isNotificationVisible"
            class="global-alert__item"
            :type="notificationType"
            dismissible
            dense
          >
            <template v-if="notificationText">
              {{ notificationText }}
            </template>
            <i18n
              v-if="notificationLocalization.path"
              :path="notificationLocalization.path"
              tag="span"
            >
              <template
                v-for="localizedSlot in notificationLocalization.slots"
                #[localizedSlot.key]
              >
                <span :key="localizedSlot.key">
                  <router-link
                    v-if="localizedSlot.link"
                    :to="getLinkValue(localizedSlot)"  
                  > 
                    <span class="global-alert__item-link">
                      {{ localizedSlot.content }}
                    </span>
                  </router-link>
                  <template v-else>
                    {{ localizedSlot.content }}
                  </template>
                </span>
              </template>
            </i18n>
            <template #close="{ toggle }">
              <BaseButton
                class="global-alert__item-close"
                no-margin-top
                icon="mdi-close"
                @click="closeNotification(toggle)"
              />
            </template>
          </v-alert>
        </Transition>
      </div>
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useApp from './App';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return useApp();
  },
};
</script>
<style lang="scss" scoped>
@import 'App';
</style>
