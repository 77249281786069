<template>
  <header 
    class="editor-header"
  >
    <div class="left-menu">
      <BaseButton
        class="left-menu__back-button"
        color="white"
        tile
        plain
        dark
        no-margin-top
        @click="$router.go(-1)"
      >
        <template #prependSvg>
          <img src="@/assets/icons/back-icon.svg">
        </template>
      </BaseButton>
    </div>
    <div class="template-name">
      <div v-if="!isNameEditing">
        <div class="template-name_initial">
          <v-tooltip bottom>
            <template
              #activator="{ on, attrs }"
            >
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ templateName }}
              </span>
            </template>
            <span>{{ templateName }}</span>
          </v-tooltip>
        </div>
      </div>

      <v-form
        v-else
        :value="isValidName"
        @input="$emit('setIsValidName', $event)"
      >
        <div
          v-click-outside="{
            handler: () => $emit('saveEditedName'),
            include: () => ignorableClickOutsideElements
          }"
          class="template-name_edit"
        >
          <TextField
            dark
            autofocus
            color="white"
            input-type="text"
            :value="templateName"
            :outlined="false"
            :rules="templateNameRules"
            @keydown.enter.stop.prevent="$emit('saveEditedName')"
            @input="$emit('setTemplateName', $event)"
          >
            <template #append>
              <BaseButton
                icon="mdi-check"
                dark
                color="white"
                no-margin-top
                :disabled="!isValidName"
                @click="$emit('saveEditedName')"
              />
            </template>
          </TextField>
        </div>
      </v-form>
    </div>
    <div class="right-menu">
      <div 
        class="right-menu__icons"
        :class="{'right-menu__icons_public': isPublic}"
      >
        <v-menu
          bottom
          left
          dark
          close-on-click
        >
          <template #activator="{ on, attrs }">
            <BaseButton
              color="white"
              no-margin-top
              height="80"
              tile
              plain
              dark
              v-bind="attrs"
              v-on="on"
            >
              <template #prependSvg>
                <v-icon>mdi-dots-horizontal</v-icon>
              </template>
            </BaseButton>
          </template>
          <v-list v-show="!isLoading">
            <div v-if="isPublic">
              <v-list-item>
                <v-list-item-title @click="$emit('openAddToMyCollectionModal')">
                  {{ $t("redactor.addToMyCollections__text") }} 
                </v-list-item-title>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item @click="$emit('toggleIsNameEditing')">
                <v-list-item-title>
                  {{ $t("redactor.rename__button") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('sendToShopifyApp')">
                <v-list-item-title>
                  {{ $t("redactor.add_to_shopify__text") }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </div>
      <BaseButton
        v-if="!isPublic"
        v-show="!isLoading"
        class="right-menu__save-button_mobile"
        color="white"
        tile
        plain
        dark
        no-margin-top
        @click="$emit('saveTemplate')"
      >
        <template #prependSvg>
          <img src="@/assets/icons/save-icon.svg">
        </template>
      </BaseButton>
      <BaseButton
        v-if="!isPublic"
        v-show="!isLoading"
        dark
        rounded
        large
        class="right-menu__save-button_tablet"
        no-margin-top
        :is-loading="isSaving"
        :button-text="$t('redactor.save__button')"
        @click="$emit('saveTemplate')"
      />
      <BaseButton
        v-if="isPublic"
        v-show="!isLoading"
        dark
        rounded
        large
        class="right-menu__save-button_tablet"
        no-margin-top
        :is-loading="isSaving"
        :button-text="$t('redactor.addToMyCollections__text')"
        @click="$emit('openAddToMyCollectionModal')"
      />
    </div>
  </header>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';

export default {
  components: {
    BaseButton,
    TextField,
  },
  props: {
    isNameEditing: {
      required: true,
      type: Boolean,
    },
    isSaving: {
      required: true,
      type: Boolean,
    },
    isPublic: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    isValidName: {
      required: true,
      type: Boolean,
    },
    templateName: {
      type: String,
    },
    templateNameRules: {
      required: true,
      type: Array,
    },
    white50: {
      required: true,
      type: String,
    },
  },
  setup() {
    const ignorableClickOutsideElements = [document.querySelector('.global-alert')];
    return {
      ignorableClickOutsideElements,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "EditorHeaderMobile";
</style>
