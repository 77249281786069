import { enlargeCanvasParams } from '@/components/Editor/helpers/types';
import { CanvasHeights } from '@/components/Editor/types/editor';
import { modelQualityFactor } from '@/constants/editor';

export const enlargeCanvas = ({
  canvasFromEditor,
  isForScan,
  pdfSizeInPx,
  targetCanvas,
}: enlargeCanvasParams): void => {
  let multiplier: number;
  let zoom: number;
  const canvasHeight = canvasFromEditor.getHeight();
  const canvasWidth = canvasFromEditor.getWidth();
  const zoomToDefault = canvasHeight / CanvasHeights.default;
  if (isForScan && pdfSizeInPx) {
    targetCanvas.setDimensions({
      height: pdfSizeInPx.height,
      width: pdfSizeInPx.width,
    });
    targetCanvas.setZoom(pdfSizeInPx.height / CanvasHeights.default);
  } else {
    multiplier = modelQualityFactor;
    zoom = multiplier * zoomToDefault;
    targetCanvas.setDimensions({
      height: canvasHeight * multiplier,
      width: canvasWidth * multiplier,
    });
    targetCanvas.setZoom(zoom);
    targetCanvas.renderAll();
  }
};
