import { actions } from '@/store/Shopify/actions';
import { getters } from '@/store/Shopify/getters';
import { mutations } from '@/store/Shopify/mutations';
import { defaultShopifyStore, ShopifyState } from '@/store/Shopify/types';

const state: ShopifyState = {
  isStoreResetting: false,
  isThemesLoading: false,
  shopifyStore: defaultShopifyStore,
  themes: [],
  website: '',
};

export default {
  actions,
  getters,
  mutations,
  state,
};
