import { computed } from 'vue';
import {
  azure,
  coral,
  primaryViolet,
  redDanger,
  ruby,
  shimmeringBlue,
  success,
  tangerine,
} from '@/constants/colors';
import { OrderStatus } from '@/store/Orders/types';

export const useOrderStatusChip = (props: { status: OrderStatus }) => {
  const colorsByStatus: Record<OrderStatus, string> = {
    [OrderStatus.Completed]: success,
    [OrderStatus.Cancelled]: redDanger,
    [OrderStatus.Error]: ruby,
    [OrderStatus.Paid]: shimmeringBlue,
    [OrderStatus.PrintReady]: primaryViolet,
    [OrderStatus.Processing]: tangerine,
    [OrderStatus.Shipped]: azure,
    [OrderStatus.SubError]: coral,
  };

  const variation = computed(() => {
    const textColor = colorsByStatus[props.status];
    const color = `${textColor}33`;
    return {
      color,
      textColor,
    };
  });

  return {
    variation,
  };
};
