import { computed, ref, watch } from 'vue';
import cursorLogo from '@/assets/icons/cursor.svg';
import { primaryViolet } from '@/constants/colors';
import { Editor3DViewIconData, EditorViewIconData } from '@/constants/editor';
import store from '@/store';
import {
  ACTIVATE_EDITOR,
  GET_ACTIVE_MESH,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_IFRAME,
  GET_IS_MOBILE,
  GET_IS_SETTINS_DISABLED,
  GET_IS_TEXTURE_UPDATED,
  SET_IS_TEXTURE_UPDATED,
} from '@/store/Editor/constants';
import { updateCanvasTexture } from '../helpers/Three/updateCanvasTexture';
import { MeshData } from '../types/editor';
import { scrollToBottom } from '../utils/editorScrollPage';

const useViewSwitcher = () => {
  const touchStartY = ref(0);
  const isShowEditor = ref(false);

  const editorActive = computed(
    (): boolean => store.getters[GET_IS_EDITOR_ACTIVE],
  );
  const isIframe = computed((): boolean => store.getters[GET_IS_IFRAME]);
  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);
  const editor3DViewActive = computed((): boolean => !editorActive.value);
  const editorViewColor = computed((): string =>
    editorActive.value ? primaryViolet : 'black',
  );
  const Editor3DViewColor = computed((): string =>
    editor3DViewActive.value ? primaryViolet : 'black',
  );
  const isSettingsDisabled = computed(
    (): boolean => store.getters[GET_IS_SETTINS_DISABLED],
  );
  const isTextureUpdated = computed(
    (): boolean => store.getters[GET_IS_TEXTURE_UPDATED],
  );
  const activeMesh = computed(
    (): MeshData => store.getters[GET_ACTIVE_MESH],
  );

  const setTouchStartY = (event: TouchEvent): void => {
    touchStartY.value = event.touches[0].pageY;
  };

  const moveScrollToBottom = (event: TouchEvent): void => {
    if (touchStartY.value - event.touches[0].pageY > 0) {
      touchStartY.value = 0;
      return scrollToBottom();
    }
  };

  const toggleScrollForEditorView = (flag: boolean): void => {
    if (flag) {
      window.addEventListener('touchstart', setTouchStartY);
      window.addEventListener('touchmove', moveScrollToBottom);
    } else {
      window.removeEventListener('touchstart', setTouchStartY);
      window.removeEventListener('touchmove', moveScrollToBottom);
    }
  };

  const goToEditorView = (): void => {
    isShowEditor.value = true;
  };

  const goTo3DView = (): void => {
    isShowEditor.value = false;
    store.commit(SET_IS_TEXTURE_UPDATED, false);
    updateCanvasTexture(activeMesh.value);
  };

  watch([ isShowEditor, isTextureUpdated ], () => {
    if (isTextureUpdated.value) {
      store.dispatch(ACTIVATE_EDITOR, isShowEditor.value);
    }
  });

  return {
    editor3DViewActive,
    Editor3DViewColor,
    Editor3DViewIconData,
    editorActive,
    editorViewColor,
    EditorViewIconData,
    goTo3DView,
    goToEditorView,
    isMobile,
    isSettingsDisabled,
    cursorLogo,
    isIframe,
    isTextureUpdated,
    primaryViolet,
  };
};

export default useViewSwitcher;
