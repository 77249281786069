<template>
  <div
    v-show="!isUndoRedoHidden"
    ref="undoRedoIcons"
    :class="[
      'undo-redo-icons',
      {
        'undo-redo-icons_mobile': isMobile,
        'undo-redo-icons_iframe': isIframe
      }
    ]"
  >
    <BaseButton
      no-margin-top
      apply-color-to-text
      :disabled="isUndoDisabled"
      @click="undo"
    >
      <template #prependSvg>
        <BaseIcon
          :color="isIframe ? 'black' : 'white'"
          :disabled="isUndoDisabled"
        >
          <UndoIcon />
        </BaseIcon>
      </template>
    </BaseButton>
    <BaseButton
      no-margin-top
      apply-color-to-text
      :disabled="isRedoDisabled"
      @click="redo"
    >
      <template #prependSvg>
        <BaseIcon
          :color="isIframe ? 'black' : 'white'"
          :disabled="isRedoDisabled"
        >
          <RedoIcon />
        </BaseIcon>
      </template>
    </BaseButton>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { useUndoRedoIcons } from '@/components/Editor/UndoRedoIcons/UndoRedoIcons';
import RedoIcon from '@/components/icons/RedoIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';

export default {
  components: {
    BaseButton,
    BaseIcon,
    RedoIcon,
    UndoIcon,
  },
  setup() {
    return useUndoRedoIcons();
  },
};
</script>

<style lang="scss" scoped>
@import "UndoRedoIcons";
</style>
