var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'editor-canvas',
    {
      'editor-canvas_light': _vm.isShopifyPage,
      'editor-canvas_shopify-mobile': _vm.isShopifyPage && _vm.isMobile,
    }
  ],on:{"click":function($event){$event.stopPropagation();return _vm.discardObjectSelection.apply(null, arguments)}}},[_c('div',{ref:"canvasContainer",class:[
      'fabric-canvas',
      {
        'fabric-canvas_object-selected': _vm.isObjectSelected,
        'disabled': _vm.isImageLoading
      }
    ],on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("overlay")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }