export const rgbToHex = (
  r: number,
  g: number,
  b: number,
) => '#' + [ r, g, b ]
  .map(x => {
    const hex = x.toString(16).toUpperCase();
    return hex.length === 1 ? '0' + hex : hex;
  })
  .join('');
