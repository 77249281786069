<template>
  <AuthLayout :is-full-height="isFullHeight">
    <SignUpForm />
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import SignUpForm from './SignUpForm/SignUpForm.vue';

export default {
  components: {
    AuthLayout,
    SignUpForm,
  },
  data() {
    return {
      isFullHeight: false,
      minInnerWidth: 1440,
    };
  },
  mounted() {
    this.$nextTick(() => {
        
      if (window.innerWidth <= this.minInnerWidth) {
        this.setIsFullHeight(true);
      }
    });
  },
  methods: {
    setIsFullHeight(flag) {
      this.isFullHeight = flag;
    },
  },
};
</script>
