<template>
  <div class="collection-list">
    <div
      v-if="collections.length"
      class="collection-list__header"
    >
      <h1 class="collection-list__header-title">
        {{ heading }}
      </h1>
      <div class="collection-list__header-sort">
        <span class="collection-list__header-sort__label">
          {{ $t('collections.sort__label') }}
        </span>
        <BaseSelect
          class="collection-list__header-sort__select"
          :value="sort"
          :items="sortItems"
          :menu-props="menuSettings"
          :color="primaryViolet"
          background-color="transparent"
          attach
          @input="$emit('sortChange', $event)"
        />
      </div>
    </div>
    <div class="collection-list__grid-wrapper">
      <CollectionCard
        v-for="collection in collections"
        :key="collection.imagePath
          ? collection.imagePath + collection.id
          : collection.id"
        :collection="collection"
        :is-editing-rights="isEditingRights"
        :collection-type="collectionType"
        @openConfirmRemoveModal="openConfirmRemoveModal"
      />
    </div>
    <div 
      v-if="isShowAddCollectionButton"
      class="collection-list__add-collection" 
    >
      <BaseButton
        v-if="isMobile"
        class="collection-list__add-collection__btn"
        :button-text="$t('collections.createNewCollection__text')"
        prepend-icon="mdi-plus"
        no-margin-top
        large
        @click="$emit('addCollection')"
      />
      <BaseButton
        v-else
        fab
        class="collection-list__add-collection__button"
        icon="mdi-plus"
        :icon-size="26"
        @click="$emit('addCollection')"
      />
    </div>
    <ConfirmRemoveCollectionModal
      v-if="isConfirmRemoveModalOpen"
      :is-open="isConfirmRemoveModalOpen"
      :collection="selectedCollection"
      :templates-in-collection="templatesInCollection"
      @triggerInitCollections="$emit('triggerInitCollections')"
      @close="closeConfirmRemoveModal"
    />
  </div>
</template>

<script lang="ts">
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import CollectionCard from './CollectionCard/CollectionCard.vue';
import useCollectionsList from './CollectionsList';
import ConfirmRemoveCollectionModal from './ConfirmRemoveCollectionModal/ConfirmRemoveCollectionModal.vue';

export default {
  components: {
    BaseButton,
    BaseSelect,
    CollectionCard,
    ConfirmRemoveCollectionModal,
  },
  props: {
    collections: Array,
    collectionType: String,
    isEditingRights: Boolean,
    isLoading: Boolean,
    sort: String,
  },
  setup(props, context) {
    return useCollectionsList(props, context);
  },
};
</script>

<style lang="scss">
@import 'CollectionsList';
</style>
