import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { black54, secondaryViolet } from '@/constants/colors';
import router from '@/router';
import store from '@/store';
import {
  GET_ALL_ORDERS,
  GET_ORDER_LOADING,
  GET_ORDERS,
  GET_ORDERS_AMOUNT_OF_PAGES,
  RESET_ORDERS,
} from '@/store/Orders/constants';
import { Order, OrderState } from '@/store/Orders/types';
import { parseDate } from '@/utils/date';

export const useAdminOrders = () => {
  const { t } = useI18n();

  const tableHeaders = [
    { title: t('orders.orderId__text'), width: '10%' },
    { title: t('orders.status__text'), width: '22%' },
    { title: t('orders.createdAt__text'), width: '23%' },
    { title: t('orders.store__text'), width: '23%' },
    { title: t('orders.products__text'), width: '22%' },
  ];

  const orders = computed<OrderState['orders']>(
    () => store.getters[GET_ORDERS],
  );

  const limit = 8;

  const isLoading = computed<OrderState['isLoading']>(() => store.getters[GET_ORDER_LOADING]);

  const amountOfPages = computed<OrderState['amountOfPages']>(
    () => store.getters[GET_ORDERS_AMOUNT_OF_PAGES],
  );

  const currentPage = ref(1);

  onMounted(() => {
    store.dispatch(GET_ALL_ORDERS);
  });

  const getProducts = (order: Order) => {
    if (!order?.products?.length) return '';
    return order?.products?.map(({ template }) => template.name).join(', ');
  };

  const changePage = (page: number) => {
    store.dispatch(GET_ALL_ORDERS, { page });
    currentPage.value = page;
  };

  const openOrder = (order: Order): void => {
    router.push({ name: 'single-order', params: { id: order.id }});
  };

  onUnmounted(() => {
    store.commit(RESET_ORDERS);
  });

  return {
    changePage,
    orders,
    getProducts,
    parseDate,
    secondaryViolet,
    amountOfPages,
    currentPage,
    black54,
    isLoading,
    limit,
    tableHeaders,
    openOrder,
  };
};
