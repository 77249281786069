<template>
  <div :class="['base-checkbox', noMarginTop && 'no-margin-top']">
    <v-checkbox
      width="5"
      height="5"
      hide-details
      :value="value"
      :input-value="value"
      :color="primaryViolet"
      :rules="required ? checkboxRules : []"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import { primaryViolet } from '@/constants/colors';
import { checkboxRequired } from '@/utils/FieldValidationRules/index';

export default {
  props: {
    noMarginTop: {
      required: false,
      type: Boolean,
    },
    required: {
      required: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      checkboxRules: [checkboxRequired],
      primaryViolet,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import 'BaseCheckbox';
</style>
